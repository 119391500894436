import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	NoteStatus,
	OrganismsNcDeleteNote,
	OrganismsNcDeleteNoteVariables,
	OrganismsNcGetNotes,
	OrganismsNcGetNotesVariables,
	OrganismsNcGetNotes_notes_nodes as Note,
	SortEnumType,
	NoteSortInput,
	NoteFilterInput,
} from '@app/codegen';
import { NotesComponentLocators } from '@app/e2e/shared/Notes';
import {
	CustomCard,
	CustomDataTable,
	ITrustColumn,
	ITrustAction,
	useTableState,
} from '@app/shared/components';
import { isFormEmpty } from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppAlert, AppIcon, localDateFormat, useAppState } from '@itrustcapital/ui';
import { Button, Input, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';

import { NewNoteMode, NoteModal } from './NoteModal';

export interface NotesProps {
	accountId?: number;
	userId?: number;
	fundingId?: number;
}

export const ORGANISMS_NC_GET_NOTES = gql`
	query OrganismsNcGetNotes(
		$where: NoteFilterInput
		$order: [NoteSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		notes(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				id
				subject
				text
				userId
				accountId
				createdByUser {
					fullName
				}
				createdAt
			}
			pageInfo {
				startCursor
				endCursor
			}
			totalCount
		}
	}
`;

export const ORGANISMS_NC_DELETE_NOTE = gql`
	mutation OrganismsNcDeleteNote($input: UpdateNoteInput!) {
		updateNote(input: $input) {
			success
			errorMessage
		}
	}
`;

const schema = yup.object({ search: yup.string().default('') }).defined();

export type NoteSearchFormData = yup.TypeOf<typeof schema>;

export function Notes(props: NotesProps) {
	const styles = useCustomStyles();
	const selectedNote = useAppState<Note | null>(null);
	const noteMode = useAppState<NewNoteMode>(null);
	const noteModalVisible = useAppState(false);
	const deleteModalVisible = useAppState(false);
	const searchTerm = useAppState('');
	const defaultWhere: NoteFilterInput = {
		status: { eq: NoteStatus.ACTIVE },
		or: [{ text: { contains: searchTerm.get } }, { subject: { contains: searchTerm.get } }],
		accountId: props.accountId ? { eq: props.accountId } : undefined,
		userId: props.userId ? { eq: props.userId } : undefined,
		fundingId: props.fundingId ? { eq: props.fundingId } : undefined,
	};

	const tableState = useTableState<NoteSortInput, NoteFilterInput>({
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere,
		pagination: true,
		striped: true,
	});
	const form = useForm<NoteSearchFormData>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const columns: ITrustColumn<Note>[] = [
		{
			key: 'createdByUser',
			name: 'Created By',
			sortable: true,
			selector: (row) => row.createdByUser?.fullName,
			sortOverride: (sortDirection) => ({ createdByUser: { fullName: sortDirection } }),
		},
		{
			key: 'createdAt',
			name: 'Created At',
			maxWidth: 180,
			sortable: true,
			selector: (row) => localDateFormat(row.createdAt),
		},
		{
			key: 'subject',
			name: 'Subject',
			minWidth: 200,
			sortable: true,
			selector: (row) => row.subject,
		},
		{
			key: 'text',
			name: 'Text',
			sortable: true,
			selector: (row) => row.text,
		},
	];

	const actions: ITrustAction<Note>[] = [
		{
			name: 'View',
			handler: onView,
			iconLib: 'ion',
			iconName: 'eye',
		},
		{
			name: 'Edit',
			handler: onEdit,
			iconLib: 'matc',
			iconName: 'pencil',
		},
		{
			name: 'Delete',
			handler: onDelete,
			iconLib: 'ion',
			iconName: 'trash',
		},
	];

	const [getNotes, getNotesQuery] = useLazyQuery<
		OrganismsNcGetNotes,
		OrganismsNcGetNotesVariables
	>(ORGANISMS_NC_GET_NOTES, {
		variables: {
			order: tableState.sort.get,
			first: tableState.rowsPerPage.get,
			where: tableState.where.get,
		},
		onCompleted: onGetNotesCompleted,
	});

	const [deleteNote] = useMutation<OrganismsNcDeleteNote, OrganismsNcDeleteNoteVariables>(
		ORGANISMS_NC_DELETE_NOTE,
		{
			variables: {
				input: {
					id: selectedNote.get?.id!,
					status: NoteStatus.DELETED,
				},
			},
		}
	);

	React.useEffect(() => {
		if (props.accountId || props.fundingId || props.userId) {
			getNotes();
		}
	}, [props.accountId, props.fundingId, props.userId]);

	function onGetNotesCompleted(data: OrganismsNcGetNotes): void {
		if (data) {
			tableState.onCompleted(data.notes?.totalCount, data.notes?.pageInfo);
		}
	}

	function onSearch(data: NoteSearchFormData): void {
		const where: NoteFilterInput = tableState.where.get || {};

		tableState.reset();

		if (isFormEmpty(data)) {
			tableState.where.set(defaultWhere);

			return;
		}

		const searchTerms = data.search.split(' ');

		where.or = [
			{
				text: {
					contains: data.search,
				},
			},
			{
				subject: {
					contains: data.search,
				},
			},
			{
				createdByUser: {
					firstName: {
						contains: data.search,
					},
				},
			},
			{
				createdByUser: {
					lastName: {
						contains: data.search,
					},
				},
			},
		];

		if (searchTerms.length > 1) {
			where.or = undefined;
			where.createdByUser = {
				firstName: { eq: searchTerms[0] },
			};
			where.createdByUser = {
				lastName: { eq: searchTerms.slice(1).join(' ') },
			};
		}

		onRefetch(where);
	}

	async function onDeleteNote(): Promise<void> {
		try {
			await deleteNote();
			onRefetch({ ...tableState.where.get });

			deleteModalVisible.set(false);
		} catch (error) {}
	}

	function onRefetch(where: NoteFilterInput) {
		getNotesQuery?.refetch?.({
			after: undefined,
			before: undefined,
			last: undefined,
			first: tableState.rowsPerPage.get,
			order: [{ createdAt: SortEnumType.DESC }],
			where,
		});

		tableState.reset();
	}

	function onView(row: Note): void {
		selectedNote.set(row);
		noteModalVisible.set(!noteModalVisible.get);
		noteMode.set('View');
	}

	function onEdit(row: Note): void {
		selectedNote.set(row);
		noteModalVisible.set(!noteModalVisible.get);
		noteMode.set('Edit');
	}

	function onDelete(row: Note): void {
		selectedNote.set(row);
		deleteModalVisible.set(!deleteModalVisible.get);
	}

	function onSaved(success: boolean, mode: NewNoteMode): void {
		let caption = '';
		let type = 'success';

		if (success) {
			if (mode === 'New') {
				caption = 'Note has been added.';
			}

			if (mode === 'Edit') {
				caption = 'Note has been updated.';
			}

			selectedNote.set(null);
			noteModalVisible.set(!noteModalVisible.get);
			onRefetch(tableState.where.get!);
		}

		if (!success) {
			type = 'error';

			if (mode === 'New') {
				caption = 'Note was unable to be added.';
			}

			if (mode === 'Edit') {
				caption = 'Note was unable to updated.';
			}
		}

		Toast.show({ type, text2: caption });
	}

	return (
		<CustomCard
			header={() => (
				<View style={styles.main.header}>
					<Text category="h6">Notes</Text>
					<View style={styles.main.actions}>
						<Button
							testID={NotesComponentLocators.notesCreateButton}
							onPress={() => {
								selectedNote.set(null);
								noteModalVisible.set(!noteModalVisible.get);
								noteMode.set('New');
							}}
						>
							Create
						</Button>
					</View>
				</View>
			)}
		>
			<NoteModal
				accountId={props.accountId}
				fundingId={props.fundingId}
				mode={noteMode.get}
				note={selectedNote.get}
				toggleVisible={() => {
					selectedNote.set(null);
					noteModalVisible.set(!noteModalVisible.get);
				}}
				userId={props.userId}
				visible={noteModalVisible.get}
				onSaved={onSaved}
			/>

			<AppAlert
				actions={[
					{
						title: 'Cancel',
						status: 'basic',
						testID: NotesComponentLocators.deleteModalCanelButton,
						onPress: () => deleteModalVisible.set(false),
					},
					{
						title: 'Confirm',
						status: 'primary',
						testID: NotesComponentLocators.deleteModalConfirmButton,
						loading: getNotesQuery.loading,
						onPress: onDeleteNote,
					},
				]}
				message="Do you really want to delete note? Your changes will be lost."
				title="Are you sure?"
				visible={deleteModalVisible.get}
			/>

			<Controller
				control={form.control}
				name="search"
				render={(control) => (
					<Input
						accessoryRight={() => (
							<TouchableOpacity
								testID={NotesComponentLocators.notesSerchIconButton}
								onPress={form.handleSubmit(onSearch)}
							>
								<AppIcon lib="fe" name="search" />
							</TouchableOpacity>
						)}
						placeholder="Search for notes by subject, text, or created by"
						testID={NotesComponentLocators.notesSearchField}
						value={control.field.value!}
						onBlur={control.field.onBlur}
						onChangeText={control.field.onChange}
						onSubmitEditing={form.handleSubmit(onSearch)}
					/>
				)}
			/>

			<CustomDataTable
				columns={columns}
				data={getNotesQuery.data?.notes?.nodes as Note[]}
				progressPending={getNotesQuery.loading}
				refetch={(variables) =>
					getNotes({ variables: { ...variables, where: tableState.where.get } })
				}
				rowActions={actions}
				{...tableState.props}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			actions: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				flex: 1,
			},
		}),
	};
}
