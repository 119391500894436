import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import {
	FundingsCard_CancelFunding,
	FundingsCard_CancelFundingVariables,
	SortEnumType,
	FundingSortInput,
	FundingFilterInput,
	OrganismsFcGetFundings_fundings_nodes as FundingNode,
	OrganismsFcGetFundings,
	OrganismsFcGetFundingsVariables,
	FundingDirection,
	EditAccountScreenGetAccountDetails_account as AccountDetails,
	AssetMovementStatus,
	OrganismsFcGetFundings_fundings_nodes_fundingAssets as FundingNodeAsset,
	CurrencyType,
	SystemRole,
	FundingStatus,
	OrganismsFcGetRfEligibleFundings,
	FundingStage,
	OrganismsFcGetRfEligibleFundingsVariables,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { FundingsLocators } from '@app/e2e/shared/FundingsCard';
import { EditFundingScreenParams } from '@app/navigations';
import { useFundingsInflowNavigation } from '@app/navigations/Main/FundingsInflow';
import { useFundingsOutflowNavigation } from '@app/navigations/Main/FundingsOutflow';
import {
	CustomCard,
	CustomDataTable,
	DangerAssetSVG,
	FundingStatusBubble,
	ITrustColumn,
	useTableState,
} from '@app/shared/components';
import {
	AppAlert,
	dateFormat,
	displayUsdNumber,
	humanize,
	localDateFormat,
	useAppState,
	useAppTheme,
} from '@itrustcapital/ui';
import { Button, Text } from '@ui-kitten/components';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import Toast from 'react-native-toast-message';

import { NewFunding } from './NewFunding';

export interface FundingsCardProps {
	accountIds?: number[];
	isCustomer?: boolean;
	accountDetails?: AccountDetails;
}

export const ORGANISMS_FC_GET_FUNDINGS = gql`
	query OrganismsFcGetFundings(
		$where: FundingFilterInput
		$order: [FundingSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		fundings(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				id
				type
				status
				stage
				fundedAt
				createdAt
				direction
				fundingAssets {
					id
					amount
					status
					requestedAmount
					currencyType
					assetMovement {
						status
					}
					paymentType
					assetMovement {
						dwollaSourceTransferStatus
						fortressTransactionStatus
						fundsReceivedAt
					}
				}
				transactionCode
			}
			totalCount
			pageInfo {
				startCursor
				endCursor
			}
		}
	}
`;

export const ORGANISMS_FC_GET_RF_ELIGIBLE_FUNDINGS = gql`
	query OrganismsFcGetRfEligibleFundings($where: FundingFilterInput) {
		fundings(where: $where) {
			nodes {
				id
				stage
				direction
				fundingAssets {
					id
					currencyType
				}
			}
			totalCount
			pageInfo {
				startCursor
				endCursor
			}
		}
	}
`;

export const FUNDINGS_CARD_CANCEL_FUNDING = gql`
	mutation FundingsCard_CancelFunding($input: CancelFundingInput!) {
		cancelFunding(input: $input) {
			success
			errorMessage
		}
	}
`;

export function FundingsCard(props: FundingsCardProps) {
	const styles = useCustomStyles();

	const maestroUserAtom = useMaestroUserAtom();
	const systemRole = maestroUserAtom.user?.role;
	const isCancelDisabled =
		systemRole === SystemRole.CUSTOMER ||
		systemRole === SystemRole.FUNCTION ||
		systemRole === SystemRole.GENERAL ||
		systemRole === SystemRole.PROCESSING ||
		systemRole === SystemRole.CUSTODIAN;

	const fundingInflowNavigation = useFundingsInflowNavigation();
	const fundingOutflowNavigation = useFundingsOutflowNavigation();
	const cancelVisible = useAppState(false);
	const newFundingModal = useAppState(false);
	const selectedRow = useAppState<FundingNode | null>(null);

	const defaultWhere: FundingFilterInput = {
		or: [
			{
				toAccountId: { in: props.accountIds },
				direction: { eq: FundingDirection.INBOUND },
			},
			{
				fromAccountId: { in: props.accountIds },
				direction: { eq: FundingDirection.OUTBOUND },
			},
		],
	};

	const tableState = useTableState<FundingSortInput, FundingFilterInput>({
		defaultWhere,
		defaultSort: { createdAt: SortEnumType.DESC },
		pagination: true,
		striped: true,
		disabledPaginationServer: true,
		disabledSortServer: true,
	});

	const getFundingsQuery = useQuery<OrganismsFcGetFundings, OrganismsFcGetFundingsVariables>(
		ORGANISMS_FC_GET_FUNDINGS,
		{
			variables: {
				where: defaultWhere,
				order: tableState.sort.get,
				first: tableState.rowsPerPage.get,
			},
			onCompleted: (data) =>
				tableState.onCompleted(data.fundings?.totalCount, data.fundings?.pageInfo),
			fetchPolicy: 'no-cache',
			skip: !(props.accountIds && props.accountIds.length > 0),
		}
	);

	const getEligibleFunding = useQuery<
		OrganismsFcGetRfEligibleFundings,
		OrganismsFcGetRfEligibleFundingsVariables
	>(ORGANISMS_FC_GET_RF_ELIGIBLE_FUNDINGS, {
		variables: {
			where: {
				direction: { eq: FundingDirection.INBOUND },
				stage: {
					nin: [
						FundingStage.ASSET_TRANSFER_COMPLETED,
						FundingStage.CANCELED,
						FundingStage.DECLINED,
					],
				},
				or: [
					{
						toAccountId: { in: props.accountIds },
					},
					{
						fromAccountId: { in: props.accountIds },
					},
				],
			},
		},
		fetchPolicy: 'no-cache',
		skip: !(props.accountIds && props.accountIds.length > 0),
	});

	const [cancelFunding, cancelFundingMutation] = useMutation<
		FundingsCard_CancelFunding,
		FundingsCard_CancelFundingVariables
	>(FUNDINGS_CARD_CANCEL_FUNDING);

	const columns: ITrustColumn<FundingNode>[] = [
		{
			key: 'status',
			name: 'Status',
			sortable: false,
			selector: (row) => <FundingStatusBubble status={getStatus(row)} />,
			maxWidth: 150,
			center: true,
		},
		{
			key: 'type',
			name: 'Type',
			sortable: true,
			center: true,
			selector: (row) => humanize(row.type),
		},
		{
			key: 'stage',
			name: 'Stage',
			minWidth: 200,
			sortable: false,
			selector: (row) => humanize(row.stage),
		},
		{
			key: 'direction',
			name: 'Direction',
			sortable: true,
			center: true,
			selector: (row) => humanize(row.direction),
		},
		{
			key: 'createdAt',
			name: 'Created Date',
			maxWidth: 150,
			sortable: true,
			center: true,
			selector: (row) => localDateFormat(row.createdAt),
		},
		{
			key: 'fundedAt',
			name: 'Funded Date',
			maxWidth: 150,
			sortable: false,
			center: true,
			selector: (row) => localDateFormat(row.fundedAt),
		},
		{
			key: 'fundedAt',
			name: 'Funds Received Date',
			minWidth: 130,
			maxWidth: 150,
			sortable: false,
			center: true,
			selector: (row) => dateFormat(findUsdFundingAsset(row)?.assetMovement?.fundsReceivedAt),
		},
		{
			key: 'fundingAssets',
			name: 'Asset Type',
			minWidth: 130,
			maxWidth: 150,
			sortable: false,
			center: true,
			selector: (row) => {
				if (row?.fundingAssets?.length === 0) {
					return '';
				}

				if (row?.fundingAssets?.length === 1) {
					return row.fundingAssets[0]?.currencyType;
				}

				return 'multiple';
			},
		},
		{
			key: 'fundingAssets',
			name: 'Amount',
			sortable: false,
			right: true,
			selector: (row) =>
				displayUsdNumber(
					findUsdFundingAsset(row)?.amount ||
						findUsdFundingAsset(row)?.requestedAmount ||
						0
				),
		},
		{
			key: 'fundingAssets',
			name: 'Payment Type',
			sortable: false,
			center: true,
			selector: (row) => findUsdFundingAsset(row)?.paymentType,
		},
		{
			key: 'transactionCode',
			name: 'Transaction Code',
			minWidth: 130,
			sortable: true,
			center: true,
			selector: (row) => row.transactionCode,
		},
	];

	function getStatus(funding: FundingNode): FundingStatus | AssetMovementStatus {
		const assetMovement = funding.fundingAssets?.[0]?.assetMovement;

		if (!assetMovement) {
			return funding.status;
		}

		return assetMovement.status || AssetMovementStatus.NONE;
	}

	function onEdit(row: FundingNode) {
		const params: EditFundingScreenParams = {
			fundingId: row.id,
			fundingStage: row.stage,
			direction: row.direction,
		};

		if (row.direction === FundingDirection.INBOUND) {
			fundingInflowNavigation.Edit(params);
		} else {
			fundingOutflowNavigation.Edit(params);
		}
	}

	async function onCancel(): Promise<void> {
		try {
			await cancelFunding({
				variables: {
					input: {
						fundingId: selectedRow.get?.id!,
					},
				},
			});
			getFundingsQuery?.refetch?.();
			selectedRow.set(null);
			cancelVisible.set(false);
			Toast.show({
				type: 'success',
				text2: 'Funding has been canceled. This cannot be undone.',
			});
		} catch (error) {
			if (error instanceof ApolloError) {
				Toast.show({
					type: 'error',
					text2: error.message,
				});
			}
		}
	}

	function findUsdFundingAsset(row: FundingNode): FundingNodeAsset | null | undefined {
		return row.fundingAssets?.find(
			(fundingAsset) => fundingAsset?.currencyType === CurrencyType.USD
		);
	}

	return (
		<CustomCard
			header={() => (
				<View style={styles.main.header}>
					<Text category="h6">Fundings</Text>
					<View style={styles.main.actions}>
						{!props.isCustomer && (
							<Button
								testID={FundingsLocators.createNewFundingButton}
								onPress={() => newFundingModal.set(!newFundingModal.get)}
							>
								Create New Funding
							</Button>
						)}
					</View>
				</View>
			)}
		>
			<AppAlert
				actions={[
					{
						title: 'Cancel',
						onPress: () => {
							cancelVisible.set(false);
							selectedRow.set(null);
						},
						testID: FundingsLocators.cancelCancelFundingButton,
					},
					{
						title: 'Confirm',
						status: 'danger',
						loading: cancelFundingMutation.loading,
						onPress: onCancel,
						testID: FundingsLocators.confirmCancelFundingButton,
					},
				]}
				message="Funding will be canceled"
				title="Are you sure?"
				visible={cancelVisible.get}
				xmlIcon={DangerAssetSVG}
			/>

			{props.accountDetails && (
				<NewFunding
					rfEligibleFunding={getEligibleFunding?.data?.fundings?.nodes}
					accountDetails={props.accountDetails!}
					toggleVisible={() => {
						selectedRow.set(null);
						newFundingModal.set(!newFundingModal.get);
					}}
					visible={newFundingModal.get}
					onSaved={(success) => {
						newFundingModal.set(false);
						Toast.show({
							type: success ? 'success' : 'error',
							text2: success
								? 'Successfully added funding'
								: 'Unexpected error, please try again.',
						});
						getFundingsQuery.refetch?.();
					}}
				/>
			)}

			<CustomDataTable
				{...tableState.props}
				columns={columns}
				data={getFundingsQuery?.data?.fundings?.nodes as FundingNode[]}
				progressPending={getFundingsQuery.loading}
				refetch={(variables) => {
					getFundingsQuery?.refetch?.({ ...variables });
				}}
				rowActions={[
					{
						name: 'Edit',
						testID: FundingsLocators.editFundingButton,
						handler: onEdit,
						iconLib: 'matc',
						iconName: 'pencil',
					},
					{
						name: 'Cancel',
						testID: FundingsLocators.cancelFundingButton,
						handler: (row) => {
							selectedRow.set(row);
							cancelVisible.set(true);
						},
						isDisabled: () => isCancelDisabled,
						iconLib: 'matc',
						iconName: 'cancel',
					},
				]}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			actions: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				flex: 1,
			},
		}),
		card: StyleSheet.create({
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		row: StyleSheet.create({
			documentName: {
				color: theme['color-info-700'],
			},
		}),
	};
}
