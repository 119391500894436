import { AccountStatus, FundingType } from '@app/codegen';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TypeOf } from 'yup';

export const ReportingScreenFilterSchema = yup.object({
	minAmount: yup.string().default('$0.00'),
	maxAmount: yup
		.string()
		.when('minAmount', (minAmount, schema) => {
			return schema.test({
				message: 'Max must be greater than min',
				test: (value: string) => maxGreaterThanMin(value, minAmount),
			});
		})
		.default('$0.00'),
	createdFrom: yup.date().default(undefined),
	createdTo: yup.date().default(undefined),
	fundingType: yup.mixed<FundingType>().oneOf(Object.values(FundingType)).default(undefined),
	accountStatus: yup
		.mixed<AccountStatus>()
		.oneOf(Object.values(AccountStatus))
		.default(undefined),
});

function maxGreaterThanMin(value: string, minAmount: string): boolean {
	const val = +value.replace('$', '');
	const min = +minAmount.replace('$', '');

	return val > min;
}

export type ReportingScreenFilterForm = TypeOf<typeof ReportingScreenFilterSchema>;

export function useReportingScreenFilterForm() {
	return useForm({
		mode: 'all',
		criteriaMode: 'all',
		defaultValues: ReportingScreenFilterSchema.cast({}),
		resolver: yupResolver(ReportingScreenFilterSchema),
	});
}
