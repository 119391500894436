import { EditFundingScreenParams, useMainDrawerNavigation } from '@app/navigations';
import { FundingsOutflowParamsRecord } from '@app/navigations/Main/FundingsOutflow';
import { EditFundingScreen, FundingsOutflowScreen } from '@app/screens';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

export function useFundingsOutflowNavigation() {
	const navigation = useMainDrawerNavigation('FundingsOutflow');

	return {
		Home: (reset?: boolean) =>
			navigation.Base(
				{
					screen: 'Home',
				},
				reset
			),
		Edit: (params: EditFundingScreenParams) =>
			navigation.Base({
				screen: 'Edit',
				params,
			}),
		FundingsOutflowLink: navigation.BaseLink + '/fundings-outflow',
	};
}

const Stack = createStackNavigator<FundingsOutflowParamsRecord>();

export function FundingsOutflowNavigation() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen component={FundingsOutflowScreen} name="Home" />
			<Stack.Screen component={EditFundingScreen} name="Edit" />
		</Stack.Navigator>
	);
}
