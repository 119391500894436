import { Platform } from 'react-native';

export const strToNumber = (value: string): number => {
	return parseFloat(value.replace(/[^\d.]/g, ''));
};

export const currency = (
	value: number = 0,
	minimumFractionDigits: number = 2,
	maximumFractionDigits: number = 2
): string => {
	let returnString = value.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits,
		maximumFractionDigits,
	});

	if (Platform.OS === 'android') {
		returnString = '$' + returnString;
	}

	return returnString;
};

export const percentage = (decimal: number, digits: number = 2): string => {
	if (decimal < 0) {
		return `${(decimal * 100).toFixed(digits)}%`;
	}

	return `+${(decimal * 100).toFixed(digits)}%`;
};

export const allocationPercentage = (decimal: number, digits: number = 2): string => {
	if (decimal === 0) {
		return `0.00%`;
	}

	if (decimal < 0.01) {
		return `< 0.01%`;
	}

	return `${(decimal * 100).toFixed(digits)}%`;
};
