import { Button, Input, Layout, TopNavigation, Text, ListItem } from '@ui-kitten/components';
import { EvaStatus, RenderProp } from '@ui-kitten/components/devsupport';
import React, { ReactNode } from 'react';
import {
	StatusBar,
	StyleProp,
	StyleSheet,
	TextProps,
	TextStyle,
	TouchableOpacity,
	View,
	ScrollView,
} from 'react-native';

import { useAppState } from '../../helpers';
import { useAppTheme } from '../../themes';
import { AppIcon } from './AppIcon';
import { AppModal } from './AppModal';

export interface AppSelectorProps {
	caption?: RenderProp<TextProps> | React.ReactText;
	children?: ReactNode;
	closeOnSelect?: boolean;
	data: any[] | undefined;
	disabled?: boolean;
	label?: string;
	placeholder?: string;
	status?: EvaStatus;
	style?: StyleProp<TextStyle>;
	testID?: string;
	value?: any;
	itemDisplay?: (data: any) => string;
	itemDisplayItem?: (data: any, selected: boolean, onPress: (item: any) => void) => ReactNode;
	itemHidden?: (data: any) => boolean;
	onSelect?: (data: any) => void;
	onDone?: (data: any) => void;
	searchFilter?: (searchTerm: string, data: any[]) => any[];
	hideFilter?: boolean;
}

export const AppSelector = React.forwardRef(AppSelectorComponent);

export function AppSelectorComponent(props: AppSelectorProps, ref: React.Ref<View>) {
	const styles = useCustomStyles(props);
	const showSelect = useAppState(false);
	const selected = useAppState<any>(props.value);
	const filteredOptions = useAppState<any[] | undefined>([]);
	const searchTerm = useAppState('');

	React.useEffect(() => {
		selected.set(props.value);

		if (props.data) {
			filteredOptions.set([
				...(props.searchFilter
					? props.searchFilter(searchTerm.get, props.data)
					: props.data?.filter(
							(x) => x?.toLowerCase().indexOf(searchTerm.get?.toLowerCase()) !== -1
					  )),
			]);
		}
	}, [props.data, searchTerm.get, props.value]);

	function onSelect(item: any): void {
		props.onSelect?.(item);

		if (props.closeOnSelect) {
			showSelect.set(false);
			searchTerm.set('');
		} else {
			selected.set(item);
		}
	}

	return (
		<>
			<AppModal
				animationType="slide"
				hardwareAccelerated={true}
				presentationStyle="overFullScreen"
				style={styles.modal}
				visible={showSelect.get}
			>
				<StatusBar barStyle="light-content" />
				<Layout style={styles.container}>
					<TopNavigation
						accessoryLeft={() => (
							<Button
								appearance="ghost"
								status="basic"
								testID={'appSelectorClearButton'}
								onPress={() => onSelect(undefined)}
							>
								Clear
							</Button>
						)}
						accessoryRight={() => (
							<Button
								appearance="ghost"
								testID={'appSelectorDoneButton'}
								onPress={() => {
									showSelect.set(false);
									props.onDone?.(selected.get);
								}}
							>
								Done
							</Button>
						)}
						alignment="center"
						subtitle={props.placeholder}
						title={() => (
							<Text category="label" style={styles.doneText}>
								{props.label}
							</Text>
						)}
					/>
					{!props.hideFilter && (
						<View style={styles.body}>
							{props.caption && typeof props.caption === 'string' ? (
								<Text
									appearance="hint"
									category="p2"
									status={props.status}
									style={styles.caption}
								>
									{props.caption}
								</Text>
							) : (
								(props.caption as any)?.()
							)}
							<Input
								accessoryLeft={() => <AppIcon lib="ev" name="search" size={24} />}
								accessoryRight={
									searchTerm.get.length > 0
										? () => (
												<Button
													size="tiny"
													status="basic"
													onPress={() => searchTerm.set('')}
												>
													Clear
												</Button>
										  )
										: undefined
								}
								placeholder="Filter options from list..."
								style={styles.filter}
								testID={'appSelectorSearchField'}
								value={searchTerm.get}
								onChangeText={searchTerm.set}
							/>
						</View>
					)}
					<ScrollView scrollIndicatorInsets={{ right: 0 }}>
						<View style={styles.scrollContainer}>
							{filteredOptions.get?.map((item: any) => {
								if (props.itemHidden?.(item)) {
									return null;
								}

								return props.itemDisplayItem ? (
									props.itemDisplayItem(item, selected.get === item, () =>
										onSelect(item)
									)
								) : (
									<ListItem
										accessoryRight={
											selected.get === item
												? () => (
														<AppIcon
															lib="fe"
															name="check"
															style={styles.check}
														/>
												  )
												: undefined
										}
										key={props.itemDisplay ? props.itemDisplay(item) : item}
										style={styles.item}
										testID={
											'SelectorItem=' +
											(props.itemDisplay ? props.itemDisplay(item) : item)
										}
										title={() => (
											<Text
												appearance="hint"
												status={
													selected.get === item ? 'primary' : undefined
												}
											>
												{props.itemDisplay ? props.itemDisplay(item) : item}
											</Text>
										)}
										onPress={() => onSelect(item)}
									/>
								);
							})}
						</View>
					</ScrollView>
				</Layout>
			</AppModal>
			<View ref={ref} style={styles.inputContainer}>
				<Input
					accessoryRight={() => (
						<AppIcon lib="ion" name="ios-chevron-down" size={22} style={styles.right} />
					)}
					caption={props.caption}
					disabled={props.disabled}
					label={props.label}
					placeholder={props.placeholder}
					status={props.status}
					style={[styles.input, props.style]}
					testID={props.testID}
					textStyle={styles.appSelectorLabel}
					value={
						props.itemDisplay
							? (selected.get && props.itemDisplay(selected.get)) || ''
							: selected.get || ''
					}
					onKeyPress={() => showSelect.set(true)}
				/>
				<TouchableOpacity
					disabled={props.disabled}
					style={styles.touchable}
					onPress={() => showSelect.set(true)}
				/>
			</View>
		</>
	);
}

function useCustomStyles(props: AppSelectorProps) {
	const theme = useAppTheme();

	return StyleSheet.create({
		modal: {
			justifyContent: 'center',
			alignItems: 'center',
			padding: 0,
			margin: 0,
		},
		container: {
			borderRadius: 30,
			width: '95%',
			height: '100%',
			maxWidth: 400,
			maxHeight: 600,
			overflow: 'hidden',
		},
		inputContainer: {
			position: 'relative',
		},
		input: {
			width: '100%',
			flex: 1,
			zIndex: 0,
		},
		touchable: {
			position: 'absolute',
			zIndex: 1,
			width: '100%',
			height: props.caption ? 85 : 70,
		},
		body: {
			padding: 25,
			paddingTop: 0,
			paddingBottom: 0,
		},
		scrollContainer: {
			padding: 25,
			paddingTop: 0,
			marginBottom: 150,
		},
		caption: {
			marginBottom: 10,
		},
		filter: {
			marginBottom: 10,
		},
		item: {
			borderBottomWidth: StyleSheet.hairlineWidth,
			borderBottomColor: theme['color-basic-transparent-500'],
			borderRadius: 3,
		},
		right: {
			color: theme['text-hint-color'],
		},
		check: {
			fontSize: 20,
			color: theme['color-primary-500'],
		},
		doneText: {
			fontSize: 14,
		},
		appSelectorLabel: {
			width: '100%',
			flex: 1,
		},
	});
}
