import { gql, useMutation, useQuery } from '@apollo/client';
import {
	EditCustomerScreen_UpdateUserKyc,
	EditCustomerScreen_UpdateUserKycVariables,
	EditCustomerScreen_getKycStatus,
	EditCustomerScreen_getKycStatusVariables,
	KycStatus,
} from '@app/codegen';
import { CustomCard } from '@app/shared/components';
import { AppAlert, useAppState } from '@itrustcapital/ui';
import { Button, Spinner, Text } from '@ui-kitten/components';
import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

export interface KYCProps {
	userId: number;
}

export const EDIT_CUSTOMER_SCREEN_UPDATE_KYC = gql`
	mutation EditCustomerScreen_UpdateUserKyc($input: UpdateUserKycInput!) {
		updateUserKyc(input: $input) {
			success
			errorMessage
		}
	}
`;

export const EDIT_CUSTOMER_SCREEN_GET_KYC = gql`
	query EditCustomerScreen_getKycStatus($where: UserFilterInput!) {
		user(where: $where) {
			kycStatus
		}
	}
`;

export function KYC(props: KYCProps) {
	const styles = useCustomStyles();

	const errorVisible = useAppState(false);

	const getUserDetailsQuery = useQuery<
		EditCustomerScreen_getKycStatus,
		EditCustomerScreen_getKycStatusVariables
	>(EDIT_CUSTOMER_SCREEN_GET_KYC, {
		variables: {
			where: {
				id: {
					eq: props.userId,
				},
			},
		},
		skip: !props.userId,
	});
	const kycStatus = getUserDetailsQuery?.data?.user?.kycStatus;

	const [updateKyc, updateKycMutation] = useMutation<
		EditCustomerScreen_UpdateUserKyc,
		EditCustomerScreen_UpdateUserKycVariables
	>(EDIT_CUSTOMER_SCREEN_UPDATE_KYC, {
		variables: {
			input: {
				id: props.userId,
			},
		},
		refetchQueries: [EDIT_CUSTOMER_SCREEN_GET_KYC],
	});

	useEffect(() => {
		if (updateKycMutation?.data?.updateUserKyc?.errorMessage) {
			errorVisible.set(true);
		}
	}, [updateKycMutation?.data?.updateUserKyc?.errorMessage]);

	return (
		<>
			<CustomCard
				header={() => (
					<View style={styles.main.header}>
						<Text category="h6">KYC</Text>
					</View>
				)}
			>
				<AppAlert
					actions={[
						{
							title: 'OK',
							status: 'danger',
							onPress: () => errorVisible.set(false),
						},
					]}
					message="Sorry, something went wrong. Please try again later."
					title="Request Failed"
					visible={errorVisible.get}
				/>

				<View
					style={{
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<View>
						<Text>Kyc Status:</Text>
						<Text>{getUserDetailsQuery?.loading ? 'Loading' : kycStatus}</Text>
					</View>

					<Button
						disabled={
							kycStatus === KycStatus.L1 ||
							kycStatus === KycStatus.L2 ||
							updateKycMutation.loading ||
							getUserDetailsQuery.loading
						}
						onPress={() => updateKyc()}
					>
						{!updateKycMutation.loading ? (
							'Update KYC'
						) : (
							<>
								<Spinner size="tiny" status="basic" />
							</>
						)}
					</Button>
				</View>
			</CustomCard>
		</>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		tags: StyleSheet.create({
			container: {
				flex: 1,
				flexDirection: 'row',
				flexWrap: 'wrap',
			},
			group: {
				flexDirection: 'row',
			},
			seperator: {
				paddingHorizontal: 8,
			},
		}),
	};
}
