import React from 'react';
import {
	Modal as RNModal,
	ModalProps,
	StyleProp,
	ViewStyle,
	Platform,
	StyleSheet,
	View,
	TouchableWithoutFeedback,
} from 'react-native';
import Modal, { Direction, PresentationStyle } from 'react-native-modal';
import { useAppTheme } from '../..';

type AppModalExtendProps = {
	ref?: any;
	children: React.ReactNode;
	style?: StyleProp<ViewStyle>;
	onBackdropPress?: () => void;
	propagateSwipe?: boolean;
	swipeDirection?: Direction | Direction[] | undefined;
	backdropOpacity?: number;
	backdropTransitionOutTiming?: number;
	customBackdrop?: React.ReactNode;
	scrollHorizontal?: boolean;
};

export type AppModalProps = Partial<ModalProps & AppModalExtendProps>;

export function AppModal(props: AppModalProps) {
	const theme = useAppTheme();

	const dynamicStyles = useDynamicStyles();

	if (Platform.OS !== 'web' && ['pageSheet', 'formSheet'].includes(props.presentationStyle!)) {
		return (
			<RNModal
				onRequestClose={props.onRequestClose}
				presentationStyle={props.presentationStyle}
				visible={props.visible}
				animationType={'slide'}
				hardwareAccelerated={true}
			>
				{props.children}
			</RNModal>
		);
	}

	const presentationStyle: PresentationStyle = ['pageSheet', 'formSheet'].includes(
		props.presentationStyle!
	)
		? 'fullScreen'
		: props.presentationStyle!;

	return (
		<Modal
			onDismiss={props.onRequestClose}
			backdropColor={theme['color-basic-transparent-500']}
			isVisible={props.visible}
			presentationStyle={presentationStyle}
			hardwareAccelerated={true}
			style={[styles.modal, props.style]}
			onBackdropPress={props.onBackdropPress}
			propagateSwipe={props.propagateSwipe}
			swipeDirection={props.swipeDirection}
			backdropOpacity={props.backdropOpacity}
			backdropTransitionOutTiming={props.backdropTransitionOutTiming}
			customBackdrop={
				props.customBackdrop ? (
					props.customBackdrop
				) : (
					<TouchableWithoutFeedback onPress={props.onBackdropPress}>
						<View style={[styles.customBackdrop, dynamicStyles.customBackdrop]} />
					</TouchableWithoutFeedback>
				)
			}
			scrollHorizontal={props.scrollHorizontal}
		>
			{props.children}
		</Modal>
	);
}

const styles = StyleSheet.create({
	modal: {
		justifyContent: 'center',
		alignItems: 'center',
		padding: 0,
		margin: 0,
	},
	customBackdrop: {
		width: Platform.OS !== 'web' ? '100%' : '100vw',
		height: Platform.OS !== 'web' ? '100%' : '100vh',
	},
});

function useDynamicStyles() {
	const theme = useAppTheme();

	return StyleSheet.create({
		customBackdrop: {
			backgroundColor: theme['color-basic-transparent-500'],
		},
	});
}
