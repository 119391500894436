import { CustomerScreenLocators } from '@app/e2e/screens/Customers';
import { TopHeader } from '@app/shared/components';
import { AppBackButton, AppScreen, StackNavigation } from '@itrustcapital/ui';
import * as ReactNavigation from '@react-navigation/native';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';

import { CustomersScreenProvider, useCustomersScreenState } from './CustomersScreenProvider';
import { CustomersFilterForm } from './components/CustomersFilterForm';
import { CustomersTable } from './components/CustomersTable';

export function CustomersScreen() {
	const navigation = ReactNavigation.useNavigation<StackNavigation>();
	const state = useCustomersScreenState();

	React.useLayoutEffect(() => {
		navigation.setOptions({
			title: 'Customers - ITrustCapital',
			headerTitle: 'Customers',
			headerLeft: () => <AppBackButton supportDrawer />,
			headerRight: () => null,
		});
	}, []);

	return (
		<AppScreen noMarginBottom noMaxWidth noPadding>
			<CustomersScreenProvider value={state}>
				<TopHeader
					exportDisabled
					createTestID={CustomerScreenLocators.createCustomerButton}
					exportTestID={CustomerScreenLocators.exportToExcelButton}
					title="Customers"
				/>

				<Card style={styles.main.card}>
					{/* Data Table */}
					<CustomersFilterForm />
					<CustomersTable />
				</Card>
			</CustomersScreenProvider>
		</AppScreen>
	);
}

const styles = {
	main: StyleSheet.create({
		card: {
			borderRadius: 12,
			padding: 12,
			margin: 32,
		},
	}),
};
