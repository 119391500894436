import { gql, useQuery } from '@apollo/client';
import {
	SortEnumType,
	TrustPilotInvitationFilterInput,
	TrustPilotInvitationSortInput,
	CsTpcGetInvitations,
	CsTpcGetInvitationsVariables,
	CsTpcGetInvitations_trustPilotInvitations_nodes as TrustPilotInvitations,
} from '@app/codegen';
import { CustomCard, CustomDataTable, ITrustColumn, useTableState } from '@app/shared/components';
import { humanize, localDateFormat } from '@itrustcapital/ui';
import React from 'react';

export interface TrustPilotCardProps {
	userId: number;
}

export const CS_TPC_GET_INVITATIONS = gql`
	query CsTpcGetInvitations(
		$where: TrustPilotInvitationFilterInput
		$order: [TrustPilotInvitationSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		trustPilotInvitations(
			first: $first
			after: $after
			last: $last
			before: $before
			where: $where
			order: $order
		) {
			pageInfo {
				startCursor
				endCursor
			}
			nodes {
				accountId
				accountType
				referenceId
				createdAt
			}
			totalCount
		}
	}
`;

export function TrustPilotCard(props: TrustPilotCardProps) {
	const tableState = useTableState<
		TrustPilotInvitationSortInput,
		TrustPilotInvitationFilterInput
	>({
		striped: true,
		pagination: true,
		defaultSort: {
			createdAt: SortEnumType.DESC,
		},
		defaultWhere: {
			userId: { eq: props.userId },
		},
	});

	const invitationsQuery = useQuery<CsTpcGetInvitations, CsTpcGetInvitationsVariables>(
		CS_TPC_GET_INVITATIONS,
		{
			variables: {
				first: tableState.rowsPerPage.get,
				where: tableState.where.get,
			},
			skip: !props.userId,
			onCompleted: (data) =>
				tableState.onCompleted(
					data.trustPilotInvitations?.totalCount,
					data.trustPilotInvitations?.pageInfo
				),
		}
	);

	const columns: ITrustColumn<TrustPilotInvitations>[] = [
		{
			key: 'accountId',
			name: 'Account ID',
			sortable: true,
			selector: (row) => row.accountId,
		},
		{
			key: 'createdAt',
			name: 'Created At',
			maxWidth: 150,
			sortable: true,
			selector: (row) => localDateFormat(row.createdAt),
		},
		{
			key: 'accountType',
			name: 'Account Type',
			sortable: true,
			selector: (row) => humanize(row.accountType),
		},
		{
			key: 'referenceId',
			name: 'Reference ID',
			sortable: true,
			selector: (row) => row.referenceId,
		},
	];

	return (
		<CustomCard header="Trust Pilot Invitations">
			<CustomDataTable
				columns={columns}
				data={
					invitationsQuery.data?.trustPilotInvitations?.nodes as TrustPilotInvitations[]
				}
				{...tableState.props}
			/>
		</CustomCard>
	);
}
