import * as yup from 'yup';

export const emailRegexString: RegExp =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegexString: RegExp =
	/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()?<>-])(?=.*[0-9]).{8,256}$/;

export const phoneRegexString: RegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

export const creditCardRegexString: RegExp =
	/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

export const upperCaseRegex: RegExp = /[A-Z]/;

export const lowerCaseRegex: RegExp = /[a-z]/;

export const numberRegex: RegExp = /[0-9]/;

export const specialRegex: RegExp = /[!@#$%^&*()?<>-]/;

export const currencyRegex: RegExp = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;

export const ssnRegex: RegExp = /^[*?\d-]{11,}$/;

export const validateStreetAddress = yup
	.string()
	.test('regex', 'Incorrect address format.', (val) => /[a-zA-Z0-9._-]*/.test(val || ''))
	.default('');

export const validateDateOfBirth = yup
	.string()
	.typeError('Invalid name.')
	.required('Date of Birth is a required field.')
	.default('');

export const validateUsername = yup
	.string()
	.typeError('Invalid username.')
	.min(6, 'Username must be at least 6 characters.')
	.max(12, 'Username must be at most 12 characters.')
	.required('Username is a required field.')
	.default('');

export const validateEmail = yup
	.string()
	.typeError('Invalid email')
	.test('regex', 'Please enter a valid email address.', (val) => regexEmail(val))
	.required('Email is a required field.')
	.default('');

export const validateOptionalEmail = yup
	.string()
	.typeError('Invalid email.')
	.test('regex', 'Please enter a valid email address.', (val) => !val || regexEmail(val))
	.default('');

export const validatePassword = yup
	.string()
	.default('')
	.required('Password is a required field.')
	.min(8, 'Must be at least 8 characters.')
	.max(256, 'Must be at most 256 characters.')
	.test('regex', 'Incorrect password format.', (val) => regexPassword(val));

export const validatePasswordConfirmation = (refName: string) =>
	yup
		.string()
		.oneOf([yup.ref(refName), null], 'Passwords do not match.')
		.required('Confirmation Password is a required field.')
		.default('');

export const validatePhone = yup
	.string()
	.typeError('Must be a number.')
	.test('regex', 'Incorrect phone number', (val) => (val ? regexPhone(val) : true))
	.default('')
	.required('Phone Number is a required field.');

export const validateZipcode = yup
	.string()
	.matches(/^[0-9]{5}$/, 'Please enter a valid zip code.')
	.required('Zip Code is a required field.')
	.default('');

export const validateCreditCard = yup
	.string()
	.required('Card Number is a required field.')
	.min(18, 'Please enter valid credit card number.')
	.default('');

export const validateCreditCardExpiration = yup
	.string()
	.default('')
	.required('Expiration is a required field.')
	.test('Expiration Date', 'Please enter a valid expiration date.', (val) =>
		isValidExpirationDate(val)
	);

export const validateSSN = yup
	.string()
	.test('validSSN', 'Must enter a valid social security number.', (value) =>
		value ? ssnRegex.test(value) : true
	)
	.default('');

export const regexEmail = (str?: string): boolean => {
	if (!str) return false;

	return emailRegexString.test(str);
};

export const regexPassword = (str?: string): boolean => {
	if (!str) return false;

	return passwordRegexString.test(str);
};

export const regexPhone = (str?: string): boolean => {
	if (!str) return false;

	return phoneRegexString.test(str);
};

export const regexCreditCard = (str?: string): boolean => {
	if (!str) return false;

	return creditCardRegexString.test(str);
};

export const regexSsn = (str?: string): boolean => {
	if (!str) return false;

	return ssnRegex.test(str);
};

export const isValidExpirationDate = (str?: string): boolean => {
	if (!str) return false;

	const expirationDate = str.split('/');
	const century = 2000;
	let month = Number(expirationDate[0]);
	let year = Number(expirationDate[1]) + century;

	const now = new Date();
	const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

	year = month === 12 ? year + 1 : year;
	month = month === 12 ? 1 : month;

	if (month > 12 || month === 0) return false;

	return new Date(year, month) > firstDayOfMonth;
};
