import { useTheme, Text, Layout, Button, Spinner } from '@ui-kitten/components';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import React, { ReactElement, ReactNode } from 'react';
import { Appearance, View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { useAppTheme } from '../..';
import { AppIcon } from './AppIcon';
import { AppModal } from './AppModal';
import { AppSvg } from './AppSvg';

export interface AppAlertProps {
	style?: StyleProp<ViewStyle>;
	childrenStyle?: StyleProp<ViewStyle>;
	children?: ReactNode;
	visible: boolean;
	title?: string;
	message?: string;
	onDismiss?: (index: number | null) => void;
	icon?: ReactNode;
	xmlIcon?: string;
	status?: EvaStatus;
	actions: AppAlertAction[];
}

export interface AppAlertAction {
	title: string;
	status?: EvaStatus;
	icon?: ReactElement | null;
	onPress?: () => any;
	loading?: boolean;
	appearance?: 'filled' | 'outline' | 'ghost';
	disabled?: boolean;
	noDismiss?: boolean;
	testID?: string;
}

export function AppAlert(props: AppAlertProps) {
	const theme = useTheme();
	const dynamicStyles = useDynamicStyles();

	function StatusIcon() {
		switch (props.status) {
			case 'primary':
				return (
					<AppIcon
						color={theme['color-primary-400']}
						lib="mat"
						name="info-outline"
						size={24}
					/>
				);
			case 'info':
				return (
					<AppIcon
						color={theme['color-info-400']}
						lib="mat"
						name="info-outline"
						size={24}
					/>
				);
			case 'danger':
				return (
					<AppIcon
						color={theme['color-danger-400']}
						lib="mat"
						name="error-outline"
						size={24}
					/>
				);
			case 'warning':
				return (
					<AppIcon
						color={theme['color-warning-400']}
						lib="mat"
						name="warning"
						size={24}
					/>
				);
			case 'success':
				return (
					<AppIcon color={theme['color-success-400']} lib="mat" name="check" size={24} />
				);
			default:
				return null;
		}
	}

	return (
		<AppModal
			presentationStyle="overFullScreen"
			style={[styles.main.modal, props.style]}
			visible={props.visible}
		>
			<Layout level="1" style={styles.main.container}>
				{props.icon && props.icon}
				{props.xmlIcon && (
					<AppSvg height={85} style={styles.main.icon} width={85} xml={props.xmlIcon} />
				)}
				{props.title && (
					<Text category="h5" style={styles.main.title}>
						{!props.icon && !props.xmlIcon && ((<StatusIcon />) as any)} {props.title}
					</Text>
				)}

				{props.message && (
					<Text category="p1" style={[styles.main.message, dynamicStyles.message]}>
						{props.message}
					</Text>
				)}

				{props.children && <View style={props.childrenStyle}>{props.children}</View>}

				<View
					style={[
						styles.actions.container,
						props.actions.length > 2 && styles.actions.multipleButton,
						props.actions.length > 2 && { height: 50 * props.actions.length },
					]}
				>
					{props.actions.map((button, index) => (
						<Button
							accessoryLeft={() =>
								button.loading ? (
									<Spinner
										size="tiny"
										status={button.status === 'basic' ? 'primary' : 'basic'}
									/>
								) : (
									button.icon || <></>
								)
							}
							appearance={button.appearance || 'filled'}
							disabled={button.disabled}
							key={button.title}
							status={button.status || 'basic'}
							style={[
								styles.actions.button,
								props.actions.length > 2 && styles.actions.buttonMultiple,
							]}
							testID={button.title}
							onPress={() => {
								if (button.loading) return;
								button.onPress?.();

								if (!button.noDismiss) {
									props.onDismiss?.(index);
								}
							}}
						>
							{button.loading ? '' : button.title}
						</Button>
					))}
				</View>
			</Layout>
		</AppModal>
	);
}

const styles = {
	main: StyleSheet.create({
		modal: {
			margin: 15,
		},
		container: {
			width: '95%',
			maxWidth: 400,
			padding: 16,
			borderRadius: 8,
			alignSelf: 'center',
			shadowColor: 'rgba(0,0,0,0.3)',
			shadowOffset: {
				width: 0,
				height: 3,
			},
			shadowOpacity: 0.29,
			shadowRadius: 4.65,
			elevation: 7,
		},
		icon: {
			alignItems: 'center',
			marginVertical: 32,
		},
		title: {
			marginBottom: 16,
			textAlign: 'center',
		},
		message: {
			textAlign: 'center',
		},
	}),
	actions: StyleSheet.create({
		container: {
			marginTop: 32,
			flexDirection: 'row',
			justifyContent: 'space-around',
		},
		multipleButton: {
			overflow: 'hidden',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
		button: {
			flex: 1,
			marginRight: 5,
			marginLeft: 5,
		},
		buttonMultiple: {
			marginBottom: 5,
		},
	}),
};

function useDynamicStyles() {
	const theme = useAppTheme();

	return StyleSheet.create({
		message: {
			color:
				Appearance.getColorScheme() === 'dark'
					? theme['color-basic-300']
					: theme['color-basic-700'],
		},
	});
}
