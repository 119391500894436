export enum AccountScreenLocators {
	exportToExcelButton = 'exportToExcelButton',
	createNewAccountButton = 'createNewAccountButton',
	accountDeleteModalCancelButton = 'accountDeleteModalCancelButton',
	accountDeleteModalConfirmButton = 'accountDeleteModalConfirmButton',
	accountsSearchField = 'accountsSearchField',
	accountsStageField = 'accountsStageField',
	accountsStatusField = 'accountsStatusField',
	accountsPromoField = 'accountsPromoField',
	accountsCreatedFromField = 'accountsCreatedFromField',
	accountsCreatedToField = 'accountsCreatedToField',
	documentStageField = 'documentStageField',
	accountsApplyButton = 'accountsApplyButton',
	accountsClearButton = 'accountsClearButton',
	accountsSearchIconButton = 'accountsSearchIconButton',
	accountEditButton = 'accountEditButton',
	accountDeleteButton = 'accountDeleteButton',
	accountsMobilePhoneNumberField = 'accountsMobilePhoneNumberField',
}
