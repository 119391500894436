export enum AccountInformationLocators {
	accountIdField = 'accountIdField',
	fortressAccountIdField = 'fortressAccountIdField',
	accountNameField = 'accountNameField',
	accountNumberField = 'accountNumberField',
	accountTypeField = 'accountTypeField',
	accountCustodianField = 'accountCustodianField',
	accountReferralCodeField = 'accountReferralCodeField',
	accountKycStatusField = 'accountKycStatusField',
}
