import 'react-native-reanimated';
import { AppCore } from '@app/core';
import * as WebBrowser from 'expo-web-browser';
import React from 'react';

WebBrowser.maybeCompleteAuthSession();

export default function App() {
	return <AppCore />;
}
