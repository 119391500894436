import { SystemRole } from '@app/codegen';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export const UsersScreen_SearchSchema = yup.object({
	search: yup.string().default(''),
	role: yup.mixed<SystemRole>().oneOf(Object.values(SystemRole)).default(undefined),
});

export type UsersScreen_SearchForm = yup.TypeOf<typeof UsersScreen_SearchSchema>;

export function useUsersScreen_SearchForm() {
	return useForm<UsersScreen_SearchForm>({
		mode: 'all',
		criteriaMode: 'all',
		defaultValues: UsersScreen_SearchSchema.cast({}),
		resolver: yupResolver(UsersScreen_SearchSchema),
	});
}

export const UsersScreen_MaestroUserFormSchema = yup.object({
	firstName: yup.string().required('This field is required').default(''),
	lastName: yup.string().default(''),
	email: yup.string().email().required('This field is required').default(''),
	role: yup
		.mixed<SystemRole>()
		.oneOf(Object.values(SystemRole))
		.required('This field is required')
		.default(SystemRole.GENERAL),
});

export type UsersScreen_MaestroUserForm = yup.TypeOf<typeof UsersScreen_MaestroUserFormSchema>;

export function useUsersScreen_MaestroUserForm() {
	return useForm<UsersScreen_MaestroUserForm>({
		mode: 'all',
		criteriaMode: 'all',
		defaultValues: UsersScreen_MaestroUserFormSchema.cast({}),
		resolver: yupResolver(UsersScreen_MaestroUserFormSchema),
	});
}
