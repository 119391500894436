import { useMutation, gql } from '@apollo/client';
import { RpsResetPassword, RpsResetPasswordVariables } from '@app/codegen';
import { NavigationComponentLocators } from '@app/e2e/screens/Navigation';
import { InputError, SuccessSVG, FailSVG } from '@app/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppIcon, AppModal, useAppState, validatePassword, useAppTheme } from '@itrustcapital/ui';
import { Button, Input, Layout, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import * as yup from 'yup';

export interface ResetPasswordProps {
	visible?: boolean;
	onClose: () => void;
}

export type ResetPasswordForm = {
	currentPassword: string;
	newPassword: string;
	confirmNewPassword: string;
};

export const RPS_RESET_PASSWORD = gql`
	mutation RpsResetPassword($input: UpdateCrmUserPasswordInput!) {
		updateCrmUserPassword(input: $input) {
			success
			errorMessage
		}
	}
`;

const schema = yup
	.object({
		currentPassword: yup.string().required('This field is required').default(''),
		newPassword: validatePassword
			.notOneOf([yup.ref('currentPassword'), null], 'Must be new password')
			.required('This field is required')
			.default(''),
		confirmNewPassword: yup
			.string()
			.oneOf([yup.ref('newPassword'), null], "The two password fields didn't match")
			.notOneOf([yup.ref('currentPassword'), null], 'Must be new password')
			.required('This field is required')
			.default(''),
	})
	.defined();

export function ResetPassword(props: ResetPasswordProps) {
	const styles = useCustomStyles();
	const currentPasswordVisible = useAppState(false);
	const newPasswordVisible = useAppState(false);
	const confirmNewPasswordVisible = useAppState(false);
	const showPasswordChangedSuccess = useAppState(false);
	const showPasswordChangedFail = useAppState(false);

	const [onSaveForm] = useMutation<RpsResetPassword, RpsResetPasswordVariables>(
		RPS_RESET_PASSWORD,
		{
			onCompleted: () => {
				props.onClose();
				setTimeout(() => showPasswordChangedSuccess.set(true), 400);
			},
		}
	);

	const resetPassword = useForm<ResetPasswordForm>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	async function onSave(): Promise<void> {
		try {
			const values = resetPassword.getValues();
			await onSaveForm({
				variables: {
					input: {
						currentPassword: values.currentPassword,
						password: values.newPassword,
					},
				},
			});
			resetPassword.reset();
		} catch (error) {
			showPasswordChangedFail.set(true);
		}
	}

	return (
		<>
			<AppModal presentationStyle="pageSheet" visible={props.visible}>
				<Layout style={styles.main.container}>
					<TouchableOpacity
						style={styles.main.close}
						onPress={() => {
							resetPassword.reset();
							props.onClose();
						}}
					>
						<AppIcon lib="ion" name="close" size={20} />
					</TouchableOpacity>
					<Text category="h5" style={styles.main.header}>
						Change Password
					</Text>
					<Controller
						control={resetPassword.control}
						name="currentPassword"
						render={(control) => (
							<Input
								accessoryRight={() => (
									<TouchableOpacity
										onPress={() =>
											currentPasswordVisible.set(!currentPasswordVisible.get)
										}
									>
										<AppIcon
											lib="ion"
											name={
												currentPasswordVisible.get
													? 'eye-off'
													: 'eye-outline'
											}
											size={20}
											style={styles.form.icon}
										/>
									</TouchableOpacity>
								)}
								caption={() => (
									<InputError
										errors={resetPassword.formState.errors.currentPassword}
									/>
								)}
								defaultValue=""
								label="Current Password"
								placeholder=""
								secureTextEntry={!currentPasswordVisible.get}
								status={
									control.formState.errors.currentPassword ? 'danger' : 'basic'
								}
								style={styles.form.control}
								testID={NavigationComponentLocators.currentPasswordField}
								value={control.field.value!}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={resetPassword.control}
						name="newPassword"
						render={(control) => (
							<Input
								accessoryRight={() => (
									<TouchableOpacity
										onPress={() =>
											newPasswordVisible.set(!newPasswordVisible.get)
										}
									>
										<AppIcon
											lib="ion"
											name={
												newPasswordVisible.get ? 'eye-off' : 'eye-outline'
											}
											size={20}
											style={styles.form.icon}
										/>
									</TouchableOpacity>
								)}
								caption={() => (
									<InputError
										errors={resetPassword.formState.errors.newPassword}
									/>
								)}
								defaultValue=""
								label="New Password"
								placeholder=""
								secureTextEntry={!newPasswordVisible.get}
								status={control.formState.errors.newPassword ? 'danger' : 'basic'}
								style={styles.form.control}
								testID={NavigationComponentLocators.newPasswordField}
								value={control.field.value!}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={resetPassword.control}
						name="confirmNewPassword"
						render={(control) => (
							<Input
								accessoryRight={() => (
									<TouchableOpacity
										onPress={() =>
											confirmNewPasswordVisible.set(
												!confirmNewPasswordVisible.get
											)
										}
									>
										<AppIcon
											lib="ion"
											name={
												confirmNewPasswordVisible.get
													? 'eye-off'
													: 'eye-outline'
											}
											size={20}
											style={styles.form.icon}
										/>
									</TouchableOpacity>
								)}
								caption={() => (
									<InputError
										errors={resetPassword.formState.errors.confirmNewPassword}
									/>
								)}
								defaultValue=""
								label="Confirm new password"
								placeholder=""
								secureTextEntry={!confirmNewPasswordVisible.get}
								status={
									control.formState.errors.confirmNewPassword ? 'danger' : 'basic'
								}
								style={styles.form.control}
								testID={NavigationComponentLocators.confirmPasswordField}
								value={control.field.value!}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
					<Button
						appearance="filled"
						disabled={
							!resetPassword.formState.isDirty || !resetPassword.formState.isValid
						}
						status="primary"
						style={styles.form.save}
						testID={NavigationComponentLocators.saveButton}
						onPress={resetPassword.handleSubmit(onSave)}
					>
						Save
					</Button>
				</Layout>
			</AppModal>
			<AppModal presentationStyle="pageSheet" visible={showPasswordChangedSuccess.get}>
				<Layout style={styles.main.container}>
					<TouchableOpacity
						style={styles.main.close}
						onPress={() => showPasswordChangedSuccess.set(false)}
					>
						<AppIcon lib="ion" name="close" size={20} />
					</TouchableOpacity>
					<View style={styles.success.container}>
						<SuccessSVG height={110} style={styles.success.icon} width={110} />
						<Text category="h4" style={styles.success.text}>
							Password has been changed
						</Text>
						<Button
							appearance="filled"
							status="primary"
							style={styles.success.button}
							testID={NavigationComponentLocators.passwordSuccessCloseButton}
							onPress={() => showPasswordChangedSuccess.set(false)}
						>
							CLOSE
						</Button>
					</View>
				</Layout>
			</AppModal>
			<AppModal presentationStyle="pageSheet" visible={showPasswordChangedFail.get}>
				<Layout style={styles.main.container}>
					<TouchableOpacity
						style={styles.main.close}
						onPress={() => {
							props.onClose();
							showPasswordChangedFail.set(false);
						}}
					>
						<AppIcon lib="ion" name="close" size={20} />
					</TouchableOpacity>
					<View style={styles.success.container}>
						<FailSVG height={110} style={styles.success.icon} width={110} />
						<Text category="h4" style={styles.success.text}>
							Password Reset Failed
						</Text>
						<Button
							appearance="filled"
							status="primary"
							style={styles.success.button}
							testID={NavigationComponentLocators.passwordFailCloseButton}
							onPress={() => showPasswordChangedFail.set(false)}
						>
							TRY AGAIN
						</Button>
					</View>
				</Layout>
			</AppModal>
		</>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return {
		main: StyleSheet.create({
			container: {
				minWidth: 480,
				minHeight: 528,
				padding: 30,
			},
			header: {
				marginVertical: 16,
			},
			close: {
				alignSelf: 'flex-end',
			},
		}),
		form: StyleSheet.create({
			control: {
				marginTop: 16,
			},
			save: {
				marginTop: 32,
			},
			icon: {
				color: theme['color-basic-600'],
			},
		}),
		success: StyleSheet.create({
			container: {
				alignItems: 'center',
			},
			icon: {
				marginTop: 100,
			},
			text: {
				marginTop: 50,
				marginBottom: 32,
			},
			button: {
				width: 343,
			},
		}),
	};
}
