import { ApolloError, gql, useMutation } from '@apollo/client';
import {
	RolloverFromType,
	EditFundingScreen_EditFundingDetailsDistribution,
	EditFundingScreen_EditFundingDetailsDistributionVariables,
	FundingType,
	FundingStage,
	AccountType,
	RolloverType,
	FundingDirection,
	CurrencyType,
	EditFundingScreen_GetFunding_funding as FundingDetailsType,
	ContributionType,
	UpdateFundingInput,
	CustodianType,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { FundingDetailsLocators } from '@app/e2e/screens/EditAccount';
import { CustomCard, DangerAssetSVG, InputError } from '@app/shared/components';
import { accountTypeHumanizer } from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppSelector,
	decimalStr,
	maskCurrency,
	numericStr,
	humanize,
	useAppState,
	toCurrency,
	unMaskCurrency,
	AppAlert,
	useAppearance,
	useAppTheme,
} from '@itrustcapital/ui';
import { Button, CheckBox, Input, Spinner, Text } from '@ui-kitten/components';
import {
	EditFundingScreen_CompleteFunding,
	EditFundingScreen_CompleteFundingVariables,
	CompleteFundingInput,
} from 'codegen';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';
import { TypeOf } from 'yup';

import { EDIT_FUNDING_SCREEN_GET_FUNDING } from '../EditFundingScreen';

interface FundingDetailsProps {
	isFortress?: boolean;
	style?: StyleProp<ViewStyle>;
	fundingId: number;
	fundingDetails?: FundingDetailsType | null;
	loading?: boolean;
}

export const EDIT_FUNDING_SCREEN_COMPLETE_FUNDING = gql`
	mutation EditFundingScreen_CompleteFunding($input: CompleteFundingInput!) {
		completeFunding(input: $input) {
			success
			errorMessage
		}
	}
`;

export const EDIT_FUNDING_SCREEN_EDIT_FUNDINGS_DISTRIBUTION_DETAILS = gql`
	mutation EditFundingScreen_EditFundingDetailsDistribution($input: UpdateFundingInput!) {
		updateFunding(input: $input) {
			success
			errorMessage
		}
	}
`;

const schema = ({ isFortress }: { isFortress: boolean }) =>
	yup
		.object({
			fundingType: yup
				.mixed<FundingType>()
				.oneOf(Object.values(FundingType))
				.default(undefined),
			fundingDirectionType: yup
				.mixed<FundingDirection>()
				.oneOf(Object.values(FundingDirection))
				.default(FundingDirection.NONE),
			rolloverType: yup
				.mixed<RolloverType>()
				.when(['fundingType'], {
					is: (fundingType: FundingType) => fundingType === FundingType.ROLLOVER,
					then: yup
						.mixed<RolloverType>()
						.oneOf(Object.values(RolloverType))
						.required()
						.default(undefined),
				})
				.default(undefined),
			rolloverFrom: yup
				.mixed<RolloverFromType>()
				.when(['fundingType', 'fundingDirectionType'], {
					is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
						fundingType === FundingType.ROLLOVER &&
						fundingDirectionType === FundingDirection.INBOUND,
					then: yup
						.mixed<RolloverFromType>()
						.oneOf(Object.values(RolloverFromType))
						.required()
						.default(undefined),
				})
				.default(undefined),
			fromAccountType: yup
				.mixed<AccountType>()
				.when(['fundingType', 'fundingDirectionType'], {
					is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
						fundingType !== FundingType.CONTRIBUTION &&
						fundingDirectionType === FundingDirection.INBOUND,
					then: yup
						.mixed<AccountType>()
						.oneOf(Object.values(AccountType))
						.required()
						.default(undefined),
				})
				.default(undefined),
			fromInstitution: yup
				.string()
				.when(['fundingType', 'fundingDirectionType'], {
					is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
						fundingType !== FundingType.CONTRIBUTION &&
						fundingDirectionType === FundingDirection.INBOUND,
					then: yup.string().required().default(''),
				})
				.default(''),
			fromAccountNumber: yup
				.string()
				.when(['fundingType', 'fundingDirectionType'], {
					is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
						fundingType !== FundingType.CONTRIBUTION &&
						fundingDirectionType === FundingDirection.INBOUND,
					then: yup.string().default(''),
				})
				.default(''),
			toAccountType: yup
				.mixed<AccountType>()
				.when(['fundingDirectionType'], {
					is: (fundingDirectionType: FundingDirection) =>
						fundingDirectionType === FundingDirection.OUTBOUND,
					then: yup
						.mixed<AccountType>()
						.oneOf(Object.values(AccountType))
						.required()
						.default(undefined),
				})
				.default(undefined),
			toInstitution: yup
				.string()
				.when(['fundingDirectionType'], {
					is: (fundingDirectionType: FundingDirection) =>
						fundingDirectionType === FundingDirection.OUTBOUND,
					then: yup.string().required().default(''),
				})
				.default(''),
			toAccountNumber: yup
				.string()
				.when(['fundingDirectionType'], {
					is: (fundingDirectionType: FundingDirection) =>
						fundingDirectionType === FundingDirection.OUTBOUND,
					then: yup.string().required().default(''),
				})
				.default(''),
			contributionYear: yup
				.string()
				.when(['fundingType'], {
					is: (fundingType: FundingType) => fundingType === FundingType.CONTRIBUTION,
					then: yup.string().required().default(''),
				})
				.default(''),
			contributionType: yup
				.mixed<ContributionType>()
				.when(['fundingType'], {
					is: (fundingType: FundingType) => fundingType === FundingType.CONTRIBUTION,
					then: yup
						.mixed<ContributionType>()
						.oneOf(Object.values(ContributionType))
						.default(undefined),
				})
				.default(undefined),
			transactionCode: yup
				.string()
				.max(2, 'Transaction code must be at most 2 characters')
				.nullable()
				.default(''),
			isFortress: yup.boolean().default(false),
			fortressTransactionId: yup
				.string()
				.default('')
				.when('isFortress', {
					is: () => isFortress,
					then: yup.string().required('Fortress Transaction ID is a required field.'),
				}),
			fortressPaymentId: yup.string().nullable().default(''),
			transactionId: yup
				.string()
				.default('')
				.when('isFortress', {
					is: () => !isFortress,
					then: yup.string().required('Transaction ID is a required field.'),
				}),
			paymentId: yup.string().nullable().default(''),
			distributionCode: yup
				.string()
				.max(4, 'Distribution code must be at most 4 characters')
				.nullable()
				.default(''),
			stateWithholdings: yup.string().default(''),
			federalWithholdings: yup.string().default(''),
			paymentType: yup.string().default(''),
			fedWHTpaymentType: yup.string().default(''),
			stateWHTpaymentType: yup.string().default(''),
			distributedToClient: yup.string().default(''),
			federalWithholdingsTransactionId: yup.string().nullable().default(''),
			stateWithholdingsTransactionId: yup.string().nullable().default(''),
			distributedTotalAmount: yup.string().default(''),
			microTestAmount: yup.string().default(''),
			showMicroTest: yup.boolean().default(false),
			microTestTransactionId: yup
				.string()
				.default('')
				.when('showMicroTest', {
					is: (showMicroTest: boolean) => showMicroTest === true,
					then: yup.string().required('Micro Test ID is a required field.'),
				}),
			microTestPaymentType: yup.string().default(''),
			microTestAmountConfirmed: yup.boolean().when('showMicroTest', {
				is: (showMicroTest: boolean) => showMicroTest === true,
				then: yup
					.boolean()
					.test(
						'showMicroTest',
						'Client must confirm Micro Test Transaction.',
						(value) => {
							if (value) {
								return true;
							}

							return false;
						}
					)
					.required('Micro Test Transaction Confirmation is a required field.'),
			}),
		})
		.defined();

export function FundingDetailsDistribution(props: FundingDetailsProps) {
	const styles = useCustomStyles();

	const theme = useAppTheme();
	const { dark } = useAppearance();

	const isEditing = useAppState<boolean>(false);
	const showError = useAppState(false);
	const errorMessage = useAppState('');
	const defaultFailedMessage = 'Sorry, something went wrong. Please try again.';

	const funding = props.fundingDetails;
	const custodian =
		funding?.custodian === CustodianType.FORTRESS ? 'Fortress' : 'iTrust Custodial Services';

	const options = { shouldTouch: true };

	const formSchema = React.useMemo(() => {
		return schema({
			isFortress: props?.isFortress || false,
		});
	}, [props?.isFortress]);

	type FundingDetailsData = TypeOf<typeof formSchema>;

	const form = useForm<FundingDetailsData>({
		mode: 'onBlur',
		criteriaMode: 'all',
		resolver: yupResolver(formSchema),
		defaultValues: formSchema.cast({}),
	});
	const formWatch = form.watch();

	const USDAsset = props.fundingDetails?.fundingAssets?.find(
		(fundingAsset) => fundingAsset?.currencyType === CurrencyType.USD
	);
	const inKindAsset = props.fundingDetails?.fundingAssets?.find(
		(fundingAsset) =>
			fundingAsset?.currencyType !== CurrencyType.USD &&
			fundingAsset?.currencyType !== CurrencyType.KMG &&
			fundingAsset?.currencyType !== CurrencyType.KMS
	);

	const showMicroTest = !!inKindAsset;

	const maestroUserAtom = useMaestroUserAtom();

	const isAdmin = !isEditing.get || !maestroUserAtom.isAdmin;
	const isFormDisabledBase = isAdmin || disabledAfterStage();

	const [editFunding, editFundingMutation] = useMutation<
		EditFundingScreen_EditFundingDetailsDistribution,
		EditFundingScreen_EditFundingDetailsDistributionVariables
	>(EDIT_FUNDING_SCREEN_EDIT_FUNDINGS_DISTRIBUTION_DETAILS, {
		refetchQueries: [EDIT_FUNDING_SCREEN_GET_FUNDING],
		fetchPolicy: 'network-only',
	});

	const [completeFunding, completeFundingMutation] = useMutation<
		EditFundingScreen_CompleteFunding,
		EditFundingScreen_CompleteFundingVariables
	>(EDIT_FUNDING_SCREEN_COMPLETE_FUNDING, {
		refetchQueries: [EDIT_FUNDING_SCREEN_GET_FUNDING],
		fetchPolicy: 'network-only',
	});

	React.useEffect(() => {
		if (funding) {
			prefill(funding);
		}
	}, [funding]);

	React.useEffect(() => {
		form.setValue('isFortress', props?.isFortress || false);
	}, [props?.isFortress]);

	function prefill(funding: FundingDetailsType) {
		form.setValue('fundingType', funding.type, options);
		form.setValue('fundingDirectionType', funding.direction, options);
		form.setValue('rolloverType', funding.rolloverType!, options);
		form.setValue('rolloverFrom', funding.rolloverFrom!, options);
		form.setValue('transactionCode', funding.transactionCode || '', options);
		form.setValue('distributionCode', funding.distributionCode || '', options);
		form.setValue(
			'stateWithholdings',
			funding?.stateWithholdings ? toCurrency(funding.stateWithholdings) : '$',
			options
		);
		form.setValue(
			'federalWithholdings',
			funding?.federalWithholdings ? toCurrency(funding.federalWithholdings) : '$',
			options
		);

		let initialDistributedToClient = '';

		if (funding?.distributedToClient) {
			initialDistributedToClient = USDAsset
				? toCurrency(funding.distributedToClient)
				: funding.distributedToClient;
		}
		form.setValue('distributedToClient', initialDistributedToClient, options);

		let initialDistributedTotalAmount = '';

		if (funding?.distributedTotalAmount) {
			initialDistributedTotalAmount = USDAsset
				? toCurrency(funding.distributedTotalAmount)
				: funding.distributedTotalAmount;
		}
		form.setValue('distributedTotalAmount', initialDistributedTotalAmount, options);

		form.setValue(
			'paymentType',
			USDAsset ? humanize(USDAsset?.currencyType)?.toUpperCase() : 'In-Kind',
			options
		);
		form.setValue(
			'fedWHTpaymentType',
			humanize(USDAsset?.currencyType!)?.toUpperCase() || '',
			options
		);
		form.setValue(
			'stateWHTpaymentType',
			humanize(USDAsset?.currencyType!)?.toUpperCase() || '',
			options
		);
		form.setValue(
			'fortressTransactionId',
			funding.fundingAssets?.at(0)?.assetMovement?.fortressTransactionId || '',
			options
		);
		form.setValue(
			'fortressPaymentId',
			funding.fundingAssets?.at(0)?.assetMovement?.fortressPaymentId || '',
			options
		);
		form.setValue('transactionId', funding.transactionId || '', options);
		form.setValue('paymentId', funding.paymentId || '', options);
		form.setValue(
			'federalWithholdingsTransactionId',
			funding.federalWithholdingsTransactionId || '',
			options
		);
		form.setValue(
			'stateWithholdingsTransactionId',
			funding.stateWithholdingsTransactionId || '',
			options
		);

		setMicroTest(funding);
		setFrom();
		setTo();
	}

	function setMicroTest(funding: FundingDetailsType) {
		if (USDAsset) {
			return;
		}

		form.setValue('microTestAmount', funding?.microTestAmount || '', options);
		form.setValue('microTestTransactionId', funding.microTestTransactionId || '', options);
		form.setValue('microTestPaymentType', (inKindAsset && 'In-Kind') || '', options);
		form.setValue('showMicroTest', showMicroTest, options);
		form.setValue(
			'microTestAmountConfirmed',
			funding.microTestAmountConfirmed || undefined,
			options
		);
		form.setValue('distributedToClient', funding?.distributedToClient || '', options);
		form.setValue('distributedTotalAmount', funding?.distributedTotalAmount || '', options);
	}

	function rolloverFromRequired(
		fundingType?: FundingType,
		fundingDirectionType?: FundingDirection
	): boolean {
		return (
			fundingType === FundingType.ROLLOVER &&
			fundingDirectionType === FundingDirection.INBOUND
		);
	}

	function setFrom() {
		const isInternal = !!funding?.fromAccount;

		form.setValue(
			'fromAccountType',
			isInternal ? funding?.fromAccount?.type : funding?.fromExternalAccount?.accountType,
			options
		);
		form.setValue(
			'fromInstitution',
			funding?.direction === FundingDirection.OUTBOUND || funding?.fromAccount
				? custodian
				: funding?.fromExternalAccount?.institutionName! || '',
			options
		);
		form.setValue(
			'fromAccountNumber',
			isInternal
				? funding?.fromAccount?.accountNumber!
				: funding?.fromExternalAccount?.accountNumber! || '',
			options
		);
	}

	function setTo() {
		const isInternal = !!funding?.toAccount;

		form.setValue(
			'toAccountType',
			isInternal ? funding?.toAccount?.type : funding?.toExternalAccount?.accountType,
			options
		);
		form.setValue(
			'toInstitution',
			funding?.direction === FundingDirection.INBOUND || funding?.toAccount
				? custodian
				: funding?.toExternalAccount?.institutionName!,
			options
		);
		form.setValue(
			'toAccountNumber',
			isInternal
				? funding?.toAccount?.accountNumber!
				: funding?.toExternalAccount?.accountNumber!,
			options
		);
	}

	// Outbound
	function isFromDisabled(): boolean {
		return (
			isFormDisabledBase ||
			!!props.fundingDetails?.fromAccount ||
			formWatch.fundingDirectionType === FundingDirection.OUTBOUND
		);
	}

	// Inbound
	function isToDisabled(): boolean {
		return (
			isFormDisabledBase ||
			!!props.fundingDetails?.toAccount ||
			formWatch.fundingDirectionType === FundingDirection.INBOUND
		);
	}

	function disabledAfterStage(): boolean {
		return (
			props.fundingDetails?.stage === FundingStage.ASSET_TRANSFER_PENDING ||
			props.fundingDetails?.stage === FundingStage.ASSET_TRANSFER_COMPLETED
		);
	}

	// actually completes it and moves the stage to asset transfer complete
	async function onSaveDetails(data: FundingDetailsData) {
		const stateWithholdings = unMaskCurrency(data?.stateWithholdings);
		const federalWithholdings = unMaskCurrency(data.federalWithholdings);

		const stateWithholdingsId = formWatch.stateWithholdingsTransactionId;
		const federalWithholdingsId = formWatch.federalWithholdingsTransactionId;

		let fundingInput: UpdateFundingInput = {
			id: Number(props.fundingId),
			type: data.fundingType ? data.fundingType : undefined,
			rolloverType:
				data.rolloverType && data.fundingType === FundingType.ROLLOVER
					? data.rolloverType
					: null,
			rolloverFrom: rolloverFromRequired(data.fundingType, data.fundingDirectionType)
				? data.rolloverFrom
				: null,
			transactionCode: data.transactionCode ? data.transactionCode : undefined,
			fortressTransactionId: data.fortressTransactionId || undefined,
			transactionId: data.transactionId || undefined,
			distributionCode: data.distributionCode ? decimalStr(data.distributionCode) : undefined,
			fromExternalAccount:
				isToDisabled() && props.fundingDetails?.fromExternalAccount
					? {
							id: props.fundingDetails.fromExternalAccount.id,
							institutionName: data.fromInstitution || '',
							accountType: data.fromAccountType,
							accountNumber: data.fromAccountNumber || '',
					  }
					: undefined,
			toExternalAccount:
				isFromDisabled() && props.fundingDetails?.toExternalAccount?.id
					? {
							id: props.fundingDetails?.toExternalAccount?.id!,
							institutionName: data.toInstitution || '',
							accountType: data.toAccountType,
							accountNumber: data.toAccountNumber || '',
					  }
					: undefined,
			distributedToClient: data?.distributedToClient
				? unMaskCurrency(data?.distributedToClient)
				: undefined,
			microTestAmount: data?.microTestAmount
				? unMaskCurrency(data?.microTestAmount)
				: undefined,
		};

		if (data.contributionYear && data.fundingType === FundingType.CONTRIBUTION) {
			fundingInput = { ...fundingInput, contributionYear: Number(data.contributionYear) };
		}

		if (data.contributionType && data.fundingType === FundingType.CONTRIBUTION) {
			fundingInput = { ...fundingInput, contributionType: data.contributionType };
		}

		if (stateWithholdingsId) {
			const stateWitholdingTransactionIdKey = props?.isFortress
				? 'stateWithholdingsFortressTransactionId'
				: 'stateWithholdingsTransactionId';

			fundingInput = {
				...fundingInput,
				[stateWitholdingTransactionIdKey]: formWatch.stateWithholdingsTransactionId,
			};
		}

		if (stateWithholdings) {
			fundingInput = {
				...fundingInput,
				stateWithholdings,
			};
		}

		if (federalWithholdingsId) {
			const federalWitholdingTransactionIdKey = props?.isFortress
				? 'federalWithholdingsFortressTransactionId'
				: 'federalWithholdingsTransactionId';

			fundingInput = {
				...fundingInput,
				[federalWitholdingTransactionIdKey]: formWatch.stateWithholdingsTransactionId,
			};
		}

		if (federalWithholdings) {
			fundingInput = {
				...fundingInput,
				federalWithholdings,
			};
		}

		if (inKindAsset) {
			fundingInput = {
				...fundingInput,
				microTestTransactionId: formWatch.microTestTransactionId,
				microTestAmountConfirmed: formWatch.microTestAmountConfirmed,
			};
		}

		const input: UpdateFundingInput = fundingInput;

		try {
			const response = await editFunding({
				variables: { input },
			});

			isEditing.set(false);

			if (response.data?.updateFunding) {
				if (!response.data?.updateFunding.success) {
					errorMessage.set(
						response.data?.updateFunding.errorMessage || defaultFailedMessage
					);
					showError.set(true);
				} else {
					Toast.show({
						type: 'success',
						text2: 'Successfully saved funding',
					});
				}
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				Toast.show({
					type: 'error',
					text2: error.message,
				});
			}
		}
	}

	// actually completes it and moves the stage to asset transfer complete
	async function onCompleteSubmit(data: FundingDetailsData) {
		const stateWithholdings = unMaskCurrency(data?.stateWithholdings);
		const federalWithholdings = unMaskCurrency(data.federalWithholdings);

		const stateWithholdingsId = formWatch.stateWithholdingsTransactionId;
		const federalWithholdingsId = formWatch.federalWithholdingsTransactionId;

		let fundingInput: CompleteFundingInput = {
			id: Number(props.fundingId),
			type: data.fundingType ? data.fundingType : undefined,
			rolloverType:
				data.rolloverType && data.fundingType === FundingType.ROLLOVER
					? data.rolloverType
					: null,
			rolloverFrom: rolloverFromRequired(data.fundingType, data.fundingDirectionType)
				? data.rolloverFrom
				: null,
			transactionCode: data.transactionCode ? data.transactionCode : undefined,
			fortressTransactionId: data.fortressTransactionId || undefined,
			distributionCode: data.distributionCode ? decimalStr(data.distributionCode) : undefined,
			fromExternalAccount:
				isToDisabled() && props.fundingDetails?.fromExternalAccount
					? {
							id: props.fundingDetails.fromExternalAccount.id,
							institutionName: data.fromInstitution || '',
							accountType: data.fromAccountType,
							accountNumber: data.fromAccountNumber || '',
					  }
					: undefined,
			toExternalAccount:
				isFromDisabled() && props.fundingDetails?.toExternalAccount?.id
					? {
							id: props.fundingDetails?.toExternalAccount?.id!,
							institutionName: data.toAccountNumber || '',
							accountType: data.toAccountType,
							accountNumber: data.toAccountNumber || '',
					  }
					: undefined,
		};

		if (data.contributionYear && data.fundingType === FundingType.CONTRIBUTION) {
			fundingInput = { ...fundingInput, contributionYear: Number(data.contributionYear) };
		}

		if (data.contributionType && data.fundingType === FundingType.CONTRIBUTION) {
			fundingInput = { ...fundingInput, contributionType: data.contributionType };
		}

		if (stateWithholdingsId) {
			fundingInput = {
				...fundingInput,
				stateWithholdingsFortressTransactionId: formWatch.stateWithholdingsTransactionId,
			};
		}

		if (stateWithholdings) {
			fundingInput = {
				...fundingInput,
				stateWithholdings,
				stateWithholdingsFortressTransactionId: formWatch.stateWithholdingsTransactionId,
			};
		}

		if (federalWithholdingsId) {
			fundingInput = {
				...fundingInput,
				federalWithholdingsFortressTransactionId:
					formWatch.federalWithholdingsTransactionId,
			};
		}

		if (federalWithholdings) {
			fundingInput = {
				...fundingInput,
				federalWithholdings,
				federalWithholdingsFortressTransactionId:
					formWatch.federalWithholdingsTransactionId,
			};
		}

		if (inKindAsset) {
			fundingInput = {
				...fundingInput,
				microTestTransactionId: formWatch.microTestTransactionId,
				microTestAmountConfirmed: formWatch.microTestAmountConfirmed,
			};
		}

		const input: CompleteFundingInput = fundingInput;

		try {
			const response = await completeFunding({
				variables: { input },
			});

			isEditing.set(false);

			if (response.data?.completeFunding) {
				if (!response.data?.completeFunding.success) {
					errorMessage.set(
						response.data?.completeFunding.errorMessage || defaultFailedMessage
					);
					showError.set(true);
				} else {
					Toast.show({
						type: 'success',
						text2: 'Successfully saved funding',
					});
				}
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				Toast.show({
					type: 'error',
					text2: error.message,
				});
			}
		}
	}

	function onCancel() {
		if (props.fundingDetails) {
			prefill(props.fundingDetails);
		}
		isEditing.set(false);
	}

	return (
		<CustomCard
			footer={() => (
				<View style={styles.actions.container}>
					{isEditing.get ? (
						<>
							<Button
								appearance="outline"
								style={styles.actions.leftButton}
								testID={FundingDetailsLocators.cancelButton}
								onPress={onCancel}
							>
								Cancel
							</Button>
							{/* Saves but does not move the stage to asset transfer complete */}
							<Button
								style={styles.actions.leftButton}
								testID={FundingDetailsLocators.saveButton}
								onPress={form.handleSubmit(onSaveDetails)}
							>
								{editFundingMutation?.loading ? (
									<View>
										<Spinner size="tiny" status="basic" />
									</View>
								) : (
									'Save'
								)}
							</Button>

							{/* Saves AND moves the stage to asset transfer complete */}
							{props?.isFortress && (
								<Button
									status="success"
									disabled={
										funding?.stage === FundingStage.ASSET_TRANSFER_COMPLETED
									}
									testID={FundingDetailsLocators.completeButton}
									onPress={form.handleSubmit(onCompleteSubmit)}
								>
									{completeFundingMutation?.loading ? (
										<View>
											<Spinner size="tiny" status="basic" />
										</View>
									) : (
										'Complete'
									)}
								</Button>
							)}
						</>
					) : (
						<>
							<Button
								testID={FundingDetailsLocators.editButton}
								disabled={funding?.stage === FundingStage.CANCELED}
								onPress={() => isEditing.set(true)}
							>
								Edit
							</Button>
						</>
					)}
				</View>
			)}
			header={() => (
				<View style={styles.main.header}>
					<Text category="h6">Funding Details</Text>
				</View>
			)}
			loading={props.loading}
			style={props.style}
		>
			<View>
				{/* FIRST ROW */}
				<View style={styles.main.row}>
					{/* Funding Type */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="fundingType"
							render={(control) => (
								<Input
									disabled
									label="Funding Type"
									value={humanize(control.field.value!)}
								/>
							)}
						/>
					</View>

					{!USDAsset ? (
						<>
							<View style={styles.main.col2}>
								<Text
									style={{
										fontSize: 12,
										color: dark
											? theme['color-basic-100']
											: theme['shadow-color-100'],
									}}
								>
									*CLIENT IS RESPONSIBLE FOR STATE AND FEDERAL WITHHOLDING(S)
								</Text>
							</View>
						</>
					) : (
						<>
							<View style={styles.main.col} />
							<View style={styles.main.col} />
						</>
					)}
				</View>

				{/* SECOND ROW */}
				<View style={styles.main.row}>
					{/* Funding Direction */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="fundingDirectionType"
							render={(control) => (
								<Input
									disabled
									label="Funding Direction"
									value={humanize(control.field.value!)}
								/>
							)}
						/>
					</View>

					{/* Transaction Code */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="transactionCode"
							render={(control) => (
								<Input
									disabled={!isEditing.get}
									label="Transaction Code"
									maxLength={2}
									caption={
										<InputError
											errors={form.formState.errors.transactionCode}
										/>
									}
									status={
										control.formState.errors.transactionCode
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.transactionCodeField}
									value={control.field.value!}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>

					{/* Distribution Code */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="distributionCode"
							render={(control) => (
								<Input
									disabled={!isEditing.get}
									label="Distribution Code"
									maxLength={4}
									status={
										control.formState.errors.distributionCode
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.distributionCodeField}
									value={control.field.value!}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				</View>

				{/* THIRD ROW - IN-KIND ASSETS */}

				<View style={inKindAsset ? styles.main.row : styles.main.hide}>
					{/* Micro Test Amount */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="microTestAmount"
							render={(control) => (
								<Input
									disabled={!isEditing.get || props?.isFortress}
									label="Micro Test Amount"
									caption={
										<InputError
											errors={form.formState.errors.microTestAmount}
										/>
									}
									status={
										control.formState.errors.microTestAmount
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.microTestAmountField}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>

					{/* Micro Test Transaction ID */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="microTestTransactionId"
							render={(control) => (
								<Input
									disabled={!isEditing.get}
									label="Micro Test Transaction ID *"
									caption={
										<InputError
											errors={form.formState.errors.microTestTransactionId}
										/>
									}
									status={
										control.formState.errors.microTestTransactionId
											? 'danger'
											: 'basic'
									}
									value={control.field.value!}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>

					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="microTestPaymentType"
							render={(control) => (
								<Input disabled label="Payment Type" value={control.field.value} />
							)}
						/>
					</View>
				</View>
				<View style={inKindAsset ? styles.main.row : styles.main.hide}>
					<View style={styles.main.col2}>
						<Controller
							control={form.control}
							name="microTestAmountConfirmed"
							render={(control) => (
								<>
									<CheckBox
										disabled={!isEditing.get}
										checked={!!control.field.value}
										testID={FundingDetailsLocators.microTestAmountConfirmed}
										onBlur={control.field.onBlur}
										onChange={control.field.onChange}
									>
										Client confirms micro transaction has been received and has
										verified the amount is accurate.
									</CheckBox>
									<InputError
										errors={form.formState.errors.microTestAmountConfirmed}
									/>
								</>
							)}
						/>
					</View>
				</View>

				{/* THIRD ROW */}
				<View style={styles.main.row}>
					{/* Distributed to Client */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="distributedToClient"
							render={(control) => (
								<Input
									disabled={!isEditing.get || props?.isFortress}
									label="Distributed to Client"
									caption={
										<InputError
											errors={form.formState.errors.distributedToClient}
										/>
									}
									status={
										control.formState.errors.distributedToClient
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.distributedToClientField}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={(value) => {
										if (USDAsset) {
											return control.field.onChange(maskCurrency(value));
										}

										control.field.onChange(value);
									}}
								/>
							)}
						/>
					</View>
					{/*transaction ID */}
					<View style={styles.main.col}>
						{!props?.isFortress && (
							<Controller
								control={form.control}
								name="transactionId"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Transaction ID *"
										caption={
											<InputError
												errors={form.formState.errors.transactionId}
											/>
										}
										status={
											control.formState.errors.transactionId
												? 'danger'
												: 'basic'
										}
										value={control.field.value!}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						)}

						{props?.isFortress && (
							<Controller
								control={form.control}
								name="fortressTransactionId"
								render={(control) => (
									<Input
										disabled={!isEditing.get}
										label="Fortress Transaction ID *"
										caption={
											<InputError
												errors={form.formState.errors.fortressTransactionId}
											/>
										}
										status={
											control.formState.errors.fortressTransactionId
												? 'danger'
												: 'basic'
										}
										value={control.field.value!}
										onBlur={control.field.onBlur}
										onChangeText={control.field.onChange}
									/>
								)}
							/>
						)}
					</View>

					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="paymentType"
							render={(control) => (
								<Input disabled label="Payment Type" value={control.field.value} />
							)}
						/>
					</View>
				</View>

				{/* FOURTH ROW - ONLY USD ASSETS */}
				{USDAsset && (
					<>
						<View style={styles.main.row}>
							{/* Federal Withholdings */}
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name="federalWithholdings"
									render={(control) => (
										<Input
											disabled={!isEditing.get || props?.isFortress}
											label="Federal Withholdings"
											status={
												control.formState.errors.federalWithholdings
													? 'danger'
													: 'basic'
											}
											testID={FundingDetailsLocators.federalWithholdingsField}
											value={control.field.value}
											onBlur={control.field.onBlur}
											onChangeText={(text) => {
												const formatted = maskCurrency(text);
												control.field.onChange(formatted);
											}}
										/>
									)}
								/>
							</View>
							{/* Federal Withholdings transaction ID */}
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name="federalWithholdingsTransactionId"
									render={(control) => (
										<Input
											disabled={!isEditing.get}
											label="Fed WHT Transaction ID"
											status={
												control.formState.errors
													.federalWithholdingsTransactionId
													? 'danger'
													: 'basic'
											}
											testID={
												FundingDetailsLocators.federalWithholdingsTransactionId
											}
											value={control.field.value!}
											onBlur={control.field.onBlur}
											onChangeText={control.field.onChange}
										/>
									)}
								/>
							</View>
							{/*Fed WHT Payment Type*/}
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name="fedWHTpaymentType"
									render={(control) => (
										<Input
											disabled
											label="Payment Type"
											value={control.field.value}
										/>
									)}
								/>
							</View>
						</View>

						{/* FIFTH ROW */}
						<View style={styles.main.row}>
							{/* State Withholdings */}
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name="stateWithholdings"
									render={(control) => (
										<Input
											disabled={!isEditing.get || props?.isFortress}
											label="State Withholdings"
											status={
												control.formState.errors.stateWithholdings
													? 'danger'
													: 'basic'
											}
											testID={FundingDetailsLocators.stateWithholdingsField}
											value={control.field.value}
											onBlur={control.field.onBlur}
											onChangeText={(text) => {
												const formatted = maskCurrency(text);
												control.field.onChange(formatted);
											}}
										/>
									)}
								/>
							</View>

							{/* State WHT transaction ID */}
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name="stateWithholdingsTransactionId"
									render={(control) => (
										<Input
											disabled={!isEditing.get}
											label="State WHT Transaction ID"
											status={
												control.formState.errors
													.stateWithholdingsTransactionId
													? 'danger'
													: 'basic'
											}
											testID={
												FundingDetailsLocators.stateWithholdingsTransactionId
											}
											value={control.field.value!}
											onBlur={control.field.onBlur}
											onChangeText={control.field.onChange}
										/>
									)}
								/>
							</View>

							{/* State WHT Payment */}
							{USDAsset && (
								<View style={styles.main.col}>
									<Controller
										control={form.control}
										name="stateWHTpaymentType"
										render={(control) => (
											<Input
												disabled
												label="Payment Type"
												value={control.field.value}
											/>
										)}
									/>
								</View>
							)}
						</View>
					</>
				)}

				{/* SIXTH ROW */}
				<View style={styles.main.row}>
					<>
						{/* From Account Type */}
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="fromAccountType"
								render={(control) => (
									<AppSelector
										closeOnSelect
										data={Object.values(AccountType).filter(
											(x) => x !== AccountType.NONE
										)}
										disabled={isFromDisabled()}
										itemDisplay={accountTypeHumanizer}
										label="From Account Type *"
										status={
											control.formState.errors.fromAccountType
												? 'danger'
												: 'basic'
										}
										testID={FundingDetailsLocators.fromAccountTypeField}
										value={control.field.value}
										onSelect={control.field.onChange}
									/>
								)}
							/>
						</View>
					</>

					{/* From Custodian Institution */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							defaultValue=""
							name="fromInstitution"
							render={(control) => (
								<Input
									disabled={isFromDisabled()}
									label="From Custodian Instiution *"
									status={
										control.formState.errors.fromInstitution
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.fromInstitutionField}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>

					{/* From Account Number */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="fromAccountNumber"
							render={(control) => (
								<Input
									disabled={isFromDisabled()}
									label="From Custodian Account Number *"
									status={
										control.formState.errors.fromAccountNumber
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.fromAccountNumberField}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={(text) =>
										control.field.onChange(numericStr(text))
									}
								/>
							)}
						/>
					</View>
				</View>

				{/* SIXTH ROW */}
				<View style={styles.main.row}>
					{/* To Account Type */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="toAccountType"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={Object.values(AccountType).filter(
										(x) => x !== AccountType.NONE
									)}
									disabled={isToDisabled()}
									itemDisplay={accountTypeHumanizer}
									label="To Account Type *"
									status={
										control.formState.errors.toAccountType ? 'danger' : 'basic'
									}
									testID={FundingDetailsLocators.toAccountTypeField}
									value={control.field.value}
									onSelect={control.field.onChange}
								/>
							)}
						/>
					</View>

					{/* To Custodian Institution */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="toInstitution"
							render={(control) => (
								<Input
									disabled={isToDisabled()}
									label="To Custodian Institution *"
									status={
										control.formState.errors.toInstitution ? 'danger' : 'basic'
									}
									testID={FundingDetailsLocators.toInstitutionField}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>

					{/* Total Amount */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="distributedTotalAmount"
							render={(control) => (
								<Input
									disabled
									label="Total Amount"
									status={
										control.formState.errors.distributedTotalAmount
											? 'danger'
											: 'basic'
									}
									testID={FundingDetailsLocators.distributedTotalAmountField}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={(text) => {
										const formatted = maskCurrency(text);
										control.field.onChange(formatted);
									}}
								/>
							)}
						/>
					</View>
				</View>
				<AppAlert
					actions={[
						{
							title: 'OK',
							status: 'danger',
							appearance: 'filled',
							onPress: () => {
								showError.set(false);
							},
						},
					]}
					message={errorMessage.get}
					title="Invalid Entry"
					visible={showError.get}
					xmlIcon={DangerAssetSVG}
				/>
			</View>
		</CustomCard>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			separator: {
				paddingHorizontal: 24,
			},
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				marginVertical: 8,
				marginHorizontal: -8,
			},
			col: {
				flex: 1,
				marginHorizontal: 8,
			},
			col2: {
				flex: 2,
				width: '100%',
				alignSelf: 'flex-end',
				marginHorizontal: 8,
			},
			hide: {
				display: 'none',
			},
		}),
		actions: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
			leftButton: {
				marginRight: 16,
			},
		}),
	};
}
