import { DrawerActions, useNavigation } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import React from 'react';
import { TouchableOpacity, StyleSheet, Platform, StyleProp, ViewStyle, View } from 'react-native';

import { useAppearance, useAppTheme } from '../../themes';
import { AppIconType, AppIcon } from './AppIcon';

export interface AppBackButtonProps {
	respectGoBack?: boolean;
	onPress?: () => void;
	title?: string;
	style?: StyleProp<ViewStyle>;
	disabled?: boolean;
	supportDrawer?: boolean;
	lib?: AppIconType;
	icon?: string;
	hideBack?: boolean;
	testID?: string;
}

export function AppBackButton(props: AppBackButtonProps) {
	const navigation = useNavigation();
	const dynamicStyles = useDynamicStyles();

	function onPressBack(): void {
		if (props.onPress) {
			if (props.respectGoBack && navigation.canGoBack()) {
				navigation.goBack();
			} else {
				props.onPress();
			}
		} else {
			if (navigation.canGoBack()) {
				navigation.goBack();
			}
		}
	}

	return (
		<View style={styles.main}>
			{!props.hideBack && (navigation.canGoBack() || props.onPress) && (
				<TouchableOpacity
					disabled={props.disabled}
					style={[styles.container, props.style]}
					testID={props.testID}
					onPress={onPressBack}
				>
					{Platform.OS === 'ios' ? (
						<AppIcon
							lib={props.lib || 'ion'}
							name={props.icon || 'ios-arrow-back'}
							size={24}
							style={dynamicStyles.headerButtons}
						/>
					) : (
						<AppIcon
							lib={props.lib || 'ion'}
							name={props.icon || 'md-arrow-back'}
							size={24}
							style={dynamicStyles.headerButtons}
						/>
					)}
					<Text style={[styles.text, Platform.OS === 'ios' ? {} : {}]}>
						{Platform.OS === 'ios' ? props.title || '' : ''}
					</Text>
				</TouchableOpacity>
			)}

			{props.supportDrawer && (
				<TouchableOpacity
					style={[styles.container, props.style]}
					testID={props.testID}
					onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
				>
					<AppIcon lib="mat" name="menu" size={26} style={dynamicStyles.headerButtons} />
				</TouchableOpacity>
			)}
		</View>
	);
}

function useDynamicStyles() {
	const { dark } = useAppearance();
	const theme = useAppTheme();

	return {
		headerButtons: {
			color: dark ? theme['color-basic-100'] : theme['color-basic-700'],
		},
	};
}

const styles = StyleSheet.create({
	main: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	container: {
		marginLeft: 16,
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		minWidth: 40,
		height: 40,
	},
	text: {
		fontSize: 18,
		marginLeft: 6,
		marginTop: -2,
	},
});
