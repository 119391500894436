import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	BeneficiaryType,
	BeneficiaryRelationshipType,
	EasEabGetAccountBeneficiary,
	EasEabGetAccountBeneficiaryVariables,
	EasEabUpdateAccountBeneficiary,
	EasEabUpdateAccountBeneficiaryVariables,
	EasEabGetAccountBeneficiary_accountBeneficiary as AccountBeneficiary,
} from '@app/codegen';
import { EditAccountBeneficiaryLocators } from '@app/e2e/screens/EditAccount';
import { CustomCard } from '@app/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppModal, AppSelector, maskPhoneNumber, validatePhone } from '@itrustcapital/ui';
import { Button, Input, Spinner } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';
import * as yup from 'yup';

export const EAS_EAB_GET_ACCOUNT_BENEFICIARY = gql`
	query EasEabGetAccountBeneficiary($where: AccountBeneficiaryFilterInput) {
		accountBeneficiary(where: $where) {
			id
			beneficiary {
				id
				firstName
				lastName
				email
				addresses {
					address
					city
					state
					zipCode
				}
				phoneNumber
				relationship
			}
			percentage
			type
		}
	}
`;

export const EAS_EAB_UPDATE_ACCOUNT_BENEFICIARY = gql`
	mutation EasEabUpdateAccountBeneficiary($input: UpdateAccountBeneficiaryInput!) {
		updateAccountBeneficiary(input: $input) {
			success
			errorMessage
		}
	}
`;

export interface EditAccountBeneficiaryProps {
	visible: boolean;
	toggleVisible: () => void;
	accountBeneficiaryId: number;
	onSave: (success: boolean) => void;
}

export const schema = yup
	.object({
		percentage: yup.number().min(1).max(100).default(0).required(),
		type: yup
			.mixed<BeneficiaryType>()
			.oneOf(Object.values(BeneficiaryType))
			.default(BeneficiaryType.NONE)
			.notOneOf([BeneficiaryType.NONE])
			.required(),
		email: yup.string().email().default(''), // validateEmail hard to validate empty string
		firstName: yup.string().default('').required(),
		lastName: yup.string().default('').required(),
		street: yup.string().default(''),
		city: yup.string().default(''),
		state: yup.string().default(''),
		phoneNumber: validatePhone.required('Phone is required'),
		zipCode: yup.string().default(''),
		isSpouse: yup.boolean().default(false).required(),
	})
	.defined();

export type EditAccountBeneficiaryForm = yup.TypeOf<typeof schema>;

export function EditAccountBeneficiary(props: EditAccountBeneficiaryProps) {
	const styles = useCustomStyles();
	const editForm = useForm<EditAccountBeneficiaryForm>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const [onGetAccountBeneficiaryQuery, getAccountBeneficiaryQuery] = useLazyQuery<
		EasEabGetAccountBeneficiary,
		EasEabGetAccountBeneficiaryVariables
	>(EAS_EAB_GET_ACCOUNT_BENEFICIARY);

	const [onUpdateAccountBeneficiary, updateAccountBeneficiaryQuery] = useMutation<
		EasEabUpdateAccountBeneficiary,
		EasEabUpdateAccountBeneficiaryVariables
	>(EAS_EAB_UPDATE_ACCOUNT_BENEFICIARY);

	React.useEffect(() => {
		if (props.visible && props.accountBeneficiaryId) {
			onGetAccountBeneficiaryQuery({
				variables: {
					where: {
						id: {
							eq: props.accountBeneficiaryId,
						},
					},
				},
			});
		}
	}, [props.visible, props.accountBeneficiaryId]);

	React.useEffect(() => {
		if (getAccountBeneficiaryQuery.data && getAccountBeneficiaryQuery.data) {
			initForm(getAccountBeneficiaryQuery.data.accountBeneficiary!);
		}
	}, [getAccountBeneficiaryQuery.data]);

	function initForm(data: AccountBeneficiary): void {
		editForm.setValue('firstName', data.beneficiary?.firstName || '');
		editForm.setValue('lastName', data.beneficiary?.lastName || '');
		editForm.setValue('email', data.beneficiary?.email || '');

		if (data.beneficiary?.addresses) {
			const beneficaryAddress = data.beneficiary?.addresses[0];
			editForm.setValue('street', beneficaryAddress?.address || '');
			editForm.setValue('state', beneficaryAddress?.state || '');
			editForm.setValue('city', beneficaryAddress?.city || '');
			editForm.setValue('zipCode', beneficaryAddress?.zipCode || '');
		}
		editForm.setValue('percentage', data.percentage);
		editForm.setValue('type', data.type);
		editForm.setValue('phoneNumber', maskPhoneNumber(data.beneficiary?.phoneNumber!));
		editForm.setValue(
			'isSpouse',
			data.beneficiary?.relationship === BeneficiaryRelationshipType.SPOUSE
		);

		editForm.trigger();
	}

	async function onSubmit(data: EditAccountBeneficiaryForm): Promise<void> {
		try {
			await onUpdateAccountBeneficiary({
				variables: {
					input: {
						id: props.accountBeneficiaryId,
						type: data.type,
						percentage: data.percentage,
						firstName: data.firstName,
						lastName: data.lastName,
						email: data.email?.length > 0 ? data.email : undefined,
						phoneNumber: data.phoneNumber.replaceAll('-', ''),
						relationship: data.isSpouse
							? BeneficiaryRelationshipType.SPOUSE
							: BeneficiaryRelationshipType.NONE,
						addresses: [
							{
								address: data.street,
								city: data.city,
								state: data.state,
								zipCode: data.zipCode,
							},
						],
					},
				},
			});

			props.onSave(true);
		} catch (err) {
			props.onSave(false);
		}
	}

	return (
		<AppModal visible={!!(props.visible && props.accountBeneficiaryId)}>
			<CustomCard
				hasCloseButton
				handleClosePress={props.toggleVisible}
				header="Edit Account Beneficiary"
				loading={getAccountBeneficiaryQuery.loading}
				testID={EditAccountBeneficiaryLocators.editAccountBeneficiary}
			>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="percentage"
							render={(control) => (
								<Input
									label="Percentage *"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryPercentageField
									}
									value={String(control.field.value)}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="type"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={Object.values(BeneficiaryType)}
									label="Type *"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryTypeField
									}
									value={control.field.value}
									onSelect={(value) => {
										editForm.setValue('type', value);
										editForm.trigger('type');
									}}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="isSpouse"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={['Yes', 'No']}
									label="Spouse"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiarySpouseField
									}
									value={control.field.value ? 'Yes' : 'No'}
									onSelect={(value) => control.field.onChange(value === 'Yes')}
								/>
							)}
						/>
					</View>
				</View>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="firstName"
							render={(control) => (
								<Input
									label="First name *"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryFirstNameField
									}
									textContentType="givenName"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="lastName"
							render={(control) => (
								<Input
									label="Last name *"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryLastNameField
									}
									textContentType="familyName"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				</View>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="street"
							render={(control) => (
								<Input
									label="Street"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryStreetField
									}
									textContentType="streetAddressLine1"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				</View>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="city"
							render={(control) => (
								<Input
									label="City"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryCityField
									}
									textContentType="addressCity"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="state"
							render={(control) => (
								<Input
									label="State"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryStateField
									}
									textContentType="addressState"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="zipCode"
							render={(control) => (
								<Input
									label="Zip code"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryZipcodeField
									}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				</View>

				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="phoneNumber"
							render={(control) => (
								<Input
									label="Primary phone *"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryPrimaryPhoneField
									}
									textContentType="telephoneNumber"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChange={(e) => {
										const formatted = maskPhoneNumber(e);
										control.field.onChange(formatted);
									}}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="email"
							render={(control) => (
								<Input
									label="Email"
									status={control.fieldState.error && 'danger'}
									testID={
										EditAccountBeneficiaryLocators.editAccountBeneficiaryEmailField
									}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				</View>
				<View style={styles.actions.container}>
					<Button
						appearance="outline"
						style={styles.actions.cancel}
						testID={EditAccountBeneficiaryLocators.editAccountBeneficiaryCancelButton}
						onPress={props.toggleVisible}
					>
						Cancel
					</Button>
					<Button
						disabled={!editForm.formState.isValid}
						style={styles.actions.save}
						testID={EditAccountBeneficiaryLocators.editAccountBeneficiarySaveButton}
						onPress={
							updateAccountBeneficiaryQuery.loading
								? undefined
								: editForm.handleSubmit(onSubmit)
						}
					>
						{updateAccountBeneficiaryQuery.loading ? (
							<View>
								<Spinner size="tiny" status="basic" />
							</View>
						) : (
							'Save'
						)}
					</Button>
				</View>
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		form: StyleSheet.create({
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginVertical: 8,
			},
			col: {
				flex: 1,
				marginHorizontal: 8,
			},
		}),
		actions: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				marginTop: 8,
			},
			cancel: {
				marginRight: 8,
			},
			save: {
				minWidth: 120,
			},
		}),
	};
}
