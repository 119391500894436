import React from 'react';
import { useRecoilState, RecoilState, SetterOrUpdater } from 'recoil';

export interface AppState<T> {
	get: T;
	set: SetterOrUpdater<T>;
}

export function useAppState<T>(initialValue: T): AppState<T> {
	const [state, setState] = React.useState(initialValue);

	return {
		get: state,
		set: setState,
	};
}

export function useAppStateRecoil<T>(initialValue: RecoilState<T>): AppState<T> {
	const [state, setState] = useRecoilState(initialValue);

	return {
		get: state,
		set: setState,
	};
}
