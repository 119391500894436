export const AuthRoutes = {
	AuthNavigation: {
		path: 'auth',
		initialRouteName: 'Home',
		screens: {
			SignIn: {
				path: 'sign-in',
			},
			SignOut: {
				path: 'sign-out',
			},
		},
	},
};

export type AuthParams = keyof typeof AuthRoutes.AuthNavigation.screens;

export type AuthParamsRecord = Record<AuthParams, undefined>;
