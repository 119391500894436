export enum CustomerBeneficiariesLocators {
	createCustomerBeneficiaryButton = 'createCustomerBeneficiaryButton',
	getCustomerBeneficiariesToast = 'getCustomerBeneficiariesToast',
	deleteCustomerBeneficiaryToast = 'deleteCustomerBeneficiaryToast',
	updateCustomerBeneficiaryToast = 'updateCustomerBeneficiaryToast',
	createCustomerBeneficiaryToast = 'createCustomerBeneficiaryToast',
	editCustomerBeneficiaryButton = 'editCustomerBeneficiaryButton',
	deleteCustomerBeneficiaryButton = 'deleteCustomerBeneficiaryButton',
	deleteCustomerBeneficiarySaveButton = 'deleteCustomerBeneficiarySaveButton',
	deleteCustomerBeneficiaryCancelButton = 'deleteCustomerBeneficiaryCancelButton',
}
