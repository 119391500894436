import { AccountAuditHistoryCardOrganism } from '@app/shared/components/organisms/AccountAuditHistoryCard/AccountAuditHistoryCardOrganism';
import { FundingAuditHistoryCardOrganism } from '@app/shared/components/organisms/FundingAuditHistoryCard/FundingAuditHistoryCardOrganism';
import { UserAuditHistoryCardOrganism } from '@app/shared/components/organisms/UserAuditHistoryCard/UserAuditHistoryCardOrganism';
import { AppBackButton, AppScreen, StackNavigation } from '@itrustcapital/ui';
import * as ReactNavigation from '@react-navigation/native';
import React from 'react';

export function AuditLogsScreen() {
	const navigation = ReactNavigation.useNavigation<StackNavigation>();

	React.useEffect(() => {
		navigation.setOptions({
			title: 'Audit Logs - ITrustCapital',
			headerTitle: 'Audit Logs',
			headerLeft: () => <AppBackButton />,
			headerRight: () => null,
		});
	}, []);

	return (
		<AppScreen noMaxWidth>
			<UserAuditHistoryCardOrganism />
			<AccountAuditHistoryCardOrganism />
			<FundingAuditHistoryCardOrganism />
		</AppScreen>
	);
}
