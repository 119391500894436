import { gql, useMutation } from '@apollo/client';
import {
	AccountStage,
	AccountStatus,
	AccountType,
	EasAiUpdateAccount,
	EasAiUpdateAccountVariables,
	EditAccountScreenGetAccountDetails_account as Account,
	GenerateDepositInstructionsInput,
	EasAiGenerateFortressFundingInstructions,
	EasAiGenerateFortressFundingInstructionsVariables,
	FundingInstructionType,
} from '@app/codegen';
import { AccountInformationLocators } from '@app/e2e/screens/EditAccount';
import { CustomCard } from '@app/shared/components';
import { accountTypeHumanizer } from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppAlert, AppSelector, humanize, useAppState, useAppTheme } from '@itrustcapital/ui';
import { Button, Input, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet, Linking } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';

import { EDIT_ACCOUNT_SCREEN_GET_ACCOUNT_DETAILS } from '../EditAccountScreenGraphql';

export const EAS_AI_UPDATE_ACCOUNT = gql`
	mutation EasAiUpdateAccount($input: UpdateAccountInput!) {
		updateAccount(input: $input) {
			success
			errorMessage
		}
	}
`;

export const EAS_AI_GENERATE_FORTRESS_FUNDING_INSTRUCTIONS = gql`
	mutation EasAiGenerateFortressFundingInstructions($input: GenerateDepositInstructionsInput!) {
		generateDepositInstructions(input: $input) {
			success
			errorMessage
			data {
				url
			}
		}
	}
`;

export const schema = yup
	.object({
		type: yup
			.mixed<AccountType>()
			.oneOf(Object.values(AccountType))
			.notOneOf([AccountType.NONE])
			.required(),
		status: yup.mixed<AccountStatus>().oneOf(Object.values(AccountStatus)).required(),
		id: yup.string().default(''),
		referralCode: yup.string().default(''),
		displayName: yup.string().default(''),
		fortressAccountId: yup.string().default(''),
		accountNumber: yup.string().default(''),
		custodian: yup.string().default(''),
		sourceOfFunds: yup.string().default(''),
		accountPurpose: yup.string().default(''),
	})
	.defined();

export type AccountInformationForm = yup.TypeOf<typeof schema>;

export interface AccountInformationProps {
	account?: Account | null;
}

export function AccountInformation(props: AccountInformationProps) {
	const styles = useCustomStyles();

	const isEditing = useAppState(false);
	const submitVisible = useAppState(false);
	const accountForm = useForm<AccountInformationForm>({
		mode: 'all',
		defaultValues: schema.cast({}),
		resolver: yupResolver(schema),
	});

	const [updateAccountInfo, updateAccountInfoQuery] = useMutation<
		EasAiUpdateAccount,
		EasAiUpdateAccountVariables
	>(EAS_AI_UPDATE_ACCOUNT, {
		refetchQueries: [EDIT_ACCOUNT_SCREEN_GET_ACCOUNT_DETAILS],
	});

	const [onGenerateFundingInstructions, generateFundinfInstructions] = useMutation<
		EasAiGenerateFortressFundingInstructions,
		EasAiGenerateFortressFundingInstructionsVariables
	>(EAS_AI_GENERATE_FORTRESS_FUNDING_INSTRUCTIONS, {
		onCompleted: async (data) => {
			if (data.generateDepositInstructions?.data?.url) {
				await Linking.openURL(data.generateDepositInstructions?.data?.url);
			} else {
				Toast.show({
					type: 'error',
					text2: 'Cannot get document url.',
				});
			}
		},
	});

	React.useEffect(() => {
		resetForm();
	}, [props.account]);

	function resetForm() {
		accountForm.reset({
			id: props.account?.id.toString() || '',
			type: props.account?.type,
			status: props.account?.status,
			referralCode: props.account?.user?.marketingAttribute?.referralId || '',
			displayName: props.account?.displayName || '',
			fortressAccountId: props.account?.custodianAccount?.custodianAccountId || '',
			accountNumber: props.account?.accountNumber || '',
			custodian: props.account?.custodian || '',
			sourceOfFunds: props.account?.sourceOfFunds || '',
			accountPurpose: props.account?.accountPurpose || '',
		});
	}

	async function onDownloadInstructions(fundingType: FundingInstructionType) {
		if (!props.account) {
			Toast.show({
				type: 'error',
				text2: 'Cannot get account id',
			});

			return;
		}

		try {
			const input: GenerateDepositInstructionsInput = {
				accountId: props.account.id,
				type: fundingType,
			};

			await onGenerateFundingInstructions({ variables: { input } });
		} catch (error) {
			Toast.show({
				type: 'error',
				text2: 'Failed to generate funding instructions',
			});
		}
	}

	async function onEditSave(): Promise<void> {
		if (!isEditing.get) {
			isEditing.set(true);

			return;
		}

		if (await accountForm.trigger()) {
			submitVisible.set(true);
		}
	}

	function onCancel() {
		resetForm();
		isEditing.set(false);
	}

	async function onSubmit(data: AccountInformationForm): Promise<void> {
		try {
			await updateAccountInfo({
				variables: {
					input: {
						id: props.account?.id!,
						type: data.type,
						status: data.status,
						sourceOfFunds: data.sourceOfFunds,
						accountPurpose: data.accountPurpose,
					},
				},
			});
			submitVisible.set(false);
			isEditing.set(false);

			Toast.show({
				type: 'success',
				text2: 'Account information has been updated',
			});
		} catch (error) {
			Toast.show({
				type: 'error',
				text2: 'Account information failed to update',
			});
		}
	}

	return (
		<CustomCard
			footer={() => (
				<View style={styles.footer.container}>
					{isEditing.get && (
						<Button
							appearance="outline"
							style={styles.footer.cancel}
							onPress={onCancel}
						>
							Cancel
						</Button>
					)}
					<Button style={styles.footer.edit} onPress={onEditSave}>
						{isEditing.get ? 'Save' : 'Edit'}
					</Button>
				</View>
			)}
			header={() => (
				<View style={styles.main.header}>
					<Text category="h6">Account Information</Text>
					<View style={styles.actions.container}>
						<Button
							style={styles.actions.leftButton}
							onPress={() => onDownloadInstructions(FundingInstructionType.CRYPTO)}
						>
							Get Crypto Instructions
						</Button>
						<Button
							style={styles.actions.leftButton}
							onPress={() => onDownloadInstructions(FundingInstructionType.FIAT)}
						>
							Get FIAT Instructions
						</Button>
					</View>
				</View>
			)}
			loading={updateAccountInfoQuery.loading || generateFundinfInstructions.loading}
		>
			<AppAlert
				actions={[
					{
						title: 'Cancel',
						status: 'primary',
						appearance: 'outline',
						onPress: () => submitVisible.set(false),
					},
					{
						title: 'Save',
						status: 'primary',
						loading: updateAccountInfoQuery.loading,
						onPress: accountForm.handleSubmit(onSubmit),
					},
				]}
				title="Are you sure?"
				visible={submitVisible.get}
			/>
			<View style={styles.main.content}>
				<View style={styles.main.inputRow}>
					<Controller
						control={accountForm.control}
						name="fortressAccountId"
						render={(control) => (
							<View style={styles.main.input}>
								<Input
									disabled
									label="Fortress Account ID"
									style={styles.main.input}
									testID={AccountInformationLocators.fortressAccountIdField}
									value={control.field.value}
								/>
							</View>
						)}
					/>

					<Controller
						control={accountForm.control}
						name="id"
						render={(control) => (
							<View style={styles.main.input}>
								<Input
									disabled
									label="Account ID"
									style={styles.main.input}
									testID={AccountInformationLocators.accountIdField}
									value={control.field.value}
								/>
							</View>
						)}
					/>

					<Controller
						control={accountForm.control}
						name="accountNumber"
						render={(control) => (
							<View style={styles.main.input}>
								<Input
									disabled
									label="Account Name"
									style={styles.main.input}
									testID={AccountInformationLocators.accountNumberField}
									value={control.field.value}
								/>
							</View>
						)}
					/>

					<Controller
						control={accountForm.control}
						name="type"
						render={(control) => (
							<View style={styles.main.input}>
								<AppSelector
									closeOnSelect
									data={Object.values(AccountType).filter((x) =>
										x.includes('IRA')
									)}
									disabled={
										!isEditing.get ||
										props.account?.stage === AccountStage.VERIFIED
									}
									itemDisplay={accountTypeHumanizer}
									label="Account Type"
									status={accountForm.formState.errors.type && 'danger'}
									testID={AccountInformationLocators.accountTypeField}
									value={control.field.value}
									onSelect={control.field.onChange}
								/>
							</View>
						)}
					/>
				</View>
				<View style={styles.main.inputRow}>
					<Controller
						control={accountForm.control}
						name="custodian"
						render={(control) => (
							<View style={styles.main.input}>
								<Input
									disabled
									label="Custodian"
									style={styles.main.input}
									testID={AccountInformationLocators.accountCustodianField}
									value={control.field.value}
								/>
							</View>
						)}
					/>

					<Controller
						control={accountForm.control}
						name="status"
						render={(control) => (
							<View style={styles.main.input}>
								<AppSelector
									closeOnSelect
									data={Object.values(AccountStatus)}
									disabled={!isEditing.get}
									itemDisplay={humanize}
									label="Account Status"
									status={accountForm.formState.errors.status && 'danger'}
									value={control.field.value}
									onSelect={control.field.onChange}
								/>
							</View>
						)}
					/>

					<Controller
						control={accountForm.control}
						name="referralCode"
						render={(control) => (
							<View style={styles.main.input}>
								<Input
									disabled
									label="Used Referral Code"
									style={styles.main.input}
									testID={AccountInformationLocators.accountReferralCodeField}
									value={control.field.value}
								/>
							</View>
						)}
					/>
				</View>
				<View style={styles.main.inputRow}>
					<Controller
						control={accountForm.control}
						name="sourceOfFunds"
						render={(control) => (
							<View style={styles.main.input}>
								<Input
									disabled={!isEditing.get}
									label="Source of Funds"
									status={control.fieldState.error && 'danger'}
									style={styles.main.input}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							</View>
						)}
					/>

					<Controller
						control={accountForm.control}
						name="accountPurpose"
						render={(control) => (
							<View style={styles.main.input}>
								<Input
									disabled={!isEditing.get}
									label="Account Purpose"
									status={control.fieldState.error && 'danger'}
									style={styles.main.input}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							</View>
						)}
					/>
				</View>
			</View>
		</CustomCard>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return {
		main: StyleSheet.create({
			content: {
				marginBottom: 12,
			},
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			inputRow: {
				flexDirection: 'row',
				flexWrap: 'wrap',
				justifyContent: 'space-between',
				marginBottom: 8,
				// marginHorizontal: -8,
			},
			input: {
				flex: 1,
				marginHorizontal: 8,
			},
		}),
		actions: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				paddingRight: 6,
			},
			leftButton: {
				marginRight: 12,
			},
			icon: {
				color: theme['text-alternate-color'],
			},
		}),
		footer: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
			cancel: {
				marginRight: 6,
			},
			edit: {
				minWidth: 60,
			},
		}),
	};
}
