import { gql, useQuery } from '@apollo/client';
import {
	EasTcGetTransactions,
	CurrencyType,
	OrderType,
	MarketOrderFilterInput,
	SortEnumType,
	EasTcGetTransactions_marketOrders_nodes as Transaction,
	MarketOrderSortInput,
	EasTcGetTransactionsVariables,
} from '@app/codegen';
import { TransactionsLocators } from '@app/e2e/screens/EditAccount/Transactions';
import { CustomCard, CustomDataTable, ITrustColumn, useTableState } from '@app/shared/components';
import { isFormEmpty } from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppSelector,
	humanize,
	ScreenSizeEnum,
	useAppDevice,
	toCurrency,
	datePickerMinDate,
	datePickerMaxDate,
	datePickerService,
	dateTimePlaceholder,
	localDateTimeFormat,
} from '@itrustcapital/ui';
import { Button, Datepicker } from '@ui-kitten/components';
import { startOfDay, endOfDay } from 'date-fns';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';

export interface TransactionsProps {
	accountId: number;
}

export const EAS_TC_GET_TRANSACTIONS = gql`
	query EasTcGetTransactions(
		$where: MarketOrderFilterInput
		$order: [MarketOrderSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		marketOrders(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			totalCount
			nodes {
				createdAt
				displayQuantity
				toCurrencyId
				displayAmount
				fromCurrencyId
				orderType
				displayFee
			}
			pageInfo {
				startCursor
				endCursor
			}
		}
	}
`;

export const schema = yup
	.object({
		dateFrom: yup.date().default(undefined),
		dateTo: yup.date().default(undefined),
		currencyType: yup
			.mixed<CurrencyType>()
			.oneOf(Object.values(CurrencyType))
			.default(undefined),

		orderType: yup.mixed<OrderType>().oneOf(Object.values(OrderType)).default(undefined),
	})
	.defined();

export type TransactionsFilterFormData = yup.TypeOf<typeof schema>;

export function Transactions(props: TransactionsProps) {
	const styles = useCustomStyles();
	const tableState = useTableState<MarketOrderSortInput, MarketOrderFilterInput>({
		defaultRowsPerPage: 5,
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere: {
			accountId: { eq: props.accountId },
			executedAt: { neq: null },
		},
		striped: true,
		pagination: true,
	});
	const form = useForm<TransactionsFilterFormData>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const getTransactionsQuery = useQuery<EasTcGetTransactions, EasTcGetTransactionsVariables>(
		EAS_TC_GET_TRANSACTIONS,
		{
			variables: {
				order: tableState.sort.get,
				first: tableState.rowsPerPage.get,
				where: tableState.where.get,
			},
			fetchPolicy: 'network-only',
			onCompleted: (data) => {
				if (data) {
					tableState.onCompleted(
						data.marketOrders?.totalCount,
						data.marketOrders?.pageInfo
					);
				}
			},
		}
	);

	const columns: ITrustColumn<Transaction>[] = [
		{
			key: 'createdAt',
			name: 'Date',
			minWidth: 180,
			sortable: true,
			selector: (row) => localDateTimeFormat(row.createdAt),
		},
		{
			key: 'displayQuantity',
			name: 'Quantity',
			center: true,
			sortable: true,
			wrap: true,
			selector: (row) =>
				`${row.displayQuantity} ${
					row.orderType === OrderType.BUY ? row.toCurrencyId : row.fromCurrencyId
				}`,
		},
		{
			key: 'displayAmount',
			name: 'Amount',
			center: true,
			sortable: true,
			selector: (row) =>
				`${row.displayAmount.toLocaleString('en-US')} ${
					row.orderType === OrderType.BUY ? row.fromCurrencyId : row.toCurrencyId
				}`,
		},
		{
			key: 'orderType',
			name: 'Transaction Type',
			maxWidth: 200,
			center: true,
			sortable: true,
			selector: (row) => row.orderType,
		},
		{
			key: 'displayFee',
			name: 'Fee',
			maxWidth: 200,
			right: true,
			sortable: true,
			selector: (row) => toCurrency(row?.displayFee),
		},
	];

	function onSubmit(data: TransactionsFilterFormData): void {
		const whereFilter: MarketOrderFilterInput = { accountId: { eq: props.accountId } };

		if (isFormEmpty(data)) {
			tableState.where.set(undefined);

			return;
		}

		if (data.orderType) {
			whereFilter.orderType = { eq: data.orderType };
		}

		if (data.currencyType) {
			whereFilter.or = [
				{
					toCurrencyId: { eq: data.currencyType },
				},
				{
					fromCurrencyId: { eq: data.currencyType },
				},
			];
		}

		if (data.dateFrom) {
			whereFilter.createdAt = { gte: startOfDay(data.dateFrom).toISOString() };
		}

		if (data.dateTo) {
			whereFilter.createdAt = {
				...whereFilter.createdAt,
				lte: endOfDay(data.dateTo).toISOString(),
			};
		}

		tableState.where.set(whereFilter);
		tableState.reset();
	}

	function onRefetchTable(variables: EasTcGetTransactionsVariables) {
		getTransactionsQuery.refetch({
			...variables,
			where: tableState.where.get,
		});
	}

	return (
		<CustomCard header="Transactions">
			<View style={styles.search.row}>
				<Controller
					control={form.control}
					name="dateFrom"
					render={(control) => (
						<View style={styles.search.control}>
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Date From"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								status={control.fieldState.error && 'danger'}
								testID={TransactionsLocators.dateFormField}
								onBlur={control.field.onBlur}
								onSelect={control.field.onChange}
							/>
						</View>
					)}
				/>
				<Controller
					control={form.control}
					name="dateTo"
					render={(control) => (
						<View style={styles.search.control}>
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Date To"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								status={control.fieldState.error && 'danger'}
								testID={TransactionsLocators.dateToField}
								onBlur={control.field.onBlur}
								onSelect={control.field.onChange}
							/>
						</View>
					)}
				/>
				<Controller
					control={form.control}
					name="currencyType"
					render={(control) => (
						<View style={styles.search.control}>
							<AppSelector
								closeOnSelect
								data={Object.values(CurrencyType)}
								label="Currency Type"
								placeholder="USD"
								testID={TransactionsLocators.currentTypeField}
								value={control.field.value}
								onSelect={control.field.onChange}
							/>
						</View>
					)}
				/>

				<Controller
					control={form.control}
					name="orderType"
					render={(control) => (
						<View style={[styles.search.control, styles.search.controlLast]}>
							<AppSelector
								closeOnSelect
								data={Object.values(OrderType)}
								itemDisplay={humanize}
								label="Transaction Type"
								placeholder="Buy"
								testID={TransactionsLocators.transactionTypeField}
								value={control.field.value}
								onSelect={control.field.onChange}
							/>
						</View>
					)}
				/>
			</View>
			<View style={styles.search.buttons}>
				<Button
					disabled={!form.formState.isValid}
					style={styles.search.apply}
					testID={TransactionsLocators.applyFilterButton}
					onPress={form.handleSubmit(onSubmit)}
				>
					Apply Filter
				</Button>
				<Button
					testID={TransactionsLocators.clearFilterButton}
					onPress={() => {
						form.reset();
						form.handleSubmit(onSubmit)();
					}}
				>
					Clear Filter
				</Button>
			</View>

			<CustomDataTable
				columns={columns}
				data={getTransactionsQuery.data?.marketOrders?.nodes as Transaction[]}
				progressPending={getTransactionsQuery.loading}
				refetch={onRefetchTable}
				{...tableState.props}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	const appDevice = useAppDevice();

	const isScreenMd = appDevice.width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			container: {},
		}),
		search: StyleSheet.create({
			row: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'space-between' : 'center',
				alignItems: isScreenMd ? 'flex-end' : undefined,
			},
			control: {
				flex: 1,
				marginBottom: isScreenMd ? 0 : 16,
				paddingRight: 8,
			},
			controlLast: {
				paddingRight: 0,
			},
			buttons: {
				flexDirection: 'row',
				marginTop: 8,
			},
			apply: {
				marginRight: 8,
			},
		}),
	};
}
