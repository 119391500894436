import { gql, useQuery } from '@apollo/client';
import {
	EmailFilterInput,
	EmailSortInput,
	OrganismsEcGetEmails,
	OrganismsEcGetEmailsVariables,
	OrganismsEcGetEmails_emails_nodes as Email,
	EcsGetUserDetails_user as UserDetails,
	EcsGetUserDetails_user_accounts as UserAccountDetails,
	EditAccountScreenGetAccountDetails_account as AccountDetails,
	EditAccountScreenGetAccountDetails_account_user as AccountUserDetails,
	SortEnumType,
	SystemRole,
} from '@app/codegen';
import { EmailsComponentLocators } from '@app/e2e/shared/Emails';
import {
	CustomCard,
	CustomDataTable,
	ITrustAction,
	ITrustColumn,
	useTableState,
} from '@app/shared/components';
import { isFormEmpty } from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppIcon, useAppState } from '@itrustcapital/ui';
import { Button, Input, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';

import { EmailModal, EmailModalMode } from './EmailModal';

export interface EmailsProps {
	accountDetails?: UserAccountDetails | AccountDetails | null | undefined;
	userDetails: UserDetails | AccountUserDetails | null | undefined;
	fundingId?: number;
}

export const ORGANISMS_EC_GET_EMAILS = gql`
	query OrganismsEcGetEmails(
		$where: EmailFilterInput
		$order: [EmailSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		emails(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				id
				subject
				body
				template
				templateId
				to
				from
				cc
				bcc
				sentByUserId
				sentByRole
				userId
				accountId
				fundingId
			}
			pageInfo {
				startCursor
				endCursor
			}
			totalCount
		}
	}
`;

const schema = yup.object({ search: yup.string().default('') }).defined();

export type EmailSearchFormData = yup.TypeOf<typeof schema>;

export function Emails(props: EmailsProps) {
	const styles = useCustomStyles();

	const tableState = useTableState<EmailSortInput, EmailFilterInput>({
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere: undefined,
		defaultRowsPerPage: 5,
		pagination: true,
		striped: true,
	});

	const newEmailMessageModal = useAppState(false);
	const newEmailMessageModalMode = useAppState<EmailModalMode>(null);
	const selectedEmailId = useAppState<number | null>(null);

	const form = useForm<EmailSearchFormData>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const getEmailsQuery = useQuery<OrganismsEcGetEmails, OrganismsEcGetEmailsVariables>(
		ORGANISMS_EC_GET_EMAILS,
		{
			variables: {
				order: tableState.sort.get,
				first: tableState.rowsPerPage.get,
				where: tableState.where.get,
			},
			skip: !tableState.where.get,
			fetchPolicy: 'network-only',
			onCompleted: onGetEmailCompleted,
		}
	);

	const columns: ITrustColumn<Email>[] = [
		{
			key: 'sentByUserId',
			name: 'Sent By ID',
			maxWidth: 300,
			sortable: true,
			selector: (row) => row.sentByUserId,
		},
		{
			key: 'sentByRole',
			name: 'Sent By Role',
			maxWidth: 300,
			sortable: true,
			selector: (row) => row.sentByRole || '',
		},
		{
			key: 'subject',
			name: 'Subject',
			minWidth: 400,
			sortable: true,
			selector: (row) => row.subject,
		},
		{
			key: 'template',
			name: 'Template',
			minWidth: 400,
			sortable: true,
			selector: (row) => row.template,
		},
		{
			key: 'templateId',
			name: 'Template Id',
			minWidth: 400,
			sortable: true,
			selector: (row) => row.templateId,
		},
		{
			key: 'from',
			name: 'Email From',
			minWidth: 400,
			sortable: false,
			selector: (row) => row.from,
		},
		{
			key: 'to',
			name: 'Email To',
			minWidth: 400,
			sortable: false,
			selector: (row) => row.to,
		},
		{
			key: 'cc',
			name: 'Email CC',
			minWidth: 400,
			sortable: false,
			selector: (row) => row.cc,
		},
		{
			key: 'bcc',
			name: 'Email BCC',
			minWidth: 400,
			sortable: false,
			selector: (row) => row.bcc,
		},
	];

	const actions: ITrustAction<Email>[] = [
		{
			name: 'View',
			handler: onView,
			iconLib: 'ion',
			iconName: 'eye',
		},
	];

	React.useEffect(() => {
		tableState.where.set(makeDefaultWhereFilter());
	}, [props.accountDetails?.id, props.userDetails?.id]);

	function makeDefaultWhereFilter() {
		const filter: EmailFilterInput = {};

		if (props.fundingId) {
			filter.fundingId = {
				eq: props.fundingId,
			};
		} else if (props.accountDetails?.id) {
			filter.accountId = {
				eq: props.accountDetails.id,
			};
		} else if (props.userDetails?.id) {
			filter.userId = {
				eq: props.userDetails?.id,
			};
		}

		return filter?.fundingId || filter?.accountId || filter?.userId ? filter : undefined;
	}

	function onRefetch(where: EmailFilterInput) {
		getEmailsQuery?.refetch?.({
			after: undefined,
			before: undefined,
			last: undefined,
			first: tableState.rowsPerPage.get,
			order: [{ createdAt: SortEnumType.DESC }],
			where,
		});

		tableState.reset();
	}

	function onGetEmailCompleted(data: OrganismsEcGetEmails): void {
		if (data) {
			tableState.onCompleted(data.emails?.totalCount, data.emails?.pageInfo);
		}
	}

	function onView(rowItem: Email): void {
		newEmailMessageModal.set(!newEmailMessageModal.get);
		newEmailMessageModalMode.set('View');
		selectedEmailId.set(rowItem.id);
	}

	function onSearch(data: EmailSearchFormData): void {
		const where: EmailFilterInput = tableState.where.get || {};

		tableState.reset();

		if (isFormEmpty(data)) {
			tableState.where.set(makeDefaultWhereFilter());

			return;
		}

		const searchTerms = data.search.split(' ');

		where.or = [
			{
				subject: {
					contains: data.search,
				},
			},
		];

		for (const searchTerm of searchTerms) {
			if (+searchTerm) {
				where.or.push({
					sentByUserId: {
						eq: +searchTerm,
					},
				});
			}

			if (Object.values<string>(SystemRole).includes(searchTerm)) {
				where.or.push({
					sentByRole: {
						eq: SystemRole[searchTerm as keyof typeof SystemRole],
					},
				});
			}
		}

		onRefetch(where);
		tableState.where.set(where);
	}

	function onToggleModal(): void {
		selectedEmailId.set(null);
		newEmailMessageModal.set(!newEmailMessageModal.get);
	}

	function onSaved(success: boolean): void {
		if (success) {
			onToggleModal();
			onRefetch(tableState.where.get!);
		}

		Toast.show({
			type: success ? 'success' : 'error',
			text2: success ? 'Email has been sent.' : 'Email was unable to be sent.',
		});
	}

	return (
		<CustomCard
			header={() => (
				<View style={styles.main.header}>
					<Text category="h6">Emails & Tickets</Text>
					<View style={styles.actions.container}>
						<Button
							testID={EmailsComponentLocators.emailsNewButton}
							onPress={() => {
								newEmailMessageModalMode.set('New');
								newEmailMessageModal.set(!newEmailMessageModal.get);
							}}
						>
							New Message
						</Button>
					</View>
				</View>
			)}
		>
			<EmailModal
				userDetails={props.userDetails!}
				accountDetails={props.accountDetails!}
				fundingId={props.fundingId}
				id={selectedEmailId.get!}
				mode={newEmailMessageModalMode.get}
				toggleVisible={onToggleModal}
				visible={newEmailMessageModal.get}
				onSaved={onSaved}
			/>

			<Controller
				control={form.control}
				name="search"
				render={(control) => (
					<Input
						accessoryRight={() => (
							<TouchableOpacity
								testID={EmailsComponentLocators.emailSerchIconButton}
								onPress={form.handleSubmit(onSearch)}
							>
								<AppIcon lib="fe" name="search" />
							</TouchableOpacity>
						)}
						placeholder="Search for email by subject or created by"
						testID={EmailsComponentLocators.emailsSearchField}
						value={control.field.value!}
						onBlur={control.field.onBlur}
						onChangeText={control.field.onChange}
						onSubmitEditing={form.handleSubmit(onSearch)}
					/>
				)}
			/>
			<CustomDataTable
				columns={columns}
				data={getEmailsQuery.data?.emails?.nodes as Email[]}
				progressPending={getEmailsQuery.loading}
				refetch={(variables) =>
					getEmailsQuery.refetch({
						...variables,
						where: tableState.where.get,
					})
				}
				rowActions={actions}
				{...tableState.props}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				alignItems: 'center',
			},
		}),
		actions: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				flex: 1,
			},
		}),
	};
}
