export const truncateString = (value: any, maxLength: number): string | undefined => {
	if (typeof value !== 'string') {
		return;
	}

	if (value.length <= maxLength) {
		return value;
	}

	return value.slice(0, maxLength) + '...';
};
