import { Spinner, Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export function Loading() {
	const styles = useCustomStyles();

	return (
		<View style={styles.container}>
			<Text category="s1" style={styles.text}>
				Loading records
			</Text>
			<Spinner />
		</View>
	);
}

function useCustomStyles() {
	return StyleSheet.create({
		container: {
			width: '100%',
			padding: 60,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
		text: {
			marginRight: 18,
		},
	});
}
