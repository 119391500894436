export enum FundingsModalLocators {
	cancelButton = 'cancelButton',
	saveButton = 'saveButton',
	addAssetButton = 'addAssetButton',
	assetTypeField = 'assetTypeField',
	assetAmountField = 'assetAmountField',
	assetOriginalBalanceField = 'assetOriginalBalanceField',
	assetRemoveButton = 'assetRemoveButton',
	fundingTypeField = 'fundingTypeField',
	fundingDirectionTypeField = 'fundingDirectionTypeField',
	rolloverTypeField = 'rolloverTypeField',
	fromAccountTypeField = 'fromAccountTypeField',
	fromInstitutionField = 'fromInstitutionField',
	fromAccountNumberField = 'fromAccountNumberField',
	toAccountTypeField = 'toAccountTypeField',
	toInstitutionField = 'toInstitutionField',
	toAccountNumberField = 'toAccountNumberField',
	contributionYearField = 'contributionYearField',
	modal = 'modal',
	rolloverFromType = 'rolloverFromType',
	assetTransferEntireBalanceField = 'assetTransferEntireBalanceField',
	currencyType = 'currencyType',
}
