import { NativeScrollEvent, NativeSyntheticEvent } from 'react-native';

function debounce(func: (event: NativeSyntheticEvent<NativeScrollEvent>) => void, timeout = 300) {
	let timer: NodeJS.Timeout;

	return (event: NativeSyntheticEvent<NativeScrollEvent>) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			// @ts-ignore
			func.apply(this, [event]);
		}, timeout);
	};
}

export function findSectionHelper<S extends { ref: any }>(
	sections: S[],
	callback: (value: S) => void
) {
	return debounce(function (event: NativeSyntheticEvent<NativeScrollEvent>) {
		const closest = sections.reduce(function (prev, curr) {
			const curOffsetTop = curr.ref.current?.offsetTop || 0;
			const prevOffsetTop = prev.ref.current?.offsetTop || 0;

			return Math.abs(curOffsetTop - event.nativeEvent.contentOffset.y) <
				Math.abs(prevOffsetTop - event.nativeEvent.contentOffset.y)
				? curr
				: prev;
		});
		callback(closest);
	});
}
