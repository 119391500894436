import { CurrencyType } from '@app/codegen';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const StakingMovementFormSchema = yup
	.object({
		createdAt: yup.date().default(undefined),
		currencyType: yup
			.mixed<CurrencyType>()
			.oneOf(Object.values(CurrencyType))
			.default(undefined),
		term: yup.number().default(undefined),
	})
	.defined();

export type StakingMovementFormData = yup.TypeOf<typeof StakingMovementFormSchema>;

export function useStakingMovementForm() {
	return useForm<StakingMovementFormData>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(StakingMovementFormSchema),
		defaultValues: StakingMovementFormSchema.cast({}),
	});
}
