import { SignInScreen, SignOutScreen } from '@app/screens';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { AuthParamsRecord } from './AuthRoutes';

export function useAuthNavigation() {
	const navigation = useNavigation();

	return {
		SignIn: (returnUrl?: string) =>
			returnUrl
				? navigation.navigate('AuthNavigation', {
						screen: 'SignIn',
						params: {
							returnUrl,
						},
				  })
				: navigation.reset({
						index: 0,
						key: '',
						routeNames: [],
						routes: [
							{
								name: 'AuthNavigation',
								params: {
									screen: 'SignIn',
								},
							},
						],
				  }),
		SignOut: () =>
			navigation.navigate('AuthNavigation', {
				screen: 'SignOut',
			}),
	};
}

const Stack = createStackNavigator<AuthParamsRecord>();

export function AuthNavigation() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen component={SignInScreen} name="SignIn" />
			<Stack.Screen component={SignOutScreen} name="SignOut" />
		</Stack.Navigator>
	);
}
