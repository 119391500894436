// Based on: https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/Pagination.tsx
import { useHover } from '@app/shared/hooks';
import {
	AppIcon,
	AppIconType,
	AppSelect,
	AppSelector,
	ThemeColorVariables,
	useAppTheme,
} from '@itrustcapital/ui';
import { Text, Button } from '@ui-kitten/components';
import * as React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';

const SMALL = 684;

function getNumberOfPages(rowCount: number, rowsPerPage: number): number {
	return Math.ceil(rowCount / rowsPerPage);
}

interface PaginationProps {
	rowsPerPage: number;
	rowCount: number;
	currentPage: number;
	paginationRowsPerPageOptions: number[];
	noRowsPerPageSelect?: boolean;
	footerElement?: () => React.ReactNode;
	onChangePage: (page: number) => void;
	onChangeRowsPerPage: (numRows: number, currentPage: number) => void;
	goToFirstPage: () => void;
	inModal?: boolean;
}

function Pagination(props: PaginationProps): JSX.Element {
	const width = Dimensions.get('window').width;
	const isSmallScreen = !!width && width <= SMALL;
	const theme = useAppTheme();
	const styles = useCustomStyles(theme, isSmallScreen);
	const numPages = getNumberOfPages(props.rowCount, props.rowsPerPage);
	const lastIndex = props.currentPage * props.rowsPerPage;
	const firstIndex = lastIndex - props.rowsPerPage + 1;
	const disabledLesser = props.currentPage === 1;
	const disabledGreater = props.currentPage === numPages;
	const range = `Showing ${firstIndex} to ${
		props.currentPage === numPages ? props.rowCount : lastIndex
	} of ${props.rowCount} entries.`;

	const handlePrevious = React.useCallback(
		() => props.onChangePage(props.currentPage - 1),
		[props.currentPage, props.onChangePage]
	);
	const handleNext = React.useCallback(
		() => props.onChangePage(props.currentPage + 1),
		[props.currentPage, props.onChangePage]
	);
	const handleFirst = React.useCallback(props.goToFirstPage, [props.goToFirstPage]);
	const handleLast = React.useCallback(
		() => props.onChangePage(getNumberOfPages(props.rowCount, props.rowsPerPage)),
		[props.onChangePage, props.rowCount, props.rowsPerPage]
	);
	const handleRowsPerPage = React.useCallback(
		(value: number) => props.onChangeRowsPerPage(value, props.currentPage),
		[props.currentPage, props.onChangeRowsPerPage]
	);

	const firstPageButtonHover = useHover<Button>();
	const previousPageButtonHover = useHover<Button>();
	const nextPageButtonHover = useHover<Button>();
	const lastPageButtonHover = useHover<Button>();

	const paginationButtons = [
		{
			ref: firstPageButtonHover.hoverRef,
			iconName: 'chevrons-left',
			iconLib: 'fe',
			iconColor: getButtonColor(disabledLesser, firstPageButtonHover.isHovered),
			disabled: disabledLesser,
			onPress: handleFirst,
		},
		{
			ref: previousPageButtonHover.hoverRef,
			iconName: 'chevron-left',
			iconLib: 'en',
			iconColor: getButtonColor(disabledLesser, previousPageButtonHover.isHovered),
			disabled: disabledLesser,
			onPress: handlePrevious,
		},
		{
			ref: nextPageButtonHover.hoverRef,
			iconName: 'chevron-right',
			iconLib: 'en',
			iconColor: getButtonColor(disabledGreater, nextPageButtonHover.isHovered),
			disabled: disabledGreater,
			onPress: handleNext,
		},
		{
			ref: lastPageButtonHover.hoverRef,
			iconName: 'chevrons-right',
			iconLib: 'fe',
			iconColor: getButtonColor(true, lastPageButtonHover.isHovered),
			disabled: true || disabledGreater, // @TODO - Enable paging to last page
			onPress: handleLast,
		},
	];

	function getButtonColor(disabled: boolean, hovered: boolean) {
		if (disabled) {
			return theme['text-disabled-color'];
		}

		if (hovered) {
			return theme['color-primary-500'];
		}
	}

	return (
		<View>
			{props.footerElement && (
				<View style={styles.customElementContainer}>{props.footerElement()}</View>
			)}
			<View style={styles.paginationContainer}>
				{!isSmallScreen && <Text category="s1">{range}</Text>}
				{!props.noRowsPerPageSelect && (
					<View style={styles.selectContainer}>
						<Text category="s1">Show</Text>
						{props.inModal ? (
							<View style={[styles.select, { flex: 1 }]}>
								<AppSelector
									closeOnSelect
									data={
										props.paginationRowsPerPageOptions.map((x) =>
											x.toString()
										) || []
									}
									value={String(props.rowsPerPage)}
									onSelect={(x: string) => {
										handleRowsPerPage(Number(x));
									}}
								/>
							</View>
						) : (
							<AppSelect
								data={props.paginationRowsPerPageOptions || []}
								style={styles.select}
								value={props.rowsPerPage}
								onSelect={handleRowsPerPage}
							/>
						)}
						<Text category="s1">Entries</Text>
					</View>
				)}
				<View style={styles.pagingContainer}>
					{paginationButtons.map((button) => (
						<View key={button.iconName} ref={button.ref as any}>
							<Button
								accessoryLeft={() => (
									<AppIcon
										color={button.iconColor}
										lib={button.iconLib as AppIconType}
										name={button.iconName}
										size={20}
									/>
								)}
								appearance="ghost"
								aria-disabled={button.disabled}
								aria-label="First Page"
								disabled={button.disabled}
								size="small"
								style={styles.paginationButton}
								onPress={button.onPress}
							/>
						</View>
					))}
				</View>
			</View>
			{isSmallScreen && (
				<Text category="s1" style={styles.responsiveText}>
					{range}
				</Text>
			)}
		</View>
	);
}

export default React.memo(Pagination);

function useCustomStyles(theme: Record<ThemeColorVariables, string>, shouldShow: boolean) {
	return StyleSheet.create({
		paginationContainer: {
			borderTopColor: 'transparent',
			lineHeight: '28px',
			minHeight: 56,
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			boxSizing: 'border-box',
			paddingHorizontal: 8,
			paddingVertical: 16,
			paddingTop: shouldShow ? 8 : 16,
			width: '100%',
		},
		customElementContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-end',
			paddingTop: 16,
			paddingHorizontal: 8,
		},
		selectContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
		pagingContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			borderRadius: 4,
			whiteSpace: 'nowrap',
		},
		paginationButton: {
			backgroundColor: 'transparent',
			cursor: 'pointer',
		},
		responsiveText: {
			paddingLeft: 8,
			paddingBottom: 16,
		},
		select: {
			marginHorizontal: 10,
		},
	});
}
