import AsyncStorage from '@react-native-async-storage/async-storage';
import * as AuthSession from 'expo-auth-session';
import * as SecureStore from 'expo-secure-store';
import * as WebBrowser from 'expo-web-browser';
import JwtDecode from 'jwt-decode';
import { Platform } from 'react-native';

import { useAppApolloClient } from '../apollo';
import { generateShortUUID, useAppStateRecoil, useNavigationParams } from '../helpers';
import { authAtom, AuthState } from '../state';

declare const window: any;

WebBrowser.maybeCompleteAuthSession();

export const Auth0Config = {
	audience: 'gateway',
	scopes: ['openid', 'profile', 'email', 'offline_access'],
};

export const ErrorMessage = {
	EmailNotVerified: 'EmailNotVerified',
	PasswordResetRequired: 'PasswordResetRequired'
}

export type AuthenticationMethods = {
	trySignIn: () => Promise<void>;
	signIn: (onCompleted?: OnSignInCompleted) => Promise<void>;
	signOut: () => Promise<void>;
	authState: AuthState;
	setAuthState: (value: AuthState) => void;
	setSignOutAction: (cb: () => void) => void;
};

export interface AuthRouteParams {
	code: string;
	state: string;
	error?: string;
	error_description?: string;
}

export type OnSignInCompleted = (userId: string | null) => Promise<void> | void;

export function useAppAuth(appsettings: any): AuthenticationMethods {
	const authState = useAppStateRecoil(authAtom);
	const discovery = AuthSession.useAutoDiscovery(appsettings.authority);
	const refreshTokenKey = 'refreshToken';
	const accessTokenKey = 'accessToken';
	const codeVerifierKey = 'codeVerifier';
	const stateKey = 'stateKey';
	const redirectPath = (appsettings.environment === 'local' ? '/' : '') + 'auth/sign-in';

	const navigationParams = useNavigationParams<AuthRouteParams | undefined>('SignIn');

	const apolloClient = useAppApolloClient({
		endpoint: appsettings.graphql,
		endpointWS: appsettings.graphqlws,
		clientId: appsettings.clientid,
		scopes: Auth0Config.scopes,
	});

	async function trySignIn(): Promise<void> {
		if (navigationParams && navigationParams.code) {
			const codeVerifier = await AsyncStorage.getItem(codeVerifierKey);
			const state = await AsyncStorage.getItem(stateKey);
			await AsyncStorage.removeItem(codeVerifierKey);
			await AsyncStorage.removeItem(stateKey);
			await exchangeCode(navigationParams.code, codeVerifier!, state!);
		} else if (navigationParams?.error && navigationParams?.error_description) {
			if (navigationParams.error_description === 'Please verify your email before logging in.') {
				throw Error(ErrorMessage.EmailNotVerified);
			}
			if (navigationParams.error_description === 'Please change your password') {
				throw Error(ErrorMessage.PasswordResetRequired)
			}
			throw Error('Not authenticated');
		}
	}

	async function signIn(onCompleted?: OnSignInCompleted): Promise<void> {
		const state = generateShortUUID();
		const redirectUri = AuthSession.makeRedirectUri({ path: redirectPath });

		const authRequestOptions: AuthSession.AuthRequestConfig = {
			responseType: AuthSession.ResponseType.Code,
			clientId: appsettings.clientid,
			redirectUri,
			prompt: AuthSession.Prompt.Login,
			scopes: Auth0Config.scopes,
			state,
			extraParams: {
				audience: Auth0Config.audience,
				access_type: 'offline',
				connection: appsettings.connection,
			},
		};

		const authRequest = new AuthSession.AuthRequest(authRequestOptions);

		if (!discovery) {
			throw Error('Failed to discover auth0 endpoints');
		}

		if (Platform.OS === 'web') {
			webSignIn(authRequest);
		} else {
			try {
				await nativeSignIn(authRequest, onCompleted);
			} catch (error) {
				throw error;
			}
		}
	}

	async function nativeSignIn(
		authRequest: AuthSession.AuthRequest,
		onCompleted?: OnSignInCompleted
	): Promise<void> {
		const authorizeResult = await authRequest.promptAsync(discovery!);

		if (authorizeResult.type === 'success') {
			await exchangeCode(
				authorizeResult.params.code,
				authRequest.codeVerifier!,
				authorizeResult.params.state,
				onCompleted
			);
		} else {
			if (authorizeResult.type === 'cancel' || authorizeResult.type === 'dismiss') {
				throw Error('Dismissed');
			}
			if (authorizeResult.type === 'error') {
				if (authorizeResult.error?.description === 'Please verify your email before logging in.') {
					throw Error(ErrorMessage.EmailNotVerified);
				}
				if (authorizeResult.error?.description === 'Please change your password') {
					throw Error(ErrorMessage.PasswordResetRequired);
				}
				throw Error('Not authenticated');
			}
		}
	}

	async function webSignIn(authRequest: AuthSession.AuthRequest): Promise<void> {
		const authUrl = await authRequest.makeAuthUrlAsync(discovery!);
		await AsyncStorage.setItem(stateKey, authRequest.state!);
		await AsyncStorage.setItem(codeVerifierKey, authRequest.codeVerifier!);
		window.location.href = authUrl;
	}

	async function signOut(): Promise<void> {
		// must invalidate apollo cache

		apolloClient.clearStore();

		AuthSession.dismiss();

		AsyncStorage.removeItem('auth').then(() => {
			authState.set({
				...authState.get,
				isAuthenticated: false,
				discovery: null,
				sub: null,
				userId: null,
			});
		});

		if (Platform.OS === 'web') {
			await AsyncStorage.removeItem(accessTokenKey);
			await AsyncStorage.removeItem(refreshTokenKey);
		} else {
			await SecureStore.deleteItemAsync(accessTokenKey);
			await SecureStore.deleteItemAsync(refreshTokenKey);
		}
		await AsyncStorage.removeItem('fundingTrackerHidden');
	}

	async function exchangeCode(
		code: string,
		codeverifier: string,
		state: string,
		onCompleted?: OnSignInCompleted
	): Promise<void> {
		const discover = await AuthSession.fetchDiscoveryAsync(appsettings.authority);
		const redirectUri = AuthSession.makeRedirectUri({ path: redirectPath });

		const tokenResult = await AuthSession.exchangeCodeAsync(
			{
				code,
				clientId: appsettings.clientid,
				redirectUri,
				scopes: Auth0Config.scopes,
				extraParams: {
					code_verifier: codeverifier || '',
					audience: Auth0Config.audience,
					access_type: 'offline',
					connection: appsettings.connection,
					state,
				},
			},
			discover!
		);

		const parsedJwt: any = JwtDecode(tokenResult.accessToken);

		if (Platform.OS === 'web') {
			await AsyncStorage.setItem(accessTokenKey, tokenResult.accessToken);
			await AsyncStorage.setItem(refreshTokenKey, tokenResult.refreshToken!);
		} else {
			await SecureStore.setItemAsync(accessTokenKey, tokenResult.accessToken);
			await SecureStore.setItemAsync(refreshTokenKey, tokenResult.refreshToken!);
		}

		await AsyncStorage.removeItem('fundingTrackerHidden');

		authState.set({
			...authState.get,
			isAuthenticated: true,
			sub: parsedJwt.sub,
			discovery: discover,
		});

		const userId = parsedJwt.sub?.split('|')?.[1] || null;

		try {
			await onCompleted?.(userId);
		} catch (error) {
			console.error(error);
		}
	}

	// accept callback - navigation hooks not available at parent component
	function setSignOutAction(callback: () => void): void {
		authState.set({
			...authState.get,
			onRefreshTokenExpired: callback,
		});
	}

	return {
		trySignIn,
		signIn,
		signOut,
		setSignOutAction,
		authState: authState.get,
		setAuthState: authState.set,
	};
}
