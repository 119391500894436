import { ApolloError, gql, MutationTuple, useMutation } from '@apollo/client';
import {
	ReportingScreen_GenerateFundingsReport,
	ReportingScreen_GenerateFundingsReportVariables,
} from '@app/codegen';

// Generate Fundings Report
export const REPORTING_SCREEN_GENERATE_FUNDINGS_REPORT = gql`
	mutation ReportingScreen_GenerateFundingsReport($input: GenerateFundingsReportInput!) {
		generateFundingsReport(input: $input) {
			success
			errorMessage
			data {
				url
			}
		}
	}
`;

export type GenerateFundingsReportResult = MutationTuple<
	ReportingScreen_GenerateFundingsReport,
	ReportingScreen_GenerateFundingsReportVariables
>;

export type GenerateFundingsReportMutationProps = {
	variables?: ReportingScreen_GenerateFundingsReportVariables;
	onCompleted?: (data: ReportingScreen_GenerateFundingsReport) => void;
	onError?: (error: ApolloError) => void;
};

export function useReportingScreenGenerateFundingsReportMutation(
	props: GenerateFundingsReportMutationProps
): GenerateFundingsReportResult {
	return useMutation<
		ReportingScreen_GenerateFundingsReport,
		ReportingScreen_GenerateFundingsReportVariables
	>(REPORTING_SCREEN_GENERATE_FUNDINGS_REPORT, {
		...props,
	});
}
