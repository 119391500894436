import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	EasAabAddAccountBeneficiary,
	BeneficiaryType,
	EasAabAddAccountBeneficiaryVariables,
	EasAabGetAvailableBeneficiaries,
	EasAabGetAvailableBeneficiariesVariables,
	EasAabGetAvailableBeneficiaries_beneficiaries_nodes as AvailableBeneficiary,
	EasAabAssignBeneficiaryToAccount,
	EasAabAssignBeneficiaryToAccountVariables,
	EditAccountScreen_GetAccountBeneficiaries_accountBeneficiaries_nodes as AccountBeneficiary,
	BeneficiaryRelationshipType,
} from '@app/codegen';
import { AddAccountBeneficiaryLocators } from '@app/e2e/screens/EditAccount';
import { CustomCard } from '@app/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppModal,
	AppSelector,
	maskPhoneNumber,
	validatePhone,
	useAppState,
	useAppTheme,
} from '@itrustcapital/ui';
import { Button, Input, Radio, Spinner, Text } from '@ui-kitten/components';
import _ from 'lodash';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';

export const EAS_AAB_ADD_ACCOUNT_BENEFICIARY = gql`
	mutation EasAabAddAccountBeneficiary($input: AddBeneficiaryInput) {
		addBeneficiary(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

export const EAS_AAB_ASSIGN_BENEFICIARY_TO_ACCOUNT = gql`
	mutation EasAabAssignBeneficiaryToAccount($input: AssignBeneficiaryToAccountInput) {
		assignBeneficiaryToAccount(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

export const EAS_AAB_GET_AVAILABLE_BENEFICARIES = gql`
	query EasAabGetAvailableBeneficiaries($where: BeneficiaryFilterInput) {
		beneficiaries(where: $where) {
			nodes {
				id
				firstName
				lastName
				email
				addresses {
					address
					city
					state
					zipCode
				}
				phoneNumber
				relationship
			}
		}
	}
`;

export interface AddAccountBeneficiaryProps {
	userId: number;
	accountId: number;
	toggleVisible: () => void;
	visible: boolean;
	accountBeneficiaries?: (AccountBeneficiary | null)[] | null;
	onSave: (success: boolean) => void;
}

export const schema = yup
	.object({
		percentage: yup.number().min(1).max(100).default(0).required(),
		type: yup
			.mixed<BeneficiaryType>()
			.oneOf(Object.values(BeneficiaryType))
			.default(BeneficiaryType.NONE)
			.notOneOf([BeneficiaryType.NONE])
			.required(),
		email: yup.string().email().default(''), // validateEmail hard to validate empty string
		firstName: yup.string().default('').required(),
		lastName: yup.string().default('').required(),
		street: yup.string().default(''),
		city: yup.string().default(''),
		state: yup.string().default(''),
		phoneNumber: validatePhone.required('Phone is required'),
		zipCode: yup.string().default(''),
		isSpouse: yup.boolean().default(false).required(),
	})
	.defined();

export type AddAccountBeneficiaryFormData = yup.TypeOf<typeof schema>;

export function AddAccountBeneficiary(props: AddAccountBeneficiaryProps) {
	const styles = useCustomStyles();
	const creatingNew = useAppState(false); // this is based on selected row
	const availableBeneficiariesSearch = useAppState('');
	const selectedExisting = useAppState<AvailableBeneficiary | null>(null);
	const createForm = useForm<AddAccountBeneficiaryFormData>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});
	const [onAddAccountBeneficiary, addAccountBeneficiaryQuery] = useMutation<
		EasAabAddAccountBeneficiary,
		EasAabAddAccountBeneficiaryVariables
	>(EAS_AAB_ADD_ACCOUNT_BENEFICIARY);

	const [onAssignBeneficiaryToAccount, assignBeneficiaryToAccountQuery] = useMutation<
		EasAabAssignBeneficiaryToAccount,
		EasAabAssignBeneficiaryToAccountVariables
	>(EAS_AAB_ASSIGN_BENEFICIARY_TO_ACCOUNT);

	const [onAvailableBeneficiariesQuery, availableBeneficiariesQuery] = useLazyQuery<
		EasAabGetAvailableBeneficiaries,
		EasAabGetAvailableBeneficiariesVariables
	>(EAS_AAB_GET_AVAILABLE_BENEFICARIES);

	function onSelectBeneficiary(index: number) {
		if (
			!availableBeneficiariesQuery.data ||
			!availableBeneficiariesQuery.data.beneficiaries ||
			!availableBeneficiariesQuery.data.beneficiaries.nodes
		)
			return;
		const person = availableBeneficiariesQuery.data.beneficiaries.nodes[index]!;

		createForm.setValue('firstName', person.firstName || '');
		createForm.setValue('lastName', person.lastName || '');
		createForm.setValue('email', person.email || '');

		if (person.addresses) {
			createForm.setValue('street', person.addresses[0]?.address || '');
			createForm.setValue('state', person.addresses[0]?.state || '');
			createForm.setValue('city', person.addresses[0]?.city || '');
			createForm.setValue('zipCode', person.addresses[0]?.zipCode || '');
		}
		createForm.setValue('phoneNumber', maskPhoneNumber(person.phoneNumber));
		createForm.setValue('isSpouse', person.relationship === BeneficiaryRelationshipType.SPOUSE);
		createForm.trigger();
		// i can..black the search?
		availableBeneficiariesSearch.set('');
		selectedExisting.set(person);
	}

	function onBeneficiarySearchChange(searchText: string) {
		selectedExisting.set(null);
		availableBeneficiariesSearch.set(searchText);

		if (searchText.length < 3) {
			return;
		}

		_.debounce(() => {
			onAvailableBeneficiariesQuery({
				variables: {
					where: {
						userId: {
							eq: props.userId,
							nin: props.accountBeneficiaries?.map((d) => d?.beneficiary?.id!),
						},
						or: [
							{
								firstName: {
									contains: searchText,
								},
							},
							{
								lastName: {
									contains: searchText,
								},
							},
						],
					},
				},
			});
		}, 500)();
	}

	async function onSubmit(data: AddAccountBeneficiaryFormData): Promise<void> {
		try {
			if (creatingNew.get) {
				await onAddAccountBeneficiary({
					variables: {
						input: {
							userId: props.userId,
							accountId: +props.accountId,
							type: data.type,
							email: data.email?.length > 0 ? data.email : undefined,
							firstName: data.firstName,
							lastName: data.lastName,
							percentage: data.percentage,
							phoneNumber: data.phoneNumber.replaceAll('-', ''),
							relationship: data.isSpouse
								? BeneficiaryRelationshipType.SPOUSE
								: BeneficiaryRelationshipType.NONE,
							addresses: [
								{
									address: data.street,
									city: data.city,
									state: data.state,
									zipCode: data.zipCode,
								},
							],
						},
					},
				});
			} else {
				await onAssignBeneficiaryToAccount({
					variables: {
						input: {
							accountId: props.accountId,
							beneficiaryId: selectedExisting.get?.id!,
							percentage: data.percentage,
							type: data.type,
						},
					},
				});
			}

			createForm.reset();
			props.onSave(true);
		} catch (err) {
			props.onSave(false);
		}
	}

	function onClose(): void {
		props.toggleVisible();
		createForm.reset();
	}

	return (
		<AppModal visible={props.visible}>
			<CustomCard
				hasCloseButton
				footer={() => (
					<View style={styles.actions.container}>
						<Button
							status="basic"
							style={styles.actions.cancel}
							testID={AddAccountBeneficiaryLocators.addAccountBeneficiaryCancelButton}
							onPress={onClose}
						>
							Cancel
						</Button>
						<Button
							disabled={
								!createForm.formState.isDirty || !createForm.formState.isValid
							}
							style={styles.actions.save}
							testID={AddAccountBeneficiaryLocators.addAccountBeneficiarySaveButton}
							onPress={
								addAccountBeneficiaryQuery.loading ||
								assignBeneficiaryToAccountQuery.loading
									? undefined
									: createForm.handleSubmit(onSubmit)
							}
						>
							{addAccountBeneficiaryQuery.loading ||
							assignBeneficiaryToAccountQuery.loading ? (
								<View>
									<Spinner size="tiny" status="basic" />
								</View>
							) : (
								'Save'
							)}
						</Button>
					</View>
				)}
				handleClosePress={onClose}
				header="Assign Account Beneficiaries"
				testID={AddAccountBeneficiaryLocators.addAccountBeneficiary}
			>
				<View style={styles.form.emailPercentage}>
					<Controller
						control={createForm.control}
						name="percentage"
						render={(control) => (
							<Input
								label="Percentage *"
								status={control.fieldState.error && 'danger'}
								style={styles.form.percentage}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryPercentageField
								}
								value={String(control.field.value)}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={createForm.control}
						name="type"
						render={(control) => (
							<AppSelector
								closeOnSelect
								data={Object.values(BeneficiaryType)}
								label="Type *"
								status={control.fieldState.error && 'danger'}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryTypeField
								}
								value={control.field.value}
								onSelect={(value) => {
									createForm.setValue('type', value);
									createForm.trigger('type');
								}}
							/>
						)}
					/>
				</View>
				<Radio
					checked={!creatingNew.get}
					style={styles.form.radio}
					testID={AddAccountBeneficiaryLocators.addAccountBeneficiaryChooseExistingField}
					onChange={() => creatingNew.set(false)}
				>
					Choose existing person
				</Radio>
				{/* TODO: update with newest autocomplete */}
				<Input
					accessoryRight={
						availableBeneficiariesQuery.loading
							? () => <Spinner size="tiny" />
							: undefined
					}
					disabled={creatingNew.get}
					placeholder="Type at least 3 characters to search..."
					testID={AddAccountBeneficiaryLocators.addAccountBeneficiarySearchAvailableField}
					value={
						selectedExisting.get
							? `${selectedExisting.get.firstName} ${selectedExisting.get.lastName}`
							: availableBeneficiariesSearch.get
					}
					onChangeText={onBeneficiarySearchChange}
				/>
				{/* Must define relative parent for absolute child positioning */}
				<View style={styles.autocomplete.resultParents}>
					{/* Absolute container of search results in expected location */}
					<View
						style={styles.autocomplete.results}
						testID={
							AddAccountBeneficiaryLocators.addAccountBeneficiaryAvailableResultsContainer
						}
					>
						{availableBeneficiariesSearch.get.length > 2 &&
							availableBeneficiariesQuery.data?.beneficiaries?.nodes?.map(
								(person, index) => (
									<Button
										key={person?.id}
										status="basic"
										style={styles.autocomplete.resultItem}
										onPress={() => onSelectBeneficiary(index)}
									>
										<Text>
											{`${person?.firstName} ${person?.lastName}` || ''}
										</Text>
									</Button>
								)
							)}
					</View>
				</View>
				<Radio
					checked={creatingNew.get}
					style={styles.form.radio}
					testID={AddAccountBeneficiaryLocators.addAccountBeneficiaryCreateNewField}
					onChange={() => creatingNew.set(true)}
				>
					Create new person
				</Radio>
				<View style={styles.form.row}>
					<Controller
						control={createForm.control}
						name="firstName"
						render={(control) => (
							<Input
								disabled={!creatingNew.get}
								label="First name *"
								status={control.fieldState.error && 'danger'}
								style={styles.form.thirdControl}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryFirstNameField
								}
								textContentType="givenName"
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={createForm.control}
						name="lastName"
						render={(control) => (
							<Input
								disabled={!creatingNew.get}
								label="Last name *"
								status={control.fieldState.error && 'danger'}
								style={styles.form.thirdControl}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryLastNameField
								}
								textContentType="familyName"
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={createForm.control}
						name="email"
						render={(control) => (
							<Input
								disabled={!creatingNew.get}
								label="Email"
								placeholder="test@example.com"
								status={control.fieldState.error && 'danger'}
								style={styles.form.thirdControl}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryEmailField
								}
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
				</View>
				<View style={styles.form.row}>
					<Controller
						control={createForm.control}
						name="street"
						render={(control) => (
							<Input
								disabled={!creatingNew.get}
								label="Street"
								status={control.fieldState.error && 'danger'}
								style={styles.form.quarterControl}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryStreetField
								}
								textContentType="streetAddressLine1"
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={createForm.control}
						name="city"
						render={(control) => (
							<Input
								disabled={!creatingNew.get}
								label="City"
								status={control.fieldState.error && 'danger'}
								style={styles.form.quarterControl}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryCityField
								}
								textContentType="addressCity"
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={createForm.control}
						name="state"
						render={(control) => (
							<Input
								disabled={!creatingNew.get}
								label="State"
								status={control.fieldState.error && 'danger'}
								style={styles.form.quarterControl}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryStateField
								}
								textContentType="addressState"
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={createForm.control}
						name="zipCode"
						render={(control) => (
							<Input
								disabled={!creatingNew.get}
								label="Zip code"
								status={control.fieldState.error && 'danger'}
								style={styles.form.quarterControl}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryZipcodeField
								}
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
				</View>
				<View style={styles.form.row}>
					<Controller
						control={createForm.control}
						name="phoneNumber"
						render={(control) => (
							<Input
								disabled={!creatingNew.get}
								label="Primary phone *"
								status={control.fieldState.error && 'danger'}
								style={styles.form.thirdControl}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiaryPrimaryPhoneField
								}
								textContentType="telephoneNumber"
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChange={(e) => {
									const formatted = maskPhoneNumber(e);
									control.field.onChange(formatted);
								}}
							/>
						)}
					/>
					<Controller
						control={createForm.control}
						name="isSpouse"
						render={(control) => (
							<AppSelector
								closeOnSelect
								data={['Yes', 'No']}
								disabled={!creatingNew.get}
								label="Spouse"
								status={control.fieldState.error && 'danger'}
								testID={
									AddAccountBeneficiaryLocators.addAccountBeneficiarySpouseField
								}
								value={control.field.value ? 'Yes' : 'No'}
								onSelect={(value) => control.field.onChange(value === 'Yes')}
							/>
						)}
					/>
				</View>
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return {
		form: StyleSheet.create({
			emailPercentage: {
				flexDirection: 'row',
				alignItems: 'center',
			},
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginBottom: 8,
			},
			percentage: {
				marginRight: 4,
			},
			thirdControl: {
				flex: 0.33,
			},
			quarterControl: {
				flex: 0.245,
			},
			radio: {
				marginVertical: 8,
			},
		}),
		actions: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
			cancel: {
				marginRight: 8,
			},
			save: {
				minWidth: 120,
			},
		}),
		autocomplete: StyleSheet.create({
			resultParents: {
				zIndex: 2,
			},
			results: {
				position: 'absolute',
				width: '100%',
				backgroundColor: 'white',
			},
			resultItem: {
				flexDirection: 'row',
				justifyContent: 'flex-start',
				paddingVertical: 2,
				paddingHorizontal: 2,
				borderRadius: 0,
			},
			hoveredResult: {
				backgroundColor: theme['background-basic-color-3'],
			},
		}),
	};
}
