import { atom } from 'recoil';

export interface CredentialsState {
	username: string | null;
	password: string | null;
	tfaGuid: string | null;
}

export const credentialsAtom = atom<CredentialsState>({
	key: 'credentialsAtom',
	default: {
		username: null,
		password: null,
		tfaGuid: null,
	},
	dangerouslyAllowMutability: false,
});
