import {
	UsersScreen_GetCrmUsers_crmUsers_nodes as CrmUser,
	AddCrmUserInput,
	UpdateCrmUserInput,
} from '@app/codegen';
import { UsersScreenLocators } from '@app/e2e/screens/Users';
import {
	UsersScreen_MaestroUserForm,
	UsersScreen_MaestroUserFormSchema,
	useUsersScreenContext,
} from '@app/screens';
import { CustomCard, InputError } from '@app/shared/components';
import { FormActionType } from '@app/shared/models';
import { AppModal, AppSelector, humanize, useAppDevice } from '@itrustcapital/ui';
import { Button, Input, Layout, Spinner } from '@ui-kitten/components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

export function UserFormModal() {
	const styles = useCustomStyles();
	const state = useUsersScreenContext();

	const form = state.usersScreenMaestroUserForm;
	const user = state.usersSelectedRow.get;

	React.useEffect(() => {
		if (user) {
			setForm(user);
		}

		if (!user) {
			form.reset(UsersScreen_MaestroUserFormSchema.cast({}));
		}
	}, [user]);

	function setForm(data: CrmUser) {
		form.reset({
			firstName: data.firstName || '',
			lastName: data.lastName || '',
			email: data.email || '',
			role: data.role,
		});
	}

	function onSubmit(data: UsersScreen_MaestroUserForm) {
		const input: AddCrmUserInput = {
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			role: data.role,
		};

		const updateInput: UpdateCrmUserInput = {
			id: user?.id,
			...input,
		};

		if (state.userFormModalAction.get === FormActionType.ADD) {
			state.onAddMaestroUser({ input });
		}

		if (state.userFormModalAction.get === FormActionType.UPDATE) {
			state.onUpdateMaestroUser({ input: updateInput });
		}
	}

	return (
		<AppModal style={styles.main.container} visible={state.userFormModalVisible.get}>
			<ScrollView contentContainerStyle={styles.main.scroll}>
				<Layout style={styles.layout.container}>
					<CustomCard
						hasCloseButton
						footer={() => (
							<View style={styles.action.container}>
								<Button
									appearance="outline"
									status="primary"
									style={styles.action.cancel}
									testID={UsersScreenLocators.userFormCancelButton}
									onPress={() => state.userFormModalVisible.set(false)}
								>
									Cancel
								</Button>

								<Button
									appearance="filled"
									disabled={state.mutationLoading}
									onPress={form.handleSubmit(onSubmit)}
								>
									{state.mutationLoading ? (
										<View>
											<Spinner size="tiny" status="basic" />
										</View>
									) : (
										'Save'
									)}
								</Button>
							</View>
						)}
						handleClosePress={() => state.userFormModalVisible.set(false)}
						header={`${state.userFormModalAction.get} user: ${
							user?.email ? user?.email : ''
						}`}
						style={styles.content.container}
						testID={UsersScreenLocators.userForm}
					>
						<Controller
							control={form.control}
							name="firstName"
							render={(control) => (
								<Input
									caption={
										<InputError errors={form.formState.errors.firstName} />
									}
									label="First Name"
									style={styles.control}
									testID={UsersScreenLocators.userFormfirstNameField}
									value={control.field.value!}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
						<Controller
							control={form.control}
							name="lastName"
							render={(control) => (
								<Input
									caption={<InputError errors={form.formState.errors.lastName} />}
									label="Last Name"
									style={styles.control}
									testID={UsersScreenLocators.userFormfirstNameField}
									value={control.field.value!}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
						<Controller
							control={form.control}
							name="email"
							render={(control) => (
								<Input
									caption={<InputError errors={form.formState.errors.email} />}
									label="Email address"
									style={styles.control}
									testID={UsersScreenLocators.userFormEmailField}
									value={control.field.value!}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
						<Controller
							control={form.control}
							name="role"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={state.systemRoles}
									itemDisplay={humanize}
									label="Role"
									style={styles.control}
									testID={UsersScreenLocators.userFormRoleField}
									value={control.field.value}
									onSelect={control.field.onChange}
								/>
							)}
						/>
					</CustomCard>
				</Layout>
			</ScrollView>
		</AppModal>
	);
}

function useCustomStyles() {
	const appDevice = useAppDevice();

	return {
		main: StyleSheet.create({
			container: {
				flex: 1,
				padding: 32,
			},
			scroll: {
				flex: 1,
			},
		}),
		layout: StyleSheet.create({
			container: {
				minWidth: appDevice.isNativeMobile ? 'auto' : 800,
				borderRadius: 8,
				marginVertical: 'auto',
			},
		}),
		content: StyleSheet.create({
			container: {
				flex: 1,
			},
		}),
		action: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
			cancel: {
				marginRight: 8,
			},
			save: {
				maxWidth: 125,
			},
		}),
		control: {
			marginTop: 16,
		},
	};
}
