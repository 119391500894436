import { AccountStage, AccountStatus, DocumentStage } from '@app/codegen';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export enum AccountExtraStage {
	ADDITIONAL_DOCUMENTS = 'ADDITIONAL_DOCUMENTS',
}

export const AccountsScreenFilterSchema = yup
	.object({
		search: yup.string().default(''),
		accountStage: yup
			.mixed<AccountStage | AccountExtraStage>()
			.oneOf([...Object.values(AccountStage), AccountExtraStage.ADDITIONAL_DOCUMENTS])
			.default(undefined),
		status: yup.mixed<AccountStatus>().oneOf(Object.values(AccountStatus)).default(undefined),
		createdFrom: yup.date().default(undefined),
		createdTo: yup.date().default(undefined),
		documentStage: yup
			.mixed<DocumentStage.SENT_TO_CLIENT | DocumentStage.SIGNED>()
			.oneOf([DocumentStage.SENT_TO_CLIENT, DocumentStage.SIGNED])
			.default(undefined),
		mobilePhone: yup.string().default('').max(12),
	})
	.defined();

export type FilterAccountsForm = yup.TypeOf<typeof AccountsScreenFilterSchema>;

export function useAccountsScreenFilterForm() {
	return useForm<FilterAccountsForm>({
		mode: 'all',
		resolver: yupResolver(AccountsScreenFilterSchema),
		defaultValues: AccountsScreenFilterSchema.cast({}),
	});
}
