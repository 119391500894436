import { useHover } from '@app/shared/hooks';
import { AppIcon, AppIconType, useAppDevice, useAppTheme } from '@itrustcapital/ui';
import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

export interface ITrustAction<T> {
	name: string;
	testID?: string;
	iconLib: AppIconType;
	iconName: string;
	isDisabled?: (row: T) => boolean;
	isHidden?: (row: T) => boolean;
	handler: (row: T) => void;
}

export interface ActionsColumnProps<T> {
	actions?: ITrustAction<T>[];
	row: T;
}

export const ActionsColumn = <T extends any>(props: ActionsColumnProps<T>) => {
	const theme = useAppTheme();
	const styles = useCustomStyles(theme);
	const appDevice = useAppDevice();

	return (
		<View style={styles.row.container}>
			{props.actions
				?.filter((action) => !(action.isHidden && action.isHidden(props.row)))
				.map((action) => {
					const { hoverRef, isHovered } = useHover<TouchableOpacity>();
					const isDisabled = action.isDisabled ? action.isDisabled(props.row) : false;

					return (
						<TouchableOpacity
							disabled={isDisabled}
							key={action.name}
							ref={appDevice.isWeb ? hoverRef : null}
							style={[styles.row.button, styles.row.marginRight]}
							testID={action?.testID}
							onPress={() => action.handler(props.row)}
						>
							<AppIcon
								color={isDisabled ? theme['color-info-disabled'] : undefined}
								lib={action.iconLib}
								name={action.iconName}
								size={16}
								style={isHovered && !isDisabled && styles.row.buttonHovered}
							/>
						</TouchableOpacity>
					);
				})}
		</View>
	);
};

function useCustomStyles(theme: ReturnType<typeof useAppTheme>) {
	return {
		row: StyleSheet.create({
			container: {
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'center',
			},
			button: {
				padding: 3,
				height: 'auto',
			},
			buttonHovered: {
				color: theme['color-primary-500'],
			},
			marginRight: {
				marginRight: 4,
			},
			actions: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				flex: 0.5,
			},
		}),
	};
}
