import { gql, useMutation } from '@apollo/client';
import {
	AccountStatus,
	AccountType,
	AddFundingAssetInput,
	ContributionType,
	CurrencyType,
	CustodianType,
	EasFcAddFunding,
	EasFcAddFundingVariables,
	EasFcFasGetAccounts_accounts_nodes,
	EditAccountScreenGetAccountDetails_account as AccountDetails,
	FundingDirection,
	FundingType,
	OrganismsFcGetRfEligibleFundings_fundings_nodes as Funding,
	RolloverFromType,
	RolloverType,
} from '@app/codegen';
import { FundingsModalLocators } from '@app/e2e/screens/EditAccount';
import { EAS_GET_PORTFOLIO } from '@app/screens/EditAccount/Portfolio';
import { CustomCard } from '@app/shared/components';
import { accountTypeHumanizer, getCurrencyType, rolloverTypeHumanizer } from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppModal,
	AppSelector,
	decimalStr,
	humanize,
	strToNumber,
	useAppDevice,
	useAppState,
} from '@itrustcapital/ui';
import { Button, CheckBox, Input, Spinner, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import * as yup from 'yup';
import { TypeOf } from 'yup';

import { FundingAccountSearch } from './FundingAccountSearch';

export interface NewFundingProps {
	accountDetails: AccountDetails | null;
	visible: boolean;
	toggleVisible: () => void;
	onSaved: (success: boolean) => void;
	rfEligibleFunding: (Funding | null)[] | null | undefined;
}

export type NewFundingForm = TypeOf<typeof schema>;

export type FundingAssetForm = TypeOf<typeof fundingAssetSchema>;

export const EAS_FC_ADD_FUNDING = gql`
	mutation EasFcAddFunding($input: AddFundingInput) {
		addFunding(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

function isFromRequired(fundingType: FundingType): boolean {
	return (
		fundingType !== FundingType.CONTRIBUTION &&
		fundingType !== FundingType.REWARD &&
		fundingType !== FundingType.CORRECTION &&
		fundingType !== FundingType.CONVERSION &&
		fundingType !== FundingType.REFUND_CONTRIBUTION
	);
}

function isToRequired(fundingType: FundingType, fundingDirectionType: FundingDirection): boolean {
	return (
		fundingType !== FundingType.CORRECTION && fundingDirectionType === FundingDirection.OUTBOUND
	);
}

const fundingAssetSchema = yup
	.object({
		currencyType: yup
			.mixed<CurrencyType>()
			.oneOf(Object.values(CurrencyType))
			.required()
			.default(undefined),
		requestedAmount: yup.string().required().default(''),
		originalBalance: yup
			.string()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.TPA_TRANSFER,
				then: yup.string().notRequired(),
			})
			.default(''),
		transferEntireBalance: yup.boolean().required().default(false),
	})
	.defined();

const schema = yup
	.object({
		fundingType: yup
			.mixed<FundingType>()
			.oneOf(Object.values(FundingType))
			.required()
			.default(undefined),
		fundingDirectionType: yup
			.mixed<FundingDirection>()
			.oneOf(Object.values(FundingDirection))
			.required()
			.default(undefined),
		fundingAsset: yup.array().of(fundingAssetSchema).min(1).default([{}]).required(),
		rolloverType: yup
			.mixed<RolloverType>()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.ROLLOVER,
				then: yup
					.mixed<RolloverType>()
					.oneOf(Object.values(RolloverType))
					.required()
					.default(undefined),
			})
			.default(undefined),
		rolloverFrom: yup
			.mixed<RolloverFromType>()
			.when(['fundingType', 'fundingDirectionType'], {
				is: (fundingType: FundingType, fundingDirectionType: FundingDirection) =>
					fundingType === FundingType.ROLLOVER &&
					fundingDirectionType === FundingDirection.INBOUND,
				then: yup
					.mixed<RolloverFromType>()
					.oneOf(Object.values(RolloverFromType))
					.required()
					.default(undefined),
			})
			.default(undefined),
		fromAccountType: yup
			.mixed<AccountType>()
			.when(['fundingType', 'fundingDirectionType'], {
				is: (fundingType: FundingType) =>
					isFromRequired(fundingType) || fundingType === FundingType.REFUND_CONTRIBUTION,
				then: yup
					.mixed<AccountType>()
					.oneOf(Object.values(AccountType))
					.required()
					.default(undefined),
			})
			.default(undefined),
		fromInstitution: yup
			.string()
			.when(['fundingType', 'fundingDirectionType'], {
				is: isFromRequired,
				then: yup.string().required().default(''),
			})
			.default(''),
		fromAccountNumber: yup.string().when(['fundingType', 'fundingDirectionType'], {
			is: (fundingType: FundingType) =>
				isFromRequired(fundingType) || fundingType === FundingType.REFUND_CONTRIBUTION,
			then: yup.string().required().default(''),
		}),
		fromAccountId: yup
			.number()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.REFUND_CONTRIBUTION,
				then: yup.string().required().default(''),
			})
			.default(undefined),
		toAccountType: yup
			.mixed<AccountType>()
			.when(['fundingDirectionType'], {
				is: isToRequired,
				then: yup
					.mixed<AccountType>()
					.oneOf(Object.values(AccountType))
					.required()
					.default(undefined),
			})
			.default(undefined),
		toInstitution: yup
			.string()
			.when(['fundingType', 'fundingDirectionType'], {
				is: isToRequired,
				then: yup.string().required().default(''),
			})
			.default(''),
		toAccountNumber: yup
			.string()
			.when(['fundingType', 'fundingDirectionType'], {
				is: isToRequired,
				then: yup.string().required().default(''),
			})
			.default(''),
		toAccountId: yup.number().default(undefined),
		contributionYear: yup
			.string()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.CONTRIBUTION,
				then: yup.string().required().default(''),
			})
			.default(''),
		contributionType: yup
			.mixed<ContributionType>()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.CONTRIBUTION,
				then: yup
					.mixed<ContributionType>()
					.oneOf(Object.values(ContributionType))
					.default(undefined),
			})
			.default(undefined),
		correctedFundingId: yup
			.string()
			.when(['fundingType'], {
				is: (fundingType: FundingType) => fundingType === FundingType.REFUND_CONTRIBUTION,
				then: yup.string().required().default(''),
			})
			.default(''),
	})
	.defined();

export function NewFunding(props: NewFundingProps) {
	const styles = useCustomStyles();
	const fromVisible = useAppState(false);
	const toVisible = useAppState(false);
	const hasBalance = useAppState(false);
	const form = useForm<NewFundingForm>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});
	const formWatch = form.watch();
	const fundingAssetFieldArray = useFieldArray({
		control: form.control,
		name: 'fundingAsset',
	});

	const [addFunding, addFundingMutation] = useMutation<EasFcAddFunding, EasFcAddFundingVariables>(
		EAS_FC_ADD_FUNDING,
		{ refetchQueries: [EAS_GET_PORTFOLIO] }
	);

	function handleInternalFundingSelection(account: EasFcFasGetAccounts_accounts_nodes) {
		if (!account) {
			return;
		}

		const custodian =
			account?.custodian === CustodianType.FORTRESS
				? 'Fortress'
				: 'iTrust Custodial Services';

		if (formWatch.fundingDirectionType === FundingDirection.INBOUND) {
			form.setValue('fromAccountType', account.type);
			form.setValue('fromAccountNumber', account.accountNumber!);
			form.setValue('fromInstitution', custodian);
			form.setValue('fromAccountId', account.id!);
		} else {
			form.setValue('toAccountType', account.type);
			form.setValue('toAccountNumber', account.accountNumber!);
			form.setValue('toInstitution', custodian);
			form.setValue('toAccountId', account.id!);
		}
	}

	React.useEffect(() => {
		if (props.accountDetails?.wallets) {
			const totalBalance = props.accountDetails?.wallets
				?.map((x) => x?.availableBalance)
				.reduce((sum, num) => sum + num, 0);

			hasBalance.set(totalBalance > 0);
		}
	}, [props.accountDetails?.wallets]);

	React.useEffect(() => {
		switch (formWatch.fundingType) {
			case FundingType.CONTRIBUTION:
				form.setValue('fundingDirectionType', FundingDirection.INBOUND);
				fromVisible.set(false);
				toVisible.set(true);
				break;
			case FundingType.CORRECTION:
				fromVisible.set(formWatch.fundingDirectionType !== FundingDirection.INBOUND);
				toVisible.set(formWatch.fundingDirectionType === FundingDirection.INBOUND);
				break;
			case FundingType.DISTRIBUTION:
			case FundingType.REFUND_CONTRIBUTION:
				form.setValue('fundingDirectionType', FundingDirection.OUTBOUND);
				fromVisible.set(true);
				toVisible.set(true);
				break;
			case FundingType.REWARD:
				form.setValue('fundingDirectionType', FundingDirection.INBOUND);
				form.setValue('fundingAsset.0.currencyType', CurrencyType.USD);
				fromVisible.set(false);
				toVisible.set(true);
				break;
			default:
				fromVisible.set(true);
				toVisible.set(true);
		}
	}, [formWatch.fundingType, formWatch.fundingDirectionType]);

	React.useEffect(() => {
		const prefix =
			(formWatch.fundingDirectionType === FundingDirection.INBOUND && 'to') ||
			(formWatch.fundingDirectionType === FundingDirection.OUTBOUND && 'from') ||
			'to';

		resetToAndFrom();

		const custodian =
			props.accountDetails?.custodian === CustodianType.FORTRESS
				? 'Fortress'
				: 'iTrust Custodial Services';

		if (formWatch.fundingType === FundingType.TPA_TRANSFER) {
			form.setValue('fundingAsset.0.currencyType', CurrencyType.USD);
			form.setValue(`fromAccountType`, props.accountDetails?.type!);

			if (formWatch.fundingDirectionType === FundingDirection.OUTBOUND) {
				/// FROM
				form.setValue(`fromInstitution`, custodian);
				form.setValue('fromAccountNumber', props.accountDetails?.accountNumber!);

				// TO
				form.setValue(`toInstitution`, humanize(props?.accountDetails?.custodian!));
				form.setValue(
					'toAccountNumber',
					props.accountDetails?.custodianAccount?.custodianAccountId!
				);
			} else {
				// FROM
				form.setValue(`fromInstitution`, humanize(props?.accountDetails?.custodian!));
				form.setValue(
					'fromAccountNumber',
					props.accountDetails?.custodianAccount?.custodianAccountId!
				);

				// TO
				form.setValue(`toInstitution`, custodian);
				form.setValue('toAccountNumber', props.accountDetails?.accountNumber!);
			}
		} else {
			form.setValue(`${prefix}AccountType`, props.accountDetails?.type!);
			form.setValue(`${prefix}Institution`, custodian);
			form.setValue(`${prefix}AccountNumber`, props.accountDetails?.accountNumber!);
		}
	}, [formWatch.fundingDirectionType]);

	function getFundingDirectionType(): string[] {
		switch (formWatch.fundingType) {
			case FundingType.REWARD:
			case FundingType.CONTRIBUTION:
				return [FundingDirection.INBOUND];
			case FundingType.DISTRIBUTION:
			case FundingType.REFUND_CONTRIBUTION:
				return [FundingDirection.OUTBOUND];
			case FundingType.CORRECTION:
			case FundingType.TPA_TRANSFER:
				return Object.keys(FundingDirection).filter((x) => x !== FundingDirection.NONE);
			default:
				return hasBalance.get || props.accountDetails?.status === AccountStatus.INTERNAL
					? Object.keys(FundingDirection).filter((x) => x !== FundingDirection.NONE)
					: Object.keys(FundingDirection).filter(
							(x) => x !== FundingDirection.NONE && x !== FundingDirection.OUTBOUND
					  );
		}
	}

	function getFundingType(): string[] {
		if (props?.accountDetails?.custodian !== CustodianType.FORTRESS) {
			return hasBalance.get
				? Object.values(FundingType).filter(
						(type) => type !== FundingType.NONE && type !== FundingType.RETURN_OF_FUNDS
				  )
				: Object.values(FundingType).filter(
						(type) =>
							type !== FundingType.NONE &&
							type !== FundingType.DISTRIBUTION &&
							type !== FundingType.RETURN_OF_FUNDS
				  );
		}

		return hasBalance.get
			? Object.values(FundingType).filter(
					(x) =>
						x !== FundingType.NONE &&
						x !== FundingType.TPA_TRANSFER &&
						x !== FundingType.RETURN_OF_FUNDS
			  )
			: Object.values(FundingType).filter(
					(x) =>
						x !== FundingType.NONE &&
						x !== FundingType.DISTRIBUTION &&
						x !== FundingType.TPA_TRANSFER &&
						x !== FundingType.RETURN_OF_FUNDS
			  );
	}

	function getContributionYear(): string[] {
		const now = new Date();
		const year = now.getFullYear();

		return [year.toString(), (year - 1).toString()];
	}

	function resetToAndFrom() {
		form.setValue('toAccountType', undefined);
		form.setValue('toInstitution', '');
		form.setValue('toAccountNumber', '');
		form.setValue('toAccountId', undefined);
		form.setValue('fromAccountType', undefined);
		form.setValue('fromInstitution', '');
		form.setValue('fromAccountNumber', '');
		form.setValue('fromAccountId', undefined);
	}

	async function onSubmit() {
		const data = form.getValues();

		const toAccountId =
			data.fundingDirectionType === FundingDirection.INBOUND
				? +props.accountDetails?.id!
				: data.toAccountId;
		const fromAccountId =
			data.fundingDirectionType === FundingDirection.OUTBOUND
				? +props.accountDetails?.id!
				: data.fromAccountId;

		try {
			const fundingAssets: AddFundingAssetInput[] | undefined = data.fundingAsset?.map(
				(fundingAsset: any) => ({
					originalBalance: strToNumber(fundingAsset.originalBalance!),
					requestedAmount: strToNumber(fundingAsset.requestedAmount!),
					currencyType: fundingAsset.currencyType as CurrencyType,
					transferEntireBalance: !!fundingAsset.transferEntireBalance,
				})
			);

			await addFunding({
				variables: {
					input: {
						type: data.fundingType!,
						direction: data.fundingDirectionType!,
						rolloverFrom:
							data.fundingType === FundingType.ROLLOVER &&
							data.fundingDirectionType === FundingDirection.INBOUND
								? data.rolloverFrom
								: null,
						rolloverType:
							data.rolloverType && data.fundingType === FundingType.ROLLOVER
								? data.rolloverType
								: null,
						fromExternalAccount: isFromRequired(data.fundingType!)
							? {
									isPrimary: false,
									institutionName: data.fromInstitution
										? data.fromInstitution
										: '',
									accountType: data.fromAccountType!,
									accountNumber: data.fromAccountNumber
										? data.fromAccountNumber
										: '',
							  }
							: undefined,
						toExternalAccount:
							isToRequired(data.fundingType!, data.fundingDirectionType!) &&
							data.fundingType !== FundingType.CONVERSION &&
							data.fundingType !== FundingType.REFUND_CONTRIBUTION
								? {
										isPrimary: false,
										institutionName: data.toAccountNumber
											? data.toInstitution
											: '',
										accountType: data.toAccountType!,
										accountNumber: data.toAccountNumber
											? data.toAccountNumber
											: '',
								  }
								: undefined,
						contributionYear:
							data.contributionYear && data.fundingType === FundingType.CONTRIBUTION
								? +data.contributionYear
								: null,
						contributionType:
							data.contributionType && data.fundingType === FundingType.CONTRIBUTION
								? data.contributionType
								: null,
						fundingAssets: fundingAssets || [],
						toAccountId:
							(data.fundingDirectionType === FundingDirection.INBOUND ||
								data.fundingType === FundingType.CONVERSION) &&
							data.fundingType !== FundingType.REFUND_CONTRIBUTION
								? toAccountId
								: undefined,
						fromAccountId:
							data.fundingDirectionType === FundingDirection.OUTBOUND ||
							data.fundingType === FundingType.CONVERSION
								? fromAccountId
								: undefined,
						associatedFundingId: +data.correctedFundingId || undefined,
					},
				},
			});
			form.reset();
			props.onSaved(true);
		} catch (error) {
			form.reset();
			props.onSaved(false);
		}
	}

	function onModalClose() {
		form.reset();
		props.toggleVisible();
	}

	return (
		<AppModal visible={props.visible} onBackdropPress={onModalClose}>
			<CustomCard
				hasCloseButton
				footer={() => (
					<View style={styles.main.footer}>
						<Button
							appearance="outline"
							style={styles.main.cancelButton}
							testID={FundingsModalLocators.cancelButton}
							onPress={onModalClose}
						>
							Cancel
						</Button>
						<Button
							testID={FundingsModalLocators.saveButton}
							onPress={
								addFundingMutation?.loading
									? undefined
									: form.handleSubmit(onSubmit)
							}
						>
							{addFundingMutation?.loading ? (
								<View>
									<Spinner size="tiny" status="basic" />
								</View>
							) : (
								'Save'
							)}
						</Button>
					</View>
				)}
				handleClosePress={onModalClose}
				header="Create New Funding"
				loading={addFundingMutation.loading}
				style={styles.main.container}
				testID={FundingsModalLocators.modal}
			>
				<View style={styles.main.row}>
					{/* Funding Type */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="fundingType"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={getFundingType()}
									itemDisplay={humanize}
									label="Funding Type *"
									status={
										control.formState.errors.fundingType ? 'danger' : 'basic'
									}
									testID={FundingsModalLocators.fundingTypeField}
									value={control.field.value}
									onSelect={(value: any) => {
										control.field.onChange(value);
										form.clearErrors();
									}}
								/>
							)}
						/>
					</View>

					{/* Funding Direction */}
					<View style={styles.main.col}>
						<Controller
							control={form.control}
							name="fundingDirectionType"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={getFundingDirectionType()}
									itemDisplay={humanize}
									label="Funding Direction *"
									status={
										control.formState.errors.fundingDirectionType
											? 'danger'
											: 'basic'
									}
									testID={FundingsModalLocators.fundingDirectionTypeField}
									value={control.field.value}
									onSelect={(value: any) => {
										control.field.onChange(value);

										form.clearErrors();
									}}
								/>
							)}
						/>
					</View>

					{/* Rollover Type */}
					{formWatch.fundingType === FundingType.ROLLOVER && (
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="rolloverType"
								render={(control) => (
									<AppSelector
										closeOnSelect
										data={Object.values(RolloverType).filter(
											(x) => x !== RolloverType.NONE
										)}
										itemDisplay={rolloverTypeHumanizer}
										label="Rollover Type *"
										status={
											control.formState.errors.rolloverType
												? 'danger'
												: 'basic'
										}
										testID={FundingsModalLocators.rolloverTypeField}
										value={control.field.value}
										onSelect={control.field.onChange}
									/>
								)}
							/>
						</View>
					)}

					{/* Rollover From */}
					{formWatch.fundingType === FundingType.ROLLOVER &&
						formWatch.fundingDirectionType !== FundingDirection.INBOUND && (
							<View style={styles.main.col} />
						)}
					{formWatch.fundingType === FundingType.ROLLOVER &&
						formWatch.fundingDirectionType === FundingDirection.INBOUND && (
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name="rolloverFrom"
									render={(control) => (
										<AppSelector
											closeOnSelect
											data={Object.values(RolloverFromType).filter(
												(x) => x !== RolloverFromType.NONE
											)}
											itemDisplay={humanize}
											label="Rollover From *"
											status={control.fieldState.error ? 'danger' : 'basic'}
											testID={FundingsModalLocators.rolloverFromType}
											value={control.field.value}
											onSelect={control.field.onChange}
										/>
									)}
								/>
							</View>
						)}
				</View>

				{formWatch.fundingType && formWatch.fundingDirectionType && (
					<>
						{/* From Fields */}
						{fromVisible.get && (
							<View style={styles.main.row}>
								{formWatch.fundingType === FundingType.CONVERSION && (
									<FundingAccountSearch
										disabled={
											formWatch.fundingDirectionType ===
											FundingDirection.OUTBOUND
										}
										onDone={handleInternalFundingSelection}
									/>
								)}
								{/* From Account Type */}
								<View style={styles.main.col}>
									<Controller
										control={form.control}
										name="fromAccountType"
										render={(control) => (
											<AppSelector
												closeOnSelect
												data={Object.values(AccountType).filter(
													(x) => x !== AccountType.NONE
												)}
												disabled={
													formWatch.fundingDirectionType !==
														FundingDirection.INBOUND ||
													formWatch.fundingType ===
														FundingType.CONVERSION ||
													formWatch.fundingType ===
														FundingType.TPA_TRANSFER
												}
												itemDisplay={accountTypeHumanizer}
												label="From Account Type *"
												status={
													control.formState.errors.fromAccountType
														? 'danger'
														: 'basic'
												}
												testID={FundingsModalLocators.fromAccountTypeField}
												value={control.field.value}
												onSelect={control.field.onChange}
											/>
										)}
									/>
								</View>

								{/* From Custodian Institution */}
								<View style={styles.main.col}>
									<Controller
										control={form.control}
										name="fromInstitution"
										render={(control) => (
											<Input
												disabled={
													formWatch.fundingDirectionType !==
														FundingDirection.INBOUND ||
													formWatch.fundingType ===
														FundingType.CONVERSION ||
													formWatch.fundingType ===
														FundingType.TPA_TRANSFER
												}
												label="From Custodian Institution *"
												status={
													control.formState.errors.fromInstitution
														? 'danger'
														: 'basic'
												}
												testID={FundingsModalLocators.fromInstitutionField}
												value={control.field.value}
												onBlur={control.field.onBlur}
												onChangeText={control.field.onChange}
											/>
										)}
									/>
								</View>

								{/* From Custodian Account Number */}
								<View style={styles.main.col}>
									<Controller
										control={form.control}
										name="fromAccountNumber"
										render={(control) => (
											<Input
												disabled={
													formWatch.fundingDirectionType !==
														FundingDirection.INBOUND ||
													formWatch.fundingType ===
														FundingType.CONVERSION ||
													formWatch.fundingType ===
														FundingType.TPA_TRANSFER
												}
												label="From Custodian Account Number *"
												status={
													control.formState.errors.fromAccountNumber
														? 'danger'
														: 'basic'
												}
												testID={
													FundingsModalLocators.fromAccountNumberField
												}
												value={control.field.value}
												onBlur={control.field.onBlur}
												onChangeText={control.field.onChange}
											/>
										)}
									/>
								</View>
							</View>
						)}

						{/* To Fields */}
						{toVisible.get && (
							<View style={styles.main.row}>
								{/* To Account Type */}
								{formWatch.fundingType === FundingType.CONVERSION && (
									<FundingAccountSearch
										disabled={
											formWatch.fundingDirectionType ===
											FundingDirection.INBOUND
										}
										onDone={handleInternalFundingSelection}
									/>
								)}

								{formWatch.fundingType !== FundingType.REFUND_CONTRIBUTION && (
									<>
										<View style={styles.main.col}>
											<Controller
												control={form.control}
												name="toAccountType"
												render={(control) => (
													<AppSelector
														closeOnSelect
														data={Object.values(AccountType).filter(
															(x) => x !== AccountType.NONE
														)}
														disabled={
															formWatch.fundingDirectionType !==
																FundingDirection.OUTBOUND ||
															formWatch.fundingType ===
																FundingType.CONVERSION ||
															formWatch.fundingType ===
																FundingType.TPA_TRANSFER
														}
														itemDisplay={accountTypeHumanizer}
														label="To Account Type *"
														status={
															control.formState.errors.toAccountType
																? 'danger'
																: 'basic'
														}
														testID={
															FundingsModalLocators.toAccountTypeField
														}
														value={control.field.value}
														onSelect={control.field.onChange}
													/>
												)}
											/>
										</View>

										{/* To Custodian Institution */}
										<View style={styles.main.col}>
											<Controller
												control={form.control}
												name="toInstitution"
												render={(control) => (
													<Input
														disabled={
															formWatch.fundingDirectionType !==
																FundingDirection.OUTBOUND ||
															formWatch.fundingType ===
																FundingType.TPA_TRANSFER
														}
														label="To Custodian Institution *"
														status={
															control.fieldState.error
																? 'danger'
																: 'basic'
														}
														testID={
															FundingsModalLocators.toInstitutionField
														}
														value={control.field.value}
														onBlur={control.field.onBlur}
														onChangeText={
															formWatch.fundingType ===
															FundingType.CONVERSION
																? undefined
																: control.field.onChange
														}
													/>
												)}
											/>
										</View>

										{/* To Custodian Account Number */}

										<View style={styles.main.col}>
											<Controller
												control={form.control}
												name="toAccountNumber"
												render={(control) => (
													<Input
														disabled={
															formWatch.fundingDirectionType !==
																FundingDirection.OUTBOUND ||
															formWatch.fundingType ===
																FundingType.TPA_TRANSFER
														}
														label="To Custodian Account Number *"
														status={
															control.fieldState.error
																? 'danger'
																: 'basic'
														}
														testID={
															FundingsModalLocators.toAccountNumberField
														}
														value={control.field.value}
														onBlur={control.field.onBlur}
														onChangeText={
															formWatch.fundingType ===
															FundingType.CONVERSION
																? undefined
																: control.field.onChange
														}
													/>
												)}
											/>
										</View>
									</>
								)}
							</View>
						)}

						{formWatch.fundingType === FundingType.CONTRIBUTION && (
							<View style={styles.main.row}>
								{/* Contribution Year */}
								<View style={styles.main.col}>
									<Controller
										control={form.control}
										name="contributionYear"
										render={(control) => (
											<AppSelector
												closeOnSelect
												data={getContributionYear()}
												label="Contribution Year *"
												status={
													control.fieldState.error ? 'danger' : 'basic'
												}
												testID={FundingsModalLocators.contributionYearField}
												value={control.field.value}
												onSelect={control.field.onChange}
											/>
										)}
									/>
								</View>

								{/* Contribution Type */}
								<View style={styles.main.col}>
									<Controller
										control={form.control}
										name="contributionType"
										render={(control) => (
											<AppSelector
												closeOnSelect
												data={Object.keys(ContributionType).filter(
													(x) => x !== ContributionType.NONE
												)}
												label="Contribution Type *"
												status={
													control.fieldState.error ? 'danger' : 'basic'
												}
												value={control.field.value}
												onSelect={control.field.onChange}
											/>
										)}
									/>
								</View>
								<View style={styles.main.col} />
							</View>
						)}
					</>
				)}

				{formWatch.fundingType === FundingType.REFUND_CONTRIBUTION && (
					<View style={styles.main.row}>
						<View style={styles.main.col}>
							<Controller
								control={form.control}
								name="correctedFundingId"
								render={(control) => (
									<AppSelector
										closeOnSelect
										style={{ maxWidth: 240 }}
										data={props?.rfEligibleFunding
											?.filter((funding) => {
												return funding?.fundingAssets?.some(
													(asset) =>
														asset?.currencyType === CurrencyType.USD
												);
											})
											.map((funding) => funding?.id?.toString())}
										label="Corrected Funding ID *"
										status={control.fieldState.error ? 'danger' : 'basic'}
										value={control.field.value}
										onSelect={control.field.onChange}
									/>
								)}
							/>
						</View>
					</View>
				)}

				<View style={styles.fundingAssets.container}>
					<Text category="h6">Funding Assets</Text>
				</View>

				{fundingAssetFieldArray.fields.map((_item, index) => {
					return (
						<View key={index} style={styles.main.row}>
							{/* Asset Type */}
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name={`fundingAsset.${index}.currencyType`}
									render={(control) => (
										<AppSelector
											closeOnSelect
											disabled={
												formWatch.fundingType === FundingType.TPA_TRANSFER
											}
											data={
												formWatch.fundingType === FundingType.REWARD ||
												formWatch.fundingType ===
													FundingType.REFUND_CONTRIBUTION
													? [CurrencyType.USD]
													: getCurrencyType()
											}
											itemDisplay={(data) => humanize(data, true)}
											label="Asset Type *"
											status={
												control.formState.errors.fundingAsset?.[index]
													?.currencyType
													? 'danger'
													: 'basic'
											}
											testID={`${FundingsModalLocators.assetTypeField}${index}`}
											value={control.field.value}
											onSelect={control.field.onChange}
										/>
									)}
								/>
							</View>

							{/* Requested Amount */}
							<View style={styles.main.col}>
								<Controller
									control={form.control}
									name={`fundingAsset.${index}.requestedAmount`}
									render={(control) => (
										<Input
											disabled={form.getValues(
												`fundingAsset.${index}.transferEntireBalance`
											)}
											keyboardType="numbers-and-punctuation"
											label="Amount *"
											placeholder="0.00"
											status={
												control.formState.errors.fundingAsset?.[index]
													?.requestedAmount
													? 'danger'
													: 'basic'
											}
											testID={`${FundingsModalLocators.assetAmountField}${index}`}
											value={
												form.getValues(
													`fundingAsset.${index}.transferEntireBalance`
												)
													? '0.00'
													: control.field.value?.toString()
											}
											onBlur={control.field.onBlur}
											onChangeText={(text) =>
												control.field.onChange(decimalStr(text))
											}
										/>
									)}
								/>
							</View>

							{/* Original Balance */}
							{formWatch.fundingType !== FundingType.REFUND_CONTRIBUTION &&
								formWatch.fundingType !== FundingType.TPA_TRANSFER && (
									<View style={styles.main.col}>
										<Controller
											control={form.control}
											name={`fundingAsset.${index}.originalBalance`}
											render={(control) => (
												<Input
													disabled={form.getValues(
														`fundingAsset.${index}.transferEntireBalance`
													)}
													keyboardType="numbers-and-punctuation"
													label="Original Balance *"
													placeholder="0.00"
													status={
														control.formState.errors.fundingAsset?.[
															index
														]?.originalBalance
															? 'danger'
															: 'basic'
													}
													testID={`${FundingsModalLocators.assetOriginalBalanceField}${index}`}
													value={
														form.getValues(
															`fundingAsset.${index}.transferEntireBalance`
														)
															? '0.00'
															: control.field.value?.toString()
													}
													onBlur={control.field.onBlur}
													onChangeText={(text) =>
														control.field.onChange(decimalStr(text))
													}
												/>
											)}
										/>
									</View>
								)}

							{/* Transfer Entire Balance Checkbox ≠*/}
							{formWatch.fundingType === FundingType.CONTRIBUTION && (
								<View style={styles.fundingAssets.checkBox}>
									<Controller
										control={form.control}
										name={`fundingAsset.${index}.transferEntireBalance`}
										render={(control) => (
											<CheckBox
												checked={!!control.field.value}
												testID={`${FundingsModalLocators.assetTransferEntireBalanceField}${index}`}
												onBlur={control.field.onBlur}
												onChange={(value) => control.field.onChange(value)}
											>
												Transfer Entire Balance
											</CheckBox>
										)}
									/>
								</View>
							)}

							{index === 0 && (
								<View style={[styles.main.col, styles.main.colSmall]}>
									<Button
										disabled={
											formWatch.fundingType === FundingType.TPA_TRANSFER
										}
										style={styles.fundingAssets.button}
										testID={FundingsModalLocators.addAssetButton}
										onPress={() => fundingAssetFieldArray.append({} as any)}
									>
										+
									</Button>
								</View>
							)}

							{/* Remove Funding Asset*/}
							{index > 0 && (
								<View style={[styles.main.col, styles.main.colSmall]}>
									<Button
										appearance="ghost"
										status="danger"
										style={styles.fundingAssets.button}
										testID={`${FundingsModalLocators.assetRemoveButton}${index}`}
										onPress={() => fundingAssetFieldArray.remove(index)}
									>
										x
									</Button>
								</View>
							)}
						</View>
					);
				})}
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	const appDevice = useAppDevice();

	return {
		main: StyleSheet.create({
			container: {
				minWidth: appDevice.isNativeMobile ? 'auto' : 800,
			},
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				marginVertical: 8,
				marginHorizontal: -8,
			},
			col: {
				flex: 1,
				marginHorizontal: 8,
			},
			colSmall: {
				flex: 0.25,
			},
			footer: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
			cancelButton: {
				marginRight: 16,
			},
		}),
		form: StyleSheet.create({
			submit: {
				marginTop: 8,
			},
		}),
		fundingAssets: StyleSheet.create({
			container: {
				marginTop: 16,
			},
			button: {
				flex: 0.25,
				marginTop: 20,
			},
			checkBox: {
				marginTop: 20,
			},
		}),
	};
}
