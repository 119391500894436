import { NativeDateService } from '@ui-kitten/components';
import { format, addDays, endOfDay, subYears, addYears, startOfDay } from 'date-fns';

export const datePickerMinDate = subYears(new Date(), 100);

export const datePickerMaxDate = endOfDay(addYears(new Date(), 20));

export const datePickerService = new NativeDateService('en', { format: 'MM/DD/YYYY' });

export const dateTimePlaceholder = 'mm/dd/yyyy';

export const dateDefaultFormat = 'MM/dd/yyyy';

export const currentYear = new Date().getFullYear();

export function getBestDate(
	from: Date | undefined,
	to: Date | undefined,
	isStartDate?: boolean
): Date {
	const defaultDate = new Date();

	if (isStartDate) {
		if (from) {
			return startOfDay(from);
		} else if (to) {
			return startOfDay(to);
		}

		return startOfDay(defaultDate);
	}

	if (to) {
		return endOfDay(to);
	} else if (from) {
		return endOfDay(from);
	}

	return endOfDay(defaultDate);
}

export function isIsoDate(str: string): boolean {
	return /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(str);
}

export function dateToUTC(date: Date | string | null | undefined, empty: string = ''): string {
	if (!date) {
		return empty;
	}

	const dateValue = date instanceof Date ? date : new Date(date);

	return new Date(dateValue.setUTCHours(0)).toISOString();
}

export function localDateFormat(
	date: Date | string | null | undefined,
	empty: string = ''
): string {
	if (!date) {
		return empty;
	}

	const dateValue = date instanceof Date ? date : new Date(date);

	return format(dateValue, 'P');
}

export function localDateTimeFormat(
	date: Date | string | null | undefined,
	empty: string = ''
): string {
	if (!date) {
		return empty;
	}

	const dateValue = date instanceof Date ? date : new Date(date);

	return format(dateValue, 'Pp');
}

export function dateFormat(
	date: Date | string | null | undefined,
	empty: string = '',
	pattern?: string
): string {
	if (!date) {
		return empty;
	}

	const dt = new Date(date);
	const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

	return format(dtDateOnly, pattern || 'MM/dd/yyyy');
}

export function dateTimeFormat(date: Date | string | null | undefined, empty: string = ''): string {
	if (!date) {
		return empty;
	}

	const dateValue = date instanceof Date ? date : new Date(date);

	return format(dateValue, 'MM/dd/yyyy, hh:mm:ss a');
}

/**
 *
 * Get next nth day to UTC format without timezone value.
 * @param {number} daysAdded
 * @param {Date} [date] - Today's date
 * @return {string} string
 *
 */

export function startOfNthDayUTC(
	daysAdded: number,
	date: Date | string = new Date() || ''
): string {
	const dateValue = date instanceof Date ? date : new Date(date);
	const startDay = startOfDay(dateValue);
	const daysAddedToDate = addDays(startDay, daysAdded);

	return dateToUTC(daysAddedToDate);
}
