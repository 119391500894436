import Users_DangerSVG from '@app/assets/img/danger.svg';
import SignIn_MaestroSVG from '@app/assets/img/itc_maestro.svg';
import SignIn_PresenterSVG from '@app/assets/img/presenter.svg';
import ResetPassword_SuccessSVG from '@app/assets/img/success.svg';
import Portfolio_UsdGreenSVG from '@app/assets/img/usdGreen.svg';
import Warning_SVG from '@app/assets/img/warning.svg';
import ResetPassword_FailSVG from '@app/assets/img/wrong.svg';
import { AppSvg, AppSvgProps } from '@itrustcapital/ui';
import React from 'react';

export const MaestroAssetSVG = SignIn_MaestroSVG;

export const PresenterAssetSVG = SignIn_PresenterSVG;

export const DangerAssetSVG = Users_DangerSVG;

export const UsdGreenAssetSVG = Portfolio_UsdGreenSVG;

export const SuccessAssetSVG = ResetPassword_SuccessSVG;

export const WarningAssetSVG = Warning_SVG;

export const MaestroSVG = (prop: AppSvgProps) => <AppSvg {...prop} xml={SignIn_MaestroSVG} />;

export const PresenterSVG = (prop: AppSvgProps) => <AppSvg {...prop} xml={SignIn_PresenterSVG} />;

export const SuccessSVG = (prop: AppSvgProps) => (
	<AppSvg {...prop} xml={ResetPassword_SuccessSVG} />
);

export const FailSVG = (prop: AppSvgProps) => <AppSvg {...prop} xml={ResetPassword_FailSVG} />;

export const DangerSVG = (prop: AppSvgProps) => <AppSvg {...prop} xml={Users_DangerSVG} />;

export const UsdGreenSVG = (prop: AppSvgProps) => <AppSvg {...prop} xml={Portfolio_UsdGreenSVG} />;

export const WarningSVG = (prop: AppSvgProps) => <AppSvg {...prop} xml={WarningAssetSVG} />;
