import { useQuery } from '@apollo/client';
import {
	SortEnumType,
	StakingTermStatus,
	StakingTermSortInput,
	StakingTermFilterInput,
	StakingTermsScreen_GetStakingTermsVariables,
	StakingTermsScreen_GetStakingTerms,
	StakingTermsScreen_GetStakingTerms_stakingTerms_nodes as StakingTerm,
} from '@app/codegen';
import {
	CustomCard,
	CustomDataTable,
	ITrustAction,
	ITrustColumn,
	useTableState,
} from '@app/shared/components';
import {
	AppScreen,
	localDateFormat,
	ScreenSizeEnum,
	StackNavigation,
	useAppDevice,
	useAppState,
} from '@itrustcapital/ui';
import { useNavigation } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import { startOfDay } from 'date-fns';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { STAKING_TERMS_SCREEN_GET_STAKING_TERMS } from './StakingTermsScreenGraphql';
import { StakingMovementFilters } from './components';

// DO NOT CHANGE THIS FUNCTION WITHOUT CONSULTING THIS WIKI
// https://dev.azure.com/itrustcapital/iTrustCapital%20Technology/_wiki/wikis/Knowledge%20Base/851/Staking-Terms-Status
function encryptStakingTermStatus(status: StakingTermStatus): StakingTermFilterInput {
	const today = startOfDay(Date.now()).toISOString();
	switch (status) {
		case StakingTermStatus.NONE:
			return {
				enrollmentStartAt: {
					gte: today,
				},
			};
		case StakingTermStatus.ENROLLMENT:
			return {
				enrollmentEndAt: {
					gte: today,
				},
				enrollmentStartAt: {
					lte: today,
				},
			};
		case StakingTermStatus.PENDING:
			return {
				startAt: {
					gte: today,
				},
				enrollmentEndAt: {
					lte: today,
				},
			};
		case StakingTermStatus.STAKED:
			return {
				endAt: {
					gte: today,
				},
				startAt: {
					lte: today,
				},
			};
		case StakingTermStatus.PENDING_REWARDS:
			return {
				endAt: {
					lte: today,
				},
				completedAt: {
					eq: null,
				},
			};
		case StakingTermStatus.COMPLETE:
			return {
				completedAt: {
					neq: null,
				},
			};
	}
}

function decryptStakingTermStatus(stakingTerm: StakingTermFilterInput): StakingTermStatus {
	if (!stakingTerm) {
		return StakingTermStatus.NONE;
	}
	const { enrollmentStartAt, enrollmentEndAt, startAt, endAt, completedAt } = stakingTerm;

	if (completedAt?.neq === null) {
		return StakingTermStatus.COMPLETE;
	}

	if (endAt?.lte) {
		return StakingTermStatus.PENDING_REWARDS;
	}

	if (endAt?.gte && startAt?.lte) {
		return StakingTermStatus.STAKED;
	}

	if (startAt?.gte && enrollmentEndAt?.lte) {
		return StakingTermStatus.PENDING;
	}

	if (enrollmentEndAt?.gte && enrollmentStartAt?.lte) {
		return StakingTermStatus.ENROLLMENT;
	}

	return StakingTermStatus.NONE;
}

export function StakingTermsScreen() {
	const dynamicStyles = useDynamicStyles();
	const navigation = useNavigation<StackNavigation>();
	const width = useAppDevice().width;
	const columnWidths = Math.max(width / 5, 200);

	const selectedRow = useAppState<StakingTerm | null>(null);
	const defaultWhere: StakingTermFilterInput = {
		...encryptStakingTermStatus(StakingTermStatus.PENDING),
	};

	const stakingTermsTableState = useTableState<StakingTermSortInput, StakingTermFilterInput>({
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere,
		defaultRowsPerPage: 10,
		pagination: true,
		striped: true,
	});
	const stakingMovementColumns: ITrustColumn<StakingTerm>[] = [
		{
			key: 'id',
			name: 'Term ID',
			maxWidth: 125,
			sortable: true,
			center: false,
			selector: (row) => row.id,
		},
		{
			name: 'Start Date',
			key: 'startAt',
			maxWidth: columnWidths,
			sortable: true,
			center: false,
			selector: (row) => localDateFormat(row.startAt),
		},
		{
			name: 'End Date',
			key: 'endAt',
			maxWidth: columnWidths,
			sortable: true,
			center: false,
			selector: (row) => localDateFormat(row.endAt),
		},
		{
			key: 'currencyType',
			name: 'Currency Type',
			maxWidth: columnWidths,
			sortable: true,
			center: false,
			selector: (row) => row.currencyType,
		},
		{
			key: 'apy',
			name: 'Est. Net APY',
			maxWidth: columnWidths,
			center: false,
			selector: (row) => `${row?.apy ? row?.apy : 0}%`,
		},
		{
			key: 'durationDays',
			name: 'Term',
			maxWidth: columnWidths,
			center: false,
			selector: (row) => `${row?.durationDays} Days`,
		},
		{
			key: 'totalPrinciple',
			name: 'Total Principal',
			maxWidth: columnWidths,
			center: false,
			selector: (row) =>
				row?.totalPrinciple
					? `${row?.totalPrinciple} ${row.currencyType}`
					: `0 ${row.currencyType}`,
		},
	];
	const stakingMovementActions: ITrustAction<StakingTerm>[] = [
		{
			name: 'View',
			handler: (row) => onStakingMovementAction(row),
			iconLib: 'ion',
			iconName: 'eye',
		},
	];

	const getStakingMovementQuery = useQuery<
		StakingTermsScreen_GetStakingTerms,
		StakingTermsScreen_GetStakingTermsVariables
	>(STAKING_TERMS_SCREEN_GET_STAKING_TERMS, {
		variables: {
			order: stakingTermsTableState.sort.get,
			first: stakingTermsTableState.rowsPerPage.get,
			where: stakingTermsTableState.where.get,
		},
		fetchPolicy: 'no-cache',
		onCompleted: (data) => {
			stakingTermsTableState.onCompleted(
				data?.stakingTerms?.totalCount,
				data?.stakingTerms?.pageInfo
			);
		},
	});

	React.useEffect(() => {
		navigation.setOptions({
			header: () => null,
		});
	}, []);

	function onStakingMovementAction(row: StakingTerm | null = null): void {
		selectedRow.set(row);
	}

	return (
		<AppScreen
			noMarginBottom
			noMaxWidth
			style={[styles.main.container, dynamicStyles.main.container]}
		>
			<View style={styles.main.content}>
				<View style={styles.header.container}>
					<View style={styles.header.row}>
						<Text category="h5" style={styles.header.text}>
							Staking
						</Text>
					</View>
				</View>

				<CustomCard>
					<StakingMovementFilters
						stakingTermFilter={decryptStakingTermStatus(
							stakingTermsTableState.where.get || {}
						)}
						onStakingMovementSubmit={(filters) => {
							stakingTermsTableState.where.set({
								...filters,
								...filters.stakingTerm,
								...stakingTermsTableState.where.get,
							});
						}}
						onStakingTermStatusChange={(stakingTermStatus) =>
							stakingTermsTableState.where.set(
								encryptStakingTermStatus(stakingTermStatus)
							)
						}
					/>

					<CustomDataTable
						columns={stakingMovementColumns}
						data={getStakingMovementQuery?.data?.stakingTerms?.nodes as StakingTerm[]}
						progressPending={getStakingMovementQuery.loading}
						refetch={(variables) =>
							getStakingMovementQuery?.refetch?.({
								...variables,
								where: stakingTermsTableState.where.get,
								order: stakingTermsTableState.sort.get,
							})
						}
						rowActions={stakingMovementActions}
						{...stakingTermsTableState.props}
					/>
				</CustomCard>
			</View>
		</AppScreen>
	);
}

const styles = {
	main: StyleSheet.create({
		container: {
			marginBottom: 20,
		},
		content: {
			flex: 1,
		},
		unbondedContainer: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingVertical: 8,
			paddingLeft: 24,
		},
		buttons: {
			marginRight: 14,
		},
	}),
	header: StyleSheet.create({
		container: {
			padding: 8,
			paddingBottom: 20,
			justifyContent: 'space-between',
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		text: {
			textTransform: 'capitalize',
		},
		row: {
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'wrap',
		},
	}),
};

function useDynamicStyles() {
	const appDevice = useAppDevice();
	const isScreenMd = appDevice.width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			container: {
				flexDirection: isScreenMd ? 'row' : 'column',
			},
		}),
	};
}
