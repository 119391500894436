import {
	ApolloClient,
	InMemoryCache,
	split,
	NormalizedCacheObject,
	createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition, Reference } from '@apollo/client/utilities';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { RefreshTokenRequest } from 'expo-auth-session';
import * as SecureStore from 'expo-secure-store';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { Platform } from 'react-native';

import { useAppState, useAppStateRecoil } from '../helpers';
import { authAtom } from '../state';

export interface JnttnApolloClientOptions {
	clientId: string;
	endpoint: string;
	endpointWS: string;
	scopes: string[];
	applicationId?: 'portfolio' | 'maestro';
	applicationVersion?: string;
}

function mergeNodes(existing: any, incoming: any) {
	let mergedNodes: Reference[] = [];

	// queries with same endcursors are duplicates, dont merge.
	// we must...figure out better pattern though.
	if (existing?.pageInfo?.endCursor === incoming?.pageInfo?.endCursor) {
		return existing;
	}

	if (existing && existing.nodes) {
		mergedNodes = mergedNodes.concat(existing.nodes);
	}

	if (incoming && incoming.nodes) {
		mergedNodes = mergedNodes.concat(incoming.nodes);
	}

	return {
		...incoming,
		nodes: mergedNodes,
	};
}

export const cache: InMemoryCache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				marketOrders: {
					keyArgs: false,
					merge: mergeNodes,
				},
				fundings: {
					keyArgs: false,
					merge: mergeNodes,
				},
				stakingEnrollments: {
					keyArgs: false,
					merge: mergeNodes,
				},
				conditionalTransactions: {
					keyArgs: false,
					merge: mergeNodes,
				},
				currency: {
					merge: true,
				},
			},
		},
	},
});

export function useAppApolloClient(options: JnttnApolloClientOptions) {
	const authState = useAppStateRecoil(authAtom);

	const client = useAppState<ApolloClient<NormalizedCacheObject>>(
		new ApolloClient({
			cache,
			queryDeduplication: true,
			link: createHttpLink({
				uri: options.endpoint + '/graphql',
			}),
			defaultOptions: {
				query: {
					notifyOnNetworkStatusChange: true,
				},
				watchQuery: {
					notifyOnNetworkStatusChange: true,
				},
			},
		})
	);

	React.useEffect(() => {
		const httpLink = createHttpLink({
			uri: options.endpoint + '/graphql',
		});

		const wsLink = new WebSocketLink({
			uri: options.endpointWS + '/graphql',
			options: {
				reconnect: false,
			},
		});

		const errorLink = onError(({ graphQLErrors, networkError }) => {
			if (graphQLErrors)
				graphQLErrors.map(({ message, locations, path }) => {
					console.log(
						`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
					);
				});

			if (networkError) {
				console.log(`[Network error]: ${networkError}`);
			}
			console.log(JSON.stringify(graphQLErrors));
			console.log(JSON.stringify(networkError));
		});

		const authMiddleware = setContext(async (_, { headers }) => {
			let accessToken: string | null;
			let authRefreshToken: string | null;

			const defaultHeaders = {
				...headers,
				...(options?.applicationId && {
					'x-client': options.applicationId,
				}),
				...(options?.applicationVersion && {
					'x-client-version': options.applicationVersion,
				}),
			};

			if (Platform.OS === 'web') {
				accessToken = await AsyncStorage.getItem('accessToken');
				authRefreshToken = await AsyncStorage.getItem('refreshToken');
			} else {
				accessToken = await SecureStore.getItemAsync('accessToken');
				authRefreshToken = await SecureStore.getItemAsync('refreshToken');
			}

			if (accessToken && authRefreshToken) {
				const parsedJwt: any = jwtDecode(accessToken!);
				const isExpired = Date.now() >= parsedJwt.exp * 1000;

				// console.log('Init token validation...', authState.get.isAuthenticated);

				if (isExpired) {
					// console.log('Token was expired...');

					const refreshToken = new RefreshTokenRequest({
						clientId: options.clientId,
						scopes: options.scopes,
						refreshToken: authRefreshToken!,
					});

					// console.log('Trying to refresh...', authRefreshToken);

					try {
						// console.log('Dicovery: ', authState.get.discovery);
						const result = await refreshToken.performAsync(authState.get.discovery!);

						// console.log('New token made retrying call...', result.refreshToken);

						if (Platform.OS === 'web') {
							await AsyncStorage.setItem('accessToken', result.accessToken);
							await AsyncStorage.setItem('refreshToken', result.refreshToken!);
						} else {
							await SecureStore.setItemAsync('accessToken', result.accessToken);
							await SecureStore.setItemAsync('refreshToken', result.refreshToken!);
						}

						return {
							headers: {
								...defaultHeaders,
								authorization: `Bearer ${result.accessToken}`,
							},
						};
					} catch (error) {
						// console.log('Refresh token is no longer valid... :(');
						authState.get.onRefreshTokenExpired?.();
						// authState.set({ ...authState.get, onRefreshTokenExpired: null });
					}
				} else {
					// console.log('Token is valid...');
					// console.log('SignOut Function: ', authState.get.onRefreshTokenExpired);

					return {
						headers: {
							...defaultHeaders,
							authorization: `Bearer ${accessToken}`,
						},
					};
				}
			} else if (accessToken) {
				return {
					headers: {
						...defaultHeaders,
						authorization: `Bearer ${accessToken}`,
					},
				};
			} else {
				// console.log('No need token validation...');

				return {
					headers: {
						...defaultHeaders,
					},
				};
			}
		});

		const splitLink = split(
			({ query }) => {
				const definition = getMainDefinition(query);

				return (
					definition.kind === 'OperationDefinition' &&
					definition.operation === 'subscription'
				);
			},
			wsLink,
			authMiddleware.concat(errorLink.concat(httpLink))
		);

		client.set(
			new ApolloClient({
				cache,
				link: splitLink,
				queryDeduplication: true,
				defaultOptions: {
					query: {
						notifyOnNetworkStatusChange: true,
					},
					watchQuery: {
						notifyOnNetworkStatusChange: true,
					},
				},
			})
		);
	}, [authState.get, options.endpoint]);

	return client.get;
}
