export const AdminRoutes = {
	Admin: {
		path: 'admin',
		initialRouteName: '',
		screens: {
			Users: {
				path: 'users',
			},
			AuditLogs: {
				path: 'audit-logs',
			},
			Reporting: {
				path: 'reporting',
			},
		},
	},
};

export type AdminParams = keyof typeof AdminRoutes.Admin.screens;

export type AdminParamsRecord = Record<AdminParams, undefined>;
