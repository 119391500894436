import { CrmUserFilterInput, CrmUserStatus } from '@app/codegen';
import { UsersScreenLocators } from '@app/e2e/screens/Users/UsersScreenLocators';
import { UsersScreen_SearchForm, useUsersScreenContext } from '@app/screens';
import { Accordion } from '@app/shared/components';
import { AppIcon, AppSelector, humanize, ScreenSizeEnum, useAppDevice } from '@itrustcapital/ui';
import { Button, Input } from '@ui-kitten/components';
import React from 'react';
import { Controller } from 'react-hook-form';
import {
	View,
	StyleSheet,
	NativeSyntheticEvent,
	TextInputKeyPressEventData,
	TouchableOpacity,
} from 'react-native';

export function UsersFilterForm() {
	const styles = useCustomStyles();
	const state = useUsersScreenContext();

	const form = state.usersScreenSearchForm;

	function onSearchKeyPress(event: NativeSyntheticEvent<TextInputKeyPressEventData>): void {
		if (event.nativeEvent.key === 'Enter') {
			form.handleSubmit(onSubmit)();
		}
	}

	function onSubmit(data: UsersScreen_SearchForm): void {
		const whereFilter: CrmUserFilterInput = getMaestroUserFilterInput(data);

		state.onRefetchMaestroUsers({
			where: whereFilter,
			first: state.tableState.rowsPerPage.get,
			after: undefined,
			last: undefined,
			before: undefined,
		});

		state.tableState.reset();
		state.tableState.where.set(whereFilter);
	}

	function getMaestroUserFilterInput(data: UsersScreen_SearchForm): CrmUserFilterInput {
		const whereFilter: CrmUserFilterInput = {};
		const searchTrimmed = data.search.trim();

		whereFilter.status = { neq: CrmUserStatus.DELETED };

		whereFilter.or = [
			{
				firstName: { contains: searchTrimmed },
			},
			{
				lastName: { contains: searchTrimmed },
			},
			{
				email: { contains: searchTrimmed },
			},
		];

		whereFilter.role = data.role ? { eq: data.role } : undefined;

		state.usersScreenSearchForm.setValue('search', searchTrimmed);

		return whereFilter;
	}

	function onClearFilter(): void {
		form.reset();
		form.handleSubmit(onSubmit)();
	}

	return (
		<>
			<Controller
				control={form.control}
				name="search"
				render={(control) => (
					<Input
						enablesReturnKeyAutomatically
						accessoryRight={() => (
							<TouchableOpacity
								testID={UsersScreenLocators.searchIconButton}
								onPress={form.handleSubmit(onSubmit)}
							>
								<AppIcon lib="fa5" name="search" size={18} />
							</TouchableOpacity>
						)}
						placeholder="Search for accounts by name or email"
						testID={UsersScreenLocators.searchField}
						value={control.field.value!}
						onBlur={control.field.onBlur}
						onChangeText={control.field.onChange}
						onKeyPress={onSearchKeyPress}
					/>
				)}
			/>

			<Accordion
				label="advanced filters"
				triggerContainerStyle={styles.advanced.triggerContainer}
			>
				<View style={styles.advanced.inputRow}>
					<Controller
						control={form.control}
						name="role"
						render={(control) => (
							<AppSelector
								closeOnSelect
								data={state.systemRoles}
								itemDisplay={humanize}
								placeholder="Filter by role"
								style={[styles.main.control, styles.main.selector]}
								testID={UsersScreenLocators.roleField}
								value={control.field.value}
								onSelect={control.field.onChange}
							/>
						)}
					/>
				</View>
				<View style={[styles.main.row, styles.main.buttons]}>
					<Button
						style={styles.advanced.apply}
						testID={UsersScreenLocators.applyFiltersButton}
						onPress={form.handleSubmit(onSubmit)}
					>
						Apply Filters
					</Button>

					<Button
						appearance="outline"
						testID={UsersScreenLocators.clearFiltersButton}
						onPress={onClearFilter}
					>
						Clear Filters
					</Button>
				</View>
			</Accordion>
		</>
	);
}

function useCustomStyles() {
	const { width } = useAppDevice();

	const isScreenMd = width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			buttons: {
				paddingVertical: 4,
			},
			row: {
				flexDirection: 'row',
			},
			control: {
				width: isScreenMd ? '35%' : 'auto',
				maxWidth: 330,
				marginRight: isScreenMd ? 8 : 0,
				paddingVertical: 8,
			},
			selector: {
				width: 'auto',
			},
		}),
		advanced: StyleSheet.create({
			apply: {
				marginRight: 12,
			},
			inputRow: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'flex-start' : 'center',
			},
			triggerContainer: {
				flexDirection: 'row',
				justifyContent: 'flex-start',
				marginVertical: 8,
			},
		}),
	};
}
