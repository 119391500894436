import { useMainDrawerNavigation } from '@app/navigations';
import { CustomersScreen, EditCustomerScreen } from '@app/screens';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { CustomersParamsRecord } from '.';

export type EditCustomerScreenParams = {
	customerId: number;
};

export function useCustomersNavigation() {
	const navigation = useMainDrawerNavigation('Customers');

	return {
		Home: (reset?: boolean) =>
			navigation.Base(
				{
					screen: 'Home',
				},
				reset
			),
		Edit: (params: EditCustomerScreenParams) =>
			navigation.Base({
				screen: 'Edit',
				params,
			}),
		CustomersLinks: navigation.BaseLink + '/customers',
	};
}

const Stack = createStackNavigator<CustomersParamsRecord>();

export function CustomersNavigation() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen component={CustomersScreen} name="Home" />
			<Stack.Screen component={EditCustomerScreen} name="Edit" />
		</Stack.Navigator>
	);
}
