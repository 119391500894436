export async function getFileUriBlob(fileUrl: string): Promise<Blob> {
	const blob = await (await fetch(fileUrl)).blob();

	return blob;
}

export async function uploadFileAsync(blob: Blob, tokenUrl: string): Promise<Response> {
	try {
		const upload = await fetch(tokenUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': blob.type,
				'Content-Length': (blob as any)._data?.size,
				'x-ms-blob-type': 'BlockBlob',
			},
			body: blob,
		});

		return upload;
	} catch (error) {
		throw error;
	}
}

export function getUploadUrl(response: Response): string {
	return response.url.slice(0, response.url.indexOf('?'));
}

export function getFileType(value: string): string | undefined {
	const base64 = new RegExp('data:[^;]+;[A-Za-z0-9]+,[^")\'s]+');

	return base64.test(value)
		? value.split(';')[0].split('/').pop()
		: value.split(/[#?]/)[0].split('.').pop()?.trim();
}
