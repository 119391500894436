export enum Countries {
	UNITEDSTATES = 'United States',
	AFGHANISTAN = 'Afghanistan',
	ALANDISLANDS = 'Aland Islands',
	ALBANIA = 'Albania',
	ALGERIA = 'Algeria',
	AMERICANSAMOA = 'American Samoa',
	ANDORRA = 'Andorra',
	ANGOLA = 'Angola',
	ANGUILLA = 'Anguilla',
	ANTARCTICA = 'Antarctica',
	ANTIGUAANDBARBUDA = 'Antigua and Barbuda',
	ARGENTINA = 'Argentina',
	ARMENIA = 'Armenia',
	ARUBA = 'Aruba',
	AUSTRALIA = 'Australia',
	AUSTRIA = 'Austria',
	AZERBAIJAN = 'Azerbaijan',
	BAHAMAS = 'Bahamas',
	BAHRAIN = 'Bahrain',
	BANGLADESH = 'Bangladesh',
	BARBADOS = 'Barbados',
	BELARUS = 'Belarus',
	BELGIUM = 'Belgium',
	BELIZE = 'Belize',
	BENIN = 'Benin',
	BERMUDA = 'Bermuda',
	BHUTAN = 'Bhutan',
	BOLIVIA = 'Bolivia',
	BONAIRESINTEUSTATIUSSABA = 'Bonaire Sint Eustatius Saba',
	BOSNIAANDHERZEGOVINA = 'Bosnia and Herzegovina',
	BOTSWANA = 'Botswana',
	BOUVETISLAND = 'Bouvet Island',
	BRAZIL = 'Brazil',
	BRITISHINDIANOCEANTERRITORY = 'British Indian Ocean Territory',
	BRUNEIDARUSSALAM = 'Brunei Darussalam',
	BULGARIA = 'Bulgaria',
	BURKINAFASO = 'BurkinaFaso',
	BURUNDI = 'Burundi',
	CAMBODIA = 'Cambodia',
	CAMEROON = 'Cameroon',
	CANADA = 'Canada',
	CAPEVERDE = 'Cape Verde',
	CAYMANISLANDS = 'Cayman Islands',
	CENTRALAFRICANREPUBLIC = 'Central African Republic',
	CHAD = 'Chad',
	CHILE = 'Chile',
	CHINA = 'China',
	CHRISTMASISLAND = 'Christmas Island',
	COCOSKEELINGISLANDS = 'Cocos Keeling Islands',
	COLOMBIA = 'Colombia',
	COMOROS = 'Comoros',
	CONGO = 'Congo',
	CONGODEMOCRATICREPUBLIC = 'Congo Democratic Republic',
	COOKISLANDS = 'Cook Islands',
	COSTARICA = 'Costa Rica',
	COTEDIVOIRE = 'Cote DIvoire',
	CROATIA = 'Croatia',
	CUBA = 'Cuba',
	CURAÇAO = 'Curaçao',
	CYPRUS = 'Cyprus',
	CZECHREPUBLIC = 'Czech Republic',
	DENMARK = 'Denmark',
	DJIBOUTI = 'Djibouti',
	DOMINICA = 'Dominica',
	DOMINICANREPUBLIC = 'Dominican Republic',
	ECUADOR = 'Ecuador',
	EGYPT = 'Egypt',
	ELSALVADOR = 'El Salvador',
	EQUATORIALGUINEA = 'Equatorial Guinea',
	ERITREA = 'Eritrea',
	ESTONIA = 'Estonia',
	ETHIOPIA = 'Ethiopia',
	FALKLANDISLANDS = 'Falkland Islands',
	FAROEISLANDS = 'Faroe Islands',
	FIJI = 'Fiji',
	FINLAND = 'Finland',
	FRANCE = 'France',
	FRENCHGUIANA = 'French Guiana',
	FRENCHPOLYNESIA = 'French Polynesia',
	FRENCHSOUTHERNTERRITORIES = 'French Southern Territories',
	GABON = 'Gabon',
	GAMBIA = 'Gambia',
	GEORGIA = 'Georgia',
	GERMANY = 'Germany',
	GHANA = 'Ghana',
	GIBRALTAR = 'Gibraltar',
	GREECE = 'Greece',
	GREENLAND = 'Greenland',
	GRENADA = 'Grenada',
	GUADELOUPE = 'Guadeloupe',
	GUAM = 'Guam',
	GUATEMALA = 'Guatemala',
	GUERNSEY = 'Guernsey',
	GUINEA = 'Guinea',
	GUINEABISSAU = 'Guinea Bissau',
	GUYANA = 'Guyana',
	HAITI = 'Haiti',
	HEARDISLANDMCDONALDISLANDS = 'Heard Island Mcdonald Islands',
	HOLYSEEVATICANCITYSTATE = 'Holy See Vatican City State',
	HONDURAS = 'Honduras',
	HONGKONG = 'Hong Kong',
	HUNGARY = 'Hungary',
	ICELAND = 'Iceland',
	INDIA = 'India',
	INDONESIA = 'Indonesia',
	IRAN = 'Iran',
	IRAQ = 'Iraq',
	IRELAND = 'Ireland',
	ISLEOFMAN = 'Isle Of Man',
	ISRAEL = 'Israel',
	ITALY = 'Italy',
	JAMAICA = 'Jamaica',
	JAPAN = 'Japan',
	JERSEY = 'Jersey',
	JORDAN = 'Jordan',
	KAZAKHSTAN = 'Kazakhstan',
	KENYA = 'Kenya',
	KIRIBATI = 'Kiribati',
	KOREA = 'Korea',
	KOREADEMOCRATICPEOPLESREPUBLIC = 'Korea Democratic Peoples Republic',
	KUWAIT = 'Kuwait',
	KYRGYZSTAN = 'Kyrgyzstan',
	LAOPEOPLESDEMOCRATICREPUBLIC = 'Lao Peoples Democratic Republic',
	LATVIA = 'Latvia',
	LEBANON = 'Lebanon',
	LESOTHO = 'Lesotho',
	LIBERIA = 'Liberia',
	LIBYANARABJAMAHIRIYA = 'Libyan Arab Jamahiriya',
	LIECHTENSTEIN = 'Liechtenstein',
	LITHUANIA = 'Lithuania',
	LUXEMBOURG = 'Luxembourg',
	MACAO = 'Macao',
	MACEDONIA = 'Macedonia',
	MADAGASCAR = 'Madagascar',
	MALAWI = 'Malawi',
	MALAYSIA = 'Malaysia',
	MALDIVES = 'Maldives',
	MALI = 'Mali',
	MALTA = 'Malta',
	MARSHALLISLANDS = 'Marshall Islands',
	MARTINIQUE = 'Martinique',
	MAURITANIA = 'Mauritania',
	MAURITIUS = 'Mauritius',
	MAYOTTE = 'Mayotte',
	MEXICO = 'Mexico',
	MICRONESIA = 'Micronesia',
	MOLDOVA = 'Moldova',
	MONACO = 'Monaco',
	MONGOLIA = 'Mongolia',
	MONTENEGRO = 'Montenegro',
	MONTSERRAT = 'Montserrat',
	MOROCCO = 'Morocco',
	MOZAMBIQUE = 'Mozambique',
	MYANMAR = 'Myanmar',
	NAMIBIA = 'Namibia',
	NAURU = 'Nauru',
	NEPAL = 'Nepal',
	NETHERLANDS = 'Netherlands',
	NEWCALEDONIA = 'New Caledonia',
	NEWZEALAND = 'New Zealand',
	NICARAGUA = 'Nicaragua',
	NIGER = 'Niger',
	NIGERIA = 'Nigeria',
	NIUE = 'Niue',
	NORFOLKISLAND = 'Norfolk Island',
	NORTHERNMARIANAISLANDS = 'Northern Mariana Islands',
	NORWAY = 'Norway',
	OMAN = 'Oman',
	PAKISTAN = 'Pakistan',
	PALAU = 'Palau',
	PALESTINIANTERRITORY = 'Palestinian Territory',
	PANAMA = 'Panama',
	PAPUANEWGUINEA = 'Papua New Guinea',
	PARAGUAY = 'Paraguay',
	PERU = 'Peru',
	PHILIPPINES = 'Philippines',
	PITCAIRN = 'Pitcairn',
	POLAND = 'Poland',
	PORTUGAL = 'Portugal',
	PUERTORICO = 'Puerto Rico',
	QATAR = 'Qatar',
	REUNION = 'Reunion',
	ROMANIA = 'Romania',
	RUSSIANFEDERATION = 'Russian Federation',
	RWANDA = 'Rwanda',
	SAINTBARTHELEMY = 'Saint Barthelemy',
	SAINTHELENA = 'Saint Helena',
	SAINTKITTSANDNEVIS = 'Saint Kitts and Nevis',
	SAINTLUCIA = 'SaintLucia',
	SAINTMARTIN = 'SaintMartin',
	SAINTPIERREANDMIQUELON = 'Saint Pierre and Miquelon',
	SAINTVINCENTANDGRENADINES = 'Saint Vincent and Grenadines',
	SAMOA = 'Samoa',
	SANMARINO = 'San Marino',
	SAOTOMEANDPRINCIPE = 'Sao Tome and Principe',
	SAUDIARABIA = 'Saudi Arabia',
	SENEGAL = 'Senegal',
	SERBIA = 'Serbia',
	SEYCHELLES = 'Seychelles',
	SIERRALEONE = 'Sierra Leone',
	SINGAPORE = 'Singapore',
	SINTMAARTEN = 'Sint Maarten',
	SLOVAKIA = 'Slovakia',
	SLOVENIA = 'Slovenia',
	SOLOMONISLANDS = 'Solomon Islands',
	SOMALIA = 'Somalia',
	SOUTHAFRICA = 'South Africa',
	SOUTHGEORGIAANDSANDWICHISL = 'South Georgia And Sandwich Isl',
	SOUTHSUDAN = 'South Sudan',
	SPAIN = 'Spain',
	SRILANKA = 'SriLanka',
	SUDAN = 'Sudan',
	SURINAME = 'Suriname',
	SVALBARDANDJANMAYEN = 'Svalbard and Jan Mayen',
	SWAZILAND = 'Swaziland',
	SWEDEN = 'Sweden',
	SWITZERLAND = 'Switzerland',
	SYRIANARABREPUBLIC = 'Syrian Arab Republic',
	TAIWAN = 'Taiwan',
	TAJIKISTAN = 'Tajikistan',
	TANZANIA = 'Tanzania',
	THAILAND = 'Thailand',
	TIMORLESTE = 'Timor Leste',
	TOGO = 'Togo',
	TOKELAU = 'Tokelau',
	TONGA = 'Tonga',
	TRINIDADANDTOBAGO = 'Trinidad and Tobago',
	TUNISIA = 'Tunisia',
	TURKEY = 'Turkey',
	TURKMENISTAN = 'Turkmenistan',
	TURKSANDCAICOSISLANDS = 'Turks  and Caicos Islands',
	TUVALU = 'Tuvalu',
	UGANDA = 'Uganda',
	UKRAINE = 'Ukraine',
	UNITEDARABEMIRATES = 'United Arab Emirates',
	UNITEDKINGDOM = 'United Kingdom',
	UNITEDSTATESOUTLYINGISLANDS = 'United States Outlying Islands',
	URUGUAY = 'Uruguay',
	UZBEKISTAN = 'Uzbekistan',
	VANUATU = 'Vanuatu',
	VENEZUELA = 'Venezuela',
	VIETNAM = 'Vietnam',
	VIRGINISLANDSBRITISH = 'Virgin Islands British',
	VIRGINISLANDSUS = 'Virgin Islands US',
	WALLISANDFUTUNA = 'Wallis and Futuna',
	WESTERNSAHARA = 'Western Sahara',
	YEMEN = 'Yemen',
	ZAMBIA = 'Zambia',
	ZIMBABWE = 'Zimbabwe',
}
