export function upperFirst(target: string | null) {
	if (!target) return null;

	return target.charAt(0).toUpperCase() + target.slice(1);
}

export function camelize(str: string) {
	if (str) {
		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
				return index === 0 ? word.toLowerCase() : word.toUpperCase();
			})
			.replace(/\s+/g, '');
	} else {
		return '';
	}
}

export function capitalize(str: string | null): string | null {
	if (str) {
		str = str.toLowerCase();

		return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
	} else {
		return null;
	}
}

export function capitalizeWords(str: string | null): string {
	if (str) {
		const lowercaseStr = str.toLowerCase();

		const words = lowercaseStr.split(' ');

		return words
			.map((word) => {
				return word[0].toUpperCase() + word.substring(1);
			})
			.join(' ');
	} else {
		return '';
	}
}

/**
 * Computes the initial letters for each word.
 */
export function initials(value: string, maxCount: number = 1): string | null {
	const re: RegExp = /([A-Z])/g;
	let newValue: string | null = capitalize(value);

	if (newValue) {
		newValue = newValue.replace(re, ' $1');
		newValue = newValue
			.split(' ')
			.map((x) => capitalize(x[0]))
			.slice(0, maxCount + 1)
			.join('');

		return newValue;
	} else {
		return null;
	}
}

export function humanize(value?: string | null, keepCasing: boolean = false): string {
	if (!value) {
		return value!;
	}

	if (!/([a-z])/g.test(value)) {
		let newValue: string[] = value.split('_');
		const re: RegExp = /([0-9]+)/g; // Check if there number
		newValue = newValue.map((x) => x.replace(re, ' $1')); // Add space before any number set
		newValue = !keepCasing ? newValue.map((x) => x.toLowerCase()) : newValue;

		const returnValue = newValue.map((x) => upperFirst(x)).join(' '); // Replace _ with space

		return returnValue.trim();
	} else {
		let newValue: string = value.split('_').join(' '); // Replace _ with space
		const re: RegExp = /([A-Z])/g;
		newValue = newValue.replace(re, ' $1'); // Add space before any capital letter
		newValue = newValue.replace('  ', ' ').trim(); // Add space before any capital letter
		newValue = newValue[0].toUpperCase() + newValue.slice(1);

		return newValue.trim(); // Make sure there are no trailing or leading spaces
	}
}

export const numericStr = (value: string): string => {
	return value.replace(/\D/g, '');
};

export const truncateFileName = (value: string, length: number): string => {
	const ext = value.substring(value.lastIndexOf('.') + 1, value.length).toLowerCase();
	let filename = value.replace('.' + ext, '');

	if (filename.length <= length) {
		return value;
	}
	filename = filename.substr(0, length) + (value.length > length ? '[...]' : '');

	return filename + '.' + ext;
};

export const generateShortUUID = (): string => Math.random().toString(36).substring(2, 15);

export const formatSocial = (value: string): string => {
	if (value) {
		const input = value.substring(0, 9); // First ten digits of input only
		const first = input.substring(0, 3);
		const middle = input.substring(3, 5);
		const last = input.substring(5, 9);
		let maskedValue = '';

		if (input.length > 5) {
			maskedValue = `${first}-${middle}-${last}`;
		} else if (input.length > 3) {
			maskedValue = `${first}-${middle}`;
		} else if (input.length > 0) {
			maskedValue = `${first}`;
		}

		return maskedValue ? maskedValue : input;
	}

	return '';
};

export const displayErrorMessage = (message: string) => {
	if (message[message.length - 1] === '.') {
		return message;
	}

	return `${message}.`;
};
