import { Spinner, SpinnerProps } from '@ui-kitten/components';
import React from 'react';
import { View, StyleSheet } from 'react-native';

export type AppLoadingProps = object & SpinnerProps;

export function AppLoading(props: AppLoadingProps) {
	return (
		<View style={[styles.container, props.style]}>
			<Spinner {...props} />
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		justifyContent: 'center',
		alignItems: 'center',
		height: 200,
	},
});
