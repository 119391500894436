export enum NavigationComponentLocators {
	currentPasswordField = 'currentPasswordField',
	newPasswordField = 'newPasswordField',
	confirmPasswordField = 'confirmPasswordField',
	passwordSuccessCloseButton = 'passwordSuccessCloseButton',
	passwordFailCloseButton = 'passwordFailCloseButton',
	saveButton = 'saveButton',
	logoutButton = 'logoutButton',
	changePasswordButton = 'changePasswordButton',
	customersDrawerItem = 'customersDrawerItem',
	accountsDrawerItem = 'accountsDrawerItem',
	fundingInflowDrawerItem = 'fundingInflowDrawerItem',
	fundingOutflowDrawerItem = 'fundingOutflowDrawerItem',
	assetsDrawerItem = 'assetsDrawerItem',
	auditsDrawerItem = 'auditsDrawerItem',
	usersDrawerItem = 'usersDrawerItem',
	logoutDrawerItem = 'logoutDrawerItem',
	reportingDrawerItem = 'reportingDrawerItem',
	stakingDrawerItem = 'stakingDrawerItem',
}
