export const CustomerRoutes = {
	Customers: {
		path: 'customers',
		initialRouteName: '',
		screens: {
			Home: {
				path: '',
			},
			Edit: {
				path: 'edit',
			},
		},
	},
};

export type CustomersParams = keyof typeof CustomerRoutes.Customers.screens;

export type CustomersParamsRecord = Record<CustomersParams, undefined>;
