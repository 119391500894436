import { FundingStage, FundingDirection } from '@app/codegen';

import { AccountsRoutes } from './Accounts/AccountsRoutes';
import { AdminRoutes } from './Admin/AdminRoutes';
import { CustomerRoutes } from './Customers/CustomersRoutes';
import { FundingsInflowRoutes } from './FundingsInflow/FundingsInflowRoutes';
import { FundingsOutflowRoutes } from './FundingsOutflow/FundingsOutflowRoutes';
import { StakingTermsRoutes } from './StakingMovements/StakingTermsRoutes';

export const MainDrawerRoutes = {
	MainDrawerNavigation: {
		path: 'main',
		screens: {
			...AccountsRoutes,
			...CustomerRoutes,
			...FundingsInflowRoutes,
			...FundingsOutflowRoutes,
			...AdminRoutes,
			...StakingTermsRoutes,
		},
	},
};

export type EditFundingScreenParams = {
	fundingId: number;
	fundingStage?: FundingStage;
	direction: FundingDirection;
};

export type MainDrawerParams = keyof typeof MainDrawerRoutes.MainDrawerNavigation.screens;

export type MainDrawerParamsRecord = Record<MainDrawerParams, undefined>;
