import { AppIcon } from '@itrustcapital/ui';
import { Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';

export function NoData() {
	const styles = useCustomStyles();

	return (
		<View style={styles.container}>
			<AppIcon lib="ant" name="database" size={18} style={styles.icon} />
			<Text category="s1">No records to display</Text>
		</View>
	);
}

function useCustomStyles() {
	return StyleSheet.create({
		container: {
			width: '100%',
			padding: 60,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
		},
		icon: {
			paddingRight: 8,
		},
	});
}
