/**
 * @param data Form Data
 *
 * @returns boolean - returns true if all the fields are falsy
 */
export function isFormEmpty(data: object) {
	const values = Object.values(data);

	let sum = 0;

	values.forEach((val) => {
		if (val) {
			sum += 1;
		}
	});

	return !sum;
}
