import { useMainDrawerNavigation } from '@app/navigations';
import { AuditLogsScreen, ReportingScreen, UsersScreen } from '@app/screens';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { AdminParamsRecord } from './AdminRoutes';

export function useAdminNavigation() {
	const navigation = useMainDrawerNavigation('Admin');

	return {
		Users: (reset?: boolean) =>
			navigation.Base(
				{
					screen: 'Users',
				},
				reset
			),
		AuditLogs: (reset?: boolean) =>
			navigation.Base(
				{
					screen: 'AuditLogs',
				},
				reset
			),
		Reporting: (reset?: boolean) =>
			navigation.Base(
				{
					screen: 'Reporting',
				},
				reset
			),
		AdminLink: navigation.BaseLink + '/admin',
	};
}

const Stack = createStackNavigator<AdminParamsRecord>();

export function AdminNavigation() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			{/* these could be their own stacks too...but for now just single? */}
			<Stack.Screen component={UsersScreen} name="Users" />
			<Stack.Screen component={AuditLogsScreen} name="AuditLogs" />
			<Stack.Screen component={ReportingScreen} name="Reporting" />
		</Stack.Navigator>
	);
}
