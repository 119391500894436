export enum AddAccountBeneficiaryLocators {
	addAccountBeneficiaryChooseExistingField = 'addAccountBeneficiaryChooseExistingField',
	addAccountBeneficiaryCreateNewField = 'addAccountBeneficiaryCreateNewField',
	addAccountBeneficiarySearchAvailableField = 'addAccountBeneficiarySearchAvailableField',
	addAccountBeneficiaryAvailableResultsContainer = 'addAccountBeneficiaryAvailableResultsContainer',
	addAccountBeneficiaryPercentageField = 'addAccountBeneficiaryPercentageField',
	addAccountBeneficiaryTypeField = 'addAccountBeneficiaryTypeField',
	addAccountBeneficiaryFirstNameField = 'addAccountBeneficiaryFirstNameField',
	addAccountBeneficiaryLastNameField = 'addAccountBeneficiaryLastNameField',
	addAccountBeneficiaryEmailField = 'addAccountBeneficiaryEmailField',
	addAccountBeneficiaryStreetField = 'addAccountBeneficiaryStreetField',
	addAccountBeneficiaryCityField = 'addAccountBeneficiaryCityField',
	addAccountBeneficiaryStateField = 'addAccountBeneficiaryStateField',
	addAccountBeneficiaryZipcodeField = 'addAccountBeneficiaryZipcodeField',
	addAccountBeneficiaryDateOfBirthField = 'addAccountBeneficiaryDateOfBirthField',
	addAccountBeneficiaryPrimaryPhoneField = 'addAccountBeneficiaryPrimaryPhoneField',
	addAccountBeneficiarySocialSecurityField = 'addAccountBeneficiarySocialSecurityField',
	addAccountBeneficiarySpouseField = 'addAccountBeneficiarySpouseField',
	addAccountBeneficiaryCloseButton = 'addAccountBeneficiaryCloseButton',
	addAccountBeneficiaryCancelButton = 'addAccountBeneficiaryCancelButton',
	addAccountBeneficiarySaveButton = 'addAccountBeneficiarySaveButton',
	addAccountBeneficiary = 'addAccountBeneficiary',
}
