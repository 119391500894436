import { FundingDirection, FundingFilterInput } from '@app/codegen';
import { TopHeader } from '@app/shared/components';
import { AppBackButton, AppScreen, StackNavigation } from '@itrustcapital/ui';
import * as ReactNavigation from '@react-navigation/native';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';

import { FundingsScreenProvider, useFundingsScreenState } from './FundingsScreenProvider';
import { FundingsScreenTable, FilterFundingsFilterForm } from './components';

export interface FundingsScreenProps {
	fundingDirection: FundingDirection;
}

export function FundingsScreen(props: FundingsScreenProps) {
	const styles = useCustomStyles();
	const navigation = ReactNavigation.useNavigation<StackNavigation>();
	const isFundingsInflow = props.fundingDirection === FundingDirection.INBOUND;
	const state = useFundingsScreenState({
		isFundingsInflow,
	});

	React.useEffect(() => {
		navigation.setOptions({
			title: isFundingsInflow
				? 'Fundings Inflow - ITrustCapital'
				: 'Fundings Outflow - ITrustCapital',
			headerTitle: isFundingsInflow ? 'Fundings Inflow' : 'Fundings Outflow',
			headerLeft: () => <AppBackButton />,
			headerRight: () => null,
		});
	}, []);

	React.useEffect(() => {
		const where: FundingFilterInput = {
			direction: {
				eq: isFundingsInflow ? FundingDirection.INBOUND : FundingDirection.OUTBOUND,
			},
		};

		state.fundingsQuery.refetch({ where });
		state.tableState.where.set(where);
	}, [props.fundingDirection]);

	return (
		<AppScreen noMarginBottom noMaxWidth noPadding>
			<FundingsScreenProvider value={state}>
				<TopHeader title={`Fundings ${isFundingsInflow ? 'Inflow' : 'Outflow'}`} />
				<Card style={styles.main.card}>
					<FilterFundingsFilterForm isFundingsInflow={isFundingsInflow} />
					{/* Data Table */}
					<FundingsScreenTable />
				</Card>
			</FundingsScreenProvider>
		</AppScreen>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			container: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				textTransform: 'capitalize',
			},
			card: {
				borderRadius: 12,
				padding: 12,
				margin: 32,
			},
		}),
	};
}

export function FundingsInflowScreen() {
	return <FundingsScreen fundingDirection={FundingDirection.INBOUND} />;
}

export function FundingsOutflowScreen() {
	return <FundingsScreen fundingDirection={FundingDirection.OUTBOUND} />;
}
