import { gql, useLazyQuery } from '@apollo/client';
import {
	AccountFilterInput,
	AccountStage,
	SortEnumType,
	EasFcFasGetAccounts,
	EasFcFasGetAccountsVariables,
	EasFcFasGetAccounts_accounts_nodes,
} from '@app/codegen';
import { accountTypeHumanizer } from '@app/shared/helpers';
import { AppIcon, AppModal, useAppState, useAppTheme } from '@itrustcapital/ui';
import { Button, Input, Layout, TopNavigation, Text, ListItem } from '@ui-kitten/components';
import React from 'react';
import { ScrollView, StatusBar, StyleSheet, TouchableOpacity, View } from 'react-native';

export const EAS_FC_FAS_GET_ACCOUNTS = gql`
	query EasFcFasGetAccounts(
		$input: GetAccountsInput
		$where: AccountFilterInput
		$order: [AccountSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		accounts(
			input: $input
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				id
				accountNumber
				type
				status
				custodian
				user {
					firstName
					lastName
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export interface AppSelectorProps {
	onDone: (item: EasFcFasGetAccounts_accounts_nodes) => void;
	disabled: boolean;
}

export function FundingAccountSearch(props: AppSelectorProps) {
	const theme = useAppTheme();
	const styles = useCustomStyles(theme);
	const showSelect = useAppState(false);
	const selected = useAppState<any>(null);
	const searchTerm = useAppState('');

	const whereValue = useAppState<AccountFilterInput>({ stage: { neq: AccountStage.NONE } });

	const [getAccounts, accountsQuery] = useLazyQuery<
		EasFcFasGetAccounts,
		EasFcFasGetAccountsVariables
	>(EAS_FC_FAS_GET_ACCOUNTS, {
		variables: {
			input: null,
			where: whereValue.get,
			order: [{ createdAt: SortEnumType.DESC }],
			first: 50,
		},
	});

	function onSelect(item: any): void {
		selected.set(item);
	}

	return (
		<>
			<AppModal
				animationType="slide"
				hardwareAccelerated={true}
				presentationStyle="overFullScreen"
				style={styles.modal}
				visible={showSelect.get}
			>
				<StatusBar barStyle="light-content" />
				<Layout style={styles.container}>
					<TopNavigation
						accessoryLeft={() => (
							<Button
								appearance="ghost"
								status="basic"
								onPress={() => onSelect(undefined)}
							>
								Clear
							</Button>
						)}
						accessoryRight={() => (
							<Button
								appearance="ghost"
								onPress={() => {
									showSelect.set(false);
									props.onDone?.(selected.get);
								}}
							>
								Done
							</Button>
						)}
						alignment="center"
					/>
					<View style={styles.body}>
						<Text appearance="hint" category="p2" style={styles.caption}>
							Search for Account
						</Text>
						<Input
							accessoryLeft={() => <AppIcon lib="ev" name="search" size={24} />}
							accessoryRight={
								searchTerm.get.length > 0
									? () => (
											<Button
												size="tiny"
												status="basic"
												onPress={() => searchTerm.set('')}
											>
												Clear
											</Button>
									  )
									: undefined
							}
							placeholder="First Name, Last Name, Email, Account #..."
							style={styles.filter}
							value={searchTerm.get}
							onChangeText={searchTerm.set}
							onSubmitEditing={() => {
								whereValue.set({
									...whereValue.get,
									or: [
										{
											user: {
												firstName: {
													contains: searchTerm.get,
												},
											},
										},
										{
											user: {
												lastName: {
													contains: searchTerm.get,
												},
											},
										},
										{
											user: {
												email: {
													contains: searchTerm.get,
												},
											},
										},
										{
											accountNumber: {
												contains: searchTerm.get,
											},
										},
										{
											accountNumber: {
												eq: searchTerm.get,
											},
										},
									],
								});
								getAccounts();
							}}
						/>
					</View>
					<ScrollView scrollIndicatorInsets={{ right: 0 }}>
						<View style={styles.scrollContainer}>
							{(accountsQuery.data?.accounts?.nodes || []).map((item) => (
								<ListItem
									accessoryRight={
										selected.get === item
											? () => (
													<AppIcon
														lib="fe"
														name="check"
														style={styles.check}
													/>
											  )
											: undefined
									}
									key={item?.id}
									style={styles.item}
									title={() => (
										<Text
											appearance="hint"
											status={selected.get === item ? 'primary' : undefined}
										>
											{`${item?.user?.firstName} ${
												item?.user?.lastName
											} - ${accountTypeHumanizer(item?.type!)} (${
												item?.accountNumber
											})`}
										</Text>
									)}
									onPress={() => onSelect(item)}
								/>
							))}
						</View>
					</ScrollView>
				</Layout>
			</AppModal>
			<TouchableOpacity disabled={props.disabled} onPress={() => showSelect.set(true)}>
				<AppIcon
					color={props.disabled ? theme['color-info-disabled'] : undefined}
					lib="matc"
					name="selection-search"
					size={20}
				/>
			</TouchableOpacity>
		</>
	);
}

function useCustomStyles(theme: ReturnType<typeof useAppTheme>) {
	return StyleSheet.create({
		modal: {
			justifyContent: 'center',
			alignItems: 'center',
			padding: 0,
			margin: 0,
		},
		container: {
			borderRadius: 30,
			width: '95%',
			height: '100%',
			maxWidth: 400,
			maxHeight: 600,
			overflow: 'hidden',
		},
		body: {
			padding: 25,
			paddingTop: 0,
			paddingBottom: 0,
		},
		scrollContainer: {
			padding: 25,
			paddingTop: 0,
			marginBottom: 150,
		},
		caption: {
			marginBottom: 10,
		},
		filter: {
			marginBottom: 10,
		},
		item: {
			borderBottomWidth: StyleSheet.hairlineWidth,
			borderBottomColor: theme['color-basic-transparent-500'],
			borderRadius: 3,
		},
		right: {
			color: theme['text-hint-color'],
		},
		check: {
			fontSize: 20,
			color: theme['color-primary-500'],
		},
	});
}
