import { SystemRole } from '@app/codegen';
import { NavigationComponentLocators } from '@app/e2e/screens/Navigation';
import { AppIcon, useAppDevice, useAppState } from '@itrustcapital/ui';
import { DrawerContentComponentProps, DrawerContentScrollView } from '@react-navigation/drawer';
import { Drawer, IndexPath, DrawerGroup, DrawerItem, Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';

import { useMainDrawerNavigation } from './MainDrawerNavigation';
import packagejson from '../../../package.json';
import { useAuthNavigation } from '../Auth';

interface MainDrawerContentProps extends DrawerContentComponentProps {
	role: SystemRole;
	isScreenLg: boolean;
	open: () => void;
	close: () => void;
}

export type TooltipState = {
	top: number;
	left: number;
	text: string;
};

const RouteMap: { [key: string]: number } = {
	Accounts: 0,
	Customers: 1,
	FundingsInflow: 2,
	FundingsOutflow: 3,
};

export function MainDrawerContent(props: MainDrawerContentProps) {
	const dynamicStyles = useDynamicStyles();
	const mainDrawerNavigation = useMainDrawerNavigation();
	const authNavigation = useAuthNavigation();

	const selectedMenuIndex = useAppState<IndexPath | undefined>(undefined);

	React.useEffect(() => {
		// initialize drawer highlighted on first load
		const last = props.state.history[props.state.history.length - 1];

		if (last.type === 'route' && props.state.index !== 6) {
			const split = last.key.split('-');
			selectedMenuIndex.set(new IndexPath(RouteMap[split[0]]));
		}
	}, []);

	React.useEffect(() => {
		if (props.isScreenLg) {
			props.navigation.openDrawer();
			props.open();
			// need timeout to give drawer enough time to open before drawer type is set to fixed which freezes animation
			setTimeout(() => {
				props.open();
			}, 1000);
		} else {
			props.navigation.closeDrawer();
			props.close();
		}
	}, [props.isScreenLg]);

	function getIsDrawerItemFocused(index: number): boolean {
		return props.state.index === index;
	}

	function onDrawerPress(screen: string, index: number): void {
		const isFocused = getIsDrawerItemFocused(index);
		mainDrawerNavigation.Base(
			{
				screen,
				params: isFocused
					? {
							screen: 'Home',
					  }
					: undefined, // if not focused go to prev state.
			},
			true
		);
	}

	return (
		<View style={[styles.general.container, dynamicStyles.general.container]}>
			{/* Component with position based on hover state */}
			<DrawerContentScrollView {...props} style={styles.drawer.container}>
				<Drawer
					appearance="noDivider"
					selectedIndex={selectedMenuIndex.get}
					onSelect={(index) => {
						// prevent selecting menu groups and logout button
						if (index.section || [0, 1, 2, 3, 4, 5].includes(index.row)) {
							selectedMenuIndex.set(index);
						}
					}}
				>
					<DrawerItem
						accessoryLeft={() => (
							<AppIcon lib="fa5" name="coins" size={22} style={styles.drawer.icon} />
						)}
						selected={getIsDrawerItemFocused(2)}
						testID={NavigationComponentLocators.accountsDrawerItem}
						title="IRA Accounts"
						onPress={() => onDrawerPress('Accounts', 2)}
					/>
					<DrawerItem
						accessoryLeft={() => (
							<AppIcon lib="fa5" name="users" size={22} style={styles.drawer.icon} />
						)}
						selected={getIsDrawerItemFocused(1)}
						testID={NavigationComponentLocators.customersDrawerItem}
						title="Customers"
						onPress={() => onDrawerPress('Customers', 1)}
					/>
					<DrawerItem
						accessoryLeft={() => (
							<AppIcon
								lib="fa5"
								name="plus-square"
								size={22}
								style={styles.drawer.icon}
							/>
						)}
						selected={getIsDrawerItemFocused(3)}
						testID={NavigationComponentLocators.fundingInflowDrawerItem}
						title="Fundings Inflow"
						onPress={() => onDrawerPress('FundingsInflow', 3)}
					/>
					<DrawerItem
						accessoryLeft={() => (
							<AppIcon
								lib="fa5"
								name="minus-square"
								size={22}
								style={styles.drawer.icon}
							/>
						)}
						selected={getIsDrawerItemFocused(4)}
						testID={NavigationComponentLocators.fundingOutflowDrawerItem}
						title="Fundings Outflow"
						onPress={() => onDrawerPress('FundingsOutflow', 4)}
					/>
					<DrawerGroup
						accessoryLeft={() => (
							<AppIcon
								lib="fa5"
								name="users-cog"
								size={22}
								style={styles.drawer.icon}
							/>
						)}
						title="Administration"
					>
						<DrawerItem
							disabled={props.role !== SystemRole.SUPER_ADMIN}
							testID={NavigationComponentLocators.usersDrawerItem}
							title="Users"
							onPress={() =>
								mainDrawerNavigation.Base({
									screen: 'Admin',
									params: {
										screen: 'Users',
									},
								})
							}
						/>
						<DrawerItem
							disabled={props.role !== SystemRole.SUPER_ADMIN}
							testID={NavigationComponentLocators.auditsDrawerItem}
							title="Audit Logs"
							onPress={() =>
								mainDrawerNavigation.Base({
									screen: 'Admin',
									params: {
										screen: 'AuditLogs',
									},
								})
							}
						/>
						<DrawerItem
							testID={NavigationComponentLocators.reportingDrawerItem}
							title="Reporting"
							onPress={() =>
								mainDrawerNavigation.Base({
									screen: 'Admin',
									params: {
										screen: 'Reporting',
									},
								})
							}
						/>
					</DrawerGroup>
					<DrawerGroup
						accessoryLeft={() => (
							<AppIcon
								lib="matc"
								name="view-grid-plus-outline"
								size={22}
								style={styles.drawer.icon}
							/>
						)}
						title="Other Programs"
					>
						<DrawerItem
							disabled={
								!(
									props.role === SystemRole.SUPER_ADMIN ||
									props.role === SystemRole.ADMIN
								)
							}
							testID={NavigationComponentLocators.stakingDrawerItem}
							title="Staking"
							onPress={() =>
								mainDrawerNavigation.Base({
									screen: 'StakingTerms',
								})
							}
						/>
					</DrawerGroup>
					<DrawerItem
						accessoryLeft={() => (
							<AppIcon
								lib="fa5"
								name="sign-out-alt"
								size={22}
								style={styles.drawer.icon}
							/>
						)}
						testID={NavigationComponentLocators.logoutDrawerItem}
						title="Log Out"
						onPress={authNavigation.SignOut}
					/>
				</Drawer>
			</DrawerContentScrollView>

			<Text style={styles.versionText}>{`v${packagejson?.version}`}</Text>
		</View>
	);
}

const styles = {
	general: StyleSheet.create({
		container: {
			flex: 1,
			overflow: 'visible',
		},
	}),

	drawer: StyleSheet.create({
		container: {
			overflow: 'visible',
		},
		icon: {
			paddingVertical: 12,
			width: 40,
			alignItems: 'center',
			justifyContent: 'center',
			marginLeft: 10,
		},
		flags: {
			marginLeft: 15,
		},
	}),
	avatar: StyleSheet.create({
		container: {
			alignItems: 'center',
			marginTop: 40,
			marginBottom: 40,
		},
		item: {
			width: 100,
			height: 100,
			borderRadius: 400,
			marginBottom: 10,
		},
	}),
	header: StyleSheet.create({
		container: {
			width: 100,
			height: 100,
			borderRadius: 400,
			marginBottom: 10,
		},
	}),
	versionText: {
		padding: 8,
		textAlign: 'center',
		fontSize: 10,
	},
};

function useDynamicStyles() {
	const appDevice = useAppDevice();

	return {
		general: StyleSheet.create({
			container: {
				paddingTop: appDevice.isNativeMobile ? 30 : 'auto',
			},
		}),
	};
}
