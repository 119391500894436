export enum EditAccountBeneficiaryLocators {
	editAccountBeneficiaryPercentageField = 'editAccountBeneficiaryPercentageField',
	editAccountBeneficiaryTypeField = 'editAccountBeneficiaryTypeField',
	editAccountBeneficiaryFirstNameField = 'editAccountBeneficiaryFirstNameField',
	editAccountBeneficiaryLastNameField = 'editAccountBeneficiaryLastNameField',
	editAccountBeneficiaryEmailField = 'editAccountBeneficiaryEmailField',
	editAccountBeneficiaryStreetField = 'editAccountBeneficiaryStreetField',
	editAccountBeneficiaryCityField = 'editAccountBeneficiaryCityField',
	editAccountBeneficiaryStateField = 'editAccountBeneficiaryStateField',
	editAccountBeneficiaryZipcodeField = 'editAccountBeneficiaryZipcodeField',
	editAccountBeneficiaryDateOfBirthField = 'editAccountBeneficiaryDateOfBirthField',
	editAccountBeneficiaryPrimaryPhoneField = 'editAccountBeneficiaryPrimaryPhoneField',
	editAccountBeneficiarySocialSecurityField = 'editAccountBeneficiarySocialSecurityField',
	editAccountBeneficiarySpouseField = 'editAccountBeneficiarySpouseField',
	editAccountBeneficiary = 'editAccountBeneficiary',
	editAccountBeneficiaryCancelButton = 'editAccountBeneficiaryCancelButton',
	editAccountBeneficiarySaveButton = 'editAccountBeneficiarySaveButton',
}
