export enum CustomerScreenLocators {
	exportToExcelButton = 'exportToExcelButton',
	createCustomerButton = 'createCustomerButton',
	deleteCustomerModalCancelButton = 'deleteCustomerModalCancelButton',
	deleteCustomerModalConfirmButton = 'deleteCustomerModalConfirmButton',
	customersSearchField = 'customersSearchField',
	customersPromoField = 'customersPromoField',
	customersCreatedFromField = 'customersCreatedFromField',
	customersCreatedToField = 'customersCreatedToField',
	customersApplyButton = 'customersApplyButton',
	customersClearButton = 'customersClearButton',
	customersFundingPendingToField = 'customersFundingPendingToField',
	customersFundingPendingFromField = 'customersFundingPendingFromField',
	customerSearchIconButton = 'customerSearchIconButton',
	deleteCustomerToast = 'deleteCustomerToast',
	customersMobilePhoneNumberField = 'customersMobilePhoneNumberField',
	customerRefreshIcon = 'customerRefreshIcon',
}
