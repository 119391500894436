import { gql, useLazyQuery } from '@apollo/client';
import {
	EditAccountScreen_GetStakingMovements,
	EditAccountScreen_GetStakingMovements_stakingMovements_nodes as Staking,
	SortEnumType,
	StakingMovementFilterInput,
	StakingMovementSortInput,
} from '@app/codegen';
import { CustomCard, CustomDataTable, ITrustColumn, useTableState } from '@app/shared/components';
import { StakingMovementDirectionHumanizer } from '@app/shared/helpers';
import { humanize } from '@itrustcapital/ui';
import { format } from 'date-fns';
import React from 'react';

export interface StakingCardProps {
	accountId: number;
}

export const EDIT_ACCOUNT_SCREEN_GET_STAKINGMOVEMENTS = gql`
	query EditAccountScreen_GetStakingMovements(
		$where: StakingMovementFilterInput
		$order: [StakingMovementSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		stakingMovements(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				accountId
				currencyType
				direction
				type
				id
				createdAt
				amount
				stakingEnrollment {
					stakingTerm {
						durationDays
						apy
					}
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export function StakingCard(props: StakingCardProps) {
	const tableState = useTableState<StakingMovementSortInput, StakingMovementFilterInput>({
		defaultRowsPerPage: 5,
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere: {
			accountId: { eq: props.accountId },
		},
		striped: true,
		pagination: true,
	});
	const [getStakingMovements, getStakingMovementsQuery] =
		useLazyQuery<EditAccountScreen_GetStakingMovements>(
			EDIT_ACCOUNT_SCREEN_GET_STAKINGMOVEMENTS,
			{
				variables: {
					order: tableState.sort.get,
					first: tableState.rowsPerPage.get,
					where: tableState.where.get,
				},
				onCompleted: (data) => {
					if (data) {
						tableState.onCompleted(
							data.stakingMovements?.totalCount,
							data.stakingMovements?.pageInfo
						);
					}
				},
			}
		);

	const columns: ITrustColumn<Staking>[] = [
		{
			key: 'createdAt',
			name: 'Created At',
			center: true,
			selector: (row) => format(new Date(row.createdAt), 'MM/dd/yyyy'),
		},
		{
			key: 'stakingEnrollment',
			name: 'Est. Net APY',
			center: true,
			selector: (row) => `${row?.stakingEnrollment?.stakingTerm?.apy} %`,
		},
		{
			key: 'amount',
			name: 'Amount',
			center: true,
			selector: (row) => `${row?.amount}  ${row?.currencyType}`,
		},
		{
			key: 'type',
			name: 'Type',
			center: true,
			selector: (row) => humanize(row.type),
		},
		{
			key: 'stakingEnrollment',
			name: 'Terms',
			center: true,
			selector: (row) =>
				row?.stakingEnrollment?.stakingTerm?.durationDays
					? `${row?.stakingEnrollment?.stakingTerm?.durationDays} Days`
					: '',
		},
		{
			key: 'direction',
			name: 'Stake Direction',
			center: true,
			selector: (row) => StakingMovementDirectionHumanizer(row?.direction),
		},
	];
	React.useEffect(() => {
		getStakingMovements();
	}, []);

	return (
		<CustomCard header="Staking">
			<CustomDataTable
				columns={columns}
				data={getStakingMovementsQuery.data?.stakingMovements?.nodes as Staking[]}
				progressPending={getStakingMovementsQuery.loading}
				refetch={(variables) =>
					getStakingMovements({
						variables: { ...variables, where: tableState.where.get },
					})
				}
				{...tableState.props}
			/>
		</CustomCard>
	);
}
