export enum StakingMovementLocators {
	statusField = 'statusField',
	currencyTypeField = 'currencyTypeField',
	directionField = 'directionField',
	createdAtField = 'createdAtField',
	destinationField = 'destinationField',
	termField = 'termField',
	applyFiltersButton = 'applyFiltersButton',
	clearFiltersButton = 'clearFiltersButton',
}
