import { States } from '../models';
import { toCurrency } from './number.helper';
import { formatSocial, numericStr } from './string.helpers';

const isModifierKey = (event: any) => {
	const key = event?.keyCode;

	return (
		event?.shiftKey === true ||
		key === 35 ||
		key === 36 || // Allow Shift, Home, End
		key === 8 ||
		key === 9 ||
		key === 13 ||
		key === 46 || // Allow Backspace, Tab, Enter, Delete
		(key > 36 && key < 41) || // Allow left, up, right, down
		// Allow Ctrl/Command + A,C,V,X,Z
		((event?.ctrlKey === true || event?.metaKey === true) &&
			(key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
	);
};

export const maskPhoneNumber = (value: any) => {
	if (isModifierKey(value)) {
		return;
	}

	if (value || value?.nativeEvent?.text) {
		const text = value?.nativeEvent?.text ? value?.nativeEvent?.text : value;

		const input = text.toString().replace(/\D/g, '').substring(0, 10); // First ten digits of input only
		const areaCode = input.substring(0, 3);
		const middle = input.substring(3, 6);
		const last = input.substring(6, 10);

		let maskedValue = '';

		if (input.length > 6) {
			maskedValue = `(${areaCode}) ${middle}-${last}`;
		} else if (input.length > 3) {
			maskedValue = `(${areaCode}) ${middle}`;
		} else if (input.length > 0) {
			maskedValue = `(${areaCode}`;
		}

		return maskedValue ? maskedValue : input;
	} else if (value?.target?.value || value?.nativeEvent?.text) {
		const target = value.target;
		const input = target.value.toString().replace(/\D/g, '').substring(0, 10); // First ten digits of input only
		const areaCode = input.substring(0, 3);
		const middle = input.substring(3, 6);
		const last = input.substring(6, 10);

		let maskedValue = '';

		if (input.length > 6) {
			maskedValue = `(${areaCode}) ${middle}-${last}`;
		} else if (input.length > 3) {
			maskedValue = `(${areaCode}) ${middle}`;
		} else if (input.length > 0) {
			maskedValue = `(${areaCode}`;
		}

		return maskedValue ? maskedValue : input;
	} else {
		return '';
	}
};

export const maskSocial = (text: string | null): string => {
	if (text) {
		const value = numericStr(text);

		return formatSocial(value);
	} else {
		return '';
	}
};

// Argument should be string but the DateTime types are being converted to any
// by the codegen so using unknown type here will force us to guarantee a string
export const maskDate = (text: unknown): string => {
	// TODO: Make this function backspace compatible

	if (typeof text !== 'string') {
		return '';
	}

	const input = numericStr(text).substring(0, 8); // First ten digits of input only

	const month = input.substring(0, 2); // 1, 2
	const day = input.substring(2, 4); // 3, 4
	const year = input.substring(4, 8); // 5, 6, 7, 8
	let maskedValue = '';

	if (input.length > 4) {
		maskedValue = `${month}/${day}/${year}`;
	} else if (input.length > 2) {
		maskedValue = `${month}/${day}`;
	} else if (input.length > 0) {
		maskedValue = `${month}`;
	}

	return maskedValue ? maskedValue : input;
};

export const maskCreditCard = (text: string): string => {
	if (!text) return '';

	const value = numericStr(text).replace(/\s+/g, '');
	const match = value.match(/.{1,4}/g);

	return match ? match.join(' ') : value;
};

export const maskPercentageShare = (text: string): string => {
	if (!text) return '';

	const value = numericStr(text).replace(/\s+/g, '');
	const maskedValue = value;

	return maskedValue ? maskedValue : value;
};

export const maskCurrency = (value: string | undefined | null): string => {
	if (!value) return '';

	if (value && value !== '') {
		const valueStr = value.replace(/[^\d.]/g, '');
		const maskedValue = parseFloat(
			valueStr
				.replace(/(.*){1}/, '0$1')
				.replace(/[^\d]/g, '')
				.replace(/(\d\d?)$/, '.$1')
		);

		return toCurrency(maskedValue);
	} else {
		return '$0.00';
	}
};

//  need to understand this
export const decimalStr = (
	value: number | string | null | undefined,
	defaultPlaceholder: number = 0,
	maxDecimals?: number | undefined
): string => {
	if (typeof value == 'number') {
		return value?.toString() || '';
	} else {
		if (!value) return '';
		const val = value.replaceAll(',', '');
		const hasDecimal = val.includes('.');
		const split = hasDecimal ? val.split('.') : null;

		if (parseInt(val) || parseFloat(val)) {
			if (split) {
				const decimals = split[1].slice(0, maxDecimals);

				return `${(+split[0]).toLocaleString()}.${decimals || ''}`;
			}

			return (+val).toLocaleString();
		}

		return val;
	}
};

export const unMaskCurrency = (number: string): number => {
	if (!number) return 0;

	if (number) {
		return parseFloat(number.replace('$', '').replaceAll(',', ''));
	} else {
		return 0;
	}
};

export const availableStates = Object.keys(States) || [];
