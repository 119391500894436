import { gql, useQuery } from '@apollo/client';
import {
	FundingAssetSortInput,
	EfsFacGetFundingAssets,
	EfsFacGetFundingAssetsVariables,
	EfsFacGetFundingAssets_fundingAssets_nodes as FundingAsset,
	FundingAssetFilterInput,
} from '@app/codegen';
import {
	CustomCard,
	CustomDataTable,
	FundingStatusBubble,
	ITrustColumn,
	useTableState,
} from '@app/shared/components';
import { displayNumber, localDateTimeFormat } from '@itrustcapital/ui';
import React from 'react';
import Toast from 'react-native-toast-message';

export const EFS_FAC_GET_FUNDING_ASSETS = gql`
	query EfsFacGetFundingAssets(
		$where: FundingAssetFilterInput
		$order: [FundingAssetSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		fundingAssets(
			where: $where
			first: $first
			last: $last
			after: $after
			before: $before
			order: $order
		) {
			nodes {
				id
				amount
				status
				currencyType
				requestedAmount
				originalBalance
				fairMarketValue
				fairMarketValueTimeStamp
			}
			totalCount
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
		}
	}
`;

export interface FundingAssetsProps {
	fundingId: number;
}

export function FundingAssets(props: FundingAssetsProps) {
	const tableState = useTableState<FundingAssetSortInput, FundingAssetFilterInput>({
		defaultWhere: {
			fundingId: { eq: props.fundingId },
		},
		defaultRowsPerPage: 10,
		striped: true,
		pagination: true,
	});
	const columns: ITrustColumn<FundingAsset>[] = [
		{
			key: 'currencyType',
			name: 'Type',
			maxWidth: 130,
			center: true,
			sortable: true,
			selector: (row) => row.currencyType,
		},
		{
			key: 'requestedAmount',
			name: 'Requested Amount',
			right: true,
			sortable: true,
			selector: (row) =>
				displayNumber(row.requestedAmount, { currency: row.currencyType.toString() }),
		},
		{
			key: 'amount',
			name: 'Actual Amount',
			right: true,
			sortable: true,
			selector: (row) => displayNumber(row.amount, { currency: row.currencyType.toString() }),
		},
		{
			key: 'originalBalance',
			name: 'Original Balance',
			right: true,
			sortable: true,
			selector: (row) =>
				displayNumber(row.originalBalance, { currency: row.currencyType.toString() }),
		},
		{
			key: 'status',
			name: 'Status',
			maxWidth: 300,
			sortable: true,
			center: true,
			selector: (row) => <FundingStatusBubble status={row?.status} />,
		},
		{
			key: 'fairMarketValue',
			name: 'Asset FMV',
			maxWidth: 300,
			sortable: true,
			center: true,
			selector: (row) => (row?.fairMarketValue ? displayNumber(row?.fairMarketValue) : ''),
		},
		{
			key: 'fairMarketValueTimeStamp',
			name: 'FMV Timestamp',
			maxWidth: 300,
			sortable: true,
			center: true,
			selector: (row) =>
				row?.fairMarketValueTimeStamp
					? localDateTimeFormat(row?.fairMarketValueTimeStamp)
					: '',
		},
	];

	const getFundingAssetsQuery = useQuery<EfsFacGetFundingAssets, EfsFacGetFundingAssetsVariables>(
		EFS_FAC_GET_FUNDING_ASSETS,
		{
			variables: {
				order: tableState.sort.get,
				first: tableState.rowsPerPage.get,
				where: tableState.where.get,
			},
			skip: !props.fundingId,
			fetchPolicy: 'no-cache',
			onCompleted: (data) => {
				if (data) {
					tableState.onCompleted(
						data.fundingAssets?.totalCount,
						data.fundingAssets?.pageInfo
					);
				}
			},
			onError: (error) => {
				Toast.show({
					text2: error.message,
					type: 'error',
				});
			},
		}
	);

	return (
		<CustomCard header="List of Assets">
			<CustomDataTable
				{...tableState.props}
				columns={columns}
				data={getFundingAssetsQuery.data?.fundingAssets?.nodes as FundingAsset[]}
				progressPending={getFundingAssetsQuery.loading}
				refetch={(variables) => {
					getFundingAssetsQuery.refetch?.({
						...variables,
						where: {
							fundingId: { eq: props.fundingId },
						},
					});
				}}
			/>
		</CustomCard>
	);
}
