import { StakingMovementFilterInput, StakingTermStatus } from '@app/codegen';
import { StakingMovementLocators } from '@app/e2e/screens/StakingMovements';
import { getTerms, isFormEmpty } from '@app/shared/helpers';
import {
	AppSelector,
	capitalize,
	datePickerMaxDate,
	datePickerMinDate,
	datePickerService,
	dateTimePlaceholder,
} from '@itrustcapital/ui';
import { Button, Datepicker, Text } from '@ui-kitten/components';
import { endOfDay, startOfDay } from 'date-fns';
import React from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';

import { StakingMovementFormData, useStakingMovementForm } from '../StakingTermsScreenForms';

function itemDisplay(item: StakingTermStatus) {
	switch (item) {
		case StakingTermStatus.NONE:
			return 'Pre Enrollment';
		case StakingTermStatus.PENDING:
			return 'Post Enrollment';
		default:
			return item
				.split('_')
				.map((value) => `${capitalize(value)}`)
				.join(' ');
	}
}

interface StakingMovementFiltersProps {
	stakingTermFilter: StakingTermStatus;
	onStakingTermStatusChange: (stakingTerm: StakingTermStatus) => void;

	onStakingMovementSubmit: (data: StakingMovementFilterInput) => void;
}

export function StakingMovementFilters(props: StakingMovementFiltersProps) {
	const dynamicStyles = useDynamicStyles();
	const form = useStakingMovementForm();

	const formWatch = form.watch();

	React.useEffect(() => {
		if (form.formState.isValid && !form.formState.isValidating) {
			onStakingMovementSubmit(formWatch);
		}
	}, [
		form.formState.isValidating,
		formWatch.createdAt,
		formWatch.term,
		formWatch.currencyType,
		form.formState.isValid,
	]);

	function onStatusSelect(value: StakingTermStatus) {
		form.reset();

		props.onStakingTermStatusChange(value);
	}

	function onStakingMovementSubmit(data: StakingMovementFormData) {
		const where: StakingMovementFilterInput = {};

		if (isFormEmpty(data)) {
			props.onStakingMovementSubmit(where);

			return;
		}

		if (data.currencyType) {
			where.currencyType = {
				eq: data.currencyType,
			};
		}

		if (data.createdAt) {
			where.createdAt = {
				gte: startOfDay(data.createdAt).toISOString(),
				lte: endOfDay(data.createdAt).toISOString(),
			};
		}

		if (data.term) {
			where.stakingTerm = {
				durationDays: {
					eq: data.term,
				},
			};
		}

		props.onStakingMovementSubmit(where);
	}

	return (
		<>
			<View style={styles.main.header}>
				<Text category="h5" style={styles.header.text}>
					Staking Term
				</Text>
			</View>

			<View style={dynamicStyles.advanced.row}>
				<AppSelector
					closeOnSelect
					data={[
						StakingTermStatus.NONE,
						StakingTermStatus.ENROLLMENT,
						StakingTermStatus.PENDING,
						StakingTermStatus.STAKED,
						StakingTermStatus.PENDING_REWARDS,
						StakingTermStatus.COMPLETE,
					]}
					itemDisplay={itemDisplay}
					label="Status"
					style={styles.main.selector}
					testID={StakingMovementLocators.statusField}
					value={props.stakingTermFilter}
					onSelect={onStatusSelect}
				/>
				<Controller
					control={form.control}
					name="createdAt"
					render={(control) => (
						<Datepicker
							date={control.field.value}
							dateService={datePickerService}
							label="Created At"
							max={datePickerMaxDate}
							min={datePickerMinDate}
							placeholder={dateTimePlaceholder}
							status={control.fieldState.error && 'danger'}
							style={[styles.main.control, styles.advanced.control]}
							testID={StakingMovementLocators.createdAtField}
							onBlur={control.field.onBlur}
							onSelect={control.field.onChange}
						/>
					)}
				/>
				<Controller
					control={form.control}
					name="currencyType"
					render={(control) => (
						<View style={[styles.main.control, styles.advanced.control]}>
							<AppSelector
								closeOnSelect
								data={['DOT']}
								label={'Currency Type'}
								testID={StakingMovementLocators.currencyTypeField}
								value={control.field.value}
								onSelect={control.field.onChange}
							/>
						</View>
					)}
				/>
				<Controller
					control={form.control}
					name="term"
					render={(control) => (
						<View style={[styles.main.control, styles.advanced.control]}>
							<AppSelector
								data={getTerms()}
								label="Term"
								testID={StakingMovementLocators.termField}
								value={control.field.value}
								onSelect={control.field.onChange}
							/>
						</View>
					)}
				/>
				<Button
					appearance="outline"
					style={styles.advanced.clear}
					testID={StakingMovementLocators.clearFiltersButton}
					onPress={() => {
						form.reset();
						form.handleSubmit(onStakingMovementSubmit)();
					}}
				>
					Clear Filters
				</Button>
			</View>
		</>
	);
}

const styles = {
	main: StyleSheet.create({
		control: {
			marginBottom: 10,
			marginRight: 8,
		},
		header: {
			flexDirection: 'row',
			alignItems: 'center',
			paddingBottom: 20,
		},
		selector: {
			paddingRight: 8,
		},
	}),
	header: StyleSheet.create({
		container: {
			marginBottom: 20,
			flexDirection: 'row',
			flexWrap: 'wrap',
		},
		text: {
			textTransform: 'capitalize',
			marginRight: 24,
		},
		row: {
			flexDirection: 'row',
			alignItems: 'center',
			flexWrap: 'wrap',
		},
	}),
	advanced: StyleSheet.create({
		container: {
			flexDirection: 'row',
			alignItems: 'flex-start',
			marginVertical: 16,
		},
		control: {
			flex: 1,
		},
		apply: {
			marginRight: 12,
		},
		action: {
			marginBottom: 8,
		},
		clear: {
			alignSelf: 'center',
		},
	}),
};

function useDynamicStyles() {
	return {
		advanced: StyleSheet.create({
			row: {
				flexDirection: 'row',
				alignItems: 'flex-start',
				justifyContent: 'center',
			},
		}),
	};
}
