import { gql, useMutation } from '@apollo/client';
import {
	AccountType,
	EcsAnacAddAccount,
	EcsAnacAddAccountVariables,
	AccountStatus,
} from '@app/codegen';
import { AddNewAccountComponentLocators } from '@app/e2e/screens/EditCustomer';
import { CustomCard } from '@app/shared/components';
import { accountTypeHumanizer } from '@app/shared/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppModal, AppSelector } from '@itrustcapital/ui';
import { Button, Input, Spinner } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';
import * as yup from 'yup';

export const ECS_ANAC_ADD_ACCOUNT = gql`
	mutation EcsAnacAddAccount($input: AddAccountInput!) {
		addAccount(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

export const schema = yup
	.object({
		name: yup.string().default(''),
		type: yup
			.mixed<AccountType>()
			.oneOf([AccountType.TRADITIONAL_IRA, AccountType.SEP_IRA])
			.required()
			.default(AccountType.NONE),
	})
	.defined();

export type AddNewAccountForm = yup.TypeOf<typeof schema>;

export interface AddNewAccountProps {
	toggleVisible: () => void;
	visible: boolean;
	userId: number;
	onSave: (success: boolean) => void;
}

export function AddNewAccount(props: AddNewAccountProps) {
	const styles = useCustomStyles();
	const addNewAccountForm = useForm({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const [onAddAccount, addAccountQuery] = useMutation<
		EcsAnacAddAccount,
		EcsAnacAddAccountVariables
	>(ECS_ANAC_ADD_ACCOUNT);

	async function onSubmit(data: AddNewAccountForm) {
		try {
			await onAddAccount({
				variables: {
					input: {
						userId: props.userId,
						type: data.type,
						displayName: data.name,
						status: AccountStatus.INTERNAL,
					},
				},
			});
			addNewAccountForm.reset();
			props.onSave(true);
		} catch (err) {
			props.onSave(false);
		}
	}

	return (
		<AppModal visible={props.visible}>
			<CustomCard
				hasCloseButton
				footer={() => (
					<View style={styles.footer.container}>
						<Button
							appearance="outline"
							style={styles.footer.cancel}
							testID={AddNewAccountComponentLocators.cancelButton}
							onPress={() => {
								addNewAccountForm.reset();
								props.toggleVisible();
							}}
						>
							Cancel
						</Button>
						<Button
							disabled={!addNewAccountForm.formState.isValid}
							testID={AddNewAccountComponentLocators.saveButton}
							onPress={
								addAccountQuery.loading
									? undefined
									: addNewAccountForm.handleSubmit(onSubmit)
							}
						>
							{addAccountQuery.loading ? (
								<View>
									<Spinner size="tiny" status="basic" />
								</View>
							) : (
								'Save'
							)}
						</Button>
					</View>
				)}
				handleClosePress={() => {
					addNewAccountForm.reset();
					props.toggleVisible();
				}}
				header="Add Account"
			>
				<View style={styles.form.row}>
					<Controller
						control={addNewAccountForm.control}
						name="name"
						render={(control) => (
							<Input
								label="Name"
								style={styles.form.input}
								testID={AddNewAccountComponentLocators.nameField}
								value={control.field.value}
								onBlur={control.field.onBlur}
								onChange={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={addNewAccountForm.control}
						name="type"
						render={(control) => (
							<View style={styles.form.input}>
								<AppSelector
									closeOnSelect
									data={[AccountType.TRADITIONAL_IRA, AccountType.SEP_IRA]}
									itemDisplay={accountTypeHumanizer}
									label="Type"
									testID={AddNewAccountComponentLocators.typeField}
									value={control.field.value}
									onSelect={(value) => {
										control.field.onChange(value);
										addNewAccountForm.trigger('type');
									}}
								/>
							</View>
						)}
					/>
				</View>
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	return {
		form: StyleSheet.create({
			row: {
				flexDirection: 'row',
				justifyContent: 'space-between',
			},
			input: {
				flex: 0.49,
			},
		}),
		header: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
				alignItems: 'center',
			},
		}),
		footer: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				alignItems: 'center',
			},
			cancel: {
				marginRight: 8,
			},
		}),
	};
}
