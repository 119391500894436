import { AppIcon, useAppState } from '@itrustcapital/ui';
import { Button } from '@ui-kitten/components';
import React from 'react';
import { StyleProp, StyleSheet, View, ViewProps, ViewStyle } from 'react-native';

export interface AccordionProps {
	children: React.ReactNode;
	label: string;
	showContent?: boolean;
	intialMaxHeight?: number;
	style?: StyleProp<ViewProps>;
	triggerContainerStyle?: StyleProp<ViewStyle>;
	toggleContent?: () => void;
}

export function Accordion(props: AccordionProps) {
	const styles = useCustomStyles();
	const showContent = useAppState<boolean | undefined>(false);

	React.useEffect(() => {
		showContent.set(props.showContent);
	}, [props.showContent]);

	return (
		<>
			<View style={props.triggerContainerStyle}>
				<Button
					accessoryLeft={() => (
						<AppIcon lib="fa5" name={showContent.get ? 'chevron-up' : 'chevron-down'} />
					)}
					appearance="ghost"
					size="small"
					onPress={() => {
						showContent.set(!showContent.get);
						props.toggleContent?.();
					}}
				>
					{(showContent.get ? 'Hide' : 'Show') + ' ' + props.label}
				</Button>
			</View>

			{showContent.get && <View style={[styles.content, props.style]}>{props.children}</View>}
		</>
	);
}

function useCustomStyles() {
	return StyleSheet.create({
		content: {
			overflow: 'hidden',
		},
		advancedText: {
			textDecorationLine: 'underline',
			marginLeft: 8,
		},
	});
}
