import { AccountStatus, FundingType } from '@app/codegen';
import {
	AppSelector,
	datePickerMaxDate,
	datePickerMinDate,
	datePickerService,
	dateTimePlaceholder,
	humanize,
	maskCurrency,
} from '@itrustcapital/ui';
import { Datepicker, Input } from '@ui-kitten/components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';

import { useReportingScreenContext } from '../ReportingScreenProvider';

export function ReportingFilterForm() {
	const styles = useCustomStyles();
	const state = useReportingScreenContext();
	const reportingFilterForm = state.reportingFilterForm;

	return (
		<>
			<View style={styles.main.row}>
				<Controller
					control={reportingFilterForm.control}
					name="minAmount"
					render={(control) => (
						<Input
							label="Min Amount"
							style={styles.main.control}
							value={control.field.value?.toString()}
							onChangeText={(value) => {
								control.field.onChange(maskCurrency(value));
							}}
						/>
					)}
				/>
				<Controller
					control={reportingFilterForm.control}
					name="maxAmount"
					render={(control) => (
						<Input
							label="Max Amount"
							status={
								reportingFilterForm.formState.errors.maxAmount ? 'danger' : 'basic'
							}
							style={styles.main.control}
							value={control.field.value?.toString()}
							onBlur={control.field.onBlur}
							onChangeText={(value) => control.field.onChange(maskCurrency(value))}
						/>
					)}
				/>
			</View>
			<View style={styles.main.row}>
				<Controller
					control={reportingFilterForm.control}
					name="createdFrom"
					render={(control) => (
						<Datepicker
							date={control.field.value}
							dateService={datePickerService}
							label="Created From"
							max={datePickerMaxDate}
							min={datePickerMinDate}
							placeholder={dateTimePlaceholder}
							status={control.fieldState.error && 'danger'}
							style={styles.main.control}
							onBlur={control.field.onBlur}
							onSelect={control.field.onChange}
						/>
					)}
				/>
				<Controller
					control={reportingFilterForm.control}
					name="createdTo"
					render={(control) => (
						<Datepicker
							date={control.field.value}
							dateService={datePickerService}
							label="Created To"
							max={datePickerMaxDate}
							min={datePickerMinDate}
							placeholder={dateTimePlaceholder}
							status={control.fieldState.error && 'danger'}
							style={styles.main.control}
							onBlur={control.field.onBlur}
							onSelect={control.field.onChange}
						/>
					)}
				/>
			</View>
			<View style={styles.main.row}>
				<Controller
					control={reportingFilterForm.control}
					name="fundingType"
					render={(control) => (
						<View style={styles.main.control}>
							<AppSelector
								closeOnSelect
								data={Object.values(FundingType)}
								itemDisplay={humanize}
								label="Funding Type"
								value={control.field.value}
								onSelect={control.field.onChange}
							/>
						</View>
					)}
				/>
				<Controller
					control={reportingFilterForm.control}
					name="accountStatus"
					render={(control) => (
						<View style={styles.main.control}>
							<AppSelector
								closeOnSelect
								data={Object.values(AccountStatus)}
								itemDisplay={humanize}
								label="Account Status"
								value={control.field.value}
								onSelect={control.field.onChange}
							/>
						</View>
					)}
				/>
			</View>
		</>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			container: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
			},
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginBottom: 8,
			},
			control: {
				flex: 0.49,
			},
		}),
	};
}
