import { CurrencyType } from '@app/codegen';
import { toCurrency } from '@itrustcapital/ui';

export function toCurrencyDisplay(value: number, currencyType: CurrencyType): string | number {
	if (!value) {
		return 0;
	}

	return currencyType === CurrencyType.USD ? toCurrency(value) : value.toLocaleString();
}
