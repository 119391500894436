import JoditEditor from 'jodit-react';
import React, { useRef } from 'react';

export interface RichTextEditorProps {
	value: string;
	readonly?: boolean;
	onChange: (value: string) => void;
	onBlur?: (value: string) => void;
}

export function RichTextEditor(props: RichTextEditorProps) {
	const editor = useRef(null);
	const config = {
		// all options from https://xdsoft.net/jodit/doc/
		height: 300,
		minHeight: 300,
		maxHeight: 300,
		useSearch: false,
		spellcheck: false,
		language: 'en',
		direction: 'ltr',
		toolbarButtonSize: 'small',
		enter: 'DIV',
		showCharsCounter: false,
		showWordsCounter: false,
		showXPathInStatusbar: false,
		askBeforePasteHTML: false,
		askBeforePasteFromWord: false,
		toolbarInlineForSelection: true,
		showPlaceholder: false,
		cleanHTML: true,
		buttons:
			'bold,italic,underline,strikethrough,eraser,ul,ol,indent,outdent,left,fontsize,link',
		readonly: props.readonly,
	};

	function onChange(value: string): void {
		props.onChange(value);
	}

	return (
		<JoditEditor
			config={config}
			ref={editor}
			value={props.value} // preferred to use only this option to update the content for performance reasons
			onBlur={props.onBlur}
			onChange={(newContent) => onChange(newContent)}
		/>
	);
}
