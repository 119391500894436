import { gql, useMutation, useQuery } from '@apollo/client';
import {
	AccountFilterInput,
	AccountSortInput,
	AccountStage,
	AccountStatus,
	EcsCacGetCustomerAccounts,
	EcsCacGetCustomerAccounts_accounts_nodes as Account,
	EcsCacGetCustomerAccountsVariables,
	EcsCacRequestTrustPilotReview,
	EcsCacRequestTrustPilotReviewVariables,
	EcsCacSoftDeleteAccount,
	EcsCacSoftDeleteAccountVariables,
	SortEnumType,
} from '@app/codegen';
import { CustomerAccountsComponentLocators } from '@app/e2e/screens/EditCustomer';
import { useAccountsNavigation } from '@app/navigations/Main/Accounts';
import { CustomCard, CustomDataTable, ITrustColumn, useTableState } from '@app/shared/components';
import { accountTypeHumanizer } from '@app/shared/helpers';
import {
	AppAlert,
	AppBackButton,
	AppIcon,
	humanize,
	localDateTimeFormat,
	ScreenSizeEnum,
	StackNavigation,
	useAppDevice,
	useAppState,
	useAppTheme,
} from '@itrustcapital/ui';
import { useNavigation } from '@react-navigation/native';
import { Button, Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';

import { AddNewAccount } from './AddNewAccount';
import { CS_TPC_GET_INVITATIONS } from '../TrustPilotCard';

export const schema = yup
	.object({
		search: yup.string().default(''),
		accountStage: yup
			.mixed<AccountStage>()
			.oneOf(Object.values(AccountStage))
			.default(undefined),
		status: yup.mixed<AccountStatus>().oneOf(Object.values(AccountStatus)).default(undefined),
		createdFrom: yup.date().max(new Date()).default(undefined),
		createdTo: yup
			.date()
			.when(
				'createdFrom',
				(eventStartDate, schema) => eventStartDate && schema.min(eventStartDate)
			)
			.default(undefined),
	})
	.defined();

export type FilterAccountsForm = yup.TypeOf<typeof schema>;

export const ECS_CAC_GET_CUSTOMER_ACCOUNTS = gql`
	query EcsCacGetCustomerAccounts(
		$where: AccountFilterInput
		$order: [AccountSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		accounts(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				id
				displayName
				accountNumber
				createdAt
				type
				status
				custodianAccount {
					custodianAccountId
				}
				user {
					kycStatus
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
			totalCount
		}
	}
`;

export const ECS_CAC_SOFT_DELETE_ACCOUNT = gql`
	mutation EcsCacSoftDeleteAccount($input: UpdateAccountInput!) {
		updateAccount(input: $input) {
			success
			errorMessage
		}
	}
`;

export const ECS_CAC_REQUEST_TRUST_PILOT_REVIEW = gql`
	mutation EcsCacRequestTrustPilotReview($input: SendTrustPilotInvitationInput!) {
		sendTrustPilotInvitation(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

export interface CustomerAccountsProps {
	userId: number;
	userName: string;
	userEmail: string;
}

interface ConfirmModalOptions {
	testIds?: [string, string];
	message?: string;
	visible: boolean;
}

export function CustomerAccounts(props: CustomerAccountsProps) {
	const styles = useCustomStyles();
	const navigation = useNavigation<StackNavigation>();
	const accountStackNavigation = useAccountsNavigation();
	const newAccountVisible = useAppState(false);
	const confirmModalOptions = useAppState<ConfirmModalOptions>({
		testIds: ['', ''],
		message: '',
		visible: false,
	});

	const generator = React.useRef<Generator>();

	function* handleAsyncAction(handler: () => Promise<void>) {
		yield; // wait for confirm action
		handler();
		confirmModalOptions.set({ visible: false });
	}

	const tableState = useTableState<AccountSortInput, AccountFilterInput>({
		defaultWhere: {
			userId: {
				eq: props.userId,
			},
		},
		defaultSort: { createdAt: SortEnumType.DESC },
		pagination: true,
		striped: true,
	});

	const customerAccountsQuery = useQuery<
		EcsCacGetCustomerAccounts,
		EcsCacGetCustomerAccountsVariables
	>(ECS_CAC_GET_CUSTOMER_ACCOUNTS, {
		variables: {
			where: tableState.where.get,
			order: tableState.sort.get,
			first: tableState.rowsPerPage.get,
		},
		skip: !props.userId,
		onCompleted: (data) => {
			if (!data) return;
			tableState.onCompleted(data.accounts?.totalCount, data.accounts?.pageInfo);
		},
	});
	const [onDeleteAccount, deleteAccountQuery] = useMutation<
		EcsCacSoftDeleteAccount,
		EcsCacSoftDeleteAccountVariables
	>(ECS_CAC_SOFT_DELETE_ACCOUNT);

	const [onRequestTrustPilotReview] = useMutation<
		EcsCacRequestTrustPilotReview,
		EcsCacRequestTrustPilotReviewVariables
	>(ECS_CAC_REQUEST_TRUST_PILOT_REVIEW, {
		refetchQueries: [{ query: CS_TPC_GET_INVITATIONS }],
	});

	async function handleTrustPilotInvitation(row: Account) {
		try {
			await onRequestTrustPilotReview({
				variables: {
					input: {
						accountId: row.id,
						accountType: row.type,
						userId: props.userId,
						userName: props.userName,
						userEmail: props.userEmail,
					},
				},
			});
			Toast.show({
				type: 'success',
				text2: `Review is on it's way!`,
			});
		} catch (err) {
			Toast.show({
				type: 'error',
				text2: 'Review failed to send',
			});
		}
	}

	const columns: ITrustColumn<Account>[] = [
		{
			key: 'accountNumber',
			name: 'Account #',
			sortable: true,
			selector: (row) => row.accountNumber,
		},
		{
			key: 'createdAt',
			name: 'Created Date',
			maxWidth: 200,
			sortable: true,
			selector: (row) => localDateTimeFormat(row.createdAt),
		},
		{
			key: 'type',
			name: 'Account Type',
			center: true,
			sortable: true,
			selector: (row) => accountTypeHumanizer(row.type),
		},
		{
			key: 'status',
			name: 'Status',
			maxWidth: 200,
			center: true,
			sortable: true,
			selector: (row) => humanize(row.status),
		},
		{
			key: 'kycStatus',
			name: 'KYC',
			minWidth: 130,
			center: true,
			sortable: true,
			selector: (row) => humanize(row.user?.kycStatus),
		},
	];

	React.useEffect(() => {
		navigation.setOptions({
			title: 'Accounts - ITrustCapital',
			headerTitle: 'Accounts',
			headerLeft: () => <AppBackButton supportDrawer />,
			headerRight: () => null,
		});
	}, []);

	async function onDeleteSubmit(row: Account) {
		try {
			await onDeleteAccount({
				variables: {
					input: {
						id: row.id,
						status: AccountStatus.CLOSED,
					},
				},
			});
			Toast.show({
				type: 'success',
				text2: 'Customer account has been closed',
			});
			customerAccountsQuery.refetch();
		} catch (err) {
			Toast.show({
				type: 'error',
				text2: 'Customer account has failed to delete',
			});
		}
	}

	return (
		<CustomCard
			header={() => (
				<View style={styles.header.container}>
					<Text category="h6">Accounts</Text>
					<Button
						accessoryLeft={
							<AppIcon color={styles.iconColor} lib="fe" name="plus" size={20} />
						}
						status="primary"
						testID={CustomerAccountsComponentLocators.newAccountButton}
						onPress={() => newAccountVisible.set(true)}
					/>
				</View>
			)}
		>
			<AddNewAccount
				toggleVisible={() => newAccountVisible.set(false)}
				userId={props.userId}
				visible={newAccountVisible.get}
				onSave={(success: boolean) => {
					if (success) {
						customerAccountsQuery.refetch();
						newAccountVisible.set(false);
					}
					Toast.show({
						type: success ? 'success' : 'error',
						text2: `Customer account ${
							success ? 'has been added' : 'has failed to add'
						}`,
					});
				}}
			/>

			<AppAlert
				actions={[
					{
						testID: confirmModalOptions.get?.testIds?.[0],
						title: 'Cancel',
						status: 'primary',
						appearance: 'outline',
						onPress: () => {
							confirmModalOptions.set({ visible: false });
						},
					},
					{
						testID: confirmModalOptions.get?.testIds?.[1],
						loading: deleteAccountQuery.loading,
						title: 'Confirm',
						status: 'danger',
						onPress: () => generator.current?.next(),
					},
				]}
				message={confirmModalOptions.get.message}
				title="Are you sure?"
				visible={confirmModalOptions.get.visible}
			/>

			{/* Data Table */}
			<CustomDataTable
				columns={columns}
				data={customerAccountsQuery.data?.accounts?.nodes as Account[]}
				progressPending={customerAccountsQuery.loading}
				refetch={(variables) => {
					customerAccountsQuery.refetch({
						...variables,
						where: tableState.where.get,
					});
				}}
				rowActions={[
					{
						testID: CustomerAccountsComponentLocators.editAccountButton,
						handler: (row) => {
							accountStackNavigation.Edit({
								accountId: row.id,
							});
						},
						iconLib: 'matc',
						iconName: 'pencil',
						name: 'edit',
					},
					{
						testID: CustomerAccountsComponentLocators.deleteAccountButton,
						handler: (row) => {
							generator.current = handleAsyncAction(() => onDeleteSubmit(row));
							generator.current.next();
							confirmModalOptions.set({
								visible: true,
								message: 'Do you really want to close this account?',
								testIds: [
									CustomerAccountsComponentLocators.sendReviewCancelButton,
									CustomerAccountsComponentLocators.sendReviewConfirmButton,
								],
							});
						},
						iconLib: 'ion',
						iconName: 'trash',
						name: 'trash',
					},
					{
						testID: CustomerAccountsComponentLocators.requestTrustPilotReviewButton,
						handler: (row) => {
							generator.current = handleAsyncAction(() =>
								handleTrustPilotInvitation(row)
							);
							generator.current.next();
							confirmModalOptions.set({
								visible: true,
								message: 'Request account review from client?',
								testIds: [
									CustomerAccountsComponentLocators.deleteAccountCancelButton,
									CustomerAccountsComponentLocators.deleteAccountSaveButton,
								],
							});
						},
						iconLib: 'fe',
						iconName: 'star',
						name: 'star',
					},
				]}
				{...tableState.props}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();
	const { width } = useAppDevice();

	const isScreenMd = width >= ScreenSizeEnum.md;

	return {
		iconColor: theme['text-alternate-color'],
		main: StyleSheet.create({
			buttons: {
				paddingVertical: 4,
			},
			row: {
				flexDirection: 'row',
			},
			inputHalves: {
				flex: 0.49,
			},
			searchRow: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'space-between' : 'center',
			},
		}),
		header: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				flexWrap: 'wrap',
				alignItems: 'center',
			},
			exportButton: {
				marginRight: 12,
			},
			exportIcon: {
				color: theme['text-info-color'],
			},
			text: {
				textTransform: 'capitalize',
			},
		}),
		advanced: StyleSheet.create({
			apply: {
				marginRight: 12,
			},
			control: {
				width: isScreenMd ? '35%' : 'auto',
				marginRight: isScreenMd ? 8 : 0,
				paddingVertical: 8,
			},
			inputRow: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'flex-start' : 'center',
			},
			triggerContainer: {
				flexDirection: 'row',
				justifyContent: 'flex-start',
				marginVertical: 8,
			},
		}),
	};
}
