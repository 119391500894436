import { TopHeader } from '@app/shared/components';
import { AppBackButton, AppScreen, StackNavigation } from '@itrustcapital/ui';
import * as ReactNavigation from '@react-navigation/native';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';

import { AccountsScreenProvider, useAccountsScreenState } from './AccountsScreenProvider';
import { AccountsFilterForm, AccountsTable } from './components';

export function AccountsScreen() {
	const styles = useCustomStyles();
	const navigation = ReactNavigation.useNavigation<StackNavigation>();
	const state = useAccountsScreenState();

	// when revisiting screen + already called, refetch instead of
	// resetting navigation stack to refresh site
	ReactNavigation.useFocusEffect(
		React.useCallback(() => {
			if (state.accountsQuery.called) {
				state.accountsQuery.refetch();
			}
		}, [])
	);

	React.useEffect(() => {
		navigation.setOptions({
			title: 'Accounts - ITrustCapital',
			headerTitle: 'Accounts',
			headerLeft: () => <AppBackButton supportDrawer />,
			headerRight: () => null,
		});
	}, []);

	return (
		<AppScreen noMarginBottom noMaxWidth noPadding>
			<AccountsScreenProvider value={state}>
				<TopHeader title="IRA Accounts" />

				<Card style={styles.main.card}>
					{/* Data Table */}
					<AccountsFilterForm />
					<AccountsTable />
				</Card>
			</AccountsScreenProvider>
		</AppScreen>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			card: {
				borderRadius: 12,
				padding: 12,
				margin: 32,
			},
		}),
	};
}
