import { useAppTheme } from '@itrustcapital/ui';
import { Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';

interface DataDisplayCardProps {
	text: string;
	data: string | number;
	testID: string;
}

export const DataDisplayCard = (props: DataDisplayCardProps) => {
	const dynamicStyles = useDynamicStyles();

	return (
		<View style={[styles.container, dynamicStyles.container]}>
			<View style={styles.textContainer}>
				<Text category="h6">{props.data}</Text>
				<Text appearance="hint" category="p2" testID={props.testID}>
					{props.text}
				</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		marginHorizontal: 24,
		marginTop: 32,
		paddingHorizontal: 24,
		paddingVertical: 18,
		borderRadius: 12,
	},
	textContainer: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
});

function useDynamicStyles() {
	const theme = useAppTheme();

	return StyleSheet.create({
		container: {
			backgroundColor: theme['color-primary-transparent-100'],
		},
	});
}
