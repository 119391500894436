import {
	AppAlert,
	AppIcon,
	humanize,
	useAppDevice,
	useAppState,
	useAppearance,
	useAppTheme,
} from '@itrustcapital/ui';
import { Text } from '@ui-kitten/components';
import React from 'react';
import { View, StyleSheet, TouchableOpacity, ViewStyle, StyleProp } from 'react-native';

import { CustomCard } from './CustomCard';

export interface BreadCrumbsProps<T> {
	onStageConfirm: (stage: T) => void;
	stages: BreadCrumbStage<T>[];
	stage: T;
	loading?: boolean;
	header?: string | (() => React.ReactNode);
	footer?: () => React.ReactNode;
	testID?: string;
	style?: StyleProp<ViewStyle>;
	disabled?: boolean;
	customHumanizer?: (enumValue: T) => string;
}

export interface BreadCrumbStage<T> {
	stage: T;
	title?: string;
	disabled?: boolean;
}

export interface BreadCrumb<T> {
	stage: T;
	title: string;
	completed?: boolean;
	disabled?: boolean;
}

const dotSize = 32;

export function BreadCrumbs<T extends string>(props: BreadCrumbsProps<T>) {
	const alertVisible = useAppState(false);
	const styles = useCustomStyles();
	const confirmStage = useAppState<BreadCrumb<T> | null>(null);

	const activeIndex = props.stages.findIndex((stage) => stage.stage === props.stage);
	const displayStages = useAppState<BreadCrumb<T>[]>([]);

	const allComplete = props.stage === props.stages[props.stages.length - 1].stage;

	React.useEffect(() => {
		displayStages.set(
			props.stages.map((stage, index) => ({
				stage: stage.stage,
				title: stage.title || humanize(stage.stage),
				completed: index <= activeIndex,
				disabled: stage.disabled,
			}))
		);
	}, [props.stage]);

	return (
		<CustomCard
			footer={props.footer}
			header={props.header || 'Documents Workflow'}
			loading={props.loading}
			style={[props.style, styles.card]}
		>
			<AppAlert
				actions={[
					{
						title: 'Cancel',
						status: 'basic',
						onPress: () => alertVisible.set(false),
						testID: props.testID && `${props.testID}UpdateStageCancelButton`,
					},
					{
						testID: props.testID && `${props.testID}UpdateStageSaveButton`,
						title: 'Confirm',
						status: 'primary',
						onPress: () => {
							if (confirmStage.get?.stage) {
								props.onStageConfirm(confirmStage.get?.stage);
								alertVisible.set(false);
							}
						},
					},
				]}
				message={`Move from stage\n\n${humanize(props.stage)}\nto\n${
					confirmStage.get?.title
				}`}
				title="Are you sure?"
				visible={alertVisible.get}
			/>
			<View style={styles.container}>
				<View style={styles.row}>
					<Text category="h6r" style={styles.marginRight}>
						Current Stage
					</Text>
					<Text category="h6r" status="info">
						{props.customHumanizer
							? props.customHumanizer(props.stage)
							: humanize(props.stage)}
					</Text>
				</View>
				<View style={styles.stageRow}>
					<Text category="h6r" style={styles.marginRight}>
						Stages
					</Text>
					<View style={styles.stageLineContainer}>
						<View style={styles.line} />
						{displayStages.get.map((stage, index) => (
							<View key={stage.stage} style={styles.stageContainer}>
								<TouchableOpacity
									disabled={
										allComplete ||
										index > activeIndex + 1 ||
										index === activeIndex ||
										stage.disabled ||
										props.disabled
									}
									style={[styles.dot, !stage.completed && styles.pendingDot]}
									testID={
										props.testID && `${props.testID}UpdateStageButton${index}`
									}
									onPress={() => {
										confirmStage.set(stage);
										alertVisible.set(true);
									}}
								>
									{stage.completed ? (
										<AppIcon color="white" lib="fe" name="check" size={20} />
									) : (
										<Text status="primary">{index + 1}</Text>
									)}
								</TouchableOpacity>
								<Text
									category="c2"
									numberOfLines={3}
									status="primary"
									style={styles.label}
								>
									{stage.title}
								</Text>
							</View>
						))}
					</View>
				</View>
			</View>
		</CustomCard>
	);
}

function useCustomStyles() {
	const width = useAppDevice().width;
	const theme = useAppTheme();
	const appearance = useAppearance();
	const primaryColor = theme['color-primary-500'];
	const textBreak = width < 625;

	return StyleSheet.create({
		container: {
			maxWidth: '100%',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
		},
		row: {
			width: '100%',
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
		stageRow: {
			width: '100%',
			flex: 1,
			flexGrow: 1,
			flexDirection: 'row',
			alignItems: textBreak ? 'flex-start' : 'center',
			justifyContent: 'flex-start',
			marginVertical: 24,
			paddingRight: 55,
		},
		stageLineContainer: {
			flex: 1,
			flexDirection: textBreak ? 'column' : 'row',
			alignItems: 'flex-start',
			justifyContent: textBreak ? 'flex-start' : 'space-between',
			flexGrow: 2,
			overflowY: 'scroll',
		},
		stageContainer: {
			height: '100%',

			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			zIndex: 100,
			backgroundColor: appearance.dark ? '#1E1E1E' : 'white',
			marginBottom: textBreak ? 20 : undefined,
		},
		dot: {
			height: dotSize,
			width: dotSize,
			borderRadius: dotSize / 2,
			backgroundColor: primaryColor,
			alignItems: 'center',
			justifyContent: 'center',
		},
		line: {
			width: textBreak ? 1 : '90%',
			height: textBreak ? '90%' : 1,
			backgroundColor: '#C5CEE0',
			position: 'absolute',
			left: textBreak ? 50 : undefined,
			top: textBreak ? undefined : dotSize / 2,
		},
		label: {
			marginTop: dotSize / 2,
			maxWidth: 100,
			minWidth: 100,
			height: '100%',
			textAlign: 'center',
		},
		marginRight: {
			marginRight: 33,
		},
		card: {
			width: '100%',
		},
		pendingDot: {
			backgroundColor: theme['color-primary-transparent-100'],
			borderColor: primaryColor,
			borderWidth: StyleSheet.hairlineWidth,
		},
	});
}
