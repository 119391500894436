export enum FundingDetailsLocators {
	cancelButton = 'cancelButton',
	saveButton = 'saveButton',
	editButton = 'editButton',
	fundingTypeField = 'fundingTypeField',
	fundingDirectionField = 'fundingDirectionField',
	rolloverTypeField = 'rolloverTypeField',
	fromAccountTypeField = 'fromAccountTypeField',
	fromInstitutionField = 'fromInstitutionField',
	fromAccountNumberField = 'fromAccountNumberField',
	toAccountTypeField = 'toAccountTypeField',
	toInstitutionField = 'toInstitutionField',
	toAccountNumberField = 'toAccountNumberField',
	contributionYearField = 'contributionYearField',
	transactionCodeField = 'transactionCodeField',
	distributionCodeField = 'distributionCodeField',
	stateWithholdingsField = 'stateWithholdingsField',
	federalWithholdingsField = 'federalWithholdingsField',
	rolloverFromType = 'rolloverFromType',
	fortressTransactionId = 'fortressTransactionId',
	fortressPaymentId = 'fortressPaymentId',
	distributedToClientField = 'distributedToClientField',
	federalWithholdingsTransactionId = 'federalWithholdingsTransactionId',
	stateWithholdingsTransactionId = 'stateWithholdingsTransactionId',
	distributedTotalAmountField = 'distributedTotalAmountField',
	microTestAmountField = 'microTestAmountField',
	microTestFortressTransactionId = 'microTestFortressTransactionId',
	microTestAmountConfirmed = 'microTestAmountConfirmed',
	completeButton = 'completeButton',
	currencyType = 'currencyType',
}
