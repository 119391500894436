import { useAppState } from '@itrustcapital/ui';
import React, { MutableRefObject } from 'react';

export function useHover<T extends React.Component>(): {
	hoverRef: MutableRefObject<T | null>;
	isHovered: boolean;
} {
	const hoveredState = useAppState(false);
	const hoverRef: MutableRefObject<T | null> = React.useRef(null);

	const handleMouseEnter = () => hoveredState.set(true);
	const handleMouseLeave = () => hoveredState.set(false);

	React.useEffect(() => {
		const node = hoverRef?.current;

		// I am struggling on how to type refs
		if (node) {
			node.addEventListener('mouseenter', handleMouseEnter);
			node.addEventListener('mouseleave', handleMouseLeave);

			return () => {
				node.removeEventListener('mouseenter', handleMouseEnter);
				node.removeEventListener('mouseleave', handleMouseLeave);
			};
		}
	}, [hoverRef]);

	return { hoverRef, isHovered: hoveredState.get };
}
