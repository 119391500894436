import { Dimensions, Platform, StatusBar } from 'react-native';

declare const window: any;
declare const navigator: any;
declare const process: any;

export function isElectron() {
	// Renderer process
	if (
		typeof window !== 'undefined' &&
		typeof window.process === 'object' &&
		window.process.type === 'renderer'
	) {
		return true;
	}

	// Main process
	if (
		typeof process !== 'undefined' &&
		typeof process.versions === 'object' &&
		!!process.versions.electron
	) {
		return true;
	}

	// Detect the user agent when the `nodeIntegration` option is set to true
	if (
		typeof navigator === 'object' &&
		typeof navigator.userAgent === 'string' &&
		navigator.userAgent.indexOf('Electron') >= 0
	) {
		return true;
	}

	return false;
}

const X_WIDTH = 375;
const X_HEIGHT = 812;
const XSMAX_WIDTH = 414;
const XSMAX_HEIGHT = 896;
const { height, width } = Dimensions.get('window');
export const isIPhoneX = () =>
	Platform.OS === 'ios' && !Platform.isPad && !Platform.isTVOS
		? (width === X_WIDTH && height === X_HEIGHT) ||
		  (width === XSMAX_WIDTH && height === XSMAX_HEIGHT)
		: false;
export const StatusBarHeight = Platform.select({
	ios: isIPhoneX() ? 44 : 20,
	android: StatusBar.currentHeight,
	default: 0,
});
