import { CustomCard } from '@app/shared/components';
import { AppModal, getBestDate, localDateFormat } from '@itrustcapital/ui';
import { Button, Spinner, Text } from '@ui-kitten/components';
import React from 'react';
import { View, StyleSheet, TouchableOpacity, Linking } from 'react-native';

import { useReportingScreenContext } from '../ReportingScreenProvider';

export interface GenerateReportProps {}

export function GenerateReport(props: GenerateReportProps) {
	const styles = useCustomStyles();
	const state = useReportingScreenContext();
	const formValues = state.reportingFilterForm.getValues();

	const createdFrom = localDateFormat(
		getBestDate(formValues.createdFrom, formValues.createdTo, true)
	);
	const createdTo = localDateFormat(getBestDate(formValues.createdFrom, formValues.createdTo));
	const dateRange = `${createdFrom} - ${createdTo}`;

	function onCloseModal(): void {
		state.reportingUrl.set('');
		state.showGenerateReport.set(false);
	}

	return (
		<AppModal visible={state.showGenerateReport.get}>
			<CustomCard
				hasCloseButton
				footer={() => (
					<View style={styles.footer.container}>
						<Button
							appearance="outline"
							disabled={state.loadingGenerateFundingReport}
							style={styles.footer.cancel}
							onPress={onCloseModal}
						>
							Cancel
						</Button>
						<Button
							disabled={state.loadingGenerateFundingReport}
							style={styles.footer.generate}
							onPress={state.onGenerateFundingReport}
						>
							{state.loadingGenerateFundingReport ? (
								<View>
									<Spinner size="tiny" status="basic" />
								</View>
							) : (
								'Generate'
							)}
						</Button>
					</View>
				)}
				handleClosePress={onCloseModal}
				header="Generate Report"
				loading={state.loadingGenerateFundingReport}
				style={styles.main.container}
			>
				<Text category="h6">Type: Daily Report</Text>
				<Text>{dateRange}</Text>
				{state.reportingUrl.get ? (
					<TouchableOpacity
						onPress={async () => await Linking.openURL(state.reportingUrl.get)}
					>
						<Text status="info" style={styles.main.reportText}>
							Download Report
						</Text>
					</TouchableOpacity>
				) : null}
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			container: {
				minWidth: 400,
			},
			reportText: {
				maxWidth: 500,
			},
		}),
		footer: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
			},
			cancel: {
				marginRight: 8,
			},
			generate: {
				minWidth: 80,
			},
		}),
	};
}
