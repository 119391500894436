import React, { useState, useEffect, ReactNode } from 'react';
import {
	View,
	ActivityIndicator,
	Image,
	LayoutChangeEvent,
	ImageBackground,
	ViewStyle,
	StyleSheet,
	StyleProp,
} from 'react-native';

export interface ScaledImageProps {
	style?: StyleProp<ViewStyle>;
	uri: string;
	children?: ReactNode;
}

export function ScaledImage(props: ScaledImageProps) {
	const [dimensions, setDimensions] = useState({
		width: 0,
		height: 0,
	});

	const [imageDimensions, setImageDimensions] = useState({
		width: 0,
		height: 0,
	});

	const [showImage, setShowImage] = useState(false);

	const [imageLoading, setImageLoading] = useState(true);

	useEffect(() => {
		if (dimensions.width > 0) {
			setShowImage(true);
		}

		if (imageLoading) {
			Image.getSize(
				props.uri,
				(width1, height1) => {
					setImageDimensions({
						width: width1,
						height: height1,
					});

					setImageLoading(false);
				},
				(error) => {
					console.log('ScaledImage,Image.getSize failed with error: ', error);
				}
			);
		}
	}, [dimensions]);

	function Loading() {
		return (
			<View style={styles.loadingContainer}>
				<ActivityIndicator size="large" />
			</View>
		);
	}

	return imageLoading === false ? (
		<View
			style={[styles.imageContainer, { height: dimensions.height }, props.style]}
			onLayout={(event: LayoutChangeEvent) => {
				const { width } = event.nativeEvent.layout;

				if (imageDimensions.width) {
					setDimensions({
						width,
						height: imageDimensions.height / (imageDimensions.width / width),
					});
				}
			}}
		>
			{showImage ? (
				<ImageBackground
					resizeMode="cover"
					source={{ uri: props.uri }}
					style={[styles.image, { height: dimensions.height }, props.style]}
				>
					{props.children}
				</ImageBackground>
			) : (
				<Loading />
			)}
		</View>
	) : (
		<Loading />
	);
}

const styles = StyleSheet.create({
	loadingContainer: {
		width: '100%',
		height: 200,
		justifyContent: 'center',
		alignItems: 'center',
		overflow: 'hidden',
	},
	imageContainer: {
		width: '100%',
	},
	image: {
		width: '100%',
	},
});
