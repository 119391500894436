import {
	CrmUserStatus,
	UpdateCrmUserInput,
	UsersScreen_GetCrmUsers_crmUsers_nodes as CrmUser,
} from '@app/codegen';
import { UsersScreenLocators } from '@app/e2e/screens/Users/UsersScreenLocators';
import { useUsersScreenContext } from '@app/screens';
import {
	CustomDataTable,
	DangerAssetSVG,
	ITrustAction,
	ITrustColumn,
} from '@app/shared/components';
import { FormActionType } from '@app/shared/models';
import { AppAlert, humanize, localDateFormat, useAppState } from '@itrustcapital/ui';
import React from 'react';

export function UsersTable() {
	const state = useUsersScreenContext();
	const deleteVisible = useAppState(false);

	const columns: ITrustColumn<CrmUser>[] = [
		{
			key: 'firstName',
			name: 'First Name',
			sortable: true,
			selector: (row) => row.firstName,
		},
		{
			key: 'lastName',
			name: 'Last Name',
			sortable: true,
			selector: (row) => row.lastName,
		},
		{
			key: 'email',
			name: 'Email Address',
			minWidth: 300,
			sortable: true,
			selector: (row) => row.email,
		},
		{
			key: 'role',
			name: 'Role',
			sortable: true,
			center: true,
			maxWidth: 200,
			selector: (row) => humanize(row.role),
		},
		{
			key: 'status',
			name: 'Status',
			sortable: true,
			center: true,
			maxWidth: 100,
			selector: (row) => humanize(row.status),
		},
		{
			key: 'createdAt',
			name: 'Created Date',
			sortable: true,
			maxWidth: 150,
			selector: (row) => localDateFormat(row.createdAt),
		},
	];
	const rowActions: ITrustAction<CrmUser>[] = [
		{
			name: 'Edit',
			handler: (row) => onEdit(row),
			iconLib: 'matc',
			iconName: 'pencil',
			testID: UsersScreenLocators.editUserButton,
		},
		{
			name: 'Lock',
			handler: (row) => onStatusUpdate(row, CrmUserStatus.DISABLED),
			iconLib: 'ant',
			iconName: 'lock',
			isHidden: (row) => row.status === CrmUserStatus.DISABLED,
			testID: UsersScreenLocators.lockUserButton,
		},
		{
			name: 'Unlock',
			handler: (row) => onStatusUpdate(row, CrmUserStatus.ACTIVE),
			iconLib: 'ant',
			iconName: 'unlock',
			isHidden: (row) =>
				!(row.status === CrmUserStatus.DISABLED || row.status === CrmUserStatus.DELETED),
			testID: UsersScreenLocators.unlockUserButton,
		},
		{
			name: 'Delete',
			handler: (row) => onDelete(row),
			iconLib: 'ion',
			iconName: 'trash',
			testID: UsersScreenLocators.deleteUserButton,
		},
	];

	function onEdit(row: CrmUser): void {
		state.usersSelectedRow.set(row);
		state.userFormModalAction.set(FormActionType.UPDATE);
		state.userFormModalVisible.set(true);
	}

	function onDelete(row: CrmUser): void {
		state.usersSelectedRow.set(row);
		deleteVisible.set(!deleteVisible.get);
	}

	function onReFetch(variables: any): void {
		state.getMaestroUsersQuery.refetch({
			...variables,
			where: state.tableState.where.get,
		});
	}

	function onDeleteAlertCancel(): void {
		deleteVisible.set(false);
		state.usersSelectedRow.set(undefined);
	}

	function onDeleteAlertDelete(): void {
		deleteVisible.set(false);

		onStatusUpdate(state.usersSelectedRow.get!, CrmUserStatus.DELETED);
	}

	async function onStatusUpdate(row: CrmUser, status: CrmUserStatus): Promise<void> {
		const input: UpdateCrmUserInput = {
			id: row.id!,
			status,
		};

		state.usersSelectedRow.set(row);
		state.onUpdateMaestroUser({ input });
	}

	return (
		<>
			<AppAlert
				actions={[
					{
						title: 'Cancel',
						onPress: onDeleteAlertCancel,
						testID: UsersScreenLocators.cancelDeleteUserButton,
					},
					{
						title: 'Delete',
						status: 'danger',
						disabled: state.updateMaestroUserLoading,
						onPress: onDeleteAlertDelete,
						testID: UsersScreenLocators.confirmDeleteUserButton,
					},
				]}
				message={`User ${state.usersSelectedRow.get?.email} will be deleted`}
				title="Are you sure?"
				visible={deleteVisible.get}
				xmlIcon={DangerAssetSVG}
			/>

			<CustomDataTable
				columns={columns}
				data={state.getMaestroUsersData as CrmUser[]}
				progressPending={state.getMaestroUsersLoading}
				refetch={onReFetch}
				rowActions={rowActions}
				{...state.tableState.props}
			/>
		</>
	);
}
