import SignInBG from '@app/assets/img/signInBG.png';
import { SignInLocators } from '@app/e2e/screens/SignIn';
import { useAuthNavigation } from '@app/navigations';
import { useAccountsNavigation } from '@app/navigations/Main/Accounts';
import { MaestroAssetSVG, PresenterAssetSVG, WarningAssetSVG } from '@app/shared/components';
import {
	AppAlert,
	AppScreen,
	AppSvg,
	ScreenSizeEnum,
	StackNavigation,
	useAppDevice,
	useAppState,
	useAppAuth,
	ErrorMessage,
} from '@itrustcapital/ui';
import { useNavigation } from '@react-navigation/native';
import { Button, Spinner, Text } from '@ui-kitten/components';
import { default as appsettings } from 'appsettings.json';
import React from 'react';
import { StyleSheet, View, ImageBackground } from 'react-native';

export function SignInScreen() {
	const styles = useCustomStyles();
	const appDevice = useAppDevice();
	const navigation = useNavigation<StackNavigation>();
	const accountsNavigation = useAccountsNavigation();
	const authNavigation = useAuthNavigation();
	const appAuth = useAppAuth(appsettings);
	const loading = useAppState(false);
	const emailVerificationAlert = useAppState(false);
	const passwordChangeAlert = useAppState(false);

	React.useEffect(() => {
		navigation.setOptions({
			title: 'Sign In - Accounts',
			headerShown: false,
		});
	}, []);

	// Auto Login
	React.useEffect(() => {
		async function authInit() {
			try {
				if (appAuth.authState.isAuthenticated) {
					appAuth.setSignOutAction(authNavigation.SignOut);
					accountsNavigation.Home(true);
				} else {
					await appAuth.trySignIn();
				}
			} catch (error) {
				if (error instanceof Error) {
					if (error.message === ErrorMessage.EmailNotVerified) {
						emailVerificationAlert.set(true);
					} else if (error.message === ErrorMessage.PasswordResetRequired) {
						passwordChangeAlert.set(true);
					}
				}
			}
		}
		authInit();
	}, [appAuth.authState.sub]);

	async function onSignIn(): Promise<void> {
		try {
			await appAuth.signIn();
		} catch (err) {
			console.error(err);
		}
	}

	return (
		<AppScreen noMarginBottom noMaxWidth noPadding noScroll style={styles.main.container}>
			<AppAlert
				actions={[
					{
						title: 'OK',
						status: 'warning',
						onPress: () => {
							emailVerificationAlert.set(false);

							if (appDevice.isWeb) {
								authNavigation.SignIn();
							}
						},
					},
				]}
				message="Please verify your email to login."
				title="Email not verified"
				visible={emailVerificationAlert.get}
				xmlIcon={WarningAssetSVG}
			/>
			<AppAlert
				actions={[
					{
						title: 'Proceed',
						status: 'warning',
						onPress: onSignIn,
					},
				]}
				message="We've made some improvements to our platform! Please click 'Forgot password?' on the next screen if this is your first time logging in to our updated site."
				status="warning"
				title="Password Update Required"
				visible={passwordChangeAlert.get}
				xmlIcon={WarningAssetSVG}
				onDismiss={() => passwordChangeAlert.set(false)}
			/>
			<AppSvg style={styles.main.presenter} xml={PresenterAssetSVG} />
			<AppSvg style={styles.main.logo} xml={MaestroAssetSVG} />
			<ImageBackground
				imageStyle={styles.main.imageBG}
				source={SignInBG}
				style={styles.main.imageContainer}
			>
				<View style={styles.main.formContainer}>
					<Text category="h1">iTrustCapital Maestro System</Text>
					<Button
						accessoryLeft={() => (loading.get ? <Spinner size="tiny" /> : <></>)}
						appearance="filled"
						disabled={loading.get}
						status="primary"
						style={styles.main.login}
						testID={SignInLocators.loginButton}
						onPress={onSignIn}
					>
						Login
					</Button>
				</View>
			</ImageBackground>
		</AppScreen>
	);
}

function useCustomStyles() {
	const appDevice = useAppDevice();
	const isScreenSm = appDevice.width <= ScreenSizeEnum.sm;
	const isScreenMd = appDevice.width <= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			container: {
				flex: 1,
				minHeight: '100vh',
			},
			imageContainer: {
				height: '100%',
				width: '100%',
			},
			imageBG: {
				resizeMode: 'cover',
			},
			login: {
				width: 343,
				marginTop: 60,
			},
			logo: {
				minWidth: 200,
				minHeight: 33,
				zIndex: 10,
				position: 'absolute',
				top: 32,
				left: 55,
			},
			presenter: {
				width: (isScreenSm && 250) || (isScreenMd && 375) || 500,
				height: (isScreenSm && 135) || (isScreenMd && 205) || 270,
				zIndex: 10,
				position: 'absolute',
				bottom: 10,
				left: 10,
			},
			formContainer: {
				flex: 1,
				paddingTop: 200,
				justifyContent: 'flex-start',
				alignItems: 'center',
			},
		}),
	};
}
