export enum EditCustomerBeneficiaryLocators {
	editCustomerBeneficiaryPercentageField = 'editCustomerBeneficiaryPercentageField',
	editCustomerBeneficiaryTypeField = 'editCustomerBeneficiaryTypeField',
	editCustomerBeneficiaryFirstNameField = 'editCustomerBeneficiaryFirstNameField',
	editCustomerBeneficiaryLastNameField = 'editCustomerBeneficiaryLastNameField',
	editCustomerBeneficiaryEmailField = 'editCustomerBeneficiaryEmailField',
	editCustomerBeneficiaryStreetField = 'editCustomerBeneficiaryStreetField',
	editCustomerBeneficiaryCityField = 'editCustomerBeneficiaryCityField',
	editCustomerBeneficiaryStateField = 'editCustomerBeneficiaryStateField',
	editCustomerBeneficiaryZipcodeField = 'editCustomerBeneficiaryZipcodeField',
	editCustomerBeneficiaryDateOfBirthField = 'editCustomerBeneficiaryDateOfBirthField',
	editCustomerBeneficiaryPrimaryPhoneField = 'editCustomerBeneficiaryPrimaryPhoneField',
	editCustomerBeneficiarySocialSecurityField = 'editCustomerBeneficiarySocialSecurityField',
	editCustomerBeneficiarySpouseField = 'editCustomerBeneficiarySpouseField',
	editCustomerBeneficiary = 'editCustomerBeneficiary',
	editCustomerBeneficiaryCancelButton = 'editCustomerBeneficiaryCancelButton',
	editCustomerBeneficiarySaveButton = 'editCustomerBeneficiarySaveButton',
}
