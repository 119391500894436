export const StakingTermsRoutes = {
	StakingTerms: {
		path: 'staking-terms',
		initialRouteName: '',
		screens: {
			Home: {
				path: '',
			},
		},
	},
};

export type StakingMovementsParams = keyof typeof StakingTermsRoutes.StakingTerms.screens;

export type StakingMovementsParamsRecord = Record<StakingMovementsParams, undefined>;
