import { useAuthNavigation } from '@app/navigations';
import { useAppAuth } from '@itrustcapital/ui';
import { useNavigation } from '@react-navigation/native';
import { Spinner, Text } from '@ui-kitten/components';
import { default as appsettings } from 'appsettings.json';
import React from 'react';
import { View, StyleSheet } from 'react-native';

export function SignOutScreen() {
	const navigation = useNavigation();
	const authNavigation = useAuthNavigation();
	const appAuth = useAppAuth(appsettings);

	React.useEffect(() => {
		appAuth.setSignOutAction(authNavigation.SignOut);

		setTimeout(async () => {
			await appAuth.signOut();
		}, 2000);
	}, []);

	React.useLayoutEffect(
		() =>
			navigation.setOptions({
				title: 'Sign Out - iTrustCapital',
				headerTitle: 'Sign Out',
				headerLeft: () => null,
			}),
		[]
	);

	React.useEffect(() => {
		if (appAuth.authState.isAuthenticated === false) {
			authNavigation.SignIn();
		}
	}, [appAuth.authState.isAuthenticated]);

	return (
		<View style={styles.container}>
			<Spinner />
			<Text style={styles.text}>This will make sure you do not have security risks.</Text>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},
	legend: {
		marginTop: 20,
	},
	text: {
		marginTop: 20,
	},
});
