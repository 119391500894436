export enum FundingsScreenLocators {
	exportToExcelButton = 'exportToExcelButton',
	createFundingButton = 'createFundingButton',
	deleteFundingModalCancelButton = 'deleteFundingModalCancelButton',
	deleteFundingModalConfirmButton = 'deleteFundingModalConfirmButton',
	fundingsSearchField = 'fundingsSearchField',
	fundingsSearchIconButton = 'fundingsSearchIconButton',
	fundingsMinAmountField = 'fundingsMinAmountField',
	fundingsMaxAmountField = 'fundingsMaxAmountField',
	fundingsFundingStatusField = 'fundingsFundingStatusField',
	fundingsCreatedFromField = 'fundingsCreatedFromField',
	fundingsCreatedToField = 'fundingsCreatedToField',
	fundingsTypeField = 'fundingsTypeField',
	fundingsAccountStatusField = 'fundingsAccountStatusField',
	fundingsApplyFilterButton = 'fundingsApplyFilterButton',
	fundingsClearFilterButton = 'fundingsClearFilterButton',
	fundingsDeleteFundingToast = 'fundingsDeleteFundingToast',
	editFundingButton = 'editFundingButton',
	deleteFundingButton = 'deleteFundingButton',
	fundingsStageField = 'fundingsStageField',
}
