export const FundingsOutflowRoutes = {
	FundingsOutflow: {
		path: 'fundings-outflow',
		initialRouteName: '',
		screens: {
			Home: {
				path: '',
			},
			Edit: {
				path: 'edit',
			},
		},
	},
};

export type FundingsParams = keyof typeof FundingsOutflowRoutes.FundingsOutflow.screens;

export type FundingsOutflowParamsRecord = Record<FundingsParams, undefined>;
