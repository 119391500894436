import {
	Ionicons,
	MaterialIcons,
	FontAwesome5,
	FontAwesome,
	Feather,
	MaterialCommunityIcons,
	SimpleLineIcons,
	Fontisto,
	EvilIcons,
	Entypo,
	AntDesign,
	Foundation,
} from '@expo/vector-icons';
import React, { Props } from 'react';
import { StyleProp, TextProps, TextStyle } from 'react-native';

import { ThemeColorVariables, useAppearance, useAppTheme } from '../../themes';

export type AppIconType =
	| 'ion'
	| 'mat'
	| 'fa5'
	| 'fa'
	| 'fe'
	| 'matc'
	| 'sl'
	| 'ft'
	| 'ev'
	| 'en'
	| 'ant'
	| 'fd';

export interface AppIconProps extends Props<any> {
	name: string | any;
	lib: AppIconType;
	style?: StyleProp<TextStyle>;
	size?: number;
	color?: string;
	themeColor?: ThemeColorVariables;
}

export function AppIcon({
	name,
	style,
	lib,
	size,
	color,
	themeColor,
}: AppIconProps): React.ReactElement<TextProps> {
	const appearance = useAppearance();
	const theme = useAppTheme();
	let iconColor: string | undefined = color;

	if (!iconColor) {
		iconColor = appearance.dark ? 'white' : 'black';
	}

	if (themeColor) {
		iconColor = undefined;
	}

	switch (lib) {
		case 'ion':
			return (
				<Ionicons
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'mat':
			return (
				<MaterialIcons
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'matc':
			return (
				<MaterialCommunityIcons
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'fa5':
			return (
				<FontAwesome5
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'fa':
			return (
				<FontAwesome
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'fe':
			return (
				<Feather
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'sl':
			return (
				<SimpleLineIcons
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'ft':
			return (
				<Fontisto
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'ev':
			return (
				<EvilIcons
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'en':
			return (
				<Entypo
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'ant':
			return (
				<AntDesign
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		case 'fd':
			return (
				<Foundation
					name={name}
					style={style}
					size={size}
					color={iconColor ? iconColor : theme[themeColor!]}
				/>
			);
		default:
			throw new Error(`No icon library found for ${name} and lib ${lib}`);
	}
}
