export enum AddCustomerBeneficiaryLocators {
	addCustomerBeneficiaryFirstNameField = 'addCustomerBeneficiaryFirstNameField',
	addCustomerBeneficiaryLastNameField = 'addCustomerBeneficiaryLastNameField',
	addCustomerBeneficiaryEmailField = 'addCustomerBeneficiaryEmailField',
	addCustomerBeneficiaryStreetField = 'addCustomerBeneficiaryStreetField',
	addCustomerBeneficiaryCityField = 'addCustomerBeneficiaryCityField',
	addCustomerBeneficiaryStateField = 'addCustomerBeneficiaryStateField',
	addCustomerBeneficiaryZipcodeField = 'addCustomerBeneficiaryZipcodeField',
	addCustomerBeneficiaryDateOfBirthField = 'addCustomerBeneficiaryDateOfBirthField',
	addCustomerBeneficiaryPrimaryPhoneField = 'addCustomerBeneficiaryPrimaryPhoneField',
	addCustomerBeneficiarySocialSecurityField = 'addCustomerBeneficiarySocialSecurityField',
	addCustomerBeneficiarySpouseField = 'addCustomerBeneficiarySpouseField',
	addCustomerBeneficiary = 'addCustomerBeneficiary',
	addCustomerBeneficiaryCancelButton = 'addCustomerBeneficiaryCancelButton',
	addCustomerBeneficiarySaveButton = 'addCustomerBeneficiarySaveButton',
}
