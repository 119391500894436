import {
	GetFundingsInput,
	FundingSortInput,
	FundingFilterInput,
	SortEnumType,
	FundingDirection,
	SystemRole,
	StringOperationFilterInput,
	RolloverType,
	AccountStatus,
	FundingStage,
	FundingType,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { useTableState } from '@app/shared/components';
import { isFormEmpty } from '@app/shared/helpers';
import { strToNumber, useAppState } from '@itrustcapital/ui';
import { endOfDay } from 'date-fns';
import React from 'react';

import {
	CustomAccountStatus,
	FilterFundingsForm,
	useFundingsScreenFilterForm,
} from './FundingsScreenForms';
import { useFundingsScreen_GetFundingsQuery } from './FundingsScreenGraphql';

export type FundingsScreenState = ReturnType<typeof useFundingsScreenState>;

export type FundingsScreenProviderProps = {
	value: FundingsScreenState | undefined;
	children: React.ReactNode;
};

const FundingsScreenContext = React.createContext<FundingsScreenState | undefined>(undefined);

export function FundingsScreenProvider(props: FundingsScreenProviderProps | null) {
	return (
		<FundingsScreenContext.Provider value={props?.value}>
			{props?.children}
		</FundingsScreenContext.Provider>
	);
}

export function useFundingsScreenContext() {
	const context = React.useContext(FundingsScreenContext);

	if (context === undefined) {
		throw new Error('useCounterContext must be used within a FundingsScreenProvider');
	}

	return context;
}

export type FundingsScreenStateProps = {
	isFundingsInflow: boolean;
};

export function useFundingsScreenState(props: FundingsScreenStateProps) {
	const pauseQuery = useAppState(false);
	const maestroUserAtom = useMaestroUserAtom();
	const inputState = useAppState<GetFundingsInput | null>(null);
	const tableState = useTableState<FundingSortInput, FundingFilterInput>({
		defaultSort: { createdAt: SortEnumType.DESC },
		pagination: true,
		striped: true,
		defaultWhere: {
			direction: {
				eq: FundingDirection.INBOUND,
			},
		},
		defaultRowsPerPage: 10,
	});
	const filterForm = useFundingsScreenFilterForm();

	const fundingsQuery = useFundingsScreen_GetFundingsQuery({
		variables: {
			where: tableState.where.get,
			order: tableState.sort.get,
			first: tableState.rowsPerPage.get,
			input: inputState.get,
		},
		onCompleted: (data) => {
			tableState.onCompleted(data.fundings?.totalCount, data.fundings?.pageInfo);
		},
		skip: pauseQuery.get,
	});

	const systemRole = maestroUserAtom.user?.role! as SystemRole;
	const isCancelDisabled =
		systemRole === SystemRole.CUSTOMER ||
		systemRole === SystemRole.FUNCTION ||
		systemRole === SystemRole.GENERAL ||
		systemRole === SystemRole.PROCESSING ||
		systemRole === SystemRole.CUSTODIAN;

	function onSubmit(data: FilterFundingsForm): void {
		pauseQuery.set(true);
		const accountDirection = props.isFundingsInflow ? 'toAccount' : 'fromAccount';
		const where: FundingFilterInput = {
			direction: {
				eq: props.isFundingsInflow ? FundingDirection.INBOUND : FundingDirection.OUTBOUND,
			},
		};
		let input: GetFundingsInput | null = null;

		tableState.reset();

		if (isFormEmpty(data)) {
			tableState.where.set(where);
			inputState.set(null);
			pauseQuery.set(false);

			return;
		}

		if (data.search) {
			const searchTrimmed = data.search.trim();
			const searchTerms = searchTrimmed.split(' ');

			const containsSearch: StringOperationFilterInput = {
				contains: searchTrimmed,
			};

			where.or = [
				{
					[accountDirection]: {
						user: {
							firstName: containsSearch,
						},
					},
				},
				{
					[accountDirection]: {
						user: {
							lastName: containsSearch,
						},
					},
				},
				{
					[accountDirection]: {
						user: {
							email: containsSearch,
						},
					},
				},
				{
					[accountDirection]: {
						accountNumber: containsSearch,
					},
				},
			];

			if (searchTerms.length > 1) {
				where.or = undefined;

				where[accountDirection] = {
					user: {
						firstName: { eq: searchTerms[0] },
						lastName: { eq: searchTerms.slice(1).join(' ') },
					},
				};
			}
		}

		if (data.type) {
			where.type = {
				eq: data.type,
			};
		}

		if (data.assetType) {
			where.fundingAssets = {
				some: {
					currencyType: { eq: data.assetType },
				},
			};
		}

		if (data.stage) {
			where.stage = {
				eq: data.stage,
			};
		}

		if (data.accountStatus) {
			if (data.accountStatus === CustomAccountStatus.INTERNAL_FUNDED) {
				where[accountDirection] = {
					...where[accountDirection],
					status: {
						eq: AccountStatus.INTERNAL,
					},
					inboundFundings: {
						some: {
							type: {
								in: [FundingType.TRANSFER, FundingType.ROLLOVER],
							},
							stage: {
								eq: FundingStage.ASSET_TRANSFER_COMPLETED,
							},
						},
					},
					wallets: {
						some: {
							balance: { gt: 0 },
						},
					},
				};
			} else {
				where[accountDirection] = {
					...where[accountDirection],
					status: {
						eq: data.accountStatus,
					},
				};
			}
		}

		if (data.accountStage) {
			where[accountDirection] = {
				...where[accountDirection],
				stage: {
					eq: data.accountStage,
				},
			};
		}

		if (data.assetMovementStatus) {
			where.fundingAssets = {};
			where.fundingAssets.some = {
				assetMovement: {
					status: {
						eq: data.assetMovementStatus,
					},
				},
			};
		}

		if (data.createdTo) {
			where.createdAt = {
				lte: endOfDay(data.createdTo).toISOString(),
			};
		}

		if (data.createdFrom) {
			where.createdAt = {
				...where.createdAt,
				gte: data.createdFrom.toISOString(),
			};
		}

		if (data.fundedTo) {
			where.fundedAt = {
				lte: endOfDay(data.fundedTo).toISOString(),
			};
		}

		if (data.fundedFrom) {
			where.fundedAt = {
				...where.fundedAt,
				gte: data.fundedFrom.toISOString(),
			};
		}

		if (data.rolloverType) {
			if (
				data.rolloverType === RolloverType.CARES_ACT_ROLLOVER ||
				data.rolloverType === RolloverType._60_DAY_ROLLOVER
			) {
				where.rolloverType = { eq: data.rolloverType };
			}

			if (data.rolloverType === RolloverType.NONE) {
				where.rolloverType = { eq: null };
			}

			if (data.rolloverType.toString() === 'All') {
				where.rolloverType = {
					neq: null,
				};
			}
		}

		tableState.where.set(where);

		if (data.minAmount || data.maxAmount) {
			input = {};

			if (data.minAmount) {
				input.minAmount = strToNumber(data?.minAmount);
			}

			if (data.maxAmount) {
				input.maxAmount = strToNumber(data?.maxAmount);
			}
		}

		inputState.set(input);
		pauseQuery.set(false);
	}

	return {
		tableState,
		onSubmit,
		isCancelDisabled,
		fundingsQuery,
		filterForm,
		isFundingsInflow: props.isFundingsInflow,
	};
}
