import { StakingTermsScreen } from '@app/screens';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { StakingMovementsParamsRecord } from '.';

const Stack = createStackNavigator<StakingMovementsParamsRecord>();

export function StakingTermsNavigation() {
	return (
		<Stack.Navigator>
			<Stack.Screen component={StakingTermsScreen} name="Home" />
		</Stack.Navigator>
	);
}
