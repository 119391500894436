import { AccountStage, AccountStatus, DocumentStage } from '@app/codegen';
import { AccountScreenLocators } from '@app/e2e/screens/Accounts';
import { Accordion } from '@app/shared/components';
import { accountStageTypeHumanizer } from '@app/shared/helpers';
import {
	AppIcon,
	AppSelector,
	humanize,
	ScreenSizeEnum,
	useAppDevice,
	datePickerMinDate,
	datePickerMaxDate,
	datePickerService,
	dateTimePlaceholder,
	useAppTheme,
} from '@itrustcapital/ui';
import { Button, Input, Datepicker } from '@ui-kitten/components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { AccountExtraStage } from '../AccountsScreenForms';
import { useAccountsScreenContext } from '../AccountsScreenProvider';

export function AccountsFilterForm() {
	const styles = useCustomStyles();
	const context = useAccountsScreenContext();

	const handleSubmit = context.filterAccountsForm.handleSubmit(context.onSubmit);

	function onResetFilters(): void {
		context.filterAccountsForm.reset();
		handleSubmit();
	}

	function onStageSelect(value: any): void {
		if (value === AccountExtraStage.ADDITIONAL_DOCUMENTS) {
			context.filterAccountsForm.setValue('documentStage', DocumentStage.SENT_TO_CLIENT);
		}

		if (value !== AccountExtraStage.ADDITIONAL_DOCUMENTS) {
			context.filterAccountsForm.setValue('documentStage', undefined);
		}
		handleSubmit();
	}

	function formatPhone(valueToFormat: string) {
		const formattedValue = valueToFormat.replace(/[^0-9]/g, '');

		const newestCharacter = formattedValue.slice(-1);

		if (!/^\d+$/.test(newestCharacter)) {
			return valueToFormat.substring(0, valueToFormat.length - 1);
		}

		if (formattedValue.length > 10 || formattedValue.length <= 3) {
			return formattedValue;
		}

		if (formattedValue.length > 3 && formattedValue.length <= 6) {
			return (
				formattedValue.slice(0, 3) + '-' + formattedValue.slice(3, formattedValue.length)
			);
		}

		return (
			formattedValue.slice(0, 3) +
			'-' +
			formattedValue.slice(3, 6) +
			'-' +
			formattedValue.slice(6, formattedValue.length)
		);
	}

	return (
		<>
			<View style={styles.main.searchRow}>
				<Controller
					control={context.filterAccountsForm.control}
					name="search"
					render={(control) => (
						<Input
							accessoryRight={() => (
								<TouchableOpacity
									testID={AccountScreenLocators.accountsSearchIconButton}
									onPress={handleSubmit}
								>
									<AppIcon lib="fa5" name="search" size={18} />
								</TouchableOpacity>
							)}
							placeholder="Search for accounts by ID, name, email, phone, account number, or Fortress account ID"
							style={styles.main.inputHalves}
							testID={AccountScreenLocators.accountsSearchField}
							value={control.field.value}
							onBlur={control.field.onBlur}
							onChangeText={control.field.onChange}
							onSubmitEditing={handleSubmit}
						/>
					)}
				/>
				<Controller
					control={context.filterAccountsForm.control}
					name="accountStage"
					render={(control) => (
						<View style={styles.main.inputHalves}>
							<AppSelector
								closeOnSelect
								data={[
									AccountStage.ONBOARDING_PERSONAL_INFORMATION,
									AccountStage.ONBOARDING_CONTACT_INFORMATION,
									AccountStage.ONBOARDING_GOVERNMENT_ID,
									AccountStage.ONBOARDING_ACCOUNT_TYPE,
									AccountStage.ONBOARDING_FUNDING_TYPE,
									AccountStage.ONBOARDING_FUNDING,
									AccountStage.ONBOARDING_BENEFICIARIES,
									AccountStage.DOCUMENTS_PENDING,
									AccountStage.DOCUMENTS_SENT_TO_CLIENT,
									AccountStage.DOCUMENTS_SIGNED,
									AccountStage.VERIFIED,
									// We are not adding this type to the account stage type
									AccountExtraStage.ADDITIONAL_DOCUMENTS,
								]}
								itemDisplay={accountStageTypeHumanizer}
								placeholder="Stage"
								testID={AccountScreenLocators.accountsStageField}
								value={control.field.value}
								onSelect={(value) => {
									control.field.onChange(value || undefined);
									onStageSelect(value);
								}}
							/>
						</View>
					)}
				/>
			</View>
			<Accordion
				label="advanced filters"
				triggerContainerStyle={styles.advanced.triggerContainer}
			>
				{context.stageType.get !== AccountExtraStage.ADDITIONAL_DOCUMENTS ? (
					<>
						<View style={styles.advanced.inputRow}>
							<Controller
								control={context.filterAccountsForm.control}
								name="status"
								render={(control) => (
									<View style={styles.advanced.control}>
										<AppSelector
											closeOnSelect
											data={Object.values(AccountStatus).filter(
												(x) => x !== AccountStatus.NONE
											)}
											itemDisplay={humanize}
											label="Status"
											testID={AccountScreenLocators.accountsStatusField}
											value={control.field.value}
											onSelect={control.field.onChange}
										/>
									</View>
								)}
							/>
							<Controller
								control={context.filterAccountsForm.control}
								defaultValue={''}
								name="mobilePhone"
								render={(control) => (
									<Input
										label="Mobile Phone Number"
										maxLength={12}
										style={styles.advanced.control}
										testID={
											AccountScreenLocators.accountsMobilePhoneNumberField
										}
										value={control.field.value!}
										placeholder="123-456-7890"
										onBlur={control.field.onBlur}
										onChangeText={(value) => {
											control.field.onChange(formatPhone(value));
										}}
									/>
								)}
							/>
							<Controller
								control={context.filterAccountsForm.control}
								name="createdFrom"
								render={(control) => (
									<Datepicker
										date={control.field.value}
										dateService={datePickerService}
										label="Created From"
										max={datePickerMaxDate}
										min={datePickerMinDate}
										placeholder={dateTimePlaceholder}
										status={control.fieldState.error && 'danger'}
										style={styles.advanced.control}
										testID={AccountScreenLocators.accountsCreatedFromField}
										onBlur={control.field.onBlur}
										onSelect={control.field.onChange}
									/>
								)}
							/>
							<Controller
								control={context.filterAccountsForm.control}
								name="createdTo"
								render={(control) => (
									<Datepicker
										date={control.field.value}
										dateService={datePickerService}
										label="Created To"
										max={datePickerMaxDate}
										min={datePickerMinDate}
										placeholder={dateTimePlaceholder}
										status={control.fieldState.error && 'danger'}
										style={styles.advanced.control}
										testID={AccountScreenLocators.accountsCreatedToField}
										onBlur={control.field.onBlur}
										onSelect={control.field.onChange}
									/>
								)}
							/>
						</View>
					</>
				) : (
					<>
						<View style={styles.advanced.inputRow}>
							<Controller
								control={context.filterAccountsForm.control}
								name="documentStage"
								render={(control) => (
									<View style={styles.advanced.control}>
										<AppSelector
											closeOnSelect
											data={[
												DocumentStage.SENT_TO_CLIENT,
												DocumentStage.SIGNED,
											]}
											itemDisplay={humanize}
											label="Document Stage"
											testID={AccountScreenLocators.documentStageField}
											value={control.field.value}
											onSelect={control.field.onChange}
										/>
									</View>
								)}
							/>
						</View>
					</>
				)}
				<View style={[styles.main.row, styles.main.buttons]}>
					<Button
						style={styles.advanced.apply}
						testID={AccountScreenLocators.accountsApplyButton}
						onPress={handleSubmit}
					>
						Apply Filters
					</Button>
					<Button
						appearance="outline"
						testID={AccountScreenLocators.accountsClearButton}
						onPress={onResetFilters}
					>
						Clear Filters
					</Button>
				</View>
			</Accordion>
		</>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();
	const { width } = useAppDevice();

	const isScreenMd = width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			container: {
				flex: 1,
				justifyContent: 'center',
				alignItems: 'center',
				textTransform: 'capitalize',
			},
			buttons: {
				paddingVertical: 4,
			},
			row: {
				flexDirection: 'row',
			},
			inputHalves: {
				flex: 0.49,
			},
			searchRow: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'space-between' : 'center',
			},
		}),
		header: StyleSheet.create({
			container: {
				padding: 8,
				justifyContent: 'space-between',
				flexDirection: 'row',
				flexWrap: 'wrap',
				alignItems: 'center',
			},
			exportIcon: {
				color: theme['text-info-color'],
			},
			text: {
				textTransform: 'capitalize',
			},
		}),
		advanced: StyleSheet.create({
			apply: {
				marginRight: 12,
			},
			control: {
				width: isScreenMd ? '35%' : 'auto',
				maxWidth: 330,
				marginRight: isScreenMd ? 8 : 0,
				paddingVertical: 8,
			},
			inputRow: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'flex-start' : 'center',
			},
			triggerContainer: {
				flexDirection: 'row',
				justifyContent: 'flex-start',
				marginVertical: 8,
			},
		}),
	};
}
