import React from 'react';
import { Platform, StyleProp, ViewStyle, View } from 'react-native';
import { Color, SvgXml } from 'react-native-svg';

import { isElectron } from '../../helpers';

export interface AppSvgProps {
	xml?: string;
	width?: number;
	height?: number;
	fill?: Color;
	style?: StyleProp<ViewStyle>;
}

export function AppSvg(props: AppSvgProps) {
	let xml = props.xml;

	if (props.fill) {
		xml = props.xml?.replace(/fill="[\w-]+"/g, `fill="${props.fill.toString()}"`)!;
	}

	if (isElectron()) {
		return (
			<View style={props.style}>
				<img alt="icon" height={props.height} src={xml} width={props.width} />
			</View>
		);
	} else if (Platform.OS === 'web') {
		// eslint-disable-next-line no-undef
		const blob = new Blob([xml!], {
			type: 'image/svg+xml',
		} as any);
		// eslint-disable-next-line no-undef
		const url = URL.createObjectURL(blob);

		return (
			<View style={props.style}>
				<img alt="icon" height={props.height} src={url} width={props.width} />
			</View>
		);
	} else {
		return (
			<View style={props.style}>
				<SvgXml fill={props.fill} height={props.height} width={props.width} xml={xml!} />
			</View>
		);
	}
}
