import { Platform } from 'react-native';

export const strToNumber = (value: string): number => {
	return parseFloat(value?.replace(/[^-\d.]/g, '') || '0');
};

function makeDynamicPrecisionNumber(value: number, useDollar: boolean) {
	const begin = value.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
	let trimToFixedTwo = /^-?\d+(?:\.\d{0,2})?/;
	let decimalPlaces = 0;

	if (value < 1) {
		trimToFixedTwo = /^-?\d+(?:\.\d{0,6})?/;
		decimalPlaces = 6;
	} else if (value < 10) {
		trimToFixedTwo = /^-?\d+(?:\.\d{0,4})?/;
		decimalPlaces = 4;
	} else if (value < 100) {
		trimToFixedTwo = /^-?\d+(?:\.\d{0,3})?/;
		decimalPlaces = 3;
	} else {
		trimToFixedTwo = /^-?\d+(?:\.\d{0,2})?/;
		decimalPlaces = 2;
	}

	const endMatch = value.toString().match(trimToFixedTwo)?.[0] || '.';
	const [start, decimal = ''] = endMatch.split('.');

	if (decimal.length < 3) {
		const returnValue =
			(useDollar ? begin : start.replace(/\B(?=(\d{3})+(?!\d))/g, ',')).split('.')[0] +
			`.${decimal}00`.substr(0, 3);

		return returnValue;
	}

	const returnValue =
		(useDollar ? begin : start.replace(/\B(?=(\d{3})+(?!\d))/g, ',')).split('.')[0] +
		`.${decimal}`.substr(0, decimalPlaces + 1);

	return returnValue;
}

export const toCurrency = (
	value: number = 0,
	useDollar: boolean = true,
	minimumFractionDigits: number = 2,
	maximumFractionDigits: number = 2,
	useDynamicDecimal?: boolean
): string => {
	if (useDynamicDecimal) {
		return makeDynamicPrecisionNumber(value, useDollar);
	}
	let begin = value?.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits,
		maximumFractionDigits,
	});

	if (!useDollar) {
		begin = begin.replace('$', '');
	}

	const trimToFixedTwo = /^-?\d+(?:\.\d{0,2})?/;
	const endMatch = value?.toString().match(trimToFixedTwo)?.[0];

	if (!endMatch || maximumFractionDigits > 2) {
		return begin;
	}

	const [, decimal = ''] = endMatch.split('.');
	const returnValue = begin.split('.')[0] + `.${decimal}00`.substr(0, 3);

	return returnValue;
};

// breaking asset trade rounding
export const toCurrencyRound = (
	value: number = 0,
	useDollar: boolean = true,
	minimumFractionDigits: number = 2,
	maximumFractionDigits: number = 2
): string => {
	let returnString = value.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
		minimumFractionDigits,
		maximumFractionDigits,
	});

	const [num, decimals] = returnString.split('.');
	const firstTwo = decimals?.slice(0, 2) || '';
	let afterTwo = decimals?.slice(2) || '';

	if (afterTwo?.length) {
		afterTwo = afterTwo.replace(/0+$/, '');
	}

	returnString = `${num}.${firstTwo}${afterTwo}`;

	if (Platform.OS === 'android') {
		returnString = '$' + returnString;
	}

	if (useDollar) {
		return returnString;
	}

	return returnString.replace('$', '');
};

export const toPercentage = (decimal: number, digits: number = 2): string => {
	if (decimal < 0) {
		return `${decimal?.toFixed(digits) || `0${digits ? '.' : ''}${'0'.repeat(digits)}`}%`;
	}

	return `+${decimal?.toFixed(digits) || `0${digits ? '.' : ''}${'0'.repeat(digits)}`}%`;
};

export const toPercentageWhole = (decimal: number): string => {
	const percentage = parseFloat((decimal * 100).toFixed(2));

	return `${percentage}%`;
};
