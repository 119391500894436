import { gql, useLazyQuery } from '@apollo/client';
import {
	AuditLogsCardOrganism_GetUserHistories,
	AuditLogsCardOrganism_GetUserHistories_userHistories_data as UserAuditData,
	AuditLogsCardOrganism_GetUserHistoriesVariables,
} from '@app/codegen';
import { CustomCard, CustomDataTable, ITrustColumn } from '@app/shared/components';
import { localDateTimeFormat, dateFormat } from '@itrustcapital/ui';
import { Button, Input, Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';

interface UserAuditHistoryCardOrganismProps {
	userId?: number;
}

const columns: ITrustColumn<UserAuditData>[] = [
	{
		key: 'user',
		name: 'Modified By Role',
		maxWidth: 130,
		selector: (row) => row?.user?.modifiedByRole ?? '',
	},
	{
		key: 'user',
		name: 'Modified By User ID',
		maxWidth: 130,
		selector: (row) => row?.user?.modifiedByUserId ?? '',
	},
	{
		key: 'validFrom',
		name: 'Valid From',
		minWidth: 200,
		sortable: false,
		selector: (row) => localDateTimeFormat(row.validFrom),
	},
	{
		key: 'validTo',
		name: 'Valid To',
		minWidth: 200,
		sortable: false,
		selector: (row) =>
			row?.validTo === '9999-12-31T23:59:59.999Z'
				? 'Current'
				: localDateTimeFormat(row.validTo),
	},
	{
		key: 'user',
		name: 'Email',
		maxWidth: 150,
		sortable: true,
		selector: (row) => row?.user?.email ?? '',
	},
	{
		key: 'user',
		name: 'Name',
		minWidth: 200,
		sortable: false,
		selector: (row) => {
			if (row?.user?.firstName) {
				return `${row?.user?.firstName} ${row?.user?.lastName ?? ''}`;
			}

			return '';
		},
	},
	{
		key: 'user',
		name: 'Status',
		maxWidth: 150,
		sortable: true,
		selector: (row) => row?.user?.status ?? '',
	},
	{
		key: 'user',
		name: 'Phone',
		minWidth: 200,
		sortable: false,
		selector: (row) => row?.user?.mobilePhone ?? '',
	},
	{
		key: 'user',
		name: 'DoB',
		minWidth: 200,
		sortable: false,
		selector: (row) => (row?.user?.dateOfBirth ? dateFormat(row?.user?.dateOfBirth) : ''),
	},
];

export const AUDIT_LOGS_SCREEN_GET_USER_HISTORIES = gql`
	query AuditLogsCardOrganism_GetUserHistories($input: GetUserHistoriesInput) {
		userHistories(input: $input) {
			success
			errorMessage
			data {
				user {
					modifiedByRole
					modifiedByUserId
					email
					firstName
					lastName
					mobilePhone
					dateOfBirth
					status
				}
				validFrom
				validTo
			}
		}
	}
`;

export const UserAuditHistoryCardOrganism = (props: UserAuditHistoryCardOrganismProps) => {
	const [userIdSearchText, setUserIdSearchText] = React.useState('');

	const [getUserAudit, getUserAuditQuery] = useLazyQuery<
		AuditLogsCardOrganism_GetUserHistories,
		AuditLogsCardOrganism_GetUserHistoriesVariables
	>(AUDIT_LOGS_SCREEN_GET_USER_HISTORIES);

	React.useEffect(() => {
		if (props?.userId) {
			getUserAudit({
				variables: {
					input: {
						userId: props.userId,
					},
				},
			});
		}
	}, [props?.userId]);

	return (
		<CustomCard style={styles.main.container}>
			<Text category="h3">User Audit History</Text>

			{!props?.userId && (
				<View style={styles.main.searchContainer}>
					<Input
						value={userIdSearchText}
						placeholder="Enter User ID"
						onChangeText={(text) => {
							setUserIdSearchText(text);
						}}
					/>

					<Button
						style={styles.main.searchBtn}
						size="small"
						status="primary"
						onPress={() => {
							if (+userIdSearchText) {
								getUserAudit({
									variables: {
										input: {
											userId: +userIdSearchText,
										},
									},
								});
							} else {
								setUserIdSearchText('');
							}
						}}
					>
						Search
					</Button>
				</View>
			)}

			<CustomDataTable
				inModal
				columns={columns}
				data={getUserAuditQuery?.data?.userHistories?.data as UserAuditData[]}
				progressPending={getUserAuditQuery.loading}
				refetch={(variables) => {
					getUserAuditQuery?.refetch?.({
						...variables,
					});
				}}
			/>
		</CustomCard>
	);
};

const styles = {
	main: StyleSheet.create({
		container: {
			minWidth: 600,
			marginTop: 16,
		},
		searchContainer: {
			flexDirection: 'row',
			marginVertical: 8,
		},
		searchBtn: {
			marginLeft: 8,
		},
	}),
};
