import { useAppState } from '@itrustcapital/ui';
import { Button, Text } from '@ui-kitten/components';
import { startOfToday, startOfYear, subDays, startOfWeek, startOfMonth } from 'date-fns';
import React, { ReactNode } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

export interface FilterByDateProps {
	style?: StyleProp<ViewStyle>;
	children?: ReactNode;
	filters: (keyof typeof filterMap)[];
	fromDate: { get: Date | null; set: (value: Date) => void };
	testID: string;
}

const today = startOfToday();
const allTime = startOfYear(new Date(2017));

const filterMap = {
	7: { displayText: '7 days', dateFrom: subDays(today, 7) },
	14: { displayText: '14 days', dateFrom: subDays(today, 14) },
	30: { displayText: '30 days', dateFrom: subDays(today, 30) },
	all: { displayText: 'All', dateFrom: allTime },
	allTime: { displayText: 'All', dateFrom: allTime },
	lifeTime: { displayText: 'Lifetime', dateFrom: allTime },
	year: { displayText: 'Year', dateFrom: startOfYear(today) },
	month: { displayText: 'Month', dateFrom: startOfMonth(today) },
	week: { displayText: 'Week', dateFrom: startOfWeek(today, { weekStartsOn: 1 }) },
	day: { displayText: 'Day', dateFrom: today },
};

export function FilterByDate(props: FilterByDateProps) {
	const filterSelection = useAppState(props.filters.length - 1);
	const filters = props.filters.map((filter) => ({
		...filterMap[filter],
		testID: `${props.testID}${filter}`,
	}));

	React.useEffect(() => {
		props.fromDate.set(filters[filters.length - 1].dateFrom);
	}, []);

	return (
		<View style={styles.filterContainer}>
			{filters.map((filter, index) => {
				const isSelected = index === filterSelection.get;

				return (
					<Button
						appearance={isSelected ? 'filled' : 'ghost'}
						key={filter.displayText}
						status={isSelected ? 'primary' : 'basic'}
						testID={filter.testID}
						onPress={() => {
							filterSelection.set(index);
							props.fromDate.set(filter.dateFrom);
						}}
					>
						<Text appearance="hint">{filter.displayText}</Text>
					</Button>
				);
			})}
		</View>
	);
}

const styles = StyleSheet.create({
	filterContainer: {
		width: '100%',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-evenly',
	},
});
