import { gql, useMutation } from '@apollo/client';
import {
	AccountStage,
	CustodianType,
	EditFundingScreen_CancelFunding,
	EditFundingScreen_CancelFundingVariables,
	EditFundingScreen_DeclineFunding,
	EditFundingScreen_DeclineFundingVariables,
	EditFundingScreen_GetFunding_funding_fromAccount,
	EditFundingScreen_GetFunding_funding_toAccount,
	EditFundingScreen_UpdateFundingStage,
	EditFundingScreen_UpdateFundingStageVariables,
	FundingStage,
	SystemRole,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { BreadCrumbs, InputError } from '@app/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppAlert, useAppState } from '@itrustcapital/ui';
import { Button, Input } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import * as yup from 'yup';

import { EDIT_FUNDING_SCREEN_GET_FUNDING } from './EditFundingScreen';

export const schema = yup
	.object({
		transactionId: yup.string().default('').required(),
		totalAmount: yup.string().default('').required(),
	})
	.defined();

export type ApproveFundingForm = yup.TypeOf<typeof schema>;

export interface FundingWorkflowProps {
	stage: FundingStage;
	loading?: boolean;
	fundingId: number;
	disabled?: boolean;
	account?:
		| EditFundingScreen_GetFunding_funding_toAccount
		| EditFundingScreen_GetFunding_funding_fromAccount
		| null;
	onApprovalRequest: (transactionIdInput: string, totalAmountInput: string) => void;
}

export const EDIT_FUNDING_SCREEN_UPDATE_FUNDING_STAGE = gql`
	mutation EditFundingScreen_UpdateFundingStage($input: UpdateFundingInput!) {
		updateFunding(input: $input) {
			success
			errorMessage
		}
	}
`;

export const EDIT_FUNDING_SCREEN_CANCEL_FUNDING = gql`
	mutation EditFundingScreen_CancelFunding($input: CancelFundingInput!) {
		cancelFunding(input: $input) {
			success
			errorMessage
		}
	}
`;

export const EDIT_FUNDING_SCREEN_DECLINE_FUNDING = gql`
	mutation EditFundingScreen_DeclineFunding($input: DeclineFundingInput!) {
		declineFunding(input: $input) {
			success
			errorMessage
		}
	}
`;

export function FundingWorkflow(props: FundingWorkflowProps) {
	const styles = useCustomStyles();
	const currentStage = useAppState(props.stage);
	const fundingStage = useAppState<FundingStage | null>(null);
	const maestroUserAtom = useMaestroUserAtom();

	const approveFundingForm = useForm<ApproveFundingForm>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const confirmedVisible = useAppState(false);
	const approveFundingVisible = useAppState(false);

	const systemRole = maestroUserAtom.user?.role;
	const isCancelDisabled =
		systemRole === SystemRole.CUSTOMER ||
		systemRole === SystemRole.FUNCTION ||
		systemRole === SystemRole.GENERAL ||
		systemRole === SystemRole.PROCESSING ||
		systemRole === SystemRole.CUSTODIAN;
	const isAccountVerified = props.account?.stage === AccountStage.VERIFIED;

	const [onUpdateFundingStage, updateFundingStageMutation] = useMutation<
		EditFundingScreen_UpdateFundingStage,
		EditFundingScreen_UpdateFundingStageVariables
	>(EDIT_FUNDING_SCREEN_UPDATE_FUNDING_STAGE, {
		refetchQueries: [EDIT_FUNDING_SCREEN_GET_FUNDING],
	});

	const [onCancelFunding, cancelFundingMutation] = useMutation<
		EditFundingScreen_CancelFunding,
		EditFundingScreen_CancelFundingVariables
	>(EDIT_FUNDING_SCREEN_CANCEL_FUNDING, {
		refetchQueries: [EDIT_FUNDING_SCREEN_GET_FUNDING],
	});

	const [onDeclineFunding, declineFundingMutation] = useMutation<
		EditFundingScreen_DeclineFunding,
		EditFundingScreen_DeclineFundingVariables
	>(EDIT_FUNDING_SCREEN_DECLINE_FUNDING, {
		refetchQueries: [EDIT_FUNDING_SCREEN_GET_FUNDING],
	});

	React.useEffect(() => {
		currentStage.set(props.stage);
	}, [props.stage]);

	async function onUpdateStage(stage: FundingStage): Promise<void> {
		let successedCaption = '';
		let failedCaption = '';

		switch (stage) {
			case FundingStage.CANCELED:
				successedCaption = 'Funding has been canceled';
				failedCaption = 'Funding has failed to cancel';
				break;
			case FundingStage.DECLINED:
				successedCaption = 'Funding has been declined';
				failedCaption = 'Funding has failed to decline';
				break;
			default:
				successedCaption = 'Funding stage has been updated';
				failedCaption = 'Funding stage failed to update';
				break;
		}

		try {
			if (stage === FundingStage.CANCELED) {
				await onCancelFunding({
					variables: {
						input: {
							fundingId: props.fundingId,
						},
					},
				});
			} else if (stage === FundingStage.DECLINED) {
				await onDeclineFunding({
					variables: {
						input: {
							fundingId: props.fundingId,
						},
					},
				});
			} else {
				await onUpdateFundingStage({
					variables: {
						input: {
							id: props.fundingId,
							stage,
						},
					},
				});
			}

			if (stage !== FundingStage.CANCELED && stage !== FundingStage.DECLINED) {
				currentStage.set(stage);
			}

			Toast.show({
				type: 'success',
				text2: successedCaption,
			});

			confirmedVisible.set(false);
		} catch (error) {
			Toast.show({
				type: 'error',
				text2: failedCaption,
			});

			confirmedVisible.set(false);
		}
	}

	function onFundingStageSet(stageType: FundingStage): void {
		fundingStage.set(stageType);
		confirmedVisible.set(true);
	}

	function onApprovePressed() {
		approveFundingVisible.set(true);
	}

	function submitApproval() {
		const { transactionId, totalAmount } = approveFundingForm.getValues();

		approveFundingVisible.set(false);

		props?.onApprovalRequest(transactionId, totalAmount);
	}

	return (
		<View>
			<AppAlert
				actions={[
					{
						title: 'Cancel',
						status: 'danger',
						onPress: () => {
							approveFundingVisible.set(false);
						},
					},
					{
						title: 'Submit',
						status: 'success',
						onPress: approveFundingForm.handleSubmit(submitApproval),
					},
				]}
				visible={approveFundingVisible.get}
				title={'Confirm Funding Details'}
			>
				<Controller
					control={approveFundingForm.control}
					name="transactionId"
					render={(control) => (
						<Input
							caption={
								<InputError
									errors={approveFundingForm.formState.errors.transactionId}
								/>
							}
							status={control.formState.errors.transactionId && 'danger'}
							label="Transaction ID"
							value={control.field.value}
							onBlur={control.field.onBlur}
							onChangeText={control.field.onChange}
						/>
					)}
				/>

				<Controller
					control={approveFundingForm.control}
					name="totalAmount"
					render={(control) => (
						<Input
							style={{
								marginTop: 8,
							}}
							caption={
								<InputError
									errors={approveFundingForm.formState.errors.totalAmount}
								/>
							}
							status={control.formState.errors.totalAmount && 'danger'}
							label="Total Amount"
							value={control.field.value}
							onBlur={control.field.onBlur}
							onChangeText={control.field.onChange}
						/>
					)}
				/>
			</AppAlert>

			<AppAlert
				actions={[
					{
						title: 'Close',
						onPress: () => {
							confirmedVisible.set(false);
						},
					},
					{
						title:
							(fundingStage.get === FundingStage.CANCELED && 'Cancel') ||
							(fundingStage.get === FundingStage.DECLINED && 'Decline') ||
							'',
						status: 'danger',
						loading:
							updateFundingStageMutation.loading ||
							cancelFundingMutation.loading ||
							declineFundingMutation.loading,
						onPress: () => onUpdateStage(fundingStage.get!),
					},
				]}
				title="Are you sure?"
				visible={confirmedVisible.get}
			/>

			<BreadCrumbs
				disabled={props.disabled}
				footer={() => (
					<View style={styles.actions}>
						{props?.account?.custodian !== CustodianType.FORTRESS && (
							<Button
								disabled={props.stage !== FundingStage.ASSET_TRANSFER_READY}
								style={styles.button}
								onPress={onApprovePressed}
							>
								Approve Funding
							</Button>
						)}

						{/* Cancel Funding */}
						<Button
							disabled={
								props.stage === FundingStage.CANCELED ||
								props.stage === FundingStage.DECLINED ||
								props.stage === FundingStage.ASSET_TRANSFER_COMPLETED ||
								isCancelDisabled
							}
							status="danger"
							style={styles.button}
							onPress={
								props.loading ||
								updateFundingStageMutation.loading ||
								cancelFundingMutation.loading ||
								declineFundingMutation.loading
									? undefined
									: () => onFundingStageSet(FundingStage.CANCELED)
							}
						>
							Cancel Funding
						</Button>
					</View>
				)}
				loading={
					(props.loading || updateFundingStageMutation.loading) && !confirmedVisible.get
				}
				stage={currentStage.get}
				stages={[
					{
						stage: FundingStage.ACCOUNT_PENDING,
						disabled: true,
					},
					{
						stage: FundingStage.DOCUMENTS_PENDING,
						disabled: !isAccountVerified,
					},
					{
						stage: FundingStage.DOCUMENTS_SENT_TO_CLIENT,
						disabled: !isAccountVerified,
					},
					{
						stage: FundingStage.DOCUMENTS_SIGNED,
						disabled: !isAccountVerified,
					},
					{
						stage: FundingStage.DOCUMENTS_SENT_TO_CUSTODIAN,
						title: 'Sent to Custodian',
						disabled: !isAccountVerified,
					},
					{
						stage: FundingStage.ASSET_TRANSFER_PENDING,
						disabled: !isAccountVerified,
					},
					...(props?.account?.custodian !== CustodianType.FORTRESS
						? [
								{
									stage: FundingStage.ASSET_TRANSFER_READY,
									disabled: !isAccountVerified,
								},
						  ]
						: []),
					{
						stage: FundingStage.ASSET_TRANSFER_COMPLETED,
						disabled: true,
					},
				]}
				onStageConfirm={onUpdateStage}
			/>
		</View>
	);
}

function useCustomStyles() {
	return StyleSheet.create({
		actions: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
		},
		button: {
			minWidth: 140,
			marginLeft: 16,
		},
	});
}
