import { Button, IndexPath, Select, SelectItem, TextProps } from '@ui-kitten/components';
import { EvaStatus, RenderProp } from '@ui-kitten/components/devsupport';
import React, { ReactNode } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

export interface AppSelectProps {
	style?: StyleProp<ViewStyle>;
	children?: ReactNode;
	data: any[];
	value?: any;
	placeholder?: string;
	label?: string;
	caption?: RenderProp<TextProps> | React.ReactText;
	itemDisplay?: (data: any) => string;
	itemDisplayItem?: (data: any) => ReactNode;
	onSelect: (data: any) => void;
	disabled?: boolean;
	status?: EvaStatus;
	clearButton?: boolean;
	testID?: string;
}

export function AppSelect(props: AppSelectProps) {
	const [selectedIndex, setSelectedIndex] = React.useState<IndexPath | null>();

	React.useEffect(() => {
		const index = props?.data?.findIndex(
			(x) => JSON.stringify(x) === JSON.stringify(props.value)
		);

		if (index >= 0) {
			setSelectedIndex(new IndexPath(index));
		} else {
			setSelectedIndex(undefined);
		}
	}, [props.value]);

	function onSelect(index: IndexPath): void {
		setSelectedIndex(index);
		props.onSelect(props?.data?.[index?.row]);
	}

	function onClear(): void {
		setSelectedIndex(null);
		props.onSelect('');
	}

	return (
		<Select
			testID={props.testID}
			disabled={props.disabled}
			style={props.style}
			selectedIndex={selectedIndex ? selectedIndex : undefined}
			onSelect={(x) => onSelect(x as IndexPath)}
			label={props.label}
			caption={props.caption as any}
			placeholder={props.placeholder || 'Select an option'}
			status={props.status}
			accessoryLeft={
				props.clearButton
					? () =>
							props.value ? (
								<Button size="tiny" status="basic" onPress={onClear}>
									Clear
								</Button>
							) : (
								<></>
							)
					: undefined
			}
			value={
				selectedIndex === undefined || !props.data || !props.data.length
					? null
					: props.itemDisplay
					? props.itemDisplay(props.data[selectedIndex?.row!])
					: props.data[selectedIndex?.row!]
			}
		>
			{props?.data?.map((item, index) => (
				<SelectItem
					key={index}
					title={
						props.itemDisplayItem
							? props.itemDisplayItem(item)
							: props.itemDisplay
							? props.itemDisplay(item)
							: item
					}
				/>
			))}
		</Select>
	);
}
