import { gql, useMutation, useQuery } from '@apollo/client';
import {
	EcsEncAddNotification as Notifications,
	EcsEncAddNotificationVariables as NotificationsVariables,
	EcsEncUpdateNotifications,
	EcsEncUpdateNotificationsVariables,
	EcsEncGetExternalNotifications_user_notificationSettings as NotificationSettings,
	NotificationSettingType,
	NotificationType,
	AddNotificationSettingInput,
} from '@app/codegen';
import { CustomCard, CustomDataTable, ITrustColumn, useTableState } from '@app/shared/components';
import { humanize } from '@itrustcapital/ui';
import { CheckBox } from '@ui-kitten/components';
import { useState } from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import Toast from 'react-native-toast-message';

export interface ExternalNotificationsProps {
	style?: StyleProp<ViewStyle>;
	userId: number;
}

export const ECS_ENC_GET_EXTERNAL_NOTIFICATIONS = gql`
	query EcsEncGetExternalNotifications($where: UserFilterInput) {
		user(where: $where) {
			id
			notificationSettings {
				userId
				notificationType
				type
				id
				createdAt
			}
		}
	}
`;

export const ECS_ENC_ADD_NOTIFICATION = gql`
	mutation EcsEncAddNotification($input: AddNotificationSettingInput) {
		addNotificationSetting(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

export const ECS_ENC_UPDATE_NOTIFICATIONS = gql`
	mutation EcsEncUpdateNotifications($input: UpdateNotificationSettingInput!) {
		updateNotificationSetting(input: $input) {
			success
			errorMessage
		}
	}
`;

const notificationReorderMap = {
	[NotificationSettingType.WIZARD_COMPLETED]: {
		position: 0,
		display: humanize(NotificationSettingType.WIZARD_COMPLETED),
	},
	[NotificationSettingType.DOCUMENTS_PENDING_CLIENT]: {
		position: 1,
		display: humanize(NotificationSettingType.DOCUMENTS_PENDING_CLIENT),
	},
	[NotificationSettingType.PENDING_CLIENT_DOCS_REMINDER]: {
		position: 2,
		display: humanize(NotificationSettingType.PENDING_CLIENT_DOCS_REMINDER),
	},
	[NotificationSettingType.FUNDING_CONTRIBUTION_INSTRUCTIONS]: {
		position: 3,
		display: humanize(NotificationSettingType.FUNDING_CONTRIBUTION_INSTRUCTIONS),
	},
	[NotificationSettingType.ROLLOVER5_BUS_DAYS]: {
		position: 4,
		display: 'Rollover 5 Bus. Days',
	},
	[NotificationSettingType.TRANSFER_AND_CASH2_BUS_DAYS]: {
		position: 5,
		display: 'Transfer And Cash 2 Bus. Days',
	},
	[NotificationSettingType.TRANSFER_PENDING]: {
		position: 6,
		display: humanize(NotificationSettingType.TRANSFER_PENDING),
	},
	[NotificationSettingType.FUNDS_RELEASED]: {
		position: 7,
		display: humanize(NotificationSettingType.FUNDS_RELEASED),
	},
	[NotificationSettingType.IRA_ADOPTION_AGREEMENT]: {
		position: 8,
		display: humanize(NotificationSettingType.IRA_ADOPTION_AGREEMENT),
	},
	[NotificationSettingType.TRANSFER_FORM_FOLLOW_UP]: {
		position: 9,
		display: humanize(NotificationSettingType.TRANSFER_FORM_FOLLOW_UP),
	},
	[NotificationSettingType.VERIFICATION]: {
		position: 10,
		display: humanize(NotificationSettingType.VERIFICATION),
	},
	[NotificationSettingType.VERIFICATION_ADDRESS]: {
		position: 11,
		display: humanize(NotificationSettingType.VERIFICATION_ADDRESS),
	},
	[NotificationSettingType.VERIFICATION_DRIVERS_LICENSE]: {
		position: 12,
		display: humanize(NotificationSettingType.VERIFICATION_DRIVERS_LICENSE),
	},
	[NotificationSettingType.VERIFICATION_SSN]: {
		position: 13,
		display: humanize(NotificationSettingType.VERIFICATION_SSN),
	},
	[NotificationSettingType.NONE]: {
		position: 14,
		display: humanize(NotificationSettingType.NONE),
	},
};

export function ExternalNotifications(props: ExternalNotificationsProps) {
	const styles = useCustomStyles();
	const tableState = useTableState({
		defaultWhere: {
			id: {
				eq: props.userId,
			},
		},
		pagination: false,
		striped: true,
		disabledPaginationServer: true,
		disabledSortServer: true,
	});

	const externalNotificationsQuery = useQuery(ECS_ENC_GET_EXTERNAL_NOTIFICATIONS, {
		variables: {
			where: tableState.where.get,
		},
		skip: !props.userId,
		onCompleted: (data) => data?.user?.notificationSettings?.length < 1 && addNotifications(),
	});

	const [isAddingExternalNotifications, setIsAddingExternalNotfication] = useState(false);
	const [addExternalNotification] = useMutation<Notifications, NotificationsVariables>(
		ECS_ENC_ADD_NOTIFICATION
	);

	const [updateExternalNotifications, updateExternalNotificationsMutation] = useMutation<
		EcsEncUpdateNotifications,
		EcsEncUpdateNotificationsVariables
	>(ECS_ENC_UPDATE_NOTIFICATIONS);

	const columns: ITrustColumn<NotificationSettings>[] = [
		{
			key: 'type',
			name: 'Type',
			minWidth: 300,
			selector: (row) => notificationReorderMap[row.type].display,
		},
		{
			key: 'type',
			name: 'Send Email',
			maxWidth: 150,
			center: true,
			cell: (row) => (
				<CheckBox
					checked={row.notificationType?.includes(NotificationType.EMAIL)}
					onChange={() => onUpdate(row, NotificationType.EMAIL)}
				/>
			),
		},
		{
			key: 'type',
			name: 'Send SMS',
			maxWidth: 150,
			center: true,
			cell: (row) => (
				<CheckBox
					checked={row.notificationType?.includes(NotificationType.SMS)}
					onChange={() => onUpdate(row, NotificationType.SMS)}
				/>
			),
		},
	];

	async function addNotifications(): Promise<void> {
		const addExternalNotificationPromises = Object.keys(NotificationSettingType)
			.filter((x) => x !== NotificationSettingType.NONE)
			.map((x) => {
				const input: AddNotificationSettingInput = {
					userId: props.userId,
					notificationTypes: [NotificationType.SMS, NotificationType.EMAIL],
					type: x as NotificationSettingType,
				};

				return addExternalNotification({
					variables: {
						input,
					},
				});
			});

		try {
			setIsAddingExternalNotfication(true);
			Promise.all(addExternalNotificationPromises).finally(() => {
				setIsAddingExternalNotfication(false);
			});

			externalNotificationsQuery?.refetch();
		} catch (error) {}
	}

	async function onUpdate(row: NotificationSettings, type: NotificationType): Promise<void> {
		const notificationTypes = row.notificationType?.includes(type)
			? row.notificationType.filter((rowNotifType: NotificationType) => type !== rowNotifType)
			: [...(row.notificationType as NotificationType[]), type];

		try {
			await updateExternalNotifications({
				variables: {
					input: {
						id: row.id,
						notificationType:
							notificationTypes.length < 1
								? [NotificationType.NONE]
								: notificationTypes,
					},
				},
			});

			Toast.show({
				type: 'success',
				text2: 'Notification updated',
			});
			externalNotificationsQuery?.refetch();
		} catch (error) {
			Toast.show({
				type: 'error',
				text2: 'Error updating notification',
			});
		}
	}

	const data = [
		...((externalNotificationsQuery?.data?.user?.notificationSettings ||
			[]) as NotificationSettings[]),
	];

	data.sort(
		(a, b) => notificationReorderMap[a.type].position - notificationReorderMap[b.type].position
	);

	return (
		<CustomCard
			header="External Notifications"
			loading={
				externalNotificationsQuery?.loading ||
				isAddingExternalNotifications ||
				updateExternalNotificationsMutation.loading
			}
			style={[styles.main.container, props.style]}
		>
			<CustomDataTable columns={columns} data={data} {...tableState.props} />
		</CustomCard>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			container: {},
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
	};
}
