import {
	UsersScreen_AddCrmUserVariables,
	UsersScreen_UpdateCrmUserVariables,
	UsersScreen_GetCrmUsers,
	UsersScreen_GetCrmUsersVariables,
	UsersScreen_GetCrmUsers_crmUsers_nodes as CrmUser,
	CrmUserSortInput,
	CrmUserFilterInput,
	SortEnumType,
	CrmUserStatus,
	SystemRole,
} from '@app/codegen';
import { useTableState } from '@app/shared/components';
import { FormActionType } from '@app/shared/models';
import { useAppState } from '@itrustcapital/ui';
import React from 'react';
import Toast from 'react-native-toast-message';

import { useUsersScreen_MaestroUserForm, useUsersScreen_SearchForm } from './UsersScreenForms';
import {
	useUsersScreen_AddMaestroUserMutation,
	useUsersScreen_GetMaestroUsersQuery,
	useUsersScreen_UpdateMaestroUsersMutation,
} from './UsersScreenGraphql';

export type UsersScreenState = ReturnType<typeof useUsersScreenState>;

export type UsersScreenProviderProps = {
	value: UsersScreenState | undefined;
	children: React.ReactNode;
};

const UsersScreenContext = React.createContext<UsersScreenState | undefined>(undefined);

export function UsersScreenProvider(props: UsersScreenProviderProps | null) {
	return (
		<UsersScreenContext.Provider value={props?.value}>
			{props?.children}
		</UsersScreenContext.Provider>
	);
}

export function useUsersScreenContext() {
	const context = React.useContext(UsersScreenContext);

	if (context === undefined) {
		throw new Error('useUsersScreenContext must be used within a UsersScreenProvider');
	}

	return context;
}

export function useUsersScreenState() {
	const usersScreenMaestroUserForm = useUsersScreen_MaestroUserForm();
	const usersScreenSearchForm = useUsersScreen_SearchForm();
	const tableState = useTableState<CrmUserSortInput, CrmUserFilterInput>({
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere: { status: { neq: CrmUserStatus.DELETED } },
		pagination: true,
		striped: true,
	});

	const getMaestroUsersData = useAppState<UsersScreen_GetCrmUsers | null>(null);
	const usersSelectedRow = useAppState<CrmUser | undefined>(undefined);
	const userFormModalVisible = useAppState(false);
	const userFormModalAction = useAppState<FormActionType>(FormActionType.NONE);

	const systemRoles = Object.values(SystemRole).filter((item) => !item.includes('GROUP'));
	const variables: UsersScreen_GetCrmUsersVariables = {
		where: tableState.where.get,
		order: tableState.sort.get,
		first: tableState.rowsPerPage.get,
	};

	// GET MAESTRO USER
	const getMaestroUsersQuery = useUsersScreen_GetMaestroUsersQuery({
		variables,
		onCompleted: onGetMaestroUserCompleted,
		onError: onGetMaestroUserError,
	});

	async function onRefetchMaestroUsers(
		variables: UsersScreen_GetCrmUsersVariables
	): Promise<void> {
		try {
			getMaestroUsersQuery.refetch({ ...variables });
		} catch (error) {
			onGetMaestroUserError();
		}
	}

	function onGetMaestroUserCompleted(data: UsersScreen_GetCrmUsers): void {
		getMaestroUsersData.set(data);

		tableState.onCompleted(data.crmUsers?.totalCount, data.crmUsers?.pageInfo);
	}

	function onGetMaestroUserError(): void {
		Toast.show({
			type: 'error',
			text2: 'Was unsuccessfully able to get users.',
		});
	}

	// ADD MAESTRO USER
	const [addMaestroUser, addMaestroUserMutation] = useUsersScreen_AddMaestroUserMutation({
		onCompleted: onAddMaestroUserCompleted,
		onError: onAddMaestroUserError,
	});

	async function onAddMaestroUser(variables: UsersScreen_AddCrmUserVariables): Promise<void> {
		try {
			addMaestroUser({ variables });

			Toast.show({
				type: 'success',
				text2: 'Successfully added user.',
			});
		} catch (error) {
			onAddMaestroUserError();
		}
	}

	function onAddMaestroUserCompleted(): void {
		userFormModalVisible.set(false);
	}

	function onAddMaestroUserError(): void {
		Toast.show({
			type: 'error',
			text2: 'Was unsuccessfully able to add user.',
		});
	}

	// UPDATE MAESTRO USER
	const [updateMaestroUser, updateMaestroUserMutation] =
		useUsersScreen_UpdateMaestroUsersMutation({
			onCompleted: onUpdateMaestroUserCompleted,
			onError: onUpdateMaestroUserError,
		});

	async function onUpdateMaestroUser(
		variables: UsersScreen_UpdateCrmUserVariables
	): Promise<void> {
		try {
			updateMaestroUser({ variables });

			Toast.show({
				type: 'success',
				text2: 'Successfully updated user status.',
			});

			onUpdateMaestroUserCompleted();
		} catch (error) {
			onUpdateMaestroUserError();
		}
	}

	function onUpdateMaestroUserCompleted(): void {
		userFormModalVisible.set(false);
		usersSelectedRow.set(undefined);
	}

	function onUpdateMaestroUserError(): void {
		Toast.show({
			type: 'error',
			text2: 'Was unsuccessfully able to update user status.',
		});
	}

	return {
		systemRoles,
		tableState,
		usersScreenSearchForm,
		usersScreenMaestroUserForm,
		userFormModalVisible,
		userFormModalAction,
		usersSelectedRow,
		getMaestroUsersQuery,
		getMaestroUsersData: getMaestroUsersData.get?.crmUsers?.nodes,
		getMaestroUsersLoading: getMaestroUsersQuery.loading,
		updateMaestroUserLoading: updateMaestroUserMutation.loading,
		mutationLoading: addMaestroUserMutation.loading || updateMaestroUserMutation.loading,
		onRefetchMaestroUsers,
		onAddMaestroUser,
		onUpdateMaestroUser,
	};
}
