import { AppBackButton, AppScreen, StackNavigation } from '@itrustcapital/ui';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import Toast from 'react-native-toast-message';

import { ReportingScreenProvider, useReportingScreenState } from './ReportingScreenProvider';
import { GenerateReport, ReportingCard } from './components';

export function ReportingScreen() {
	const navigation = useNavigation<StackNavigation>();
	const state = useReportingScreenState();

	React.useEffect(() => {
		navigation.setOptions({
			title: 'Reporting - iTrustCapital',
			headerTitle: 'Reporting',
			headerLeft: () => <AppBackButton supportDrawer />,
			headerRight: () => null,
		});
	}, []);

	React.useEffect(() => {
		showToaster(state.reportingSuccess);
	}, [state.reportingSuccess]);

	function showToaster(success: boolean | null): void {
		if (success === null) {
			return;
		}

		Toast.show({
			type: success ? 'success' : 'error',
			text2: `Report has ${success ? 'been generated' : 'failed to generate'}`,
		});
	}

	return (
		<AppScreen>
			<ReportingScreenProvider value={state}>
				<GenerateReport />

				<ReportingCard />
			</ReportingScreenProvider>
		</AppScreen>
	);
}
