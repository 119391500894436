import { useAppTheme } from '@itrustcapital/ui';
import React, { ReactNode } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

export interface AllocationProgressProps {
	style?: StyleProp<ViewStyle>;
	children?: ReactNode;
	percentage?: number;
}

export function AllocationProgress(props: AllocationProgressProps) {
	const styles = useCustomStyles();

	return (
		<View style={[styles.container, props.style]}>
			<View style={[styles.fill, { width: props?.percentage + '%' }]}></View>
		</View>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return StyleSheet.create({
		container: {
			borderRadius: 10,
			width: 140,
			height: 8,
			backgroundColor: theme['color-basic-300'],
		},
		fill: {
			borderRadius: 10,
			height: '100%',
			backgroundColor: theme['color-primary-600'],
		},
	});
}
