import ITrustCapitalLogoSVG from '@app/assets/img/iTrustCapital_logo.svg';
import ITrustCapitalLogoDarkSVG from '@app/assets/img/iTrustCapital_logo_dark.svg';
import { AppSvg, useAppearance } from '@itrustcapital/ui';
import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

export interface ITrustCapitalLogoProps {
	width?: number;
	height?: number;
	style?: StyleProp<ViewStyle>;
}

export function ITrustCapitalLogo(props: ITrustCapitalLogoProps) {
	const { dark } = useAppearance();

	return (
		<AppSvg
			height={props.height}
			style={props.style}
			width={props.width}
			xml={dark ? ITrustCapitalLogoDarkSVG : ITrustCapitalLogoSVG}
		/>
	);
}
