import { useTheme } from '@ui-kitten/components';

export function useAppTheme(): Record<ThemeColorVariables, string> {
	return useTheme();
}

export type ThemeColorVariables =
	| 'color-primary-100'
	| 'color-primary-200'
	| 'color-primary-300'
	| 'color-primary-400'
	| 'color-primary-500'
	| 'color-primary-600'
	| 'color-primary-700'
	| 'color-primary-800'
	| 'color-primary-900'
	| 'color-primary-1100'
	| 'color-primary-transparent-100'
	| 'color-primary-transparent-200'
	| 'color-primary-transparent-300'
	| 'color-primary-transparent-400'
	| 'color-primary-transparent-500'
	| 'color-primary-transparent-600'
	| 'color-primary-transparent-700'
	| 'color-primary-transparent-800'
	| 'color-success-100'
	| 'color-success-200'
	| 'color-success-300'
	| 'color-success-400'
	| 'color-success-500'
	| 'color-success-600'
	| 'color-success-700'
	| 'color-success-800'
	| 'color-success-900'
	| 'color-success-transparent-100'
	| 'color-success-transparent-200'
	| 'color-success-transparent-300'
	| 'color-success-transparent-400'
	| 'color-success-transparent-500'
	| 'color-success-transparent-600'
	| 'color-info-100'
	| 'color-info-200'
	| 'color-info-300'
	| 'color-info-400'
	| 'color-info-500'
	| 'color-info-600'
	| 'color-info-700'
	| 'color-info-800'
	| 'color-info-900'
	| 'color-info-1000'
	| 'color-info-transparent-100'
	| 'color-info-transparent-200'
	| 'color-info-transparent-300'
	| 'color-info-transparent-400'
	| 'color-info-transparent-500'
	| 'color-info-transparent-600'
	| 'color-warning-100'
	| 'color-warning-200'
	| 'color-warning-300'
	| 'color-warning-400'
	| 'color-warning-500'
	| 'color-warning-600'
	| 'color-warning-700'
	| 'color-warning-800'
	| 'color-warning-900'
	| 'color-warning-1000'
	| 'color-warning-transparent-100'
	| 'color-warning-transparent-200'
	| 'color-warning-transparent-300'
	| 'color-warning-transparent-400'
	| 'color-warning-transparent-500'
	| 'color-warning-transparent-600'
	| 'color-danger-100'
	| 'color-danger-200'
	| 'color-danger-300'
	| 'color-danger-400'
	| 'color-danger-500'
	| 'color-danger-600'
	| 'color-danger-700'
	| 'color-danger-800'
	| 'color-danger-900'
	| 'color-danger-transparent-100'
	| 'color-danger-transparent-200'
	| 'color-danger-transparent-300'
	| 'color-danger-transparent-400'
	| 'color-danger-transparent-500'
	| 'color-danger-transparent-600'
	| 'color-basic-100'
	| 'color-basic-200'
	| 'color-basic-300'
	| 'color-basic-400'
	| 'color-basic-500'
	| 'color-basic-600'
	| 'color-basic-700'
	| 'color-basic-800'
	| 'color-basic-900'
	| 'color-basic-1000'
	| 'color-basic-1100'
	| 'color-basic-1200'
	| 'color-basic-transparent-100'
	| 'color-basic-transparent-200'
	| 'color-basic-transparent-300'
	| 'color-basic-transparent-400'
	| 'color-basic-transparent-500'
	| 'color-basic-transparent-600'
	| 'color-basic-control-transparent-100'
	| 'color-basic-control-transparent-200'
	| 'color-basic-control-transparent-300'
	| 'color-basic-control-transparent-400'
	| 'color-basic-control-transparent-500'
	| 'color-basic-control-transparent-600'
	| 'color-basic-focus'
	| 'color-basic-hover'
	| 'color-basic-default'
	| 'color-basic-active'
	| 'color-basic-disabled'
	| 'color-basic-focus-border'
	| 'color-basic-hover-border'
	| 'color-basic-default-border'
	| 'color-basic-active-border'
	| 'color-basic-disabled-border'
	| 'color-basic-transparent-focus'
	| 'color-basic-transparent-hover'
	| 'color-basic-transparent-default'
	| 'color-basic-transparent-active'
	| 'color-basic-transparent-disabled'
	| 'color-basic-transparent-focus-border'
	| 'color-basic-transparent-hover-border'
	| 'color-basic-transparent-default-border'
	| 'color-basic-transparent-active-border'
	| 'color-basic-transparent-disabled-border'
	| 'color-primary-focus'
	| 'color-primary-hover'
	| 'color-primary-default'
	| 'color-primary-active'
	| 'color-primary-disabled'
	| 'color-primary-focus-border'
	| 'color-primary-hover-border'
	| 'color-primary-default-border'
	| 'color-primary-active-border'
	| 'color-primary-disabled-border'
	| 'color-primary-transparent-focus'
	| 'color-primary-transparent-hover'
	| 'color-primary-transparent-default'
	| 'color-primary-transparent-active'
	| 'color-primary-transparent-disabled'
	| 'color-primary-transparent-focus-border'
	| 'color-primary-transparent-hover-border'
	| 'color-primary-transparent-default-border'
	| 'color-primary-transparent-active-border'
	| 'color-primary-transparent-disabled-border'
	| 'color-primary-transparent'
	| 'color-success-focus'
	| 'color-success-hover'
	| 'color-success-default'
	| 'color-success-active'
	| 'color-success-disabled'
	| 'color-success-focus-border'
	| 'color-success-hover-border'
	| 'color-success-default-border'
	| 'color-success-active-border'
	| 'color-success-disabled-border'
	| 'color-success-transparent-focus'
	| 'color-success-transparent-hover'
	| 'color-success-transparent-default'
	| 'color-success-transparent-active'
	| 'color-success-transparent-disabled'
	| 'color-success-transparent-focus-border'
	| 'color-success-transparent-hover-border'
	| 'color-success-transparent-default-border'
	| 'color-success-transparent-active-border'
	| 'color-success-transparent-disabled-border'
	| 'color-info-focus'
	| 'color-info-hover'
	| 'color-info-default'
	| 'color-info-active'
	| 'color-info-disabled'
	| 'color-info-focus-border'
	| 'color-info-hover-border'
	| 'color-info-default-border'
	| 'color-info-active-border'
	| 'color-info-disabled-border'
	| 'color-info-transparent-focus'
	| 'color-info-transparent-hover'
	| 'color-info-transparent-default'
	| 'color-info-transparent-active'
	| 'color-info-transparent-disabled'
	| 'color-info-transparent-focus-border'
	| 'color-info-transparent-hover-border'
	| 'color-info-transparent-default-border'
	| 'color-info-transparent-active-border'
	| 'color-info-transparent-disabled-border'
	| 'color-warning-focus'
	| 'color-warning-hover'
	| 'color-warning-default'
	| 'color-warning-active'
	| 'color-warning-disabled'
	| 'color-warning-focus-border'
	| 'color-warning-hover-border'
	| 'color-warning-default-border'
	| 'color-warning-active-border'
	| 'color-warning-disabled-border'
	| 'color-warning-transparent-focus'
	| 'color-warning-transparent-hover'
	| 'color-warning-transparent-default'
	| 'color-warning-transparent-active'
	| 'color-warning-transparent-disabled'
	| 'color-warning-transparent-focus-border'
	| 'color-warning-transparent-hover-border'
	| 'color-warning-transparent-default-border'
	| 'color-warning-transparent-active-border'
	| 'color-warning-transparent-disabled-border'
	| 'color-danger-focus'
	| 'color-danger-hover'
	| 'color-danger-default'
	| 'color-danger-active'
	| 'color-danger-disabled'
	| 'color-danger-focus-border'
	| 'color-danger-hover-border'
	| 'color-danger-default-border'
	| 'color-danger-active-border'
	| 'color-danger-disabled-border'
	| 'color-danger-transparent-focus'
	| 'color-danger-transparent-hover'
	| 'color-danger-transparent-default'
	| 'color-danger-transparent-active'
	| 'color-danger-transparent-disabled'
	| 'color-danger-transparent-focus-border'
	| 'color-danger-transparent-hover-border'
	| 'color-danger-transparent-default-border'
	| 'color-danger-transparent-active-border'
	| 'color-danger-transparent-disabled-border'
	| 'color-control-focus'
	| 'color-control-hover'
	| 'color-control-default'
	| 'color-control-active'
	| 'color-control-disabled'
	| 'color-control-focus-border'
	| 'color-control-hover-border'
	| 'color-control-default-border'
	| 'color-control-active-border'
	| 'color-control-disabled-border'
	| 'color-control-transparent-focus'
	| 'color-control-transparent-hover'
	| 'color-control-transparent-default'
	| 'color-control-transparent-active'
	| 'color-control-transparent-disabled'
	| 'color-control-transparent-focus-border'
	| 'color-control-transparent-hover-border'
	| 'color-control-transparent-default-border'
	| 'color-control-transparent-active-border'
	| 'color-control-transparent-disabled-border'
	| 'background-basic-color-1'
	| 'background-basic-color-2'
	| 'background-basic-color-3'
	| 'background-basic-color-4'
	| 'background-alternative-color-1'
	| 'background-alternative-color-2'
	| 'background-alternative-color-3'
	| 'background-alternative-color-4'
	| 'border-basic-color-1'
	| 'border-basic-color-2'
	| 'border-basic-color-3'
	| 'border-basic-color-4'
	| 'border-basic-color-5'
	| 'border-basic-color-6'
	| 'border-alternative-color-1'
	| 'border-alternative-color-2'
	| 'border-alternative-color-3'
	| 'border-alternative-color-4'
	| 'border-alternative-color-5'
	| 'border-primary-color-1'
	| 'border-primary-color-2'
	| 'border-primary-color-3'
	| 'border-primary-color-4'
	| 'border-primary-color-5'
	| 'border-success-color-1'
	| 'border-success-color-2'
	| 'border-success-color-3'
	| 'border-success-color-4'
	| 'border-success-color-5'
	| 'border-info-color-1'
	| 'border-info-color-2'
	| 'border-info-color-3'
	| 'border-info-color-4'
	| 'border-info-color-5'
	| 'border-warning-color-1'
	| 'border-warning-color-2'
	| 'border-warning-color-3'
	| 'border-warning-color-4'
	| 'border-warning-color-5'
	| 'border-danger-color-1'
	| 'border-danger-color-2'
	| 'border-danger-color-3'
	| 'border-danger-color-4'
	| 'border-danger-color-5'
	| 'text-basic-color'
	| 'text-alternate-color'
	| 'text-control-color'
	| 'text-disabled-color'
	| 'text-hint-color'
	| 'text-label-color'
	| 'text-primary-color'
	| 'text-primary-focus-color'
	| 'text-primary-hover-color'
	| 'text-primary-active-color'
	| 'text-primary-disabled-color'
	| 'text-success-color'
	| 'text-success-focus-color'
	| 'text-success-hover-color'
	| 'text-success-active-color'
	| 'text-success-disabled-color'
	| 'text-info-color'
	| 'text-info-focus-color'
	| 'text-info-hover-color'
	| 'text-info-active-color'
	| 'text-info-disabled-color'
	| 'text-warning-color'
	| 'text-warning-focus-color'
	| 'text-warning-hover-color'
	| 'text-warning-active-color'
	| 'text-warning-disabled-color'
	| 'text-danger-color'
	| 'text-danger-focus-color'
	| 'text-danger-hover-color'
	| 'text-danger-active-color'
	| 'text-danger-disabled-color'
	| 'outline-color'
	| 'shadow-color-100'
	| 'color-accent-green-light'
	| 'color-accent-green-dark'
	| 'color-accent-red-light'
	| 'color-accent-red-dark'
	| 'color-accent-blue-light'
	| 'color-accent-blue-dark'
	| 'color-accent-orange-light'
	| 'color-accent-orange-dark'
	| 'color-accent-cloud-light'
	| 'color-accent-grey-light'
	| 'color-accent-grey-dark';
