import { CurrencyType, ReportingScreen_GenerateFundingsReport } from '@app/codegen';
import { getBestDate, useAppState } from '@itrustcapital/ui';
import React from 'react';

import { useReportingScreenFilterForm } from './ReportingScreenForms';
import { useReportingScreenGenerateFundingsReportMutation } from './ReportingScreenGraphql';

export type ReportingScreenState = ReturnType<typeof useReportingScreenState>;

export type ReportingScreenProviderProps = {
	value: ReportingScreenState | undefined;
	children: React.ReactNode;
};

const ReportingScreenContext = React.createContext<ReportingScreenState | undefined>(undefined);

export function ReportingScreenProvider(props: ReportingScreenProviderProps | null) {
	return (
		<ReportingScreenContext.Provider value={props?.value}>
			{props?.children}
		</ReportingScreenContext.Provider>
	);
}

export function useReportingScreenContext() {
	const context = React.useContext(ReportingScreenContext);

	if (context === undefined) {
		throw new Error('useCounterContext must be used within a ReportingScreenProvider');
	}

	return context;
}

export function useReportingScreenState() {
	const showGenerateReport = useAppState(false);
	const reportingSuccess = useAppState<boolean | null>(null);
	const reportingError = useAppState<any | undefined>(undefined);
	const reportingUrl = useAppState('');
	const reportingFilterForm = useReportingScreenFilterForm();

	const [generateFundingsReport, generateFundingsReportMutation] =
		useReportingScreenGenerateFundingsReportMutation({
			onCompleted: onGenerateReportingCompleted,
			onError: onGenerateReportingError,
		});

	async function onGenerateFundingReport(): Promise<void> {
		const formValues = reportingFilterForm.getValues();

		try {
			generateFundingsReport({
				variables: {
					input: {
						createdFrom: getBestDate(
							formValues.createdFrom,
							formValues.createdTo,
							true
						).toISOString(),
						createdTo: getBestDate(
							formValues.createdFrom,
							formValues.createdTo
						).toISOString(),
						currencyType: CurrencyType.USD,
					},
				},
			});

			reportingSuccess.set(true);
		} catch (error) {
			onGenerateReportingError(error);
		}
	}

	function onGenerateReportingCompleted(data: ReportingScreen_GenerateFundingsReport): void {
		reportingUrl.set(data.generateFundingsReport?.data?.url || '');
		reportingSuccess.set(true);
	}

	function onGenerateReportingError(error: any): void {
		reportingError.set(error);
		reportingSuccess.set(false);
	}

	return {
		reportingFilterForm,
		showGenerateReport,
		reportingUrl,
		loadingGenerateFundingReport: generateFundingsReportMutation.loading,
		reportingSuccess: reportingSuccess.get,
		reportingError: reportingError.get,
		onGenerateFundingReport,
	};
}
