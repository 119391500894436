import { useTheme, Text, Layout } from '@ui-kitten/components';
import { EvaStatus } from '@ui-kitten/components/devsupport';
import React, { ReactNode } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import { AppIcon } from './AppIcon';

export interface AppMessageProps {
	style?: StyleProp<ViewStyle>;
	children?: ReactNode;
	status?: EvaStatus;
	noMarginBottom?: boolean;
	message: string;
}

export function AppMessage(props: AppMessageProps) {
	const theme = useTheme();

	function StatusIcon() {
		switch (props.status) {
			case 'primary':
				return (
					<AppIcon
						lib="mat"
						name="info-outline"
						size={24}
						color={theme[`color-${props.status}-900`]}
					/>
				);
			case 'info':
				return (
					<AppIcon
						lib="mat"
						name="info-outline"
						size={24}
						color={theme[`color-${props.status}-900`]}
					/>
				);
			case 'danger':
				return (
					<AppIcon
						lib="mat"
						name="error-outline"
						size={24}
						color={theme[`color-${props.status}-900`]}
					/>
				);
			case 'warning':
				return (
					<AppIcon
						lib="mat"
						name="warning"
						size={24}
						color={theme[`color-${props.status}-900`]}
					/>
				);
			case 'success':
				return (
					<AppIcon
						lib="mat"
						name="check"
						size={24}
						color={theme[`color-${props.status}-900`]}
					/>
				);
			default:
				return null;
		}
	}

	return (
		<Layout
			style={[
				styles.container,
				props.style,
				props.status !== undefined && {
					backgroundColor: theme[`color-${props.status}-400`],
				},
				props.noMarginBottom && { marginBottom: 0 },
			]}
		>
			<View style={styles.wrapper}>
				<StatusIcon />
				<Text style={[styles.text, { color: theme[`color-${props.status}-900`] }]}>
					{props.message}
				</Text>
			</View>
		</Layout>
	);
}

const styles = StyleSheet.create({
	container: {
		padding: 20,
		borderRadius: 20,
		marginBottom: 15,
	},
	wrapper: {
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	text: {
		marginLeft: 10,
		color: 'white',
	},
});
