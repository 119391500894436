import { gql, useQuery, useMutation } from '@apollo/client';
import {
	EcsCbGetBeneficaries,
	EcsCbGetBeneficariesVariables,
	BeneficiaryFilterInput,
	BeneficiarySortInput,
	SortEnumType,
	EcsCbGetBeneficaries_beneficiaries_nodes as Beneficiary,
	EcsCbDeleteBeneficiary,
	EcsCbDeleteBeneficiaryVariables,
	EcsCbGetBeneficaries_beneficiaries_nodes_addresses as BeneficiaryAddress,
	BeneficiaryRelationshipType,
} from '@app/codegen';
import { CustomerBeneficiariesLocators } from '@app/e2e/screens/EditCustomer';
import { CustomCard, CustomDataTable, ITrustColumn, useTableState } from '@app/shared/components';
import { AppAlert, AppIcon, maskPhoneNumber, useAppState, useAppTheme } from '@itrustcapital/ui';
import { Text, Button } from '@ui-kitten/components';
import React from 'react';
import { View, StyleSheet } from 'react-native';
import Toast from 'react-native-toast-message';

import { AddCustomerBeneficiary } from './AddCustomerBeneficiary';
import { EditCustomerBeneficiary } from './EditCustomerBeneficiary';

export interface CustomerBeneficariesProps {
	userId: number;
}

export const ECS_CB_GET_BENEFICIARIES = gql`
	query EcsCbGetBeneficaries(
		$where: BeneficiaryFilterInput
		$order: [BeneficiarySortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		beneficiaries(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				id
				firstName
				lastName
				email
				addresses {
					address
					city
					state
					zipCode
				}
				phoneNumber
				relationship
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			totalCount
		}
	}
`;

export const ECS_CB_DELETE_BENEFICIARY = gql`
	mutation EcsCbDeleteBeneficiary($input: DeleteBeneficiaryInput!) {
		deleteBeneficiary(input: $input) {
			success
			errorMessage
		}
	}
`;

export function CustomerBeneficaries(props: CustomerBeneficariesProps) {
	const styles = useCustomStyles();
	const theme = useAppTheme();

	const selectedRow = useAppState<Beneficiary | null>(null);
	const createBeneficiaryVisible = useAppState(false);
	const editBeneficiaryVisible = useAppState(false);
	const deleteBeneficiaryVisible = useAppState(false);

	const tableState = useTableState<BeneficiarySortInput, BeneficiaryFilterInput>({
		defaultRowsPerPage: 5,
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere: {
			userId: {
				eq: props.userId,
			},
		},
		pagination: true,
		striped: true,
	});

	const getBeneficiariesQuery = useQuery<EcsCbGetBeneficaries, EcsCbGetBeneficariesVariables>(
		ECS_CB_GET_BENEFICIARIES,
		{
			variables: {
				order: tableState.sort.get,
				first: tableState.rowsPerPage.get,
				where: tableState.where.get, // where is updated via state onSubmit
			},
			skip: !props.userId,
			onCompleted: (data) => {
				tableState.onCompleted(
					data.beneficiaries?.totalCount,
					data.beneficiaries?.pageInfo
				);
			},
			onError: (error) => {
				Toast.show({
					text2: error.message,
					type: 'error',
				});
			},
		}
	);

	const [onDeleteBeneficiary, deleteBeneficiaryQuery] = useMutation<
		EcsCbDeleteBeneficiary,
		EcsCbDeleteBeneficiaryVariables
	>(ECS_CB_DELETE_BENEFICIARY);

	const columns: ITrustColumn<Beneficiary>[] = [
		{
			key: 'firstName',
			name: 'Name',
			selector: (row) => `${row.firstName} ${row.lastName}`,
		},
		{
			key: 'email',
			name: 'Email',
			minWidth: 300,
			sortable: true,
			selector: (row) => row.email,
		},
		{
			key: 'addresses',
			name: 'Address',
			minWidth: 400,
			selector: (row) => (row.addresses ? formatAddress(row.addresses[0]) : ''),
		},
		{
			key: 'phoneNumber',
			name: 'Phone',
			maxWidth: 130,
			sortable: true,
			selector: (row) => maskPhoneNumber(row.phoneNumber),
		},
		{
			key: 'relationship',
			name: 'Spouse',
			maxWidth: 150,
			center: true,
			sortable: true,
			selector: (row) =>
				row?.relationship === BeneficiaryRelationshipType.SPOUSE ? 'Yes' : 'No',
		},
	];

	// want to make a global helper, but need a pattern for creating address Fragments
	function formatAddress(address: BeneficiaryAddress | null): string {
		if (!address) return '';

		return `${address.address} ${address.city}, ${address.state} ${address.zipCode}`;
	}

	async function onDeleteSubmit(): Promise<void> {
		if (!selectedRow.get) {
			return;
		}

		try {
			await onDeleteBeneficiary({
				variables: {
					input: {
						id: selectedRow.get?.id,
					},
				},
			});
			Toast.show({
				text2: 'Customer beneficiary has been deleted',
				type: 'success',
			});

			deleteBeneficiaryVisible.set(false);
			selectedRow.set(null);
			tableState.reset();
			getBeneficiariesQuery.refetch();
		} catch (err) {
			Toast.show({
				text2: 'Customer beneficiary failed to delete',
				type: 'error',
			});
		}
	}

	return (
		<CustomCard
			header={() => (
				<View style={styles.main.header}>
					<Text category="h6">Beneficiaries</Text>
					<Button
						testID={CustomerBeneficiariesLocators.createCustomerBeneficiaryButton}
						onPress={() => {
							selectedRow.set(null);
							createBeneficiaryVisible.set(true);
						}}
					>
						New
					</Button>
				</View>
			)}
		>
			{props.userId && (
				<AddCustomerBeneficiary
					toggleVisible={() => {
						createBeneficiaryVisible.set(!createBeneficiaryVisible.get);
					}}
					userId={props.userId}
					visible={createBeneficiaryVisible.get}
					onSave={(success: boolean) => {
						Toast.show({
							text2: `Customer beneficiary ${
								success ? 'has been added' : 'failed to add'
							}`,
							type: success ? 'success' : 'error',
						});

						if (success) {
							selectedRow.set(null);
							createBeneficiaryVisible.set(false);
							getBeneficiariesQuery.refetch();
						}
					}}
				/>
			)}
			{selectedRow.get && (
				<EditCustomerBeneficiary
					beneficiaryId={selectedRow.get.id}
					toggleVisible={() => {
						selectedRow.set(null);
						editBeneficiaryVisible.set(!editBeneficiaryVisible.get);
					}}
					visible={editBeneficiaryVisible.get}
					onSave={(success: boolean) => {
						Toast.show({
							text2: `Customer beneficiary ${
								success ? 'has been updated' : 'failed to update'
							}`,
							type: success ? 'success' : 'error',
						});

						if (success) {
							selectedRow.set(null);
							editBeneficiaryVisible.set(false);
							getBeneficiariesQuery.refetch();
						}
					}}
				/>
			)}
			<AppAlert
				actions={[
					{
						testID: CustomerBeneficiariesLocators.deleteCustomerBeneficiaryCancelButton,
						title: 'Close',
						onPress: () => {
							deleteBeneficiaryVisible.set(false);
						},
					},
					{
						testID: CustomerBeneficiariesLocators.deleteCustomerBeneficiarySaveButton,
						loading: deleteBeneficiaryQuery.loading,
						title: 'Confirm',
						status: 'danger',
						onPress: onDeleteSubmit,
					},
				]}
				icon={
					<AppIcon
						color={theme['color-danger-500']}
						lib="ion"
						name="close-circle-outline"
						size={70}
						style={styles.deleteModal.closeIcon}
					/>
				}
				message={`Customer beneficiary ${selectedRow.get?.email} will be deleted`}
				title="Are you sure?"
				visible={deleteBeneficiaryVisible.get}
			/>
			<CustomDataTable
				{...tableState.props}
				columns={columns}
				data={getBeneficiariesQuery.data?.beneficiaries?.nodes as Beneficiary[]}
				progressPending={getBeneficiariesQuery.loading}
				refetch={(variables) => {
					getBeneficiariesQuery.refetch({
						...variables,
						where: tableState.where.get,
					});
				}}
				rowActions={[
					{
						testID: CustomerBeneficiariesLocators.editCustomerBeneficiaryButton,
						iconName: 'pencil',
						iconLib: 'matc',
						handler: (row) => {
							selectedRow.set(row);
							editBeneficiaryVisible.set(true);
						},
						name: 'edit',
					},
					{
						testID: CustomerBeneficiariesLocators.deleteCustomerBeneficiaryButton,
						iconName: 'trash',
						iconLib: 'ion',
						handler: (row) => {
							selectedRow.set(row);
							deleteBeneficiaryVisible.set(true);
						},
						name: 'delete',
					},
				]}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		deleteModal: StyleSheet.create({
			closeIcon: {
				marginHorizontal: 'auto',
			},
		}),
		row: StyleSheet.create({
			actions: {
				flexDirection: 'row',
				alignContent: 'center',
				justifyContent: 'space-between',
			},
			edit: {
				marginRight: 4,
			},
		}),
	};
}
