import { CustomCard } from '@app/shared/components';
import { Button } from '@ui-kitten/components';
import React from 'react';
import { View, StyleSheet } from 'react-native';

import { ReportingFilterForm } from './ReportingFilterForm';
import { useReportingScreenContext } from '../ReportingScreenProvider';

export interface ReportingCardProps {}

export function ReportingCard(props: ReportingCardProps) {
	const styles = useCustomStyles();
	const state = useReportingScreenContext();

	return (
		<CustomCard
			footer={() => (
				<View style={styles.footer}>
					<Button onPress={() => state.showGenerateReport.set(true)}>
						Generate Report
					</Button>
				</View>
			)}
			header="Reporting"
		>
			<ReportingFilterForm />
		</CustomCard>
	);
}

function useCustomStyles() {
	return StyleSheet.create({
		footer: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
			alignItems: 'center',
		},
	});
}
