import aave from '@app/assets/img/coins/aave.svg';
import ada from '@app/assets/img/coins/ada.svg';
import algo from '@app/assets/img/coins/algo.svg';
import ape from '@app/assets/img/coins/ape.svg';
import arb from '@app/assets/img/coins/arb.svg';
import atom from '@app/assets/img/coins/atom.svg';
import avax from '@app/assets/img/coins/avax.svg';
import axs from '@app/assets/img/coins/axs.svg';
import bat from '@app/assets/img/coins/bat.svg';
import bch from '@app/assets/img/coins/bch.svg';
import bonk from '@app/assets/img/coins/bonk.svg';
import btc from '@app/assets/img/coins/btc.svg';
import chz from '@app/assets/img/coins/chz.svg';
import comp from '@app/assets/img/coins/comp.svg';
import crv from '@app/assets/img/coins/crv.svg';
import doge from '@app/assets/img/coins/doge.svg';
import dot from '@app/assets/img/coins/dot.svg';
import enj from '@app/assets/img/coins/enj.svg';
import eos from '@app/assets/img/coins/eos.svg';
import eth from '@app/assets/img/coins/eth.svg';
import flow from '@app/assets/img/coins/flow.svg';
import ftm from '@app/assets/img/coins/ftm.svg';
import gala from '@app/assets/img/coins/gala.svg';
import gold from '@app/assets/img/coins/gold.svg';
import grt from '@app/assets/img/coins/grt.svg';
import hbar from '@app/assets/img/coins/hbar.svg';
import link from '@app/assets/img/coins/link.svg';
import lrc from '@app/assets/img/coins/lrc.svg';
import ltc from '@app/assets/img/coins/ltc.svg';
import mana from '@app/assets/img/coins/mana.svg';
import matic from '@app/assets/img/coins/matic.svg';
import mkr from '@app/assets/img/coins/mkr.svg';
import near from '@app/assets/img/coins/near.svg';
import pepe from '@app/assets/img/coins/pepe.svg';
import qnt from '@app/assets/img/coins/qnt.svg';
import ren from '@app/assets/img/coins/ren.svg';
import render from '@app/assets/img/coins/render.svg';
import sand from '@app/assets/img/coins/sand.svg';
import shib from '@app/assets/img/coins/shib.svg';
import silver from '@app/assets/img/coins/silver.svg';
import sol from '@app/assets/img/coins/sol.svg';
import spark from '@app/assets/img/coins/spark.svg';
import sushi from '@app/assets/img/coins/sushi.svg';
import ton from '@app/assets/img/coins/ton.svg';
import uni from '@app/assets/img/coins/uni.svg';
import usd from '@app/assets/img/coins/usd.svg';
import wif from '@app/assets/img/coins/wif.svg';
import xlm from '@app/assets/img/coins/xlm.svg';
import xrp from '@app/assets/img/coins/xrp.svg';
import xtz from '@app/assets/img/coins/xtz.svg';
import yfi from '@app/assets/img/coins/yfi.svg';
import { CurrencyType } from '@app/codegen';
import { AppSvg } from '@itrustcapital/ui';
import { Text } from '@ui-kitten/components';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

export interface AppCurrencyIconProps {
	style?: StyleProp<ViewStyle>;
	currency: CurrencyType;
}

export function AppCurrencyIconComponent(props: AppCurrencyIconProps) {
	switch (props.currency) {
		case CurrencyType.BTC:
			return <AppSvg style={props.style} xml={btc} />;
		case CurrencyType.AVAX:
			return <AppSvg style={props.style} xml={avax} />;
		case CurrencyType.AAVE:
			return <AppSvg style={props.style} xml={aave} />;
		case CurrencyType.ALGO:
			return <AppSvg style={props.style} xml={algo} />;
		case CurrencyType.ATOM:
			return <AppSvg style={props.style} xml={atom} />;
		case CurrencyType.ADA:
			return <AppSvg style={props.style} xml={ada} />;
		case CurrencyType.APE:
			return <AppSvg style={props.style} xml={ape} />;
		case CurrencyType.AXS:
			return <AppSvg style={props.style} xml={axs} />;
		case CurrencyType.BAT:
			return <AppSvg style={props.style} xml={bat} />;
		case CurrencyType.CRV:
			return <AppSvg style={props.style} xml={crv} />;
		case CurrencyType.DOGE:
			return <AppSvg style={props.style} xml={doge} />;
		case CurrencyType.SAND:
			return <AppSvg style={props.style} xml={sand} />;
		case CurrencyType.CHZ:
			return <AppSvg style={props.style} xml={chz} />;
		case CurrencyType.COMP:
			return <AppSvg style={props.style} xml={comp} />;
		case CurrencyType.BCH:
			return <AppSvg style={props.style} xml={bch} />;
		case CurrencyType.DOT:
			return <AppSvg style={props.style} xml={dot} />;
		case CurrencyType.ENJ:
			return <AppSvg style={props.style} xml={enj} />;
		case CurrencyType.EOS:
			return <AppSvg style={props.style} xml={eos} />;
		case CurrencyType.ETH:
			return <AppSvg style={props.style} xml={eth} />;
		case CurrencyType.FLOW:
			return <AppSvg style={props.style} xml={flow} />;
		case CurrencyType.GALA:
			return <AppSvg style={props.style} xml={gala} />;
		case CurrencyType.HBAR:
			return <AppSvg style={props.style} xml={hbar} />;
		case CurrencyType.LINK:
			return <AppSvg style={props.style} xml={link} />;
		case CurrencyType.LRC:
			return <AppSvg style={props.style} xml={lrc} />;
		case CurrencyType.LTC:
			return <AppSvg style={props.style} xml={ltc} />;
		case CurrencyType.MANA:
			return <AppSvg style={props.style} xml={mana} />;
		case CurrencyType.MATIC:
			return <AppSvg style={props.style} xml={matic} />;
		case CurrencyType.MKR:
			return <AppSvg style={props.style} xml={mkr} />;
		case CurrencyType.QNT:
			return <AppSvg style={props.style} xml={qnt} />;
		case CurrencyType.REN:
			return <AppSvg style={props.style} xml={ren} />;
		case CurrencyType.SOL:
			return <AppSvg style={props.style} xml={sol} />;
		case CurrencyType.FLR:
			return <AppSvg style={props.style} xml={spark} />;
		case CurrencyType.SHIB:
			return <AppSvg style={props.style} xml={shib} />;
		case CurrencyType.SUSHI:
			return <AppSvg style={props.style} xml={sushi} />;
		case CurrencyType.UNI:
			return <AppSvg style={props.style} xml={uni} />;
		case CurrencyType.XLM:
			return <AppSvg style={props.style} xml={xlm} />;
		case CurrencyType.XRP:
			return <AppSvg style={props.style} xml={xrp} />;
		case CurrencyType.XTZ:
			return <AppSvg style={props.style} xml={xtz} />;
		case CurrencyType.YFI:
			return <AppSvg style={props.style} xml={yfi} />;
		case CurrencyType.KMG:
			return <AppSvg style={props.style} xml={gold} />;
		case CurrencyType.KMS:
			return <AppSvg style={props.style} xml={silver} />;
		case CurrencyType.USD:
			return <AppSvg style={props.style} xml={usd} />;
		case CurrencyType.GRT:
			return <AppSvg style={props.style} xml={grt} />;
		case CurrencyType.FTM:
			return <AppSvg style={props.style} xml={ftm} />;
		case CurrencyType.ARB:
			return <AppSvg style={props.style} xml={arb} />;
		case CurrencyType.BONK:
			return <AppSvg style={props.style} xml={bonk} />;
		case CurrencyType.NEAR:
			return <AppSvg style={props.style} xml={near} />;
		case CurrencyType.RENDER:
			return <AppSvg style={props.style} xml={render} />;
		case CurrencyType.PEPE:
			return <AppSvg style={props.style} xml={pepe} />;
		case CurrencyType.TON:
			return <AppSvg style={props.style} xml={ton} />;
		case CurrencyType.WIF:
			return <AppSvg style={props.style} xml={wif} />;

		default:
			return (
				<View>
					<Text>Not supported yet: </Text>
				</View>
			);
	}
}

export const AppCurrencyIcon = React.memo(AppCurrencyIconComponent, (prev, next) => {
	if (prev.currency !== next.currency) {
		return false;
	}

	return true;
});
