import { EditFundingScreenParams, useMainDrawerNavigation } from '@app/navigations';
import { FundingsInflowParamsRecord } from '@app/navigations/Main/FundingsInflow';
import { EditFundingScreen, FundingsInflowScreen } from '@app/screens';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

export function useFundingsInflowNavigation() {
	const navigation = useMainDrawerNavigation('FundingsInflow');

	return {
		Home: (reset?: boolean) =>
			navigation.Base(
				{
					screen: 'Home',
				},
				reset
			),
		Edit: (params: EditFundingScreenParams) =>
			navigation.Base({
				screen: 'Edit',
				params,
			}),
		FundingsInflowLink: navigation.BaseLink + '/fundings-inflow',
	};
}

const Stack = createStackNavigator<FundingsInflowParamsRecord>();

export function FundingsInflowNavigation() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen component={FundingsInflowScreen} name="Home" />
			<Stack.Screen component={EditFundingScreen} name="Edit" />
		</Stack.Navigator>
	);
}
