import { AppIcon, useAppTheme } from '@itrustcapital/ui';
import { Button, Layout, Text } from '@ui-kitten/components';
import React, { ReactNode } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, TouchableOpacity } from 'react-native';

export interface TopHeaderProps {
	children?: ReactNode;
	title: string;
	subTitle?: string | JSX.Element;
	subTitleColor?: string;
	style?: StyleProp<ViewStyle>;
	createName?: string;
	exportTestID?: string;
	createTestID?: string;
	createDisabled?: boolean;
	exportDisabled?: boolean;
	goBack?: () => void;
	onCreate?: () => void;
	onExport?: () => void;
}

export function TopHeader(props: TopHeaderProps) {
	const styles = useCustomStyles();
	const subtitleColor = props.subTitleColor ? { color: props.subTitleColor } : undefined;

	return (
		<Layout style={[styles.main.container, props.style]}>
			{props.goBack && (
				<TouchableOpacity onPress={props.goBack}>
					<AppIcon lib="en" name="chevron-left" size={16} style={styles.actions.goBack} />
				</TouchableOpacity>
			)}
			<View style={styles.main.row}>
				<Text category="h6" style={styles.main.header}>
					{props.title}
				</Text>
				{props.subTitle && (
					<View style={styles.main.row}>
						<View style={styles.actions.seperator} />
						<Text category="h6" style={[styles.main.subHeader, subtitleColor]}>
							{props.subTitle}
						</Text>
					</View>
				)}
			</View>
			{(props.onExport || props.onCreate) && (
				<View style={styles.actions.container}>
					{props?.onExport && (
						<Button
							appearance="outline"
							disabled={props.exportDisabled}
							size="small"
							status="primary"
							style={styles.actions.button}
							testID={props?.exportTestID}
							onPress={props?.onExport}
						>
							Export to Excel
						</Button>
					)}
					{props?.onCreate && (
						<Button
							appearance="filled"
							disabled={props.createDisabled}
							size="small"
							status="primary"
							style={[styles.actions.button, styles.actions.margin]}
							testID={props?.createTestID}
							onPress={props?.onCreate}
						>
							{`Create New ${props.createName || ''}`}
						</Button>
					)}
				</View>
			)}
			{props.children && <View style={styles.main.children}>{props.children}</View>}
		</Layout>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return {
		main: StyleSheet.create({
			container: {
				width: '100%',
				minHeight: 50,
				alignItems: 'center',
				flexDirection: 'row',
			},
			subHeader: {
				color: theme['color-basic-600'],
				fontWeight: '400',
				marginTop: 2,
			},
			children: {
				flexDirection: 'row',
				flex: 1,
			},
			header: {
				flex: 1,
				paddingLeft: 20,
				marginTop: 2,
				alignSelf: 'center',
			},
			row: {
				flexDirection: 'row',
			},
		}),
		actions: StyleSheet.create({
			container: {
				flex: 1,
				flexDirection: 'row',
				justifyContent: 'flex-end',
				marginRight: 32,
			},
			button: {
				paddingVertical: 8,
				paddingHorizontal: 16,
				width: 'auto',
				maxWidth: 200,
				marginTop: 6,
				marginBottom: 10,
			},
			margin: {
				marginLeft: 16,
			},
			goBack: {
				marginTop: 2,
				paddingHorizontal: 20,
			},
			seperator: {
				marginHorizontal: 24,
				width: 1,
				height: 24,
				backgroundColor: theme['color-basic-600'],
			},
		}),
	};
}
