import { SystemRole } from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import {
	ScreenSizeEnum,
	useAppDevice,
	useAppState,
	useAppAuth,
	useAppTheme,
} from '@itrustcapital/ui';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import { default as appsettings } from 'appsettings.json';
import React from 'react';

import { AccountsNavigation } from './Accounts';
import { AdminNavigation } from './Admin';
import { CustomersNavigation } from './Customers';
import { FundingsInflowNavigation } from './FundingsInflow';
import { FundingsOutflowNavigation } from './FundingsOutflow';
import { MainDrawerContent } from './MainDrawerContent';
import { MainDrawerParamsRecord } from './MainDrawerRoutes';
import { StakingTermsNavigation } from './StakingMovements';
import { useAuthNavigation } from '../Auth/AuthNavigation';

// todo set to required after first time working
export function useMainDrawerNavigation(mainScreen?: string) {
	const navigation = useNavigation();

	return {
		Base: (params: any, reset?: boolean) =>
			reset
				? navigation.reset({
						index: 0,
						key: '',
						routeNames: [],
						routes: [
							{
								// @ts-expect-error
								name: 'MainDrawerNavigation',
								params: mainScreen
									? {
											screen: mainScreen,
											params,
									  }
									: params,
							},
						],
				  })
				: navigation.navigate(
						// @ts-expect-error
						'MainDrawerNavigation',
						mainScreen
							? {
									screen: mainScreen,
									params,
							  }
							: params
				  ),
		BaseLink: `/main`,
	};
}

const Drawer = createDrawerNavigator<MainDrawerParamsRecord>();

export function MainDrawerNavigation() {
	const theme = useAppTheme();
	const appDevice = useAppDevice();
	const isScreenLg = appDevice.width >= ScreenSizeEnum.lg;
	const maestroUserAtom = useMaestroUserAtom();
	const appAuth = useAppAuth(appsettings);
	const authNavigation = useAuthNavigation();
	const drawerType = useAppState<'permanent' | 'front'>(isScreenLg ? 'permanent' : 'front');

	React.useEffect(() => {
		if (appAuth.authState.isAuthenticated) {
			appAuth.setSignOutAction(authNavigation.SignOut);
		}
	}, [appAuth.authState.sub]);

	return (
		<Drawer.Navigator
			backBehavior="history"
			drawerContent={(props) => (
				<MainDrawerContent
					{...props}
					close={() => {
						if (drawerType.get === 'permanent') {
							drawerType.set('front');
						}
					}}
					isScreenLg={isScreenLg}
					open={() => {
						if (drawerType.get === 'front') {
							// need timeout to give drawer enough time to open before drawer type is set to fixed which freezes animation
							setTimeout(() => {
								drawerType.set('permanent');
							}, 1000);
						}
					}}
					role={maestroUserAtom.user?.role! as SystemRole}
				/>
			)}
			screenOptions={{
				drawerType: drawerType.get,
				headerShown: !isScreenLg,
				drawerStyle: {
					backgroundColor: isScreenLg ? 'transparent' : theme['background-basic-color-1'],
					maxWidth: appDevice.isNativeMobile ? 'auto' : 250,
				},
				sceneContainerStyle: {
					backgroundColor: theme['background-basic-color-3'],
				},
			}}
		>
			<Drawer.Screen
				component={AccountsNavigation}
				name="Accounts"
				options={{ title: 'Accounts' }}
			/>
			<Drawer.Screen
				component={CustomersNavigation}
				name="Customers"
				options={{ title: 'Customers' }}
			/>
			<Drawer.Screen
				component={FundingsInflowNavigation}
				name="FundingsInflow"
				options={{ title: 'Fundings Inflow' }}
			/>
			<Drawer.Screen
				component={FundingsOutflowNavigation}
				name="FundingsOutflow"
				options={{ title: 'Fundings Outflow' }}
			/>
			<Drawer.Screen
				component={AdminNavigation}
				name="Admin"
				options={{
					title: 'Admin',
				}}
			/>
			<Drawer.Screen
				component={StakingTermsNavigation}
				name="StakingTerms"
				options={{ title: 'Staking Movements' }}
			/>
		</Drawer.Navigator>
	);
}
