import * as yup from 'yup';

export const emailRegexString: RegExp =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordRegexString: RegExp =
	/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()?<>-])[0-9a-zA-Z].{8,256}$/;

export const phoneRegexString: RegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;

export const creditCardRegexString: RegExp =
	/^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/;

export const upperCaseRegex: RegExp = /[A-Z]/;

export const lowerCaseRegex: RegExp = /[a-z]/;

export const numberRegex: RegExp = /[0-9]/;

export const specialRegex: RegExp = /[!@#$%^&*()?<>-]/;

export const currencyRegex: RegExp = /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;

export const validateDateOfBirth = yup.string().typeError('Invalid name').default('');

export const validateUsername = yup
	.string()
	.typeError('Invalid username')
	.min(6)
	.max(12)
	.default('');

export const validateEmail = yup
	.string()
	.typeError('Invalid email')
	.test('regex', 'Please enter a valid email address', (val) => regexEmail(val))
	.default('');

export const validatePassword = yup
	.string()
	.default('')
	.required('Password is a required field.')
	.min(8, 'Must be at least 8 characters.')
	.max(256, 'Must be at most 256 characters.')
	.test('regex', 'Incorrect password format.', (val) => regexPassword(val));

export const validatePasswordConfirmation = (refName: string) =>
	yup
		.string()
		.oneOf([yup.ref(refName), null], 'Passwords must match')
		.required('Confirmation Password is a required field.')
		.default('');

export const validatePhone = yup
	.string()
	.typeError('Must be a number')
	.test('regex', 'Incorrect phone number', (val) => (val ? regexPhone(val) : true))
	.default('');

export const validateZipcode = yup
	.string()
	.matches(/^\d{5}$/, 'Please enter a valid zip code.')
	.default('');

export const validateCreditCard = yup.string().min(19, 'Please enter valid credit card number');

export const validateCreditCardExpiration = yup
	.string()
	.test('Expiration Date', 'Please enter a valid expiration date', (val) =>
		isValidExpirationDate(val)
	);

export const validateSSN = yup
	.string()
	.min(11, 'Please enter valid social security')
	.max(12)
	.default('');

export const validateTextDate = yup
	.string()
	.test('regex', 'Incorrect Date Format', (val) => (val ? /\d{2}\/\d{2}\/\d{4}/.test(val) : true))
	.default('');

export const regexEmail = (str?: string): boolean => {
	if (!str) return false;

	return emailRegexString.test(str);
};

export const regexPassword = (str?: string): boolean => {
	if (!str) return false;

	return passwordRegexString.test(str);
};

export const regexPhone = (str?: string): boolean => {
	if (!str) return false;

	return phoneRegexString.test(str);
};

export const regexCreditCard = (str?: string): boolean => {
	if (!str) return false;

	return creditCardRegexString.test(str);
};

export const isValidExpirationDate = (str?: string): boolean => {
	if (!str) return false;

	const expirationDate = str.split('/');
	const century = 2000;
	let month = parseInt(expirationDate[0], 10);
	let year = parseInt(expirationDate[1], 10) + century;

	year = month === 12 ? year + 1 : year;
	month = month === 12 ? 1 : month + 1;

	return new Date(year, month + 1) > new Date();
};
