import { ApolloError, gql, useMutation, useQuery } from '@apollo/client';
import {
	UsersScreen_AddCrmUser,
	UsersScreen_AddCrmUserVariables,
	UsersScreen_GetCrmUsers,
	UsersScreen_GetCrmUsersVariables,
	UsersScreen_UpdateCrmUser,
	UsersScreen_UpdateCrmUserVariables,
} from '@app/codegen';

// Get Maestro User
export const USERS_SCREEN_GET_MAESTRO_USERS = gql`
	query UsersScreen_GetCrmUsers(
		$where: CrmUserFilterInput
		$order: [CrmUserSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		crmUsers(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			totalCount
			nodes {
				id
				firstName
				lastName
				email
				role
				status
				createdAt
			}
			pageInfo {
				startCursor
				endCursor
			}
		}
	}
`;

export type UsersScreen_GetMaestroUsersQueryProps = {
	variables?: UsersScreen_GetCrmUsersVariables;
	onCompleted?: (data: UsersScreen_GetCrmUsers) => void;
	onError?: (error: ApolloError) => void;
};

export function useUsersScreen_GetMaestroUsersQuery(props?: UsersScreen_GetMaestroUsersQueryProps) {
	return useQuery<UsersScreen_GetCrmUsers, UsersScreen_GetCrmUsersVariables>(
		USERS_SCREEN_GET_MAESTRO_USERS,
		{
			variables: props?.variables,
			onCompleted: props?.onCompleted,
			onError: props?.onError,
		}
	);
}

// Add Maestro User
export const USERS_SCREEN_ADD_MAESTRO_USER = gql`
	mutation UsersScreen_AddCrmUser($input: AddCrmUserInput!) {
		addCrmUser(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

export type UsersScreen_AddMaestroUserMutationProps = {
	variables?: UsersScreen_AddCrmUserVariables;
	onCompleted?: (data: UsersScreen_AddCrmUser) => void;
	onError?: (error: ApolloError) => void;
};

export function useUsersScreen_AddMaestroUserMutation(
	props?: UsersScreen_AddMaestroUserMutationProps
) {
	return useMutation<UsersScreen_AddCrmUser, UsersScreen_AddCrmUserVariables>(
		USERS_SCREEN_ADD_MAESTRO_USER,
		{
			variables: props?.variables,
			refetchQueries: [USERS_SCREEN_GET_MAESTRO_USERS],
			onCompleted: props?.onCompleted,
			onError: props?.onError,
		}
	);
}

// Update Maestro User
export const USERS_SCREEN_UPDATE_MAESTRO_USER = gql`
	mutation UsersScreen_UpdateCrmUser($input: UpdateCrmUserInput!) {
		updateCrmUser(input: $input) {
			success
			errorMessage
		}
	}
`;

export type UsersScreen_UpdateMaestroUserMutationProps = {
	variables?: UsersScreen_UpdateCrmUserVariables;
	onCompleted?: (data: UsersScreen_UpdateCrmUser) => void;
	onError?: (error: ApolloError) => void;
};

export function useUsersScreen_UpdateMaestroUsersMutation(
	props?: UsersScreen_UpdateMaestroUserMutationProps
) {
	return useMutation<UsersScreen_UpdateCrmUser, UsersScreen_UpdateCrmUserVariables>(
		USERS_SCREEN_UPDATE_MAESTRO_USER,
		{
			variables: props?.variables,
			refetchQueries: [USERS_SCREEN_GET_MAESTRO_USERS],
			onCompleted: props?.onCompleted,
			onError: props?.onError,
		}
	);
}
