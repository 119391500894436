import { gql, useMutation } from '@apollo/client';
import {
	OrganismsNmcAddNote,
	OrganismsNmcAddNoteVariables,
	OrganismsNmcEditNote,
	OrganismsNmcEditNoteVariables,
	OrganismsNcGetNotes_notes_nodes as Note,
} from '@app/codegen';
import { NoteModalComponentLocators } from '@app/e2e/shared/Notes';
import { CustomCard } from '@app/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppModal, useAppDevice } from '@itrustcapital/ui';
import { Button, Input, Spinner, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';
import * as yup from 'yup';

export type NewNoteMode = 'New' | 'Edit' | 'View' | null;

export interface NewNoteProps {
	accountId?: number;
	userId?: number;
	fundingId?: number;
	note: Note | null;
	toggleVisible: () => void;
	onSaved: (success: boolean, mode: NewNoteMode) => void;
	visible: boolean;
	mode?: NewNoteMode;
}

export const ORGANISMS_NMC_EDIT_NOTE = gql`
	mutation OrganismsNmcEditNote($input: UpdateNoteInput!) {
		updateNote(input: $input) {
			success
			errorMessage
		}
	}
`;

export const ORGANISMS_NMC_ADD_NOTE = gql`
	mutation OrganismsNmcAddNote($input: AddNoteInput!) {
		addNote(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

const schema = yup
	.object({
		subject: yup.string().default('').required(),
		text: yup.string().default('').required(),
	})
	.defined();

export type NoteFormData = yup.TypeOf<typeof schema>;

export function NoteModal(props: NewNoteProps) {
	const styles = useCustomStyles();
	const form = useForm<NoteFormData>({
		mode: 'all',
		criteriaMode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const [addNote, addNoteQuery] = useMutation<OrganismsNmcAddNote, OrganismsNmcAddNoteVariables>(
		ORGANISMS_NMC_ADD_NOTE
	);

	const [editNote, editNoteQuery] = useMutation<
		OrganismsNmcEditNote,
		OrganismsNmcEditNoteVariables
	>(ORGANISMS_NMC_EDIT_NOTE);

	React.useEffect(() => {
		if (props.note && props.visible) {
			form.setValue('subject', props.note.subject!);
			form.setValue('text', props.note.text!);
		}
	}, [props.visible, props.note]);

	async function onSubmit(data: NoteFormData): Promise<void> {
		switch (props.mode) {
			case 'New':
				try {
					await addNote({
						variables: {
							input: {
								accountId: props.accountId || null,
								userId: props.userId || null,
								fundingId: props.fundingId || null,
								text: data.text,
								subject: data.subject,
							},
						},
					});
					form.reset();
					props.onSaved(true, 'New');
				} catch (error) {
					props.onSaved(false, null);
				}
				break;
			case 'Edit':
				try {
					await editNote({
						variables: {
							input: {
								id: props.note?.id!,
								text: data.text,
								subject: data.subject,
							},
						},
					});
					form.reset();
					props.onSaved(true, 'Edit');
				} catch (error) {
					props.onSaved(false, null);
				}
				break;
		}
	}

	function onCloseModal(): void {
		form.reset();
		props.toggleVisible();
	}

	return (
		<AppModal visible={props.visible} onBackdropPress={props.toggleVisible}>
			<CustomCard
				hasCloseButton
				footer={() => (
					<View style={styles.actions.container}>
						<Button
							appearance="outline"
							testID={NoteModalComponentLocators.cancelButton}
							onPress={onCloseModal}
						>
							Cancel
						</Button>

						{props.mode !== 'View' && (
							<Button
								disabled={
									!form.formState.isDirty ||
									!form.formState.isValid ||
									addNoteQuery.loading ||
									editNoteQuery.loading
								}
								style={styles.actions.save}
								testID={NoteModalComponentLocators.saveButton}
								onPress={form.handleSubmit(onSubmit)}
							>
								{addNoteQuery.loading || editNoteQuery.loading ? (
									<View>
										<Spinner size="tiny" status="basic" />
									</View>
								) : (
									'Save'
								)}
							</Button>
						)}
					</View>
				)}
				handleClosePress={onCloseModal}
				header={props.mode ? () => <Text category="h6">{props.mode || ''} Note</Text> : ''}
				style={styles.main.container}
			>
				<View style={styles.inputs.row}>
					<Text style={styles.inputs.label}>
						Subject:<Text status="danger">*</Text>
					</Text>
					<Controller
						control={form.control}
						name="subject"
						render={(control) => (
							<Input
								disabled={props.mode === 'View'}
								placeholder=""
								style={styles.inputs.control}
								testID={NoteModalComponentLocators.subjectField}
								value={control.field.value!}
								onBlur={control.field.onBlur}
								onChangeText={control.field.onChange}
							/>
						)}
					/>
				</View>

				<Controller
					control={form.control}
					name="text"
					render={(control) => (
						<Input
							multiline
							disabled={props.mode === 'View'}
							numberOfLines={12}
							placeholder="Add a note"
							testID={NoteModalComponentLocators.noteField}
							value={control.field.value!}
							onBlur={control.field.onBlur}
							onChangeText={control.field.onChange}
						/>
					)}
				/>
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	const appDevice = useAppDevice();

	return {
		main: StyleSheet.create({
			container: {
				minWidth: appDevice.isNativeMobile ? 'auto' : 600,
			},
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		inputs: StyleSheet.create({
			row: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				marginBottom: 8,
			},
			label: {
				flex: 0.3,
			},
			control: {
				flex: 0.7,
			},
		}),
		actions: StyleSheet.create({
			container: {
				justifyContent: 'flex-end',
				flexDirection: 'row',
			},
			save: {
				marginLeft: 8,
			},
		}),
	};
}
