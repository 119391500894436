import { gql, NetworkStatus, OperationVariables, useQuery } from '@apollo/client';
import {
	SharedAtom_GetCrmUserDetails,
	SharedAtom_GetCrmUserDetails_crmUserInfo as CrmUserInfo,
	SystemRole,
} from '@app/codegen';
import { useAppStateRecoil } from '@itrustcapital/ui';
import React from 'react';
import { atom } from 'recoil';

export type MaestroUserState = {
	loading: boolean;
	networkStatus?: NetworkStatus;
	isAdmin?: boolean;
	refetch: (variables?: Partial<OperationVariables> | undefined) => void;
	user?: CrmUserInfo | null;
};

export const MaestroUserAtom = atom<MaestroUserState>({
	key: 'maestroUserAtom',
	default: {
		loading: false,
		isAdmin: false,
		networkStatus: undefined,
		refetch: () => {},
	},
	dangerouslyAllowMutability: false,
});

export const SHARED_ATOM_GET_CRM_USER_DETAILS = gql`
	query SharedAtom_GetCrmUserDetails {
		crmUserInfo {
			id
			firstName
			lastName
			fullName
			email
			status
			role
		}
	}
`;

export const useMaestroUserAtom = (): MaestroUserState => {
	const userAtomState = useAppStateRecoil(MaestroUserAtom);

	const maestroUserQuery = useQuery<SharedAtom_GetCrmUserDetails>(
		SHARED_ATOM_GET_CRM_USER_DETAILS
	);

	React.useEffect(() => {
		userAtomState.set({
			...userAtomState.get,
			loading: maestroUserQuery.loading,
			isAdmin:
				maestroUserQuery.data?.crmUserInfo?.role === SystemRole.ADMIN ||
				maestroUserQuery.data?.crmUserInfo?.role === SystemRole.SUPER_ADMIN ||
				maestroUserQuery.data?.crmUserInfo?.role === SystemRole.ADMIN_GROUP,
			networkStatus: maestroUserQuery.networkStatus,
			user: maestroUserQuery.data?.crmUserInfo,
			refetch: maestroUserQuery.refetch,
		});
	}, [
		maestroUserQuery.loading,
		maestroUserQuery.networkStatus,
		maestroUserQuery.data,
		maestroUserQuery.refetch,
	]);

	return userAtomState.get;
};
