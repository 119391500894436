import {
	AccountStage,
	AccountStatus,
	EditAccountScreenGetAccountDetails_account_user_accounts as UserAccount,
} from '@app/codegen';
import { useMaestroUserAtom } from '@app/core/atoms';
import { EditAccountScreenLocators } from '@app/e2e/screens/EditAccount';
import { EditAccountScreenParams, useAccountsNavigation } from '@app/navigations/Main/Accounts';
import { useCustomersNavigation } from '@app/navigations/Main/Customers';
import {
	AccountAuditHistoryCardOrganism,
	CustomerInformation,
	Documents,
	Emails,
	FundingsCard,
	NavConfigOptions,
	Notes,
	ServicePin,
	StickyNav,
	TopHeader,
} from '@app/shared/components';
import { accountTypeHumanizer, findSectionHelper } from '@app/shared/helpers';
import {
	AppBackButton,
	AppScreen,
	AppSelect,
	humanize,
	ScreenSizeEnum,
	StackNavigation,
	useAppDevice,
	useAppState,
	useNavigationParams,
	useAppTheme,
	AppIcon,
} from '@itrustcapital/ui';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import React from 'react';
import { ScrollView, StyleSheet, TouchableOpacity, View } from 'react-native';

import { AccountBeneficiaries } from './AccountBeneficiaries';
import { AccountInformation } from './AccountInformation';
import { AccountWorkflow } from './AccountWorkflow';
import { useEditAccountScreenAccountDetailsQuery } from './EditAccountScreenGraphql';
import { PortfolioCard } from './Portfolio';
import { StakingCard } from './Staking';
import { Transactions } from './Transactions';

export enum EditAccountSectionLabel {
	CustomerInformation = 'CUSTOMER_INFORMATION',
	AccountInformation = 'ACCOUNT_INFORMATION',
	Documents = 'DOCUMENTS',
	Fundings = 'FUNDINGS',
	Portfolio = 'PORTFOLIO',
	Beneficiaries = 'BENEFICIARIES',
	EmailsAndTickets = 'EMAILS_&_TICKETS',
	ServicePin = 'SERVICE_PIN',
	Notes = 'NOTES',
	Transactions = 'TRANSACTIONS',
	Staking = 'STAKING',
	AccountAuditHistory = 'ACCOUNT_HISTORY',
}

export function EditAccountScreen() {
	const dynamicStyles = useDynamicStyles();
	const theme = useAppTheme();
	const navigation = useNavigation<StackNavigation>();
	const accountsNavigation = useAccountsNavigation();
	const customersNavigation = useCustomersNavigation();
	const navigationParams = useNavigationParams<EditAccountScreenParams>('Edit');
	const selectedSection = useAppState(EditAccountSectionLabel.CustomerInformation);
	const accountId = Number(navigationParams?.accountId!);
	const maestroUserAtom = useMaestroUserAtom();

	const accountDetails = useEditAccountScreenAccountDetailsQuery(accountId);

	const account = accountDetails.data?.account;
	const user = accountDetails.data?.account?.user;

	const NavOptions: {
		[key in EditAccountSectionLabel]: NavConfigOptions<EditAccountSectionLabel>;
	} = {
		[EditAccountSectionLabel.CustomerInformation]: {
			label: EditAccountSectionLabel.CustomerInformation,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'fe',
			iconName: 'info',
		},
		[EditAccountSectionLabel.AccountInformation]: {
			label: EditAccountSectionLabel.AccountInformation,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'fe',
			iconName: 'info',
		},
		[EditAccountSectionLabel.Documents]: {
			label: EditAccountSectionLabel.Documents,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'fa5',
			iconName: 'folder-open',
		},
		[EditAccountSectionLabel.Fundings]: {
			label: EditAccountSectionLabel.Fundings,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'ion',
			iconName: 'ios-cash-outline',
		},
		[EditAccountSectionLabel.Portfolio]: {
			label: EditAccountSectionLabel.Portfolio,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'ant',
			iconName: 'linechart',
		},
		[EditAccountSectionLabel.Beneficiaries]: {
			label: EditAccountSectionLabel.Beneficiaries,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'mat',
			iconName: 'people-outline',
		},
		[EditAccountSectionLabel.EmailsAndTickets]: {
			label: EditAccountSectionLabel.EmailsAndTickets,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'mat',
			iconName: 'mail-outline',
		},
		[EditAccountSectionLabel.ServicePin]: {
			label: EditAccountSectionLabel.ServicePin,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'ion',
			iconName: 'ios-qr-code-outline',
		},
		[EditAccountSectionLabel.Notes]: {
			label: EditAccountSectionLabel.Notes,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'fe',
			iconName: 'file-text',
		},
		[EditAccountSectionLabel.Transactions]: {
			label: EditAccountSectionLabel.Transactions,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'ion',
			iconName: 'md-repeat-sharp',
		},
		[EditAccountSectionLabel.Staking]: {
			label: EditAccountSectionLabel.Staking,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'fe',
			iconName: 'database',
		},
		[EditAccountSectionLabel.AccountAuditHistory]: {
			label: EditAccountSectionLabel.AccountAuditHistory,
			ref: React.useRef<HTMLElement & View>(null),
			iconLib: 'ant',
			iconName: '',
		},
	};

	const handleScroll = React.useCallback(
		findSectionHelper<NavConfigOptions<EditAccountSectionLabel>>(
			Object.values(NavOptions),
			(section) => selectedSection.set(section.label)
		),
		[]
	);

	React.useLayoutEffect(() => {
		navigation.setOptions({
			title: 'Account Details - iTrustCapital',
			headerTitle: 'Account Details',
			headerLeft: () => <AppBackButton supportDrawer />,
			headerRight: () => null,
		});
	}, []);

	useFocusEffect(
		React.useCallback(() => {
			initScrollToCard();

			return () => selectedSection.set(EditAccountSectionLabel.CustomerInformation);
		}, [navigationParams?.accountStage])
	);

	function initScrollToCard() {
		if (!navigationParams?.accountStage) {
			return;
		}

		let label = EditAccountSectionLabel.CustomerInformation;

		switch (navigationParams?.accountStage) {
			case AccountStage.DOCUMENTS_PENDING:
				label = EditAccountSectionLabel.CustomerInformation;
				break;
			case AccountStage.DOCUMENTS_SIGNED:
			case AccountStage.DOCUMENTS_SENT_TO_CLIENT:
				label = EditAccountSectionLabel.Documents;
				break;
		}

		selectedSection.set(label);
		setTimeout(() => {
			// @ts-ignore
			NavOptions[label].ref?.current?.scrollIntoView({
				behavior: 'smooth',
			});
		}, 100);
	}

	function onCustomerNavigation(customerId: number): void {
		if (!customerId) {
			return;
		}

		customersNavigation.Edit({ customerId });
	}

	function onAccountSelect(account: UserAccount): void {
		if (account.id === accountId) {
			return;
		}

		accountsNavigation.Edit({
			accountId: account.id,
		});
	}

	return (
		<AppScreen noMarginBottom noMaxWidth noPadding noScroll style={styles.main.container}>
			<TopHeader
				goBack={navigation.canGoBack() ? navigation.goBack : accountsNavigation.Home}
				subTitle={account?.status && `Status: ${humanize(account?.status!)}`}
				subTitleColor={theme['text-warning-color']}
				title="Create / Edit Account"
			>
				<View style={styles.header.row}>
					<TouchableOpacity
						testID={EditAccountScreenLocators.userNavigationButton}
						onPress={() => onCustomerNavigation(user?.id!)}
					>
						<Text
							appearance="alternative"
							category="h6"
							status="primary"
							style={styles.header.accountName}
						>
							{user?.fullName || ''}
						</Text>
					</TouchableOpacity>

					{user?.isVip && (
						<AppIcon lib="fe" name="star" size={24} style={styles.header.vip} />
					)}

					<AppSelect
						data={user?.accounts as UserAccount[]}
						itemDisplay={(account) =>
							`${accountTypeHumanizer(account.type)} ${
								account.status === AccountStatus.INTERNAL ? '*' : ''
							}`
						}
						style={styles.header.select}
						testID={EditAccountScreenLocators.userAccountsDropdown}
						value={user?.accounts?.filter((data) => data?.id === accountId)[0]} // get current account object from list and set as current value for dropdown
						onSelect={onAccountSelect}
					/>
				</View>
			</TopHeader>

			<View style={[styles.main.subContainer, dynamicStyles.main.subContainer]}>
				<StickyNav data={Object.values(NavOptions)} selectedSection={selectedSection.get} />
				<View style={styles.main.content}>
					<ScrollView scrollEventThrottle={16} onScroll={handleScroll}>
						<AccountWorkflow
							accountId={accountId}
							accountStatus={account?.status!}
							kycStatus={account?.user?.kycStatus!}
							loading={accountDetails.loading}
							stage={account?.stage || AccountStage.NONE}
						/>

						{/* Customer Information */}
						<View
							ref={NavOptions.CUSTOMER_INFORMATION.ref}
							style={styles.card.container}
						>
							<CustomerInformation
								loading={accountDetails.loading}
								userInfo={account?.user}
							/>
						</View>

						{/* Account Information */}
						<View
							ref={NavOptions.ACCOUNT_INFORMATION.ref}
							style={styles.card.container}
						>
							<AccountInformation account={account} />
						</View>

						{/* Documents */}
						<View ref={NavOptions.DOCUMENTS.ref} style={styles.card.container}>
							<Documents accountDetails={account!} userId={account?.userId!} />
						</View>

						{/* Fundings Card */}
						<View ref={NavOptions.FUNDINGS.ref} style={styles.card.container}>
							<FundingsCard accountDetails={account!} accountIds={[accountId]} />
						</View>

						{/* Portfolio */}
						<View ref={NavOptions.PORTFOLIO.ref} style={styles.card.container}>
							<PortfolioCard accountId={account?.id!} />
						</View>

						{/* Account Beneficiaries */}
						<View ref={NavOptions.BENEFICIARIES.ref} style={styles.card.container}>
							<AccountBeneficiaries accountId={accountId} userId={account?.userId!} />
						</View>

						{/* Emails */}
						<View
							ref={NavOptions[EditAccountSectionLabel.EmailsAndTickets].ref}
							style={styles.card.container}
						>
							<Emails accountDetails={account} userDetails={account?.user} />
						</View>

						{/* Generate Service Pin */}
						<View ref={NavOptions.SERVICE_PIN.ref} style={styles.card.container}>
							<ServicePin userId={account?.userId} />
						</View>

						{/* Notes */}
						<View ref={NavOptions.NOTES.ref} style={styles.card.container}>
							<Notes accountId={accountId} userId={account?.userId} />
						</View>

						{/* Transactions */}
						<View ref={NavOptions.TRANSACTIONS.ref} style={styles.card.container}>
							<Transactions accountId={accountId} />
						</View>

						{/* Staking */}
						<View ref={NavOptions.STAKING.ref} style={styles.card.container}>
							<StakingCard accountId={accountId} />
						</View>

						{maestroUserAtom?.isAdmin && (
							<View
								ref={NavOptions.ACCOUNT_HISTORY.ref}
								style={styles.main.container}
							>
								<AccountAuditHistoryCardOrganism accountId={accountId} />
							</View>
						)}
					</ScrollView>
				</View>
			</View>
		</AppScreen>
	);
}

const styles = {
	main: StyleSheet.create({
		container: {
			flexDirection: 'column',
		},
		subContainer: {
			padding: 32,
			flex: 1,
		},
		content: {
			flex: 1,
			marginLeft: 16,
		},
	}),
	header: StyleSheet.create({
		row: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
			alignItems: 'center',
			marginRight: 40,
			flex: 1,
		},
		accountName: {
			marginHorizontal: 16,
			fontWeight: '400',
		},
		select: {
			minWidth: 200,
		},
		vip: {
			marginRight: 12,
		},
	}),
	card: StyleSheet.create({
		container: {
			marginBottom: 8,
		},
	}),
};

function useDynamicStyles() {
	const appDevice = useAppDevice();

	const isScreenMd = appDevice.width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			subContainer: {
				flexDirection: isScreenMd ? 'row' : 'column',
			},
		}),
	};
}
