import { BottomTabNavigationOptions } from '@react-navigation/bottom-tabs';
import {
	CommonActions,
	NavigationProp,
	ParamListBase,
	useNavigation,
	useNavigationState,
} from '@react-navigation/native';
import { StackNavigationOptions } from '@react-navigation/stack';
import React from 'react';

type SetOptions = { setOptions: (options: StackNavigationOptions) => void };

export type StackNavigation = NavigationProp<ParamListBase> & SetOptions;

type TabSetOptions = { setOptions: (options: BottomTabNavigationOptions) => void };

export type TabNavigation = TabSetOptions & NavigationProp<ParamListBase>;

export const insertBeforeLast: any = (routeName: any, params: any) => (state: any) => {
	const routes = [
		...state.routes.slice(0, -1),
		{ name: routeName, params },
		state.routes[state.routes.length - 1],
	];

	return CommonActions.reset({
		...state,
		routes,
		index: routes.length - 1,
	});
};

export function useInsertScreenBeforeLatest(screenName: string) {
	const navigationState = useNavigationState((x) => x);
	const navigation = useNavigation();
	React.useEffect(() => {
		insertBeforeLastName(navigationState, navigation, screenName);
	}, []);
}

export function insertBeforeLastName(navigationState: any, navigation: any, routeName: string) {
	if (navigationState.routes.findIndex((x: any) => x.name === routeName) === -1) {
		navigation.dispatch(insertBeforeLast(routeName));
	}
}

export function useNavigationParams<TParamType>(screenName: string): TParamType | undefined {
	return useNavigationState(
		(x) => x.routes.find((f) => f.name === screenName)?.params as TParamType | undefined
	);
}
