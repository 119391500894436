import { AppIcon, useAppTheme } from '@itrustcapital/ui';
import { Spinner, Text } from '@ui-kitten/components';
import React, { ReactNode } from 'react';
import { View, StyleSheet, StyleProp, ViewStyle, TouchableOpacity } from 'react-native';
import { Card } from 'react-native-paper';

export interface CustomCardProps {
	testID?: string;
	style?: StyleProp<ViewStyle>;
	children: ReactNode;
	header?: string | (() => ReactNode);
	loading?: boolean;
	processing?: boolean;
	footer?: () => ReactNode;
	hasCloseButton?: boolean;
	handleClosePress?: () => void;
}

export function CustomCard(props: CustomCardProps) {
	const styles = useCustomStyles();

	return (
		<Card style={[styles.main, props.style]} testID={props.testID}>
			{props.header && (
				<View style={[styles.header, props.hasCloseButton && styles.row]}>
					{typeof props.header === 'string' ? (
						<Text category="h6">{props.header}</Text>
					) : (
						props.header()
					)}
					{props.hasCloseButton ? (
						<TouchableOpacity
							disabled={props.processing}
							style={styles.closeButton}
							testID={props.testID ? `${props.testID}CloseButton` : 'closeButton'}
							onPress={props.handleClosePress}
						>
							<AppIcon lib="fe" name="x" size={24} />
						</TouchableOpacity>
					) : undefined}
				</View>
			)}

			<View style={styles.content}>
				{props.loading && (
					<View style={styles.loadingOverlay}>
						<Spinner />
					</View>
				)}
				{props.children}
			</View>

			{props.footer && <View style={styles.footer}>{props.footer()}</View>}
		</Card>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return StyleSheet.create({
		row: {
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		main: {
			paddingVertical: 24,
			paddingHorizontal: 6,
		},
		header: {
			borderBottomWidth: 1,
			borderBottomColor: theme['border-basic-color-3'],
			paddingBottom: 16,
			paddingHorizontal: 16,
		},
		closeButton: {
			marginLeft: 16,
		},
		content: {
			paddingHorizontal: 16,
			paddingTop: 12,
		},
		loadingOverlay: {
			height: '100%',
			width: '95%',
			flex: 1,
			zIndex: 4,
			position: 'absolute',
			backgroundColor: theme['color-basic-control-transparent-100'],
			alignItems: 'center',
			justifyContent: 'center',
		},
		footer: {
			borderTopWidth: 1,
			borderTopColor: theme['border-basic-color-3'],
			paddingTop: 16,
			paddingHorizontal: 16,
			marginTop: 16,
		},
	});
}
