import { UserStatus } from '@app/codegen';
import { CustomerScreenLocators } from '@app/e2e/screens/Customers';
import { Accordion } from '@app/shared/components';
import {
	AppIcon,
	AppSelector,
	humanize,
	datePickerMinDate,
	datePickerMaxDate,
	datePickerService,
	dateTimePlaceholder,
	ScreenSizeEnum,
	useAppDevice,
	useAppTheme,
} from '@itrustcapital/ui';
import { Button, Datepicker, Input, Text } from '@ui-kitten/components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { TouchableOpacity, View, StyleSheet } from 'react-native';

import { useCustomersScreenContext } from '../CustomersScreenProvider';

export interface CustomersFilterFormProps {}

export function CustomersFilterForm(props: CustomersFilterFormProps) {
	const styles = useCustomStyles();
	const context = useCustomersScreenContext();
	const {
		formState: { errors },
		reset,
		clearErrors,
		handleSubmit,
	} = context.filterCustomersForm;

	function formatPhone(valueToFormat: string) {
		const formattedValue = valueToFormat.replace(/[^0-9]/g, '');

		const newestCharacter = formattedValue.slice(-1);

		if (!/^\d+$/.test(newestCharacter)) {
			return valueToFormat.substring(0, valueToFormat.length - 1);
		}

		if (formattedValue.length > 10 || formattedValue.length <= 3) {
			return formattedValue;
		}

		if (formattedValue.length > 3 && formattedValue.length <= 6) {
			return (
				formattedValue.slice(0, 3) + '-' + formattedValue.slice(3, formattedValue.length)
			);
		}

		return (
			formattedValue.slice(0, 3) +
			'-' +
			formattedValue.slice(3, 6) +
			'-' +
			formattedValue.slice(6, formattedValue.length)
		);
	}

	const onRefreshTable = () => {
		reset();
		clearErrors();
		context.tableState.where.set(undefined);
		context.pauseQuery.set(false);
	};

	return (
		<>
			<View style={styles.main.searchRow}>
				<Controller
					control={context.filterCustomersForm.control}
					defaultValue={''}
					name="search"
					render={(control) => (
						<Input
							accessoryRight={() => (
								<TouchableOpacity
									testID={CustomerScreenLocators.customerSearchIconButton}
									onPress={handleSubmit(context.onSubmit)}
								>
									<AppIcon lib="fa5" name="search" size={18} />
								</TouchableOpacity>
							)}
							placeholder="Search for customers by ID, name, email, phone, SSN, or Fortress identity ID"
							style={styles.main.inputHalves}
							testID={CustomerScreenLocators.customersSearchField}
							value={control.field.value}
							onBlur={control.field.onBlur}
							onChangeText={control.field.onChange}
							onSubmitEditing={handleSubmit(context.onSubmit)}
						/>
					)}
				/>

				<TouchableOpacity
					testID={CustomerScreenLocators.customerRefreshIcon}
					style={styles.main.inputHalves}
					onPress={() => onRefreshTable()}
				>
					<AppIcon lib="fe" name="refresh-ccw" size={20} style={styles.main.refresh} />
				</TouchableOpacity>

				<Controller
					control={context.filterCustomersForm.control}
					name="status"
					render={(control) => (
						<View style={styles.main.inputHalves}>
							<AppSelector
								closeOnSelect
								data={[
									'ALL',
									UserStatus.ACTIVE,
									UserStatus.DISABLED,
									UserStatus.LEAD,
									UserStatus.AUTH_VERIFY,
									UserStatus.EMAIL_VERIFY,
								]}
								itemDisplay={humanize}
								value={control.field.value}
								onSelect={(value) => {
									control.field.onChange(value || UserStatus.ACTIVE);
									context.filterCustomersForm.handleSubmit(context.onSubmit)();
								}}
							/>
						</View>
					)}
				/>
			</View>
			<>
				{errors.search && (
					<Text style={styles.main.errorText}>{errors.search?.message}</Text>
				)}
			</>

			<Accordion
				label="advanced filters"
				triggerContainerStyle={styles.advanced.triggerContainer}
			>
				<View style={styles.advanced.inputRow}>
					<Controller
						control={context.filterCustomersForm.control}
						defaultValue={''}
						name="mobilePhone"
						render={(control) => (
							<Input
								label="Mobile Phone Number"
								style={styles.advanced.control}
								maxLength={12}
								testID={CustomerScreenLocators.customersMobilePhoneNumberField}
								value={control.field.value!}
								placeholder="123-456-7890"
								onBlur={control.field.onBlur}
								onChangeText={(value) => {
									control.field.onChange(formatPhone(value));
								}}
							/>
						)}
					/>
				</View>
				<View style={styles.advanced.inputRow}>
					<Controller
						control={context.filterCustomersForm.control}
						name="createdFrom"
						render={(control) => (
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Created From"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								status={control.fieldState.error && 'danger'}
								style={styles.advanced.control}
								testID={CustomerScreenLocators.customersCreatedFromField}
								onBlur={control.field.onBlur}
								onSelect={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={context.filterCustomersForm.control}
						name="createdTo"
						render={(control) => (
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Created To"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								status={control.fieldState.error && 'danger'}
								style={styles.advanced.control}
								testID={CustomerScreenLocators.customersCreatedToField}
								onBlur={control.field.onBlur}
								onSelect={control.field.onChange}
							/>
						)}
					/>
				</View>
				<View style={styles.advanced.inputRow}>
					<Controller
						control={context.filterCustomersForm.control}
						name="fundedDateFrom"
						render={(control) => (
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Funded Date From"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								style={styles.advanced.control}
								testID={CustomerScreenLocators.customersFundingPendingFromField}
								onSelect={control.field.onChange}
							/>
						)}
					/>
					<Controller
						control={context.filterCustomersForm.control}
						name="fundedDateTo"
						render={(control) => (
							<Datepicker
								date={control.field.value}
								dateService={datePickerService}
								label="Funded Date To"
								max={datePickerMaxDate}
								min={datePickerMinDate}
								placeholder={dateTimePlaceholder}
								style={styles.advanced.control}
								testID={CustomerScreenLocators.customersFundingPendingToField}
								onBlur={control.field.onBlur}
								onSelect={control.field.onChange}
							/>
						)}
					/>
				</View>
				<View style={[styles.main.row, styles.main.buttons]}>
					<Button
						style={styles.advanced.apply}
						testID={CustomerScreenLocators.customersApplyButton}
						onPress={() => {
							clearErrors();
							handleSubmit(context.onSubmit);
						}}
					>
						Apply Filters
					</Button>
					<Button
						appearance="outline"
						testID={CustomerScreenLocators.customersClearButton}
						onPress={() => onRefreshTable()}
					>
						Clear Filters
					</Button>
				</View>
			</Accordion>
		</>
	);
}

function useCustomStyles() {
	const { width } = useAppDevice();
	const theme = useAppTheme();

	const isScreenMd = width >= ScreenSizeEnum.md;

	return {
		main: StyleSheet.create({
			buttons: {
				paddingVertical: 4,
			},
			row: {
				flexDirection: 'row',
			},
			control: {
				width: isScreenMd ? '35%' : 'auto',
				marginRight: isScreenMd ? 8 : 0,
				paddingVertical: 8,
			},
			selector: {
				width: 'auto',
			},
			success: {
				width: 250,
				marginLeft: 'auto',
			},
			inputHalves: {
				flex: 0.49,
			},
			searchRow: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'space-between' : 'center',
				alignItems: isScreenMd ? 'center' : 'baseline',
			},
			search: {
				flex: 1,
			},
			errorText: {
				color: theme['color-danger-500'],
				fontSize: 12,
				marginTop: 4,
				fontFamily:
					'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
			},
			refresh: {
				color: theme['color-success-500'],
				fontWeight: '700',
				marginLeft: 12,
			},
		}),

		advanced: StyleSheet.create({
			apply: {
				marginRight: 12,
			},
			control: {
				width: isScreenMd ? '35%' : 'auto',
				maxWidth: 330,
				marginRight: isScreenMd ? 8 : 0,
				paddingVertical: 8,
			},
			inputRow: {
				flexDirection: isScreenMd ? 'row' : 'column',
				justifyContent: isScreenMd ? 'flex-start' : 'center',
			},
			triggerContainer: {
				flexDirection: 'row',
				justifyContent: 'flex-start',
				marginVertical: 8,
			},
		}),
	};
}
