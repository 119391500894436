import { AppIcon, useAppTheme } from '@itrustcapital/ui';
import { Text } from '@ui-kitten/components';
import React from 'react';
import { DeepMap, FieldErrors } from 'react-hook-form';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

export type InputErrorProps = {
	errors: DeepMap<any, FieldErrors> | undefined;
	style?: StyleProp<ViewStyle>;
};

export function InputError(props: InputErrorProps) {
	const styles = useCustomStyles();

	return (
		<View style={[styles.container, props.style]}>
			{Object.values<string>(props.errors?.types || [])?.map((error) => (
				<View key={error} style={styles.errorContainer}>
					<AppIcon lib="fe" name="x-circle" style={styles.icon} />
					<Text category="c2" style={styles.error} testID={error}>
						{error}
					</Text>
				</View>
			))}
		</View>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();

	return StyleSheet.create({
		container: {
			flexDirection: 'column',
			lineHeight: 16,
		},
		errorContainer: {
			flexDirection: 'row',
			alignItems: 'center',
		},
		error: {
			color: theme['color-danger-600'],
		},
		icon: {
			color: theme['color-danger-600'],
			marginRight: 11,
		},
	});
}
