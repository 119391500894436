import { gql, useLazyQuery } from '@apollo/client';
import {
	AuditLogsCardOrganism_GetAccountHistories,
	AuditLogsCardOrganism_GetAccountHistories_accountHistories_data as AccountAuditData,
	AuditLogsCardOrganism_GetAccountHistoriesVariables,
} from '@app/codegen';
import { CustomCard, CustomDataTable, ITrustColumn } from '@app/shared/components';
import { localDateTimeFormat } from '@itrustcapital/ui';
import { Button, Input, Text } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';

interface AccountAuditHistoryCardOrganismProps {
	accountId?: number;
}

const columns: ITrustColumn<AccountAuditData>[] = [
	{
		key: 'account',
		name: 'Modified By Role',
		maxWidth: 130,
		selector: (row) => row?.account?.modifiedByRole ?? '',
	},
	{
		key: 'account',
		name: 'Modified By User ID',
		maxWidth: 130,
		selector: (row) => row?.account?.modifiedByUserId ?? '',
	},
	{
		key: 'validFrom',
		name: 'Valid From',
		minWidth: 200,
		sortable: false,
		selector: (row) => localDateTimeFormat(row.validFrom),
	},
	{
		key: 'validTo',
		name: 'Valid To',
		minWidth: 200,
		sortable: false,
		selector: (row) =>
			row?.validTo === '9999-12-31T23:59:59.999Z'
				? 'Current'
				: localDateTimeFormat(row.validTo),
	},
	{
		key: 'account',
		name: 'Account Number',
		maxWidth: 150,
		sortable: true,
		selector: (row) => row?.account?.accountNumber ?? '',
	},
	{
		key: 'account',
		name: 'Name',
		minWidth: 200,
		sortable: false,
		selector: (row) => row?.account?.displayName ?? '',
	},
	{
		key: 'account',
		name: 'Type',
		maxWidth: 150,
		sortable: true,
		selector: (row) => row?.account?.type ?? '',
	},
	{
		key: 'account',
		name: 'Status',
		maxWidth: 150,
		sortable: true,
		selector: (row) => row?.account?.status ?? '',
	},
	{
		key: 'account',
		name: 'Stage',
		maxWidth: 150,
		sortable: true,
		selector: (row) => row?.account?.stage ?? '',
	},
];

export const AUDIT_LOGS_SCREEN_GET_ACCOUNT_HISTORIES = gql`
	query AuditLogsCardOrganism_GetAccountHistories($input: GetAccountHistoriesInput) {
		accountHistories(input: $input) {
			success
			errorMessage
			data {
				account {
					modifiedByRole
					modifiedByUserId
					accountNumber
					displayName
					type
					status
					stage
				}
				validFrom
				validTo
			}
		}
	}
`;

export const AccountAuditHistoryCardOrganism = (props: AccountAuditHistoryCardOrganismProps) => {
	const [accountIdSearchText, setAccountIdSearchText] = React.useState('');

	const [getAccountAudit, getAccountAuditQuery] = useLazyQuery<
		AuditLogsCardOrganism_GetAccountHistories,
		AuditLogsCardOrganism_GetAccountHistoriesVariables
	>(AUDIT_LOGS_SCREEN_GET_ACCOUNT_HISTORIES);

	React.useEffect(() => {
		if (props?.accountId) {
			getAccountAudit({
				variables: {
					input: {
						accountId: props.accountId,
					},
				},
			});
		}
	}, [props?.accountId]);

	return (
		<CustomCard style={styles.main.container}>
			<Text category="h3">Account Audit History</Text>

			{!props?.accountId && (
				<View style={styles.main.searchContainer}>
					<Input
						value={accountIdSearchText}
						placeholder="Enter Account ID"
						onChangeText={(text) => {
							setAccountIdSearchText(text);
						}}
					/>

					<Button
						style={styles.main.searchBtn}
						size="small"
						status="primary"
						onPress={() => {
							if (+accountIdSearchText) {
								getAccountAudit({
									variables: {
										input: {
											accountId: +accountIdSearchText,
										},
									},
								});
							} else {
								setAccountIdSearchText('');
							}
						}}
					>
						Search
					</Button>
				</View>
			)}

			<CustomDataTable
				inModal
				columns={columns}
				data={getAccountAuditQuery?.data?.accountHistories?.data as AccountAuditData[]}
				progressPending={getAccountAuditQuery.loading}
				refetch={(variables) => {
					getAccountAuditQuery?.refetch?.({
						...variables,
					});
				}}
			/>
		</CustomCard>
	);
};

const styles = {
	main: StyleSheet.create({
		container: {
			minWidth: 600,
			marginTop: 16,
		},
		searchContainer: {
			flexDirection: 'row',
			marginVertical: 8,
		},
		searchBtn: {
			marginLeft: 8,
		},
	}),
};
