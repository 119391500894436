import {
	AppIcon,
	AppIconType,
	humanize,
	ScreenSizeEnum,
	useAppDevice,
	useAppState,
	useAppTheme,
} from '@itrustcapital/ui';
import { Button, Text } from '@ui-kitten/components';
import React, { RefObject } from 'react';
import { View, StyleSheet } from 'react-native';

export interface StickyNavProps<T> {
	data: NavConfigOptions<T>[];
	selectedSection: T;
}

export interface NavConfigOptions<T> {
	label: T;
	defaultLabelValue?: boolean;
	iconLib: AppIconType;
	iconName: string;
	iconSize?: number;
	ref: RefObject<HTMLElement & View>;
}

export function StickyNav<T extends string>(props: StickyNavProps<T>) {
	const styles = useCustomStyles();
	const appDevice = useAppDevice();
	const showContent = useAppState(false);
	const isScreenMd = appDevice.width >= ScreenSizeEnum.md;
	const selectedSection = useAppState(props.selectedSection);

	React.useEffect(() => {
		selectedSection.set(props.selectedSection);
	}, [props.selectedSection]);

	function renderSections() {
		return props.data.map((section) => (
			<Button
				accessoryLeft={(accLeftProps) => (
					<View style={{ width: section.iconSize || 20 }}>
						<AppIcon
							color={(accLeftProps?.style as any).tintColor}
							lib={section.iconLib}
							name={section.iconName}
							size={section.iconSize || 20}
						/>
					</View>
				)}
				key={section.label}
				status={selectedSection.get === section.label ? 'primary' : 'basic'}
				style={styles.nav.item}
				onPress={() => {
					showContent.set(false);
					selectedSection.set(section.label);
					section.ref.current?.scrollIntoView?.({
						behavior: 'smooth',
					});
				}}
			>
				<Text
					category="s2"
					style={selectedSection.get === section.label && styles.nav.selectedText}
				>
					{humanize(section.label, section.defaultLabelValue)}
				</Text>
			</Button>
		));
	}

	return isScreenMd ? (
		<View style={styles.nav.container}>{renderSections()}</View>
	) : (
		<View>
			<Button
				appearance="outline"
				style={styles.nav.selectedSection}
				onPress={() => showContent.set(!showContent.get)}
			>
				{selectedSection.get}
			</Button>

			{showContent.get && <View style={styles.nav.container}>{renderSections()}</View>}
		</View>
	);
}

function useCustomStyles() {
	const theme = useAppTheme();
	const appDevice = useAppDevice();

	const isScreenMd = appDevice.width >= ScreenSizeEnum.md;

	return {
		nav: StyleSheet.create({
			container: {
				flexDirection: 'column',
				marginRight: isScreenMd ? 12 : 0,
				marginTop: isScreenMd ? 0 : 8,
			},
			selectedText: {
				color: theme['text-alternate-color'],
			},
			item: {
				borderRadius: 0,
				flexDirection: 'row',
				justifyContent: 'flex-start',
				paddingLeft: 18,
			},
			selectedSection: {
				borderRadius: 4,
			},
		}),
	};
}
