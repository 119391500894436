import { gql } from '@apollo/client';

export const STAKING_TERMS_SCREEN_GET_STAKING_TERMS = gql`
	query StakingTermsScreen_GetStakingTerms(
		$where: StakingTermFilterInput
		$order: [StakingTermSortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		stakingTerms(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			pageInfo {
				hasNextPage
				hasPreviousPage
				startCursor
				endCursor
			}
			nodes {
				id
				startAt
				endAt
				currencyType
				apy
				durationDays
				totalPrinciple
			}
			totalCount
		}
	}
`;
