import { gql, useLazyQuery, useMutation } from '@apollo/client';
import {
	EcsEcbUpdateBeneficiary,
	EcsEcbUpdateBeneficiaryVariables,
	EcsEcbGetBeneficiary,
	EcsEcbGetBeneficiaryVariables,
	EcsEcbGetBeneficiary_beneficiary as Beneficiary,
	BeneficiaryRelationshipType,
} from '@app/codegen';
import { EditCustomerBeneficiaryLocators } from '@app/e2e/screens/EditCustomer';
import { CustomCard } from '@app/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AppModal, AppSelector, maskPhoneNumber, validatePhone } from '@itrustcapital/ui';
import { Button, Input, Spinner } from '@ui-kitten/components';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet } from 'react-native';
import * as yup from 'yup';

export interface EditCustomerBeneficiaryProps {
	visible: boolean;
	toggleVisible: () => void;
	beneficiaryId: number;
	onSave: (success: boolean) => void;
}

export const schema = yup
	.object({
		email: yup.string().email().default(''), // validateEmail hard to validate empty string
		firstName: yup.string().default('').required(),
		lastName: yup.string().default('').required(),
		street: yup.string().default(''),
		city: yup.string().default(''),
		state: yup.string().default(''),
		phoneNumber: validatePhone.required('Phone is required'),
		zipCode: yup.string().default(''),
		isSpouse: yup.boolean().default(false).required(),
	})
	.defined();

export type EditBeneficiaryForm = yup.TypeOf<typeof schema>;

export const ECS_ECB_GET_BENEFICIARY = gql`
	query EcsEcbGetBeneficiary($where: BeneficiaryFilterInput) {
		beneficiary(where: $where) {
			id
			firstName
			lastName
			email
			addresses {
				address
				city
				state
				zipCode
			}
			phoneNumber
			relationship
		}
	}
`;

export const ECS_ECB_UPDATE_BENEFICIARY = gql`
	mutation EcsEcbUpdateBeneficiary($input: UpdateBeneficiaryInput!) {
		updateBeneficiary(input: $input) {
			success
			errorMessage
		}
	}
`;

export function EditCustomerBeneficiary(props: EditCustomerBeneficiaryProps) {
	const styles = useCustomStyles();
	const editForm = useForm<EditBeneficiaryForm>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const [onGetBeneficary, getBeneficiaryQuery] = useLazyQuery<
		EcsEcbGetBeneficiary,
		EcsEcbGetBeneficiaryVariables
	>(ECS_ECB_GET_BENEFICIARY);

	const [onUpdateBeneficiary, updateBeneficiaryQuery] = useMutation<
		EcsEcbUpdateBeneficiary,
		EcsEcbUpdateBeneficiaryVariables
	>(ECS_ECB_UPDATE_BENEFICIARY);

	React.useEffect(() => {
		if (props.visible && props.beneficiaryId) {
			onGetBeneficary({
				variables: {
					where: {
						id: {
							eq: props.beneficiaryId,
						},
					},
				},
			});
		}
	}, [props.visible, props.beneficiaryId]);

	React.useEffect(() => {
		if (getBeneficiaryQuery.data && getBeneficiaryQuery.data) {
			initForm(getBeneficiaryQuery.data.beneficiary!);
		}
	}, [getBeneficiaryQuery.data]);

	function initForm(beneficiary: Beneficiary): void {
		editForm.setValue('firstName', beneficiary.firstName || '');
		editForm.setValue('lastName', beneficiary.lastName || '');
		editForm.setValue('email', beneficiary.email || '');

		if (beneficiary.addresses) {
			const beneficaryAddress = beneficiary.addresses[0];
			editForm.setValue('street', beneficaryAddress?.address || '');
			editForm.setValue('state', beneficaryAddress?.state || '');
			editForm.setValue('city', beneficaryAddress?.city || '');
			editForm.setValue('zipCode', beneficaryAddress?.zipCode || '');
		}

		editForm.setValue('phoneNumber', maskPhoneNumber(beneficiary.phoneNumber));
		editForm.setValue(
			'isSpouse',
			beneficiary.relationship === BeneficiaryRelationshipType.SPOUSE
		);
		editForm.trigger();
	}

	async function onSubmit(data: EditBeneficiaryForm): Promise<void> {
		try {
			await onUpdateBeneficiary({
				variables: {
					input: {
						id: props.beneficiaryId,
						firstName: data.firstName,
						lastName: data.lastName,
						email: data.email?.length > 0 ? data.email : undefined,
						phoneNumber: data.phoneNumber.replaceAll('-', ''),
						relationship: data.isSpouse
							? BeneficiaryRelationshipType.SPOUSE
							: BeneficiaryRelationshipType.NONE,
						addresses: [
							{
								address: data.street,
								city: data.city,
								state: data.state,
								zipCode: data.zipCode,
							},
						],
					},
				},
			});

			props.onSave(true);
		} catch (err) {
			props.onSave(false);
		}
	}

	return (
		<AppModal visible={!!(props.visible && props.beneficiaryId)}>
			<CustomCard
				hasCloseButton
				handleClosePress={props.toggleVisible}
				header="Edit Customer Beneficiary"
				loading={updateBeneficiaryQuery.loading || getBeneficiaryQuery.loading}
				testID={EditCustomerBeneficiaryLocators.editCustomerBeneficiary}
			>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="firstName"
							render={(control) => (
								<Input
									label="First name *"
									status={control.fieldState.error && 'danger'}
									testID={
										EditCustomerBeneficiaryLocators.editCustomerBeneficiaryFirstNameField
									}
									textContentType="givenName"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="lastName"
							render={(control) => (
								<Input
									label="Last name *"
									status={control.fieldState.error && 'danger'}
									testID={
										EditCustomerBeneficiaryLocators.editCustomerBeneficiaryLastNameField
									}
									textContentType="familyName"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				</View>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="street"
							render={(control) => (
								<Input
									label="Street"
									status={control.fieldState.error && 'danger'}
									testID={
										EditCustomerBeneficiaryLocators.editCustomerBeneficiaryStreetField
									}
									textContentType="streetAddressLine1"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="city"
							render={(control) => (
								<Input
									label="City"
									status={control.fieldState.error && 'danger'}
									testID={
										EditCustomerBeneficiaryLocators.editCustomerBeneficiaryCityField
									}
									textContentType="addressCity"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="state"
							render={(control) => (
								<Input
									label="State"
									status={control.fieldState.error && 'danger'}
									testID={
										EditCustomerBeneficiaryLocators.editCustomerBeneficiaryStateField
									}
									textContentType="addressState"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="zipCode"
							render={(control) => (
								<Input
									label="Zip code"
									status={control.fieldState.error && 'danger'}
									testID={
										EditCustomerBeneficiaryLocators.editCustomerBeneficiaryZipcodeField
									}
									textContentType="postalCode"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				</View>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="email"
							render={(control) => (
								<Input
									label="Email"
									placeholder="test@example.com"
									status={control.fieldState.error && 'danger'}
									testID={
										EditCustomerBeneficiaryLocators.editCustomerBeneficiaryEmailField
									}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="phoneNumber"
							render={(control) => (
								<Input
									label="Primary phone *"
									status={control.fieldState.error && 'danger'}
									testID={
										EditCustomerBeneficiaryLocators.editCustomerBeneficiaryPrimaryPhoneField
									}
									textContentType="telephoneNumber"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChange={(e) => {
										const formatted = maskPhoneNumber(e);
										control.field.onChange(formatted);
									}}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={editForm.control}
							name="isSpouse"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={['Yes', 'No']}
									label="Spouse"
									status={control.fieldState.error && 'danger'}
									testID={
										EditCustomerBeneficiaryLocators.editCustomerBeneficiarySpouseField
									}
									value={control.field.value ? 'Yes' : 'No'}
									onSelect={(value) => control.field.onChange(value === 'Yes')}
								/>
							)}
						/>
					</View>
				</View>
				<View style={styles.actions.container}>
					<Button
						appearance="outline"
						style={styles.actions.cancel}
						testID={EditCustomerBeneficiaryLocators.editCustomerBeneficiaryCancelButton}
						onPress={props.toggleVisible}
					>
						Cancel
					</Button>
					<Button
						disabled={!editForm.formState.isDirty || !editForm.formState.isValid}
						style={styles.actions.save}
						testID={EditCustomerBeneficiaryLocators.editCustomerBeneficiarySaveButton}
						onPress={
							updateBeneficiaryQuery.loading
								? undefined
								: editForm.handleSubmit(onSubmit)
						}
					>
						{updateBeneficiaryQuery.loading ? (
							<View>
								<Spinner size="tiny" status="basic" />
							</View>
						) : (
							'Save'
						)}
					</Button>
				</View>
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		form: StyleSheet.create({
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginVertical: 8,
			},
			col: {
				flex: 1,
				marginHorizontal: 8,
			},
		}),
		actions: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				marginTop: 8,
			},
			cancel: {
				marginRight: 8,
			},
			save: {
				minWidth: 120,
			},
		}),
	};
}
