import { gql, useMutation } from '@apollo/client';
import {
	BeneficiaryRelationshipType,
	EcsCbAddCustomerBeneficiary,
	EcsCbAddCustomerBeneficiaryVariables,
} from '@app/codegen';
import { AddCustomerBeneficiaryLocators } from '@app/e2e/screens/EditCustomer';
import { CustomCard } from '@app/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	AppModal,
	AppSelector,
	maskPhoneNumber,
	validatePhone,
	validateZipcode,
} from '@itrustcapital/ui';
import { Input, Button, Spinner } from '@ui-kitten/components';
import React, { ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import * as yup from 'yup';

export const ECS_CB_ADD_CUSTOMER_BENEFICIARY = gql`
	mutation EcsCbAddCustomerBeneficiary($input: AddBeneficiaryInput) {
		addBeneficiary(input: $input) {
			success
			errorMessage
			data {
				id
			}
		}
	}
`;

export interface AddCustomerBeneficiaryProps {
	style?: StyleProp<ViewStyle>;
	children?: ReactNode;
	userId: number;
	visible: boolean;
	toggleVisible: () => void;
	onSave: (success: boolean) => void;
}

export const schema = yup
	.object({
		email: yup.string().email().default(''), // validateEmail hard to validate empty string
		firstName: yup.string().default('').required(),
		lastName: yup.string().default('').required(),
		street: yup.string().default('').required(),
		city: yup.string().default('').required(),
		state: yup.string().default('').required(),
		phoneNumber: validatePhone.required('Phone is required'),
		zipCode: validateZipcode.required(),
		isSpouse: yup.boolean().default(false).required(),
	})
	.defined();

export type AddCustomerBeneficiaryFormData = yup.TypeOf<typeof schema>;

export function AddCustomerBeneficiary(props: AddCustomerBeneficiaryProps) {
	const styles = useCustomStyles();

	const addForm = useForm<AddCustomerBeneficiaryFormData>({
		mode: 'all',
		resolver: yupResolver(schema),
		defaultValues: schema.cast({}),
	});

	const [onAddCustomerBeneficiary, addCustomerBeneficiaryQuery] = useMutation<
		EcsCbAddCustomerBeneficiary,
		EcsCbAddCustomerBeneficiaryVariables
	>(ECS_CB_ADD_CUSTOMER_BENEFICIARY);

	async function onSubmit(data: AddCustomerBeneficiaryFormData): Promise<void> {
		try {
			await onAddCustomerBeneficiary({
				variables: {
					input: {
						userId: props.userId,
						email: data.email,
						firstName: data.firstName,
						lastName: data.lastName,
						phoneNumber: data.phoneNumber.replaceAll('-', ''),
						relationship: data.isSpouse
							? BeneficiaryRelationshipType.SPOUSE
							: BeneficiaryRelationshipType.NONE,
						addresses: [
							{
								address: data.street,
								city: data.city,
								state: data.state,
								zipCode: data.zipCode,
							},
						],
					},
				},
			});

			addForm.reset();
			props.onSave(true);
		} catch (err) {
			props.onSave(false);
		}
	}

	function onClose(): void {
		props.toggleVisible();
		addForm.reset();
	}

	return (
		<AppModal visible={props.visible}>
			<CustomCard
				hasCloseButton
				handleClosePress={onClose}
				header="Add Customer Beneficiary"
				testID={AddCustomerBeneficiaryLocators.addCustomerBeneficiary}
			>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={addForm.control}
							name="firstName"
							render={(control) => (
								<Input
									label="First name *"
									status={control.fieldState.error && 'danger'}
									testID={
										AddCustomerBeneficiaryLocators.addCustomerBeneficiaryFirstNameField
									}
									textContentType="givenName"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={addForm.control}
							name="lastName"
							render={(control) => (
								<Input
									label="Last name *"
									status={control.fieldState.error && 'danger'}
									testID={
										AddCustomerBeneficiaryLocators.addCustomerBeneficiaryLastNameField
									}
									textContentType="familyName"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				</View>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={addForm.control}
							name="street"
							render={(control) => (
								<Input
									label="Street *"
									status={control.fieldState.error && 'danger'}
									testID={
										AddCustomerBeneficiaryLocators.addCustomerBeneficiaryStreetField
									}
									textContentType="streetAddressLine1"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={addForm.control}
							name="city"
							render={(control) => (
								<Input
									label="City *"
									status={control.fieldState.error && 'danger'}
									testID={
										AddCustomerBeneficiaryLocators.addCustomerBeneficiaryCityField
									}
									textContentType="addressCity"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={addForm.control}
							name="state"
							render={(control) => (
								<Input
									label="State *"
									status={control.fieldState.error && 'danger'}
									testID={
										AddCustomerBeneficiaryLocators.addCustomerBeneficiaryStateField
									}
									textContentType="addressState"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={addForm.control}
							name="zipCode"
							render={(control) => (
								<Input
									label="Zip code *"
									status={control.fieldState.error && 'danger'}
									testID={
										AddCustomerBeneficiaryLocators.addCustomerBeneficiaryZipcodeField
									}
									textContentType="postalCode"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
				</View>
				<View style={styles.form.row}>
					<View style={styles.form.col}>
						<Controller
							control={addForm.control}
							name="email"
							render={(control) => (
								<Input
									label="Email"
									placeholder="test@example.com"
									status={control.fieldState.error && 'danger'}
									testID={
										AddCustomerBeneficiaryLocators.addCustomerBeneficiaryEmailField
									}
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChangeText={control.field.onChange}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={addForm.control}
							name="phoneNumber"
							render={(control) => (
								<Input
									label="Primary phone *"
									status={control.fieldState.error && 'danger'}
									testID={
										AddCustomerBeneficiaryLocators.addCustomerBeneficiaryPrimaryPhoneField
									}
									textContentType="telephoneNumber"
									value={control.field.value}
									onBlur={control.field.onBlur}
									onChange={(e) => {
										const formatted = maskPhoneNumber(e);
										control.field.onChange(formatted);
									}}
								/>
							)}
						/>
					</View>
					<View style={styles.form.col}>
						<Controller
							control={addForm.control}
							name="isSpouse"
							render={(control) => (
								<AppSelector
									closeOnSelect
									data={['Yes', 'No']}
									label="Spouse"
									status={control.fieldState.error && 'danger'}
									testID={
										AddCustomerBeneficiaryLocators.addCustomerBeneficiarySpouseField
									}
									value={control.field.value ? 'Yes' : 'No'}
									onSelect={(value) => control.field.onChange(value === 'Yes')}
								/>
							)}
						/>{' '}
					</View>
				</View>

				<View style={styles.actions.container}>
					<Button
						status="basic"
						style={styles.actions.cancel}
						testID={AddCustomerBeneficiaryLocators.addCustomerBeneficiaryCancelButton}
						onPress={onClose}
					>
						Cancel
					</Button>
					<Button
						disabled={!addForm.formState.isDirty || !addForm.formState.isValid}
						style={styles.actions.save}
						testID={AddCustomerBeneficiaryLocators.addCustomerBeneficiarySaveButton}
						onPress={
							addCustomerBeneficiaryQuery.loading
								? undefined
								: addForm.handleSubmit(onSubmit)
						}
					>
						{addCustomerBeneficiaryQuery.loading ? (
							<View>
								<Spinner size="tiny" status="basic" />
							</View>
						) : (
							'Save'
						)}
					</Button>
				</View>
			</CustomCard>
		</AppModal>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		form: StyleSheet.create({
			row: {
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
				marginVertical: 8,
			},
			col: {
				flex: 1,
				marginHorizontal: 8,
			},
		}),
		actions: StyleSheet.create({
			container: {
				flexDirection: 'row',
				justifyContent: 'flex-end',
				marginTop: 8,
			},
			cancel: {
				marginRight: 8,
			},
			save: {
				minWidth: 120,
			},
		}),
	};
}
