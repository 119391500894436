import { AccountStage } from '@app/codegen';
import { useMainDrawerNavigation } from '@app/navigations';
import { AccountsScreen, EditAccountScreen } from '@app/screens';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';

import { AccountsParamsRecord } from './AccountsRoutes';

export type EditAccountScreenParams = {
	accountId: number;
	accountStage?: AccountStage;
};

export function useAccountsNavigation() {
	const navigation = useMainDrawerNavigation('Accounts');

	return {
		Home: (reset?: boolean) =>
			navigation.Base(
				{
					screen: 'Home',
				},
				reset
			),
		Edit: (params: EditAccountScreenParams) =>
			navigation.Base({
				screen: 'Edit',
				params,
			}),
		AccountsLink: navigation.BaseLink + '/accounts',
	};
}

const Stack = createStackNavigator<AccountsParamsRecord>();

export function AccountsNavigation() {
	return (
		<Stack.Navigator screenOptions={{ headerShown: false }}>
			<Stack.Screen component={AccountsScreen} name="Home" />
			<Stack.Screen component={EditAccountScreen} name="Edit" />
		</Stack.Navigator>
	);
}
