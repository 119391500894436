export enum CustomerAccountsComponentLocators {
	newAccountButton = 'newAccountButton',
	deleteAccountSaveButton = 'deleteAccountSaveButton',
	deleteAccountCancelButton = 'deleteAccountCancelButton',
	sendReviewConfirmButton = 'sendReviewConfirmButton',
	sendReviewCancelButton = 'sendReviewCancelButton',
	editAccountButton = 'editAccountButton',
	deleteAccountButton = 'deleteAccountButton',
	requestTrustPilotReviewButton = 'requestTrustPilotReviewButton',
}
