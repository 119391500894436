import * as React from 'react';
import { Dimensions, StyleSheet, View, ViewStyle } from 'react-native';
import { BaseToast, BaseToastProps } from 'react-native-toast-message';

import { AppIcon } from './AppIcon';

export const ToastConfig = {
	success: (props: BaseToastProps) => (
		<BaseToast
			{...props}
			text1={props.text1 || 'Success'}
			text1Style={styles.text1}
			text2Style={styles.text2}
			contentContainerStyle={styles.contentContainer}
			style={[styles.base, { backgroundColor: '#28BE54' }] as ViewStyle}
			renderLeadingIcon={() => (
				<View style={styles.icon}>
					<AppIcon lib="fe" name="check" color="#37DD58" />
				</View>
			)}
		/>
	),
	error: (props: BaseToastProps) => (
		<BaseToast
			{...props}
			text1={props.text1 || 'Error'}
			text1Style={styles.text1}
			text2Style={styles.text2}
			contentContainerStyle={styles.contentContainer}
			style={[styles.base, { backgroundColor: '#DB3824' }] as ViewStyle}
			renderLeadingIcon={() => (
				<View style={styles.icon}>
					<AppIcon lib="ion" name="flash-outline" color="#FF5832" />
				</View>
			)}
		/>
	),
	info: (props: BaseToastProps) => (
		<BaseToast
			{...props}
			text1={props.text1 || 'Info'}
			text1Style={styles.text1}
			text2Style={styles.text2}
			contentContainerStyle={styles.contentContainer}
			style={[styles.base, { backgroundColor: '#2471F4' }] as ViewStyle}
			renderLeadingIcon={() => (
				<View style={styles.icon}>
					<AppIcon lib="ant" name="question" color="#2471F4" />
				</View>
			)}
		/>
	),
	warn: (props: BaseToastProps) => (
		<BaseToast
			{...props}
			text1={props.text1 || 'Warning'}
			text1Style={styles.text1}
			text2Style={styles.text2}
			contentContainerStyle={styles.contentContainer}
			style={[styles.base, { backgroundColor: '#DBA200' }] as ViewStyle}
			renderLeadingIcon={() => (
				<View style={styles.icon}>
					<AppIcon lib="fe" name="alert-triangle" color="#FFC300" />
				</View>
			)}
		/>
	),
	primary: (props: BaseToastProps) => (
		<BaseToast
			{...props}
			text1Style={styles.text1}
			text2Style={styles.text2}
			contentContainerStyle={styles.contentContainer}
			style={[styles.base, { backgroundColor: '#7EA842' }] as ViewStyle}
			renderLeadingIcon={() => (
				<View style={styles.icon}>
					<AppIcon lib="fe" name="mail" color="#7EA842" />
				</View>
			)}
		/>
	),
};

const styles = StyleSheet.create({
	text1: { fontSize: 12, fontWeight: 'bold', marginBottom: 3, color: 'white' },
	text2: { fontSize: 10, color: 'white' },
	contentContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'flex-start',
	},
	base: {
		flexDirection: 'row',
		height: 72,
		width: 400,
		borderRadius: 4,
		shadowOffset: { width: 0, height: 0 },
		shadowOpacity: 0.1,
		shadowRadius: 6,
		elevation: 2,
		alignItems: 'center',
		padding: 16,
		maxWidth: Dimensions.get('window').width * 0.9,
		borderLeftWidth: 0,
	},
	icon: {
		width: 40,
		height: 40,
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: 8,
		marginRight: 16,
		backgroundColor: 'white',
	},
});
