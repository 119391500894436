export const AccountsRoutes = {
	Accounts: {
		path: 'accounts',
		initialRouteName: '',
		screens: {
			Home: {
				path: '',
			},
			Edit: {
				path: 'edit',
			},
		},
	},
};

export type AccountsParams = keyof typeof AccountsRoutes.Accounts.screens;

export type AccountsParamsRecord = Record<AccountsParams, undefined>;
