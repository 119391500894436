export enum StakingMovementFormLocators {
	completeButton = 'completeButton',
	saveButton = 'saveButton',
	verifyButton = 'verifyButton',
	editTotalRewardsModalCancelButton = 'editTotalRewardsModalCancelButton',
	editTotalRewardsModalConfirmButton = 'editTotalRewardsModalConfirmButton',
	saveTotalRewardsModalConfirmButton = 'saveTotalRewardsModalConfirmButton',
	saveTotalRewardsModalCancelButton = 'saveTotalRewardsModalCancelButton',
	totalRewardsField = 'totalRewardsField',
}
