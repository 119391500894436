export const FundingsInflowRoutes = {
	FundingsInflow: {
		path: 'fundings-inflow',
		initialRouteName: '',
		screens: {
			Home: {
				path: '',
			},
			Edit: {
				path: 'edit',
			},
		},
	},
};

export type FundingsInflowParams = keyof typeof FundingsInflowRoutes.FundingsInflow.screens;

export type FundingsInflowParamsRecord = Record<FundingsInflowParams, undefined>;
