import { gql, useMutation } from '@apollo/client';
import {
	AccountStage,
	AccountStatus,
	ChurnReason,
	EditAccountScreen_CloseAccount,
	EditAccountScreen_CloseAccountVariables,
	EditAccountScreen_UpdateAccount,
	EditAccountScreen_UpdateAccountVariables,
	KycStatus,
} from '@app/codegen';
import { AccountStagesLocators } from '@app/e2e/screens/EditAccount';
import { BreadCrumbs, BreadCrumbStage } from '@app/shared/components';
import { accountStageTypeHumanizer } from '@app/shared/helpers';
import { AppAlert, upperFirst, useAppState } from '@itrustcapital/ui';
import { Button, Input, Radio } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';

export interface AccountWorkflowProps {
	stage: AccountStage;
	loading?: boolean;
	accountId: number;
	accountStatus: AccountStatus;
	kycStatus: KycStatus;
}

export const EDIT_ACCOUNT_SCREEN_UPDATE_ACCOUNT = gql`
	mutation EditAccountScreen_UpdateAccount($input: UpdateAccountInput!) {
		updateAccount(input: $input) {
			success
			errorMessage
		}
	}
`;

export const EDIT_ACCOUNT_SCREEN_CLOSE_ACCOUNT = gql`
	mutation EditAccountScreen_CloseAccount($input: CloseAccountInput) {
		closeAccount(input: $input) {
			success
			errorMessage
		}
	}
`;

enum AccountCloseReasons {
	Platform = 'Funded - Dissatisfied with platform',
	Market = 'Funded - Market Uncertainty',
	Dead = 'Client Passed Away',
	WrongAccount = 'Funded Wrong Account Type',
	Unqualified = 'Funded IRA when they were not eligible',
	OverContibuted = 'Over-Contributed',
	NeverFunded = 'Never Completed Funding',
	Other = 'Other',
}

export function AccountWorkflow(props: AccountWorkflowProps) {
	const styles = useCustomStyles();
	const cancelAccountVisible = useAppState(false);
	const freezeAccountVisible = useAppState(false);
	const accountClosedReason = useAppState<AccountCloseReasons | null>(null);
	const additionalCloseInfo = useAppState('');

	const stages: BreadCrumbStage<AccountStage>[] = [
		{ stage: AccountStage.ONBOARDING_PERSONAL_INFORMATION, title: 'Personal Information' },
		{ stage: AccountStage.ONBOARDING_CONTACT_INFORMATION, title: 'Contact Information' },
		{ stage: AccountStage.ONBOARDING_GOVERNMENT_ID, title: 'Government ID' },
		{ stage: AccountStage.ONBOARDING_ACCOUNT_TYPE, title: 'Account Type' },
		{ stage: AccountStage.ONBOARDING_FUNDING_TYPE, title: 'Funding Type' },
		{ stage: AccountStage.ONBOARDING_FUNDING, title: 'Funding' },
		{ stage: AccountStage.ONBOARDING_BENEFICIARIES, title: 'Beneficiaries' },
		{ stage: AccountStage.DOCUMENTS_PENDING },
		{ stage: AccountStage.DOCUMENTS_SENT_TO_CLIENT },
		{ stage: AccountStage.DOCUMENTS_SIGNED },
		{
			stage: AccountStage.VERIFIED,
			disabled:
				props.kycStatus !== KycStatus.PASSED &&
				props.kycStatus !== KycStatus.L1 &&
				props.kycStatus !== KycStatus.L2,
		},
	];

	const isAccountDisabled = props.accountStatus === AccountStatus.DISABLED;
	const freezeText = !isAccountDisabled ? 'freeze' : 'unfreeze';

	const [onUpdateAccount, updateAccountQuery] = useMutation<
		EditAccountScreen_UpdateAccount,
		EditAccountScreen_UpdateAccountVariables
	>(EDIT_ACCOUNT_SCREEN_UPDATE_ACCOUNT, {
		refetchQueries: ['EditAccountScreenGetAccountDetails'],
	});

	const [onCloseAccount] = useMutation<
		EditAccountScreen_CloseAccount,
		EditAccountScreen_CloseAccountVariables
	>(EDIT_ACCOUNT_SCREEN_CLOSE_ACCOUNT, {
		refetchQueries: ['EasGetAccountDetails', 'EditAccountScreenGetAccountDetails'],
	});

	const isLoading = props.loading || updateAccountQuery.loading;

	function closeToaster(accountStatus: AccountStatus): void {
		if (accountStatus === AccountStatus.CLOSED) {
			cancelAccountVisible.set(false);
		}

		if (accountStatus === AccountStatus.DISABLED || accountStatus === AccountStatus.ACTIVE) {
			freezeAccountVisible.set(false);
		}
	}

	async function onUpdate(
		accountStatus: Exclude<
			AccountStatus,
			typeof AccountStatus.NONE | typeof AccountStatus.INTERNAL | typeof AccountStatus.PENDING
		>
	): Promise<void> {
		if (!props.accountId) {
			return;
		}

		if (accountStatus === AccountStatus.CLOSED) {
			const onCloseError = () => {
				Toast.show({
					type: 'error',
					text2: 'Account has failed to cancel.',
				});
			};

			let churnReason: ChurnReason = ChurnReason.NONE;

			// @ts-ignore
			switch (AccountCloseReasons[accountClosedReason.get]) {
				case AccountCloseReasons.Platform:
					churnReason = ChurnReason.DISSATISFIED;
					break;
				case AccountCloseReasons.Market:
					churnReason = ChurnReason.MARKET_UNCERTAINTY;
					break;
				case AccountCloseReasons.Dead:
					churnReason = ChurnReason.CLIENT_DECEASED;
					break;
				case AccountCloseReasons.WrongAccount:
					churnReason = ChurnReason.INCORRECT_ACCOUNT_TYPE;
					break;
				case AccountCloseReasons.Unqualified:
					churnReason = ChurnReason.INELIGIBLE;
					break;
				case AccountCloseReasons.OverContibuted:
					churnReason = ChurnReason.OVER_CONTRIBUTED;
					break;
				case AccountCloseReasons.NeverFunded:
					churnReason = ChurnReason.INCOMPLETE_FUNDING;
					break;
				case AccountCloseReasons.Other:
					churnReason = ChurnReason.OTHER;
					break;
			}

			try {
				const result = await onCloseAccount({
					variables: {
						input: {
							churnReason,
							accountId: props.accountId,
							note:
								accountClosedReason.get === AccountCloseReasons.Other
									? additionalCloseInfo.get
									: `Other - ${accountClosedReason.get}`,
						},
					},
				});

				if (result.data?.closeAccount?.success) {
					Toast.show({
						type: 'success',
						text2: 'Account has been canceled.',
					});
				} else {
					onCloseError();
				}
			} catch (err) {
				onCloseError();
			}
		} else {
			const frozenText = !isAccountDisabled ? 'frozen' : 'unfrozen';

			try {
				await onUpdateAccount({
					variables: {
						input: {
							id: props.accountId,
							stage: undefined,
							status:
								accountStatus === AccountStatus.DISABLED ||
								accountStatus === AccountStatus.ACTIVE
									? accountStatus
									: undefined,
						},
					},
				});

				Toast.show({
					type: 'success',
					text2: `Account has been ${frozenText}.`,
				});
			} catch (error) {
				Toast.show({
					type: 'error',
					text2: `Account has failed to ${freezeText}.`,
				});
			}
		}

		closeToaster(accountStatus);
	}

	async function onStageConfirm(stage: AccountStage): Promise<void> {
		try {
			await onUpdateAccount({
				variables: {
					input: [
						{
							id: props.accountId,
							stage,
						},
					],
				},
			});

			Toast.show({
				type: 'success',
				text2: 'Account stage has been updated',
			});
		} catch (err) {
			Toast.show({
				type: 'error',
				text2: 'Account stage failed to update',
			});
		}
	}

	return (
		<View>
			<AppAlert
				actions={[
					{
						title: 'Do Not Close',
						testID: AccountStagesLocators.accountStagesCancelCancelButton,
						onPress: () => cancelAccountVisible.set(false),
						status: 'danger',
						appearance: 'outline',
					},
					{
						testID: AccountStagesLocators.accountStagesCancelSaveButton,
						title: 'Close',
						status: 'danger',
						loading: updateAccountQuery.loading,
						disabled:
							(accountClosedReason.get === AccountCloseReasons.Other &&
								!additionalCloseInfo.get.trim().length) ||
							accountClosedReason.get === null,
						onPress: () => onUpdate(AccountStatus.CLOSED),
					},
				]}
				message="Provide a reason for closure"
				title="Close Account?"
				visible={cancelAccountVisible.get}
			>
				<View style={styles.closeAccountTopPadding}>
					{Object.entries(AccountCloseReasons).map(([key, reason]) => (
						<Radio
							checked={key === accountClosedReason.get}
							key={key}
							status="danger"
							style={styles.radio}
							onChange={(_) => accountClosedReason.set(key as AccountCloseReasons)}
						>
							{reason}
						</Radio>
					))}

					<Input
						multiline
						disabled={accountClosedReason.get !== AccountCloseReasons.Other}
						numberOfLines={6}
						placeholder="If Other, Please provide a reason for closure here."
						value={additionalCloseInfo.get}
						onChangeText={(value) => additionalCloseInfo.set(value)}
					/>
				</View>
			</AppAlert>

			<AppAlert
				actions={[
					{
						title: 'Close',
						onPress: () => freezeAccountVisible.set(false),
					},
					{
						title: upperFirst(freezeText)!,
						status: 'danger',
						loading: updateAccountQuery.loading,
						onPress: () =>
							onUpdate(
								isAccountDisabled ? AccountStatus.ACTIVE : AccountStatus.DISABLED
							),
					},
				]}
				message={`Are you sure you want to ${freezeText} this account?`}
				title={`${upperFirst(freezeText)} Account?`}
				visible={freezeAccountVisible.get}
			/>

			<BreadCrumbs
				customHumanizer={accountStageTypeHumanizer}
				footer={() => (
					<View style={styles.actions}>
						<Button
							disabled={isLoading || props.stage === AccountStage.CLOSED}
							status="warning"
							onPress={() => freezeAccountVisible.set(true)}
						>
							{`${upperFirst(freezeText)} Account`}
						</Button>

						<Button
							disabled={isLoading || props.stage === AccountStage.CLOSED}
							status="danger"
							style={styles.cancelButton}
							testID={AccountStagesLocators.accountStagesCancelButton}
							onPress={() => cancelAccountVisible.set(true)}
						>
							Close Account
						</Button>
					</View>
				)}
				loading={(props.loading || updateAccountQuery.loading) && !cancelAccountVisible.get}
				stage={props.stage}
				stages={stages}
				style={styles.container}
				testID={AccountStagesLocators.accountStages}
				onStageConfirm={onStageConfirm}
			/>
		</View>
	);
}

function useCustomStyles() {
	return StyleSheet.create({
		actions: {
			flexDirection: 'row',
			justifyContent: 'flex-end',
		},
		cancelButton: {
			minWidth: 140,
			marginLeft: 16,
		},
		container: {
			marginBottom: 8,
		},
		closeAccountTopPadding: {
			paddingTop: 24,
		},
		radio: {
			marginBottom: 12,
		},
	});
}
