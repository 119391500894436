import {
	AccountFilterInput,
	AccountSortInput,
	AccountStage,
	AccountStatus,
	AccountsScreen_GetAccountsVariables,
	AccountsScreen_GetAccounts_accounts_nodes as Account,
	DocumentStage,
	DocuSignTemplateType,
	GetAccountsInput,
	SortEnumType,
	StringOperationFilterInput,
} from '@app/codegen';
import { useTableState } from '@app/shared/components';
import { isFormEmpty } from '@app/shared/helpers';
import { useAppState } from '@itrustcapital/ui';
import { endOfDay } from 'date-fns';
import React from 'react';

import {
	AccountExtraStage,
	FilterAccountsForm,
	useAccountsScreenFilterForm,
} from './AccountsScreenForms';
import {
	useAccountsScreen_GetAccountsQuery,
	useAccountUpdateMutation,
} from './AccountsScreenGraphql';

export type AccountsScreenState = ReturnType<typeof useAccountsScreenState>;

const AccountsScreenContext = React.createContext<AccountsScreenState | undefined>(undefined);

export function AccountsScreenProvider(props: AccountsScreenProviderProps | null) {
	return (
		<AccountsScreenContext.Provider value={props?.value}>
			{props?.children}
		</AccountsScreenContext.Provider>
	);
}

export function useAccountsScreenContext() {
	const context = React.useContext(AccountsScreenContext);

	if (context === undefined) {
		throw new Error('useAccountsScreenContext must be used within a AccountsScreenProvider');
	}

	return context;
}

export type AccountsScreenProviderProps = {
	value: AccountsScreenState | undefined;
	children: React.ReactNode;
};

export function useAccountsScreenState() {
	const filterAccountsForm = useAccountsScreenFilterForm();
	const { setError } = filterAccountsForm;

	const selectedRow = useAppState<Account | null>(null);
	const deleteVisible = useAppState(false);
	const pauseQuery = useAppState(false);
	const stageType = useAppState<AccountStage | AccountExtraStage | null>(null);
	const tableInput = useAppState<GetAccountsInput | null | undefined>(null);
	const defaultWhere = { stage: { neq: AccountStage.NONE } };
	const [onDeleteAccount, deleteAccountQuery] = useAccountUpdateMutation();
	const tableState = useTableState<AccountSortInput, AccountFilterInput>({
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere,
		pagination: true,
		striped: true,
	});

	const variables: AccountsScreen_GetAccountsVariables = {
		input: tableInput.get,
		where: tableState.where.get,
		order: tableState.sort.get,
		first: tableState.rowsPerPage.get,
	};

	const accountsQuery = useAccountsScreen_GetAccountsQuery({
		variables,
		onCompleted: (data) => {
			tableState.onCompleted(data.accounts?.totalCount, data.accounts?.pageInfo);
		},
		skip: pauseQuery.get,
	});

	function onSubmit(data: FilterAccountsForm) {
		pauseQuery.set(true);
		const where: AccountFilterInput = {};

		stageType.set(data.accountStage || null);
		tableState.reset();

		if (isFormEmpty(data)) {
			tableState.where.set(defaultWhere);
			tableInput.set(null);
			pauseQuery.set(false);

			return;
		}

		switch (data.accountStage) {
			case AccountExtraStage.ADDITIONAL_DOCUMENTS:
				tableInput.set({
					documentStage: data.documentStage as DocumentStage,
					docuSignTemplateTypes: [DocuSignTemplateType.ACCOUNT_CHANGES],
				});

				where.status = {
					eq: AccountStatus.ACTIVE,
				};
				break;
			default:
				where.stage = data.accountStage
					? {
							eq: data.accountStage,
					  }
					: undefined;
				break;
		}

		if (data.status) {
			where.status = {
				eq: data.status,
			};
		}

		if (data.search) {
			const searchTrimmed = data.search.trim();
			const searchTerms = searchTrimmed.split(' ');
			const andConditions: any[] = [];

			// each search term is and'ed together
			searchTerms.forEach((searchTerm) => {
				if (searchTerms.length <= 0) {
					return;
				}

				const isValidNameSearch = /^[a-zA-Z-]+$/.test(searchTerm);

				if (isValidNameSearch) {
					const containsSearchName: StringOperationFilterInput = {
						contains: searchTerm.replaceAll(/[^a-zA-Z-]/g, ''),
					};

					andConditions.push({
						user: {
							or: [
								{
									firstName: containsSearchName,
								},
								{
									lastName: containsSearchName,
								},
							],
						},
					});

					return;
				}

				if (searchTerm.includes('@')) {
					andConditions.push({
						user: {
							email: {
								contains: searchTerm,
							},
						},
					});

					return;
				}

				const isValidDigitSearch = /^[+0-9()-]+$/i.test(searchTerm);

				if (isValidDigitSearch) {
					const digitsOnlyValue = searchTerm.replaceAll(/\D/g, '');
					let condition = null;

					if (digitsOnlyValue.length <= 9) {
						condition = {
							id: {
								eq: +digitsOnlyValue,
							},
						};
					} else if (digitsOnlyValue.length >= 10 && digitsOnlyValue.length <= 14) {
						condition = {
							user: {
								mobilePhone: {
									contains: digitsOnlyValue,
								},
							},
						};
					}

					if (condition) {
						andConditions.push(condition);

						return;
					}
				}

				const isValidAccountNumber = /^(ITR|IC)[0-9]+$/i.test(searchTerm);

				if (isValidAccountNumber) {
					andConditions.push({
						accountNumber: {
							eq: searchTerm,
						},
					});

					return;
				}

				const isValidFortressAccountId =
					/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
						searchTerm
					);

				if (isValidFortressAccountId) {
					andConditions.push({
						custodianAccount: {
							custodianAccountId: {
								eq: searchTerm,
							},
						},
					});
				}
			});

			if (andConditions.length > 0) {
				where.and = andConditions;
			} else {
				setError('search', {
					message:
						'Phone numbers must be between 10-14 digits. Account numbers must start with ITR|IC.',
				});

				pauseQuery.set(true);

				return;
			}

			filterAccountsForm.setValue('search', searchTrimmed);
		}

		if (data.mobilePhone) {
			where.user = {
				mobilePhone: {
					eq: data.mobilePhone.replaceAll(/[-()\s]/g, ''),
				},
			};
		}

		if (data.createdTo) {
			where.createdAt = {
				lte: endOfDay(data.createdTo).toISOString(),
			};
		}

		if (data.createdFrom) {
			where.createdAt = {
				...where.createdAt,
				gte: data.createdFrom.toISOString(),
			};
		}

		tableState.where.set(where);
		pauseQuery.set(false);
	}

	return {
		tableInput,
		tableState,
		stageType,
		accountsQuery,
		deleteVisible,
		selectedRow,
		deleteAccountLoading: deleteAccountQuery.loading,
		onSubmit,
		onDeleteAccount,
		filterAccountsForm,
	};
}
