import { ApolloQueryResult } from '@apollo/client';
import { SortEnumType } from '@app/codegen';
import { useAppState, useAppTheme } from '@itrustcapital/ui';
import { Text } from '@ui-kitten/components';
import React from 'react';
import DataTable, {
	createTheme,
	IDataTableColumn,
	IDataTableProps,
	IDataTableStyles,
} from 'react-data-table-component';
import { LayoutChangeEvent, StyleSheet, useWindowDimensions, View, ViewStyle } from 'react-native';

import { ActionsColumn, ITrustAction } from './ActionsColumn';
import { Loading } from './Loading';
import { NoData } from './NoData';
import Pagination from './Pagination';

interface TableOptions<S, F> {
	defaultSort?: S;
	defaultWhere?: F;
	defaultRowsPerPage?: number;
	striped?: boolean;
	pagination?: boolean;
	disabledPaginationServer?: boolean;
	disabledSortServer?: boolean;
	selectableRows?: boolean;
	onSelectedRowsChange?: <T>(selectedRowState: {
		allSelected: boolean;
		selectedCount: number;
		selectedRows: T[];
	}) => void;
}

export function useTableState<S extends object, F>(options?: TableOptions<S, F>) {
	const rowsPerPage = useAppState(options?.defaultRowsPerPage || 10);
	const tableReset = useAppState(false);
	const selectedRowReset = useAppState(false);
	const sort = useAppState<S[] | null>((options?.defaultSort && [options?.defaultSort]) || null);
	const where = useAppState<F | undefined>(options?.defaultWhere || undefined);
	const cursors = useAppState(['', '']); // [start cursor, end cursor]
	const totalRows = useAppState(0);
	const selectedRowState = useAppState<{
		allSelected: boolean;
		selectedCount: number;
		selectedRows: any[];
	}>({ allSelected: false, selectedCount: 0, selectedRows: [] });

	function onCompleted(
		_totalRows: number = 0,
		pageInfo?: { startCursor: string | null; endCursor: string | null }
	) {
		totalRows.set(_totalRows);
		cursors.set([pageInfo?.startCursor || '', pageInfo?.endCursor || '']);
	}

	function reset() {
		tableReset.set(!tableReset.get);
	}

	function clearSelectedRows(): void {
		selectedRowReset.set(!selectedRowReset.get);
	}

	return {
		rowsPerPage,
		tableReset,
		sort,
		where,
		cursors,
		totalRows,
		onCompleted,
		reset,
		selectedRowState: selectedRowState.get,
		clearSelectedRows,
		props: {
			defaultRowsPerPage: rowsPerPage.get,
			sort: sort.set,
			startCursor: cursors.get[0],
			endCursor: cursors.get[1],
			paginationTotalRows: totalRows.get,
			tableResetGet: tableReset.get,
			tableResetSet: tableReset.set,
			striped: options?.striped,
			pagination: options?.pagination,
			selectableRows: options?.selectableRows,
			onSelectedRowsChange: selectedRowState.set,
			clearSelectedRows: selectedRowReset.get,
			paginationServer: !options?.disabledPaginationServer,
			sortServer: !options?.disabledSortServer,
		},
	};
}

export interface ITrustColumn<T> extends Omit<IDataTableColumn<T>, 'maxWidth' | 'minWidth'> {
	maxWidth?: number;
	minWidth?: number;
	key: keyof T;
	sortable?: boolean;
	sortOverride?: (sortDirection: SortEnumType) => any;
}

export interface ITrustDataTable<T> extends Omit<IDataTableProps<T>, 'columns'> {
	columns: ITrustColumn<T>[];
	rowActions?: ITrustAction<T>[];
	startCursor?: string;
	endCursor?: string;
	defaultRowsPerPage?: number;
	sort?: (value: any) => void; // useAppState.set function
	refetch?: (variables?: any) => Promise<ApolloQueryResult<any>> | void;
	hideCellBorders?: boolean;
	tableResetSet?: (tableResetGet: boolean) => void; // useAppState.set function
	tableResetGet?: boolean;
	footerElement?: () => React.ReactNode;
	inModal?: boolean;
}

const ExpandedComponent = ({ data, columns }: { data: any; columns: IDataTableColumn<any>[] }) => {
	const styles = useCustomStyles();

	return (
		<>
			{columns.map((column) => {
				const value =
					(typeof column.selector === 'function' && (column.selector as any)(data)) || '';

				if (column.hide) {
					return (
						<View
							key={`${
								// name can be a string or Text component
								React.isValidElement(column.name)
									? column.name?.props?.children || ''
									: (column.name as string)
							}-${data.id}`}
							style={styles.hiddenColumns.listItem}
						>
							<Text category="s1">{column.name as string}: </Text>
							<Text>{value}</Text>
						</View>
					);
				}
			})}
		</>
	);
};

function usePrevious(value: boolean | undefined) {
	const ref = React.useRef<boolean>();
	React.useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
}

export const CustomDataTable = <T extends any>(props: ITrustDataTable<T>) => {
	const styles = useCustomStyles(props.hideCellBorders);
	const sortDict = useAppState<{ [key: string]: string }>({});
	const currentPage = useAppState<number>(1);
	const rowsPerPage = useAppState<number>(props.defaultRowsPerPage || 10);
	const layoutWidth = useAppState(0);
	const lastReset = usePrevious(props.tableResetGet);

	React.useEffect(() => {
		if (lastReset !== props.tableResetGet) {
			currentPage.set(1);
		}
	}, [lastReset, props.tableResetGet]);

	const windowDimensions = useWindowDimensions();

	const handleLayout = (e: LayoutChangeEvent) => {
		layoutWidth.set(e.nativeEvent.layout.width);
	};

	let showExpander = false;
	let columnMinWidthTotal = 0;

	const actionsWidth =
		props.rowActions && props.rowActions.length > 2 ? props.rowActions.length * 30 : 80;

	const columns: IDataTableColumn<T>[] = (
		props.rowActions
			? [
					{
						name: 'Actions',
						span: actionsWidth,
						maxWidth: actionsWidth,
						minWidth: actionsWidth,
						cell: (row: T) => <ActionsColumn<T> actions={props.rowActions} row={row} />,
						sortable: false,
					},
					...props.columns,
			  ]
			: props.columns
	).map((column) => {
		const name = column.name ? column.name.toString() : '';
		const minWidth = !column.minWidth ? 100 : column.minWidth;
		const maxWidth = !column.maxWidth ? '100%' : `${column.maxWidth}px`;

		columnMinWidthTotal += minWidth;

		const hide = columnMinWidthTotal > layoutWidth.get ? windowDimensions.width : 0;

		showExpander = !!hide;

		return {
			...column,
			hide,
			minWidth: `${minWidth}px`,
			maxWidth,
			name: column.sortable ? (
				column.name
			) : (
				<Text key={`title-${name}`} style={styles.tableHeader.nonSortable}>
					{name}
				</Text>
			),
		};
	});

	function onSort(column: ITrustColumn<T>) {
		const cachedColumnDirection = sortDict.get[column.key as string];
		const sortDirection =
			cachedColumnDirection === SortEnumType.ASC ? SortEnumType.DESC : SortEnumType.ASC;

		let sort = [{ [String(column.key)]: sortDirection }];

		if (typeof column.sortOverride === 'function') {
			sort = [column.sortOverride(sortDirection)];
		}

		sortDict.set({ ...sortDict.get, [column.key]: sortDirection });
		props.sort && props.sort(sort);
		currentPage.set(1);
	}

	function onChangePage(page: number) {
		currentPage.set(page);

		if (!props.refetch) {
			return;
		}

		if (page > currentPage.get) {
			props.refetch({
				first: rowsPerPage.get,
				after: props.endCursor,
				last: undefined,
				before: undefined,
			});
		} else {
			props.refetch({
				first: undefined,
				after: undefined,
				last: rowsPerPage.get,
				before: props.startCursor,
			});
		}
	}

	function onChangeRowsPerPage(currentRowsPerPage: number) {
		if (!props.refetch) {
			return;
		}
		props.refetch({
			first: currentRowsPerPage,
			after: undefined,
			last: undefined,
			before: undefined,
		});

		rowsPerPage.set(currentRowsPerPage);
		currentPage.set(1);

		if (props.tableResetSet && props.tableResetGet) {
			props.tableResetSet(props.tableResetGet);
		}
	}

	function goToFirstPage() {
		currentPage.set(1);

		if (!props.refetch) {
			return;
		}

		props.refetch({
			first: rowsPerPage.get,
			after: undefined,
			last: undefined,
			before: undefined,
		});
	}

	const PaginationComponent = (paginationProps: any) => (
		<Pagination
			currentPage={currentPage.get}
			footerElement={props.footerElement}
			goToFirstPage={goToFirstPage}
			inModal={props.inModal}
			paginationRowsPerPageOptions={props.paginationRowsPerPageOptions || [10, 15, 25, 50]}
			rowCount={paginationProps.rowCount}
			rowsPerPage={paginationProps.rowsPerPage}
			onChangePage={onChangePage}
			onChangeRowsPerPage={paginationProps.onChangeRowsPerPage}
		/>
	);

	return (
		<View style={styles.container as ViewStyle} onLayout={handleLayout}>
			<DataTable
				{...props}
				noHeader
				persistTableHead
				responsive
				columns={columns}
				customStyles={styles.table as IDataTableStyles}
				data={props.data}
				expandableRows={showExpander}
				expandableRowsComponent={
					<ExpandedComponent columns={columns} data={undefined as any} />
				}
				noDataComponent={<NoData />}
				paginationComponent={PaginationComponent}
				paginationDefaultPage={1}
				paginationPerPage={rowsPerPage.get}
				paginationResetDefaultPage={props.tableResetGet}
				paginationServerOptions={{
					persistSelectedOnSort: true,
					persistSelectedOnPageChange: true,
				}}
				progressComponent={<Loading />}
				theme="iTrustCapital"
				onChangePage={onChangePage}
				onChangeRowsPerPage={onChangeRowsPerPage}
				onSort={onSort as any}
			/>
		</View>
	);
};

function useCustomStyles(hideCellBorders?: boolean) {
	const theme = useAppTheme();

	const borderColor = hideCellBorders ? 'transparent' : theme['border-basic-color-3'];

	// REFERENCE: Table Theme - https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/themes.js
	createTheme('iTrustCapital', {
		text: {
			primary: theme['text-basic-color'],
			secondary: theme['text-basic-color'],
			disabled: theme['text-disabled-color'],
		},
		background: {
			default: theme['background-basic-color-1'],
		},
		context: {
			background: theme['background-basic-color-1'],
			text: theme['text-basic-color'],
		},
		divider: {
			default: borderColor,
		},
		sortFocus: {
			default: theme['text-primary-focus-color'],
		},
		striped: {
			default: theme['background-basic-color-2'],
			text: theme['text-basic-color'],
		},
	});

	// REFERENCE: Table Style Overrides - https://github.com/jbetancur/react-data-table-component/blob/master/src/DataTable/styles.js
	return {
		table: {
			cells: {
				style: {
					'&:not(:last-of-type)': {
						borderRightStyle: 'solid',
						borderRightWidth: StyleSheet.hairlineWidth,
						borderRightColor: borderColor,
					},
					fontSize: '15px',
					lineHeight: '20px',
					fontWeight: '400',
					fontFamily:
						'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
					paddingLeft: '8px', // override the cell padding for data cells
					paddingRight: '8px',
					borderWidth: StyleSheet.hairlineWidth,
				},
			},
			headRow: {
				style: {
					borderBottomStyle: 'solid',
					borderBottomWidth: StyleSheet.hairlineWidth,
					borderBottomColor: theme['border-basic-color-3'],
				},
			},
			headCells: {
				style: {
					'&:not(:last-of-type)': {
						borderRightStyle: 'solid',
						borderRightWidth: StyleSheet.hairlineWidth,
						borderRightColor: borderColor,
					},
					paddingLeft: '8px', // override the cell padding for head cells
					paddingRight: '8px',
					fontSize: '15px',
					lineHeight: '20px',
					fontWeight: '600',
					fontFamily:
						'-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
				},
			},
			expanderButton: {
				style: {
					color: theme['text-primary-color'],
					fill: theme['color-info-200'],
					backgroundColor: 'transparent',
					borderRadius: '2px',
					transition: '0.25s',
					height: '100%',
					width: '100%',
					'&:hover:enabled': {
						cursor: 'pointer',
					},
					'&:disabled': {
						color: theme['text-disabled-color'],
					},
					'&:hover:not(:disabled)': {
						cursor: 'pointer',
						backgroundColor: theme['color-basic-200'],
					},
					'&:focus': {
						outline: 'none',
						backgroundColor: theme['color-basic-200'],
					},
					svg: {
						margin: 'auto',
					},
				},
			},
		},
		container: {
			overflow: 'hidden',
			marginTop: 4, // less agressive than 50, can update
			borderStyle: 'solid',
			borderWidth: StyleSheet.hairlineWidth,
			borderColor,
		},
		hiddenColumns: StyleSheet.create({
			listItem: {
				flexDirection: 'row',
				alignItems: 'center',
				padding: 12,
				borderBottomColor: borderColor,
				borderBottomWidth: StyleSheet.hairlineWidth,
			},
		}),
		tableHeader: StyleSheet.create({
			nonSortable: {
				fontWeight: '600',
			},
		}),
	};
}
