import { useQuery, gql, useMutation } from '@apollo/client';
import {
	EditAccountScreen_GetAccountBeneficiaries,
	AccountBeneficiaryFilterInput,
	SortEnumType,
	AccountBeneficiarySortInput,
	EditAccountScreen_GetAccountBeneficiariesVariables,
	EditAccountScreen_DeleteAccountBeneficiary,
	EditAccountScreen_DeleteAccountBeneficiaryVariables,
	EditAccountScreen_GetAccountBeneficiaries_accountBeneficiaries_nodes as AccountBeneficiary,
	BeneficiaryRelationshipType,
} from '@app/codegen';
import { AccountBeneficiariesLocators } from '@app/e2e/screens/EditAccount';
import { CustomCard, CustomDataTable, ITrustColumn, useTableState } from '@app/shared/components';
import { AppAlert, AppIcon, humanize, useAppState, useAppTheme } from '@itrustcapital/ui';
import { Text, Button } from '@ui-kitten/components';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';

import { AddAccountBeneficiary } from './AddAccountBeneficiary';
import { EditAccountBeneficiary } from './EditAccountBeneficiary';

export interface AccountBeneficiariesProps {
	accountId: number;
	userId: number;
}

export const EDIT_ACCOUNT_SCREEN_GET_ACCOUNT_BENEFICIARIES = gql`
	query EditAccountScreen_GetAccountBeneficiaries(
		$where: AccountBeneficiaryFilterInput
		$order: [AccountBeneficiarySortInput!]
		$first: Int
		$last: Int
		$after: String
		$before: String
	) {
		accountBeneficiaries(
			where: $where
			order: $order
			first: $first
			last: $last
			after: $after
			before: $before
		) {
			nodes {
				id
				beneficiary {
					id
					firstName
					lastName
					email
					addresses {
						address
						city
						state
						zipCode
					}
					phoneNumber
					relationship
				}
				percentage
				type
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			totalCount
		}
	}
`;

export const EDIT_ACCOUNT_SCREEN_DELETE_ACCOUNT_BENEFICIARY = gql`
	mutation EditAccountScreen_DeleteAccountBeneficiary($input: DeleteAccountBeneficiaryInput!) {
		deleteAccountBeneficiary(input: $input) {
			success
			errorMessage
		}
	}
`;

export function AccountBeneficiaries(props: AccountBeneficiariesProps) {
	const styles = useCustomStyles();
	const theme = useAppTheme();

	const selectedRow = useAppState<AccountBeneficiary | null>(null);
	const createBeneficiaryVisible = useAppState(false);
	const editBeneficiaryVisible = useAppState(false);
	const deleteBeneficiaryVisible = useAppState(false);
	const tableState = useTableState<AccountBeneficiarySortInput, AccountBeneficiaryFilterInput>({
		defaultRowsPerPage: 5,
		defaultSort: { createdAt: SortEnumType.DESC },
		defaultWhere: {
			accountId: {
				eq: props.accountId,
			},
		},

		pagination: true,
		striped: true,
	});

	const getBeneficiariesQuery = useQuery<
		EditAccountScreen_GetAccountBeneficiaries,
		EditAccountScreen_GetAccountBeneficiariesVariables
	>(EDIT_ACCOUNT_SCREEN_GET_ACCOUNT_BENEFICIARIES, {
		variables: {
			order: tableState.sort.get,
			first: tableState.rowsPerPage.get,
			where: tableState.where.get, // where is updated via state onSubmit
		},
		skip: !props.accountId,
		onCompleted: (data) => {
			if (data) {
				tableState.onCompleted(
					data.accountBeneficiaries?.totalCount,
					data.accountBeneficiaries?.pageInfo
				);
			}
		},
		onError: () => {
			Toast.show({
				type: 'error',
				text2: 'Failed to get account beneficaries',
			});
		},
	});

	const [onDeleteAccountBeneficiary, deleteAccountBeneficiaryQuery] = useMutation<
		EditAccountScreen_DeleteAccountBeneficiary,
		EditAccountScreen_DeleteAccountBeneficiaryVariables
	>(EDIT_ACCOUNT_SCREEN_DELETE_ACCOUNT_BENEFICIARY);

	const columns: ITrustColumn<AccountBeneficiary>[] = [
		{
			key: 'beneficiary',
			name: 'Name',
			selector: (row) => `${row.beneficiary?.firstName} ${row.beneficiary?.lastName}`,
		},
		{
			key: 'beneficiary',
			name: 'Email',
			minWidth: 300,
			selector: (row) => row.beneficiary?.email,
		},
		{
			key: 'percentage',
			name: 'Percentage',
			maxWidth: 150,
			center: true,
			sortable: true,
			selector: (row) => row.percentage,
		},
		{
			key: 'type',
			name: 'Type',
			maxWidth: 150,
			center: true,
			sortable: true,
			selector: (row) => humanize(row.type),
		},
		{
			key: 'beneficiary',
			name: 'Spouse',
			maxWidth: 150,
			center: true,
			sortable: true,
			selector: (row) =>
				row.beneficiary?.relationship === BeneficiaryRelationshipType.SPOUSE ? 'Yes' : 'No',
		},
	];

	async function onDeleteSubmit(): Promise<void> {
		if (!selectedRow.get) {
			return;
		}
		try {
			await onDeleteAccountBeneficiary({
				variables: {
					input: {
						id: selectedRow.get.id,
					},
				},
			});

			Toast.show({
				text2: 'Account beneficiary has been deleted',
				type: 'success',
			});

			selectedRow.set(null);
			deleteBeneficiaryVisible.set(false);
			tableState.reset();
			getBeneficiariesQuery.refetch();
		} catch (error) {
			Toast.show({
				text2: 'Account beneficary failed to delete',
				type: 'error',
			});
		}
	}

	return (
		<CustomCard
			header={() => (
				<View style={styles.main.header}>
					<Text category="h6">Beneficiaries</Text>
					<Button
						testID={AccountBeneficiariesLocators.createAccountBeneficiaryButton}
						onPress={() => {
							selectedRow.set(null);
							createBeneficiaryVisible.set(true);
						}}
					>
						New
					</Button>
				</View>
			)}
		>
			<AddAccountBeneficiary
				accountBeneficiaries={getBeneficiariesQuery.data?.accountBeneficiaries?.nodes}
				accountId={props.accountId}
				toggleVisible={() => {
					selectedRow.set(null);
					createBeneficiaryVisible.set(!createBeneficiaryVisible.get);
				}}
				userId={props.userId}
				visible={createBeneficiaryVisible.get}
				onSave={(success: boolean) => {
					Toast.show({
						text2: `Account beneficiary ${
							success ? 'has been added' : 'failed to add'
						}`,
						type: success ? 'success' : 'error',
					});

					if (success) {
						selectedRow.set(null);
						createBeneficiaryVisible.set(false);
						getBeneficiariesQuery.refetch();
					}
				}}
			/>

			{selectedRow.get && (
				<EditAccountBeneficiary
					accountBeneficiaryId={selectedRow.get.id}
					toggleVisible={() => {
						selectedRow.set(null);
						editBeneficiaryVisible.set(!editBeneficiaryVisible.get);
					}}
					visible={editBeneficiaryVisible.get}
					onSave={(success: boolean) => {
						Toast.show({
							text2: `Account beneficiary ${
								success ? 'has been updateed' : 'failed to update'
							}`,
							type: success ? 'success' : 'error',
						});

						if (success) {
							selectedRow.set(null);
							editBeneficiaryVisible.set(false);
							getBeneficiariesQuery.refetch();
						}
					}}
				/>
			)}
			<AppAlert
				actions={[
					{
						testID: AccountBeneficiariesLocators.deleteAccountBeneficiaryCancelButton,
						title: 'Close',
						onPress: () => {
							deleteBeneficiaryVisible.set(false);
						},
					},
					{
						testID: AccountBeneficiariesLocators.deleteAccountBeneficiarySaveButton,
						appearance: 'filled',
						status: 'danger',
						title: 'Confirm',
						loading: deleteAccountBeneficiaryQuery.loading,
						onPress: onDeleteSubmit,
					},
				]}
				icon={
					<AppIcon
						color={theme['color-danger-500']}
						lib="ion"
						name="close-circle-outline"
						size={70}
						style={styles.deleteModal.closeIcon}
					/>
				}
				message={`Account beneficiary ${selectedRow.get?.beneficiary?.email} will be deleted`}
				title="Are you sure?"
				visible={deleteBeneficiaryVisible.get}
			/>
			<CustomDataTable
				{...tableState.props}
				columns={columns}
				data={
					getBeneficiariesQuery.data?.accountBeneficiaries?.nodes as AccountBeneficiary[]
				}
				progressPending={getBeneficiariesQuery.loading}
				refetch={(variables) => {
					getBeneficiariesQuery.refetch({
						...variables,
						where: tableState.where.get,
					});
				}}
				rowActions={[
					{
						testID: AccountBeneficiariesLocators.editAccountBeneficiaryButton,
						iconName: 'pencil',
						iconLib: 'matc',
						handler: (row) => {
							selectedRow.set(row);
							editBeneficiaryVisible.set(true);
						},
						name: 'edit',
					},
					{
						testID: AccountBeneficiariesLocators.deleteAccountBeneficiaryButton,
						iconName: 'trash',
						iconLib: 'ion',
						handler: (row) => {
							selectedRow.set(row);
							deleteBeneficiaryVisible.set(true);
						},
						name: 'delete',
					},
				]}
			/>
		</CustomCard>
	);
}

function useCustomStyles() {
	return {
		main: StyleSheet.create({
			header: {
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
			},
		}),
		deleteModal: StyleSheet.create({
			closeIcon: {
				marginHorizontal: 'auto',
			},
		}),
		row: StyleSheet.create({
			actions: {
				flexDirection: 'row',
				alignContent: 'center',
				justifyContent: 'space-between',
			},
			edit: {
				marginRight: 4,
			},
		}),
	};
}
