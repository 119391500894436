import { UserStatus } from '@app/codegen';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { TypeOf } from 'yup';

export const CustomersScreenFilterSchema = yup
	.object({
		search: yup.string().default(''),
		fundedDateFrom: yup.date().default(undefined),
		fundedDateTo: yup.date().default(undefined),
		createdFrom: yup.date().default(undefined),
		createdTo: yup.date().default(undefined),
		status: yup
			.mixed<UserStatus | 'ALL'>()
			.oneOf([
				UserStatus.ACTIVE,
				UserStatus.DISABLED,
				UserStatus.LEAD,
				UserStatus.AUTH_VERIFY,
				UserStatus.EMAIL_VERIFY,
				'ALL',
			])
			.default(UserStatus.ACTIVE),
		mobilePhone: yup.string().default('').max(12),
	})
	.defined();

export type CustomersScreenFilterForm = TypeOf<typeof CustomersScreenFilterSchema>;

export function useCustomersScreenFilterForm() {
	return useForm<CustomersScreenFilterForm>({
		mode: 'all',
		resolver: yupResolver(CustomersScreenFilterSchema),
		defaultValues: CustomersScreenFilterSchema.cast({}),
	});
}
