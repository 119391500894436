import { NetworkStatus } from '@apollo/client';
import React, { ReactNode } from 'react';
import {
	View,
	StyleProp,
	ViewStyle,
	ScrollView,
	KeyboardAvoidingView,
	RefreshControl,
	Platform,
	StyleSheet,
} from 'react-native';

import { isElectron } from '../../helpers';
import { useAppTheme } from '../../themes';

export interface AppScreenProps {
	style?: StyleProp<ViewStyle>;
	parentStyle?: StyleProp<ViewStyle>;
	children?: ReactNode;
	topChildren?: ReactNode;
	noScroll?: boolean;
	noPadding?: boolean;
	noMaxWidth?: boolean;
	noMarginBottom?: boolean;
	keyboardVerticalOffset?: number;
	dismissKeyboardOnTouch?: boolean;
	networkStatus?: NetworkStatus;
	onRefetch?: () => void;
	allowElectronTransparentBackgroung?: boolean;
	flatList?: boolean;
	noPaddingBottom?: boolean;
}

export const AppScreen = React.memo(AppScreenComponent);

function AppScreenComponent(props: AppScreenProps) {
	const theme = useAppTheme();
	const styles = useCustomStyles(props);

	const state: AppScreenProps = {
		...props,
		children: undefined,
		topChildren: undefined,
		noMarginBottom: props.flatList ? true : props.noMarginBottom,
		noScroll: props.flatList ? true : props.noScroll,
		noPadding: props.flatList ? true : props.noPadding,
		noMaxWidth: props.flatList ? true : props.noMaxWidth,
	};

	if (Platform.OS === 'web' && isElectron()) {
		if (state.noScroll) {
			return (
				<View
					style={[
						isElectron() && {
							flex: 1,
						},
						isElectron() &&
							!state.allowElectronTransparentBackgroung && {
								backgroundColor: theme['background-basic-color-3'],
							},
						state.parentStyle,
					]}
				>
					<View
						style={[
							styles.webView,
							state.noMaxWidth && { maxWidth: '100%' },
							state.noPadding && { padding: 0 },
							state.noMarginBottom && { marginBottom: 0 },
							state.style,
						]}
					>
						{props.children}
						{props.topChildren && props.topChildren}
					</View>
				</View>
			);
		} else {
			return (
				<ScrollView
					style={[
						isElectron() &&
							!state.allowElectronTransparentBackgroung && {
								backgroundColor: theme['background-basic-color-3'],
							},
						state.parentStyle,
					]}
				>
					<View
						style={[
							styles.scrollView,
							state.noMaxWidth && { maxWidth: '100%' },
							state.noPadding && { padding: 0 },
							state.noMarginBottom && { marginBottom: 0 },
							state.style,
						]}
					>
						{props.children}
					</View>
					{props.topChildren && props.topChildren}
				</ScrollView>
			);
		}
	}

	if (Platform.OS === 'web' && !isElectron()) {
		if (state.noScroll) {
			return (
				<View
					style={[
						styles.webView,
						state.noMaxWidth && { maxWidth: '100%' },
						state.noPadding && { padding: 0 },
						state.noMarginBottom && { marginBottom: 0 },
						state.style,
					]}
				>
					{props.children}
					{props.topChildren && props.topChildren}
				</View>
			);
		} else {
			return (
				<ScrollView style={state.parentStyle}>
					<View
						style={[
							styles.scrollView,
							state.noMaxWidth && { maxWidth: '100%' },
							state.noPadding && { padding: 0 },
							state.noMarginBottom && { marginBottom: 0 },
							state.style,
						]}
					>
						{props.children}
					</View>
					{props.topChildren && props.topChildren}
				</ScrollView>
			);
		}
	}

	if (state.noScroll || state.flatList) {
		return (
			<KeyboardAvoidingView
				behavior={Platform.OS === 'ios' ? 'padding' : undefined}
				style={{ flex: 1 }}
				enabled
				keyboardVerticalOffset={
					state.keyboardVerticalOffset !== undefined ? state.keyboardVerticalOffset : 60
				}
			>
				<View
					style={[
						styles.view,
						state.noMaxWidth && { maxWidth: '100%' },
						state.noPadding && { padding: 0 },
						state.noMarginBottom && { marginBottom: 0 },
						state.style,
					]}
				>
					{props.children}
					{props.topChildren && props.topChildren}
				</View>
			</KeyboardAvoidingView>
		);
	}

	return (
		<KeyboardAvoidingView
			behavior={Platform.OS === 'ios' ? 'padding' : undefined}
			style={{ flex: 1 }}
			enabled
			keyboardVerticalOffset={
				state.keyboardVerticalOffset !== undefined ? state.keyboardVerticalOffset : 80
			}
		>
			<ScrollView
				style={{ height: '100%' }}
				scrollIndicatorInsets={{ right: 1 }}
				refreshControl={
					state.onRefetch && (
						<RefreshControl
							refreshing={state.networkStatus === NetworkStatus.refetch}
							onRefresh={state.onRefetch}
						/>
					)
				}
			>
				<View
					style={[
						styles.scrollView,
						state.noMaxWidth && { maxWidth: '100%' },
						state.noPadding && { padding: 0 },
						state.noMarginBottom && { marginBottom: 0 },
						state.style,
					]}
				>
					{props.children}
				</View>
			</ScrollView>
			{props.topChildren && props.topChildren}
		</KeyboardAvoidingView>
	);
}

export const AppScreenStyleDefaults: ViewStyle = {
	width: '100%',
	maxWidth: 800,
	margin: 'auto',
	padding: 16,
	alignSelf: 'center',
	paddingBottom: 150,
	minHeight: Platform.OS === 'web' ? '100vh' : undefined,
};

function useCustomStyles(props: AppScreenProps) {
	return StyleSheet.create({
		scrollView: {
			...AppScreenStyleDefaults,
			minHeight: undefined,
		},
		view: {
			...AppScreenStyleDefaults,
			flex: 1,
			paddingBottom: props.flatList || props.noPaddingBottom || props.noPadding ? 0 : 80,
			maxWidth: props.flatList ? '100%' : AppScreenStyleDefaults.maxWidth,
		},
		webView: {
			flex: 1,
			width: '100%',
			maxWidth: props.flatList ? '100%' : AppScreenStyleDefaults.maxWidth,
			margin: 'auto',
			padding: 16,
		},
	});
}
