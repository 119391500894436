export enum UsersScreenLocators {
	searchIconButton = 'searchIconButton',
	createNewButton = 'createNewButton',
	applyFiltersButton = 'applyFiltersButton',
	clearFiltersButton = 'clearFiltersButton',
	userFormSaveButton = 'userFormSaveButton',
	userFormCancelButton = 'userFormCancelButton',
	userForm = 'userForm',
	editUserButton = 'editUserButton',
	unlockUserButton = 'unlockUserButton',
	lockUserButton = 'lockUserButton',
	deleteUserButton = 'deleteUserButton',
	cancelDeleteUserButton = 'cancelDeleteUserButton',
	confirmDeleteUserButton = 'confirmDeleteUserButton',
	searchField = 'searchField',
	roleField = 'roleField',
	userFormfirstNameField = 'userFormfirstNameField',
	userFormlastNameField = 'userFormlastNameField',
	userFormEmailField = 'userFormEmailField',
	userFormUsernameField = 'userFormUsernameField',
	userFormRoleField = 'userFormRoleField',
}
