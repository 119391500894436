export function exchangeCurrency(
	fromAmount: number,
	targetPrice: number,
	orderType: 'BUY' | 'SELL'
): number {
	if (orderType === 'BUY') {
		return fromAmount / targetPrice;
	} else {
		return fromAmount * targetPrice;
	}
}

function displayToFixed(num: number, decimals: number): string {
	const [head = '0', tail = '00'] = String(num).split('.');

	return `${head}.${(tail + '0'.repeat(decimals)).substring(0, decimals)}`;
}

export function addCommas(num: number, decimals: number): string {
	const parts = displayToFixed(num, decimals).split('.');
	const numbersGroupedByThree = /\B(?=(\d{3})+(?!\d))/g;
	parts[0] = parts[0].replace(numbersGroupedByThree, ',');

	return parts.join('.');
}

function trimZeros(numString: string, numZeros: number = 2): string {
	if (!numString.includes('.')) {
		return numString;
	}
	const [num, dec] = numString.split('.');
	// chop off anything past 8th decimal.
	let modifiedDecimal = dec.slice(0, 8);
	modifiedDecimal = modifiedDecimal.replace(new RegExp(`(\\d{${numZeros}})0*$`), '$1');

	if (!modifiedDecimal.length) {
		modifiedDecimal = '00';
	}

	return `${num}.${modifiedDecimal}`;
}

export function displayUsdNumber(value: number = 0, decimals?: number) {
	return displayNumber(value, { currency: 'USD', decimals });
}

export function displayNumber(
	value: number = 0,
	options: {
		currency?: string;
		decimals?: number;
		showTrailingZeros?: boolean;
		minNumbersToShow?: number;
		round?: boolean;
		hideDecimalsIfZeros?: boolean;
	} = {}
): string {
	let {
		currency = '',
		decimals = 8,
		showTrailingZeros = false,
		minNumbersToShow = 2,
		round = false,
		hideDecimalsIfZeros = false,
	} = options;

	if (options.decimals === undefined || options.decimals === null) {
		if (currency === 'USD') {
			decimals = value > 1 ? 2 : 8;
		} else if (['KMG', 'KMS'].includes(currency)) {
			decimals = 3;
		}
	}

	const decimalAmount = decimals + (round ? 0 : 1);
	const numStrWithCommas = value.toLocaleString('en-US', {
		minimumFractionDigits: decimalAmount,
		maximumFractionDigits: decimalAmount,
	});

	// if not rounded, slice off last decimal to trim to correct decimals amount.
	const sliced = numStrWithCommas.slice(
		0,
		round ? numStrWithCommas.length : numStrWithCommas.length - 1
	);

	let trimmedDecimals = showTrailingZeros ? sliced : trimZeros(sliced, minNumbersToShow);

	if (decimals === 0) {
		trimmedDecimals = trimmedDecimals.split('.')[0] || trimmedDecimals;
	}

	if (hideDecimalsIfZeros) {
		trimmedDecimals = trimmedDecimals.replace(/\.0+$/, '');
	}

	return `${currency === 'USD' ? '$' : ''}${trimmedDecimals}`;
}
