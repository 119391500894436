import { useEffect, useState } from 'react';
import { useColorScheme } from 'react-native';

import { IAppearance } from './ITheme';

export function useAppearance(): IAppearance {
	const scheme = useColorScheme();

	const [theme, setTheme] = (useState(
		scheme === 'light'
			? ({
					dark: false,
					statusBar: 'dark-content',
			  } as IAppearance)
			: ({
					dark: true,
					statusBar: 'light-content',
			  } as IAppearance)
	) as unknown) as [IAppearance, any];

	useEffect(() => {
		let appTheme: IAppearance;

		if (scheme === 'light') {
			appTheme = {
				dark: false,
				statusBar: 'dark-content',
			} as IAppearance;
		} else {
			appTheme = {
				dark: true,
				statusBar: 'light-content',
			} as IAppearance;
		}

		setTheme(appTheme);
	}, [scheme]);

	return theme;
}
