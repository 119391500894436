import { UsersScreenLocators } from '@app/e2e/screens/Users';
import { TopHeader } from '@app/shared/components';
import { FormActionType } from '@app/shared/models';
import { AppScreen } from '@itrustcapital/ui';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Card } from 'react-native-paper';

import { UsersScreenProvider, useUsersScreenState } from './UsersScreenProvider';
import { UserFormModal, UsersFilterForm, UsersTable } from './components';

export function UsersScreen() {
	const styles = useCustomStyles();
	const state = useUsersScreenState();

	function onCreate(): void {
		state.usersSelectedRow.set(undefined);

		setTimeout(() => {
			state.userFormModalAction.set(FormActionType.ADD);
			state.userFormModalVisible.set(true);
		}, 1);
	}

	return (
		<AppScreen noMarginBottom noMaxWidth noPadding>
			<UsersScreenProvider value={state}>
				<TopHeader
					exportDisabled
					createName="User"
					createTestID={UsersScreenLocators.createNewButton}
					title="Users"
					onCreate={onCreate}
				/>

				<UserFormModal />

				<Card style={styles.card}>
					<UsersFilterForm />

					<UsersTable />
				</Card>
			</UsersScreenProvider>
		</AppScreen>
	);
}

function useCustomStyles() {
	return StyleSheet.create({
		card: {
			borderRadius: 12,
			padding: 12,
			margin: 32,
		},
	});
}
